@import "../../../styles/theme";

.finance-export {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  &__input {
    width: 285px;
    @media screen and (max-width: 767px) {
      width: 100%; } }
  &__select {
    width: 285px;
    margin-right: 10px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-right: 0; }
    & .ui.selection.dropdown {
      height: 36px; }
    &_uploadType {
      width: 400px; } }
  &_overflow {
    overflow: auto; }
  & .ui.form > .field {
    margin: 0 10px 15px 0; }
  &__download.ui.button {
      background: $primary-10;
      border: 1px solid $primary-10;
      box-sizing: border-box;
      border-radius: 7px;
      color: $primary-100;
      height: 32px;
      font-size: 12px;
      &:hover {
        background: $primary-10;
        color: $primary-100; }
      &:focus {
        background: $primary-10;
        color: $primary-100; } }
  &__filter {
    align-items: flex-end;
    flex-shrink: 0;
    display: flex; }

  &__logo {
    margin-right: 16px;
    &_empty {
      width: 24px;
      height: 24px; } }

  &__unload {
    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
      width: 100%; } }

  &__logo {
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-top: 24px; }
    &_empty {
      width: 48px;
      height: 48px; } }
  &__primary-header {
    &_mobile {
      margin-left: 62px; } } }
