@import "../../../../styles/theme";
@import "../../../../styles/mixins";

%border {
  border: 5px solid $inert-70; }

.arrival-notice-card-2 {
  &:not(:first-child) {
    margin-top: 20px; }
  &_border {
    @extend %border; }
  &-sub-block {
    &_border {
      @extend %border; } }
  &__divider {
    display: flex;
    height: 1px;
    background-color: rgba(34,36,38,.15);
    margin: 12px 0;
    @include respond-to(md) {
      margin: 16px 0; }
    &_yellow {
      background-color: $inert-70; } } }
