@import '../../styles/theme';
@import '../../styles/mixins';

.login {
  font-family: Montserrat, sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 44px 16px;
    @include respond-to(md) {
      padding: 44px 24px; } } }
