@import "../../styles/mixins";
@import "../../styles/theme";

.time-sheet {
  &__button {
    width: 100%;
    justify-content: center; }

  &__content {
    position: relative; }

  &__confirm-fio {
    word-break: break-all; }

  &__period {
    width: 100%;
    @include respond-to(md) {
      width: 360px; } }

  &__time {
    font-size: 12px;
    line-height: 18px;
    min-width: 74px;
    color: $secondary-75; }

  &__date {
    background: $secondary-5 !important;
    &:focus {
      border: 1px solid $primary-100 !important;
      background: $color-white !important; } }

  &__distance {
    margin-left: 3px;
    font-size: 12px;
    line-height: 18px; }

  &__room {
    display: flex;
    position: relative; }

  & .material-icons {
    cursor: pointer; }

  &__close {
    cursor: pointer;
    margin-left: 20px;
    color: $secondary-40;
    margin-right: 10px; }

  &__info {
    display: flex;
    align-items: center;
    margin-left: 9px;
    color: $primary-100;
    & .material-icons {
      color: $primary-100; }
    & .material-icons:nth-child(1) {
      margin-right: 0; } }

  &__column-work-approve {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-75;
    max-width: 150px; }

  &__column-work-start {
    width: 45%;
    margin-right: 8px;
    display: flex;
    align-items: center; }

  &__column-work-end {
    width: 45%;
    display: flex;
    align-items: center; }

  &__column-work-hours {
    margin-left: 8px;
    min-width: 190px; }

  &__edit {
    cursor: pointer;
    margin-left: 8px;
    color: $secondary-70; }

  &__confirm {
    cursor: pointer;
    margin-left: 8px;
    color: $primary-100; }

  &__delete {
    cursor: pointer;
    margin-left: 8px;
    color: $negative-100; }

  &__row {
    display: flex;
    align-items: center;
    padding: 6px 0;
    min-height: 56px;
    border-bottom: 1px solid $secondary-15;
    justify-content: space-between;
    &_expired {
      @include respond-to(md) {
        margin: 0 -32px;
        padding: 0 32px; }
      @include respond-to(xxl) {
        margin: 0 -48px;
        padding: 0 48px; }
      background-color: $negative-20; } }

  &-control {
    margin-right: 20px; }

  &__header-row {
    padding: 10px 0;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-75; }

  &__header {
    display: flex;
    margin-bottom: 16px;
    width: 100%;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row;
      justify-content: space-between; } }

  &__fio {
    width: 100%;
    @include respond-to(md) {
      width: 360px; } }

  &__time-total-label {
    font-size: 14px;
    line-height: 20px;
    color: $secondary-75; }

  &__time-total {
    width: 25%;
    margin-top: 20px;
    margin-left: 20px; }

  &__time-total-count {
    font-size: 16px;
    line-height: 24px;
    color: $secondary-100;
    font-weight: 600; } }
