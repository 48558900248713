@import "../../../styles/theme";

.order-schedule-time-period {
  align-items: center;
  display: flex;
  &__close {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 6px; }
  &__time {
    color: $secondary-100;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    width: 103px;
    &_empty {
      color: $secondary-45; }
    &-start {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 14px 5px;
      border-right: 1px solid $secondary-15; }
    &-end {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 14px 5px; } } }
