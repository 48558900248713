@import "../../../../styles/mixins";

.all-numbers {
  &__content {
    overflow: auto;
    max-height: calc(100vh - 176px);
    @include respond-to(md) {
      max-height: calc(100vh - 232px); }
    @include respond-to(xxl) {
      max-height: calc(100vh - 346px); } } }
