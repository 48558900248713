@import "../../../styles/theme";
@import "../../../styles/mixins";

.login-header {
  background-color: $color-white;
  border-bottom: 1px solid $open-naimix-black-5;
  padding: 6px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  @include respond-to(md) {
    padding: 8px 24px; }
  @include respond-to(xxl) {
    padding: 20px; }
  &_promoPoisk {
    @include respond-to(xxl) {
      padding: 12px 20px; } }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include respond-to(xxl) {
      width: 1320px; } }

  &__tooltip {
    width: auto!important;
    left: unset!important; }

  &__tooltip-phone {
    margin-right: 8px;
    font-family: Montserrat,serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: $open-naimix-secondary-100; }

  &__tooltip-phone-icon {
    width: 20px;
    height: 20px; }

  &__phone {
    display: flex;
    align-items: center;
    background-color: $open-naimix-black-5;
    padding: 6px;
    border-radius: 50%;
    @include respond-to(md) {
      padding: 10px 16px 10px 12px;
      border-radius: unset; } }

  &__phone-icon {
    cursor: pointer;
    color: $open-naimix-secondary-100;
    width: 20px;
    height: 20px;
    @include respond-to(md) {
      margin-right: 5px; } }

  &__phone-number {
    display: none;
    color: $open-naimix-secondary-100;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    &:hover {
      color: $open-naimix-secondary-100; }
    @include respond-to(md) {
      display: block; } }

  &__link {
    display: flex;
    align-items: center; }

  &__logo {
    background-image: url("../../../images/naimix-logo-mobile.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 44px;
    @include respond-to(md) {
      width: 188px;
      height: 32px;
      background-image: url("../../../images/naimix-logo-with-name.svg"); }
    @include respond-to(xxl) {
      width: 235px;
      height: 40px; }

    &_promoPoisk {
      width: 189px;
      height: 44px;
      background-image: url("../../../images/promopoisk-logo-mobile.svg");
      @include respond-to(xxl) {
        width: 240px;
        height: 56px; } } } }
