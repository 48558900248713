@import "../../../styles/theme";
@import "../../../styles/mixins";

@mixin desktopViewBlock($breakpoint) {
  @include respond-to($breakpoint) {
    flex-direction: column;
    padding: 8px 12px;
    border: 1px solid $secondary-10;
    border-radius: 8px;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%; } }

@mixin desktopViewTitle($breakpoint) {
  @include respond-to($breakpoint) {
    width: 100%;
    white-space: unset;
    margin-bottom: 6px;
    margin-right: 0; } }

@mixin desktopViewSubTitle($breakpoint) {
  @include respond-to($breakpoint) {
    margin-bottom: 6px;
    margin-right: 0; } }

@mixin desktopViewIcon($breakpoint) {
  @include respond-to($breakpoint) {
    margin-left: 0; } }

@mixin desktopViewSubValue($breakpoint) {
  @include respond-to($breakpoint) {
    margin-top: 0; } }

@mixin desktopViewValue($breakpoint) {
  @include respond-to($breakpoint) {
    width: 100%;
    margin-right: 0; } }

.nm-info-card {
  $block: &;

  display: flex;
  position: relative;
  flex-wrap: wrap;
  &_alignItems-baseline {
    align-items: baseline; }
  &_alignItems-center {
    align-items: center; }
  &_desktopViewFrom-xxl {
    @include desktopViewBlock(xxl); }
  &_desktopViewFrom-xl {
    @include desktopViewBlock(xl); }
  &_desktopViewFrom-md {
    @include desktopViewBlock(md); }

  &__icon {
    display: flex;
    align-items: center; }

  &__icons-group {
    display: flex;
    margin-left: 8px;
    & #{$block}__icon {
      @include respond-to(xl) {
        margin-left: 0; }
      &:not(:first-child) {
        margin-left: 6px;
        align-items: center; } }
    &_desktopViewFrom-xxl #{$block}__icon {
      @include desktopViewIcon(xxl); }
    &_desktopViewFrom-xl #{$block}__icon {
      @include desktopViewIcon(xl); }
    &_desktopViewFrom-md #{$block}__icon {
      @include desktopViewIcon(md); } }

  &__title {
    font-size: 12px;
    line-height: 18px;
    font-weight: normal;
    color: $secondary-70;
    margin-right: 6px;
    justify-content: space-between;
    display: flex;
    align-items: flex-start;
    &_desktopViewFrom-xxl {
      @include desktopViewTitle(xxl); }
    &_desktopViewFrom-xl {
      @include desktopViewTitle(xl); }
    &_desktopViewFrom-md {
      @include desktopViewTitle(md); } }

  &__sub-title {
    margin-right: 6px;
    &_desktopViewFrom-xxl {
      @include desktopViewSubTitle(xxl); }
    &_desktopViewFrom-xl {
      @include desktopViewSubTitle(xl); }
    &_desktopViewFrom-md {
      @include desktopViewSubTitle(md); } }

  &__value {
    display: flex;
    margin-right: 6px;
    max-width: 100%;
    position: relative;
    min-height: 20px;
    @include respond-to(md) {
      min-height: 24px; }
    &_desktopViewFrom-xxl {
      @include desktopViewValue(xxl); }
    &_desktopViewFrom-xl {
      @include desktopViewValue(xl); }
    &_desktopViewFrom-md {
      @include desktopViewValue(md); }
    &_wrapValue {
      flex-wrap: wrap; } }

  &__sub-value {
    color: $secondary-45;
    &_desktopViewFrom-xxl {
      @include desktopViewSubValue(xxl); }
    &_desktopViewFrom-xl {
      @include desktopViewSubValue(xl); }
    &_desktopViewFrom-md {
      @include desktopViewSubValue(md); } }

  &__divider {
    margin: 12px 0;
    background-color: $secondary-5;
    height: 1px;
    width: 100%; }

  &__labels {
    width: 100%;
    & #{$block}__label-text:not(:last-child) {
      margin-bottom: 6px; } }

  &__label-text {
    width: 100%; }

  &__text {
    &_multiple {
      display: flex;
      &:not(:first-child):before {
        display: block;
        color: $secondary-90;
        content: "/";
        margin: 0 4px; } } }

  &__loader {
    &.ui.loader {
      left: 8px;
      z-index: 900; } } }
