@import "../../../../../styles/mixins";

.order-edit-form-pre-conditions {
  &__template-name {
    white-space: nowrap; }
  &__responsible-phone {
    display: flex;
    align-items: center;
    @include respond-to(md) {
      min-height: 48px; } } }
