.device-log {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  &__pagination {
    margin-top: 24px; }
  &__table {
    flex-grow: 1;
    overflow-y: auto; } }
