@import "../../styles/theme";
@import "../../styles/mixins";

.nmx-pag {
  & .material-icons {
    color: $secondary-45;
    font-size: 18px; }
  &.ui.pagination.menu {
    border: none;
    box-shadow: none;
    display: flex;
    padding: 2px;
    align-items: center;
    border-radius: 7px;
    background-color: $secondary-5;
    height: 36px;
    max-height: 36px;
    min-height: 36px;
    width: 100%;
    @include respond-to(md) {
      width: auto;
      height: auto;
      max-height: 36px;
      min-height: auto; }

    & .item {
      width: auto;
      height: auto;
      margin: 0;
      color: $primary-100 !important;
      font-size: 12px;
      border-radius: 7px;
      min-width: unset;
      padding: 5px 8px!important;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      @include respond-to(md) {
        padding: 7px 13px !important; }
      &:focus {
        outline: none; }
      &:before {
        width: 0; }
      &:last-child {
        border-radius: 7px;
        padding: 2px !important;
        @include respond-to(md) {
          padding: 7px!important; } }
      &:first-child {
        padding: 2px !important;
        @include respond-to(md) {
          padding: 7px!important; } } }

    & .active.item {
      color: #000 !important;
      border-radius: 7px;
      background-color: #fff; } } }
