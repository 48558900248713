@import "../../../styles/theme";

.nm-item-status {
  padding: 6px 9px;
  border-radius: 7px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  &_success {
    color: $color-white;
    background-color: $primary-100; }
  &_part-success {
    color: $primary-100;
    background-color: $primary-10; }
  &_progress {
    color: $secondary-70;
    background-color: $color-white;
    border: 1px solid $secondary-15; }
  &_waiting {
    color: $secondary-70;
    background-color: $secondary-5; }
  &_error {
    color: $color-white;
    background-color: $negative-100; }

  &__info {
    margin-left: 4px;
    position: relative;
    &-tooltip {
      position: absolute;
      right: 0px; }
    &-icon {
      color: $color-white; } } }

