.receipt-duplicate-list {
  width: 450px;
  &__header-title {
    font-size: 18px;
    line-height: 21px; }
  &__apply-buttons {
    float: right; }
  &__checkbox-group {
    margin-top: 10px; }
  &__row {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-top: 20px; } }
  &__requisites {
    margin-left: 40px;
    &-item {
      font-size: 12px; } }
  &__receipt {
    margin-left: 10px; } }
