@import "../../styles/theme";
@import "../../styles/mixins";

.scan-solution {
  display: flex;
  width: 100%;
  min-height: 166px;
  border: 1px solid $secondary-10;
  border-radius: 8px;
  padding: 16px;
  @include respond-to(md) {
    padding: 12px 24px; }
  @include respond-to(xxl) {
    padding: 24px; }

  &:not(:first-child) {
    margin-top: 16px; }
  flex-direction: column;
  @include respond-to(xxl) {
    flex-direction: row; }
  &__solution-block {
    width: 100%; }
  &__row {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 12px;
    @include respond-to(md) {
      gap: unset;
      flex-wrap: unset; } }
  &__title {
    margin-right: 106px;
    margin-bottom: 16px;
    @include respond-to(xxl) {
      margin-bottom: 0; } }
  &__info {
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    white-space: pre-line;
    color: $secondary-45;
    margin-top: 16px;
    &_error {
      color: $negative-100; } } }
