@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.chat-client-area {
  background: #EEEEEE;
  width: 100%;

  &-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $secondary-10;
    padding: 24px 16px 20px 16px;
    @include respond-to(md) {
      padding: 24px 48px 20px 24px; }

    &__content {
      display: flex;
      flex-direction: column;
      margin-right: auto; }

    &__sub-text {
      color: $secondary-70; } }
  &__messages {
    max-height: 100%;
    overflow: auto;
    flex-grow: 1;
    padding: 38px 24px 24px 24px;
    @include respond-to(md) {
      padding: 38px 48px 24px 24px; } }
  &__message {
    &:not(:last-child) {
      margin-bottom: 8px; } }
  &__empty-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
  &__date-divider {
    display: flex;
    justify-content: center;
    padding-bottom: 16px;
    &-value {
      margin: 0 17px;
      width: auto;
      white-space: nowrap; }
    & hr {
      width: 100%;
      border-top: 1px solid $secondary-10; } } }
