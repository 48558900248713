@import "../../../styles/theme";

.coords-input {
  position: relative;
  width: 100%;
  &__icon {
    color: $secondary-45;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 6px; }
  &__coords input {
    padding-left: 40px; } }
