@import "../../../styles/theme";

.marker-list {
  padding: 2px 0;
  margin-top: 0;
  padding-inline-start: 24px;
  margin-bottom: 0;

  &__item {
    &:not(:last-child) {
      margin-bottom: 16px; } }

  &__link {
    color: $passive-100; } }
