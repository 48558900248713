@import "../../styles/mixins";

.system-transactions {
  &__tooltip {
    width: 320px!important;
    max-width: 320px!important;
    left: auto!important; }
  &__tax-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include respond-to(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center; } } }
