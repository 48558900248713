@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.vertical-moving-list-draggable {
  width: 100%;

  &__item-container {
    position: relative;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $secondary-5;
    padding: 48px 12px 12px 12px;
    @include respond-to(md) {
      padding: 16px; } }

  &__item-name {
    flex-grow: 1; }

  &__central-block {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    @include respond-to(md) {
      margin-left: 16px; } }

  &__icon-container {
    min-width: 24px;
    margin-left: 16px; }

  &__drag-button {
    position: absolute;
    top: 12px;
    left: 12px;
    @include respond-to(md) {
      position: unset; } }

  &__right-block {
    position: absolute;
    top: 12px;
    right: 12px;
    @include respond-to(md) {
      position: unset; } }

  &__delete-icon {
    cursor: pointer; } }
