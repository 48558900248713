.projects-list {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  & .nmx-list_table .ui.grid .row .column {
    &:nth-child(1) {
      width: 35%; }
    &:nth-child(2) {
      width: 12%;
      min-width: 180px;
      @media screen and (max-width: 1500px) {
        min-width: 125px; } }
    &:nth-child(3) {
      width: 26%; }
    &:nth-child(4) {
      width: 15%; }
    &:nth-child(5) {
      width: unset;
      flex-grow: 1; } }
  &__info {
    flex-grow: 1;
    width: 100%;
    justify-content: flex-end;
    @media screen and (max-width: 1300px) {
      margin-bottom: 10px; } }
  &__row {
    @media screen and (max-width: 1300px) {
      flex-direction: column-reverse; } }
  &__button {
    margin-right: 16px; }
  &-amount {
    display: flex;
    align-items: center;
    &__icon {
      font-size: 18px !important;
      color: #C4C4C4 !important;
      cursor: pointer; }
    &__value {
      margin-right: 35px;
      @media screen and (max-width: 1500px) {
        margin-right: 5px; } } }
  &__status {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px; }
  &__object-count {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #7B7B7B; }
  &__modal {
    width: 670px; } }


