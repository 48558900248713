@import "../../../styles/mixins";

.registry-header-with-actions {
  display: flex;
  align-items: center;

  &_contextMenu {
    justify-content: space-between; }

  &__title {
    margin-right: 48px; }

  &__buttons {
    display: flex;
    align-items: center;
    flex-grow: 1;

    &_contextMenu {
      flex-grow: unset; } }

  &__button:not(:last-child) {
    margin-right: 16px;
    @include respond-to(xxl) {
      margin-right: 32px; } } }
