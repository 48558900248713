@import "../../styles/theme";

.nm-sort {
  display: flex;
  width: max-content;
  position: relative;

  &__text {
    color: $secondary-100;
    margin-right: 8px; }

  &__active-sort {
    margin-left: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__icon-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden; }

  &__icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    &_asc {
      transform: rotate(180deg); } }

  &__context-menu-content {
    &:before {
      display: none; } } }
