@import "../../styles/mixins";

.button-with-tooltip {
  display: flex;
  align-items: center;
  &__tlp {
    margin-left: 8px;
    width: 24px;
    height: 24px;
    @include respond-to(md) {
      margin-left: 16px; }
    &_filter {
      margin-left: 8px; } } }
