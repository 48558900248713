@import "../../../styles/theme";

.help-tooltip {
  display: flex;
  align-items: center;
  &_defaultSize {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px; }

  &__tooltip {
    &_bottomLeft {
      left: -12px; }
    &_bottomRight {
      right: -12px; }
    &_topRight {
      right: -12px; }
    &_topLeft {
      left: -12px; } }

  &__icon {
    cursor: pointer;
    color: $secondary-40;
    &_attention {
      color: $inert-100; }
    &_inheritColor {
      color: inherit; }
    &_type {
      &-light {
        color: $secondary-40; }
      &-dark {
        color: $color-white;
        background-color: $secondary-80; }
      &-warning {
        //background-color: $inert-100
        color: $inert-100; }
      &-error {
        color: $negative-100; } } } }
