@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-horizontal-toggle-v2 {
  $block: &;
  display: flex;
  align-items: center;
  position: relative;

  &__switcher {
    &_marginLeft {
      margin-left: 8px; }
    &_marginRight {
      margin-right: 8px; } }
  &__helper {
    margin-left: 5px; }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-45;
    @include respond-to(md) {
      font-size: 16px;
      line-height: 24px; }
    &_noWrap {
      white-space: nowrap; }

    &_active {
      color: $primary-100;
      &#{$block}__label_disabled {
        color: $color-green-3; } }

    &_disabled {
      color: $secondary-15; } }

  &__tooltip {
    margin-left: 8px; }

  &__popup {
    left: -157px;
    top: 30px; } }
