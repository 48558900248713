@import "../../styles/theme";
@import "../../styles/mixins";

.nm-page-header {
  display: flex;
  font-style: normal;
  align-items: center;
  position: relative;
  &_noWrap {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  &__tooltip {
    margin-left: 8px; }
  &__tooltip-icon {
    width: 20px;
    height: 20px;
    @include respond-to(md) {
      width: 24px;
      height: 24px; } }
  &__media-controls {
    margin-left: 16px; }
  &__status {
    margin-left: 12px; }
  &__title {
    width: 100%;
    &_hidden {
      overflow: hidden; } }
  &__button-back {
    margin-right: 8px;
    @include respond-to(md) {
      margin-right: 12px; } }
  &__loading {
    z-index: 1;
    align-items: center;
    margin-left: 10px;
    display: none;
    @include respond-to(md) {
      display: flex; }
    &-loader.ui.loader.active.tiny {
      margin-right: 5px; } }

  &__loader {
    display: flex;
    align-items: center;
    &.ui.loader {
      z-index: 990; } }
  &__addingTitle {
    margin-left: 8px; } }
