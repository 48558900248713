@import "../../../../styles/mixins";

.recruitment-candidates-comments {
  &__files-container {
    gap: 12px;
    @include respond-to(xl) {
      gap: 24px; } }
  &__image {
    background: url("../../../../images/aphrodite.png");
    width: 24px;
    height: 24px; } }
