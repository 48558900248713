@import "../../../../styles/theme";

.bank-indicator-refresh {
  margin-left: 4px;
  &__container {
    display: flex;
    align-items: center; }
  &__tooltip {
    left: -14px; }
  &__button {
    color: $secondary-70;
    width: 18px;
    height: 18px; } }
