@import "../../styles/theme";
@import "../../styles/mixins";

.specialty-offer {
    $block: &;

    &__primary-header-value {
      width: 100%;
      @include respond-to(md) {
        padding-right: 172px; }
      @include respond-to(xxl) {
        padding-right: 0; } } }

