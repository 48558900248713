@import '../../../../styles/theme';
@import '../../../../styles/mixins';

.registry-new-popup {
  &__radio-container {
    display: flex;
    align-items: center;
    position: relative; }
  &__radio-auto {
    margin-bottom: 8px;
    @include respond-to(md) {
      margin-bottom: 0; } }
  &__radio {
    @include respond-to(md) {
      margin-left: 16px; } }
  &__row {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row;
      align-items: center; } }
  &__tooltip {
    margin-left: 5px; } }
