@import "../../../styles/theme";
@import "../../../styles/mixins";

.client-finance-upload {
  height: unset;

  &__download.ui.button {
    @media screen and (max-width: 1279px) {
      padding: 6px 8px;
      font-size: .85em; } }

  &__title {
    margin-right: 10px;
    white-space: nowrap; }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    &-item {
      &:not(:last-child) {
        margin-right: 10px; } } }

  &__column {
    width: 20%;
    @media screen and (min-width: 1152px) and (max-width: 1599px) {
      width: 140px; }
    @media screen and (max-width: 1151px) {
      width: 33%; } }

  &__table {
    flex-grow: 1; }

  &__select {
    width: 420px;
    @media screen and (max-width: 767px) {
      width: 100%; } }

  &__datepicker {
    width: 300px;
    @media screen and (max-width: 767px) {
      width: 100%; } }

  &__start-button {
    margin-top: 24px;
    @media screen and (max-width: 767px) {
      margin: 4px 0 8px 0;
      width: 100%; } }

  &__download-link {
    background: $color-white;
    color: $primary-100;
    text-decoration-line: underline;
    padding: 0; }

  & .nm-list-card__secondary-header {
    margin-bottom: 16px; }

  &__file-link {
    font-size: 16px;
    font-style: normal;
    line-height: 23px;
    margin-right: 25px;
    color: $primary-100;
    cursor: pointer;
    border-bottom: 1px dotted #128A10;
    white-space: nowrap;
    &:hover {
      opacity: 0.7;
      color: $color-black; } }

  &__handlers-action {
    padding: 0; } }
