@import "../../../styles/theme";
@import "../../../styles/mixins";

.checkbox-accordion-list {
  &__header {
    display: flex;
    align-items: center; }
  &__name {
    flex-grow: 1;
    margin-left: 6px;
    &_cropped {
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden; } }

  &__chevron {
    cursor: pointer;
    width: 24px;
    height: 24px;
    min-width: 24px;
    &_open {
      transform: rotate(180deg); } }
  &__content {
    margin-top: 6px; }
  &__content-container {
    width: 100%;
    display: flex;
    flex-direction: column; }
  &__row {
    color: $secondary-70;
    &:not(:last-child) {
      margin-bottom: 8px;
      @include respond-to(md) {
        margin-bottom: 16px; } }
    &_active {
      color: $secondary-100; }
    &_positionRelative {
      position: relative; } }
  &__tag-select {
    margin-bottom: 16px; } }
