@import "../../../styles/theme";

.promocode-channel-new {
  $block: &;
  & .ui.form .field {
    margin-bottom: 15px; }
  &__period {
    width: 280px; }
  &__type-use {
    display: flex;
    margin: 22px 0; }
  &__container {
    & #{$block}__row {
      margin-bottom: 16px; } }
  &__sub-title {
    font-size: 16px;
    line-height: 19px;
    font-weight: 500;
    margin-top: 36px;
    margin-bottom: 22px;
    color: $color-black; }
  &__field {
    margin-bottom: 0 !important;
    margin-right: 7px !important;
    &_mini {
      width: 18%; }
    & .ui.radio.checkbox {
      & input:checked ~ label:before {
        border-color: $primary-100; }
      & label {
        font-size: 14px;
        line-height: 19px;
        color: $secondary-100;
        margin-bottom: 0;
        &:before {
          width: 18px;
          height: 18px;
          top: 0; }
        &:after {
          width: 18px;
          height: 18px;
          top: 0; } } } }
  &__client {
    width: 100%; }
  &__requisites {
    width: 190px; }
  &__row {
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    & .nm-form-dropdown {
      width: 100%; } }
  &__label {
    width: 200px;
    padding-top: 5px;
    margin-right: 10px;
    color: $secondary-70;
    font-size: 14px;
    line-height: 16px;
    &:after {
      content: ":"; } }
  &__tooltip-popup {
    left: -159px;
    top: 30px; }
  &__tooltip {
    color: $secondary-40;
    &:not(:last-child) {
      margin-right: 34px; } }
  &__tooltip-icon {
    cursor: pointer;
    position: relative;
    width: 17px;
    height: 17px;
    margin-right: 0; }
  &__one-time {
    margin-right: 26px!important; } }
