@import "../../../../../styles/theme";

.dropzone-uploaded-image-preview {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: relative;
  &:not(:last-child) {
    margin-right: 8px; }
  &_mt {
    margin-top: 8px; }
  &_error {
    border: 1px solid $negative-100; }
  &_topImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-radius: 8px;
    padding: 16px;
    height: max-content;
    border: 1px solid $secondary-15;
    margin-bottom: 16px; }

  &__container {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }

  &__loader {
    &_type-small.ui.inverted.dimmer {
      width: 74px;
      height: 74px;
      margin: 16px; }
    &_type-full.ui.inverted.dimmer {
      background-color: transparent; } }

  &__action {
    color: $secondary-45;
    cursor: pointer;
    &_topImage {
      &:not(:first-child) {
        margin-left: 8px; } } }

  &__image {
    cursor: zoom-in;
    max-width: 100%;
    max-height: 100%;
    &_full {
      cursor: zoom-out;
      max-height: 100% !important; }
    &_topImage {
      max-height: 300px;
      max-width: 70%;
      z-index: 19; } }

  &__controls {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    background-color: $color-white;
    &_topImage {
      margin: 12px;
      left: 0;
      top: 0; } } }
