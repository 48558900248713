@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-radio-v2 {
  $block: &;
  // в макетах иконка чекбокса взята в контейнере с размерами 24х24
  min-height: 24px;
  display: flex;
  align-items: center;

  & label {
    margin: 0; }
  &__control {
    display: flex!important;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    opacity: 1!important;
    &:before {
      content: '';
      display: block;
      width: 19px;
      height: 19px;
      margin: 2.5px;
      min-width: 19px;
      border: 2px solid $secondary-75;
      border-radius: 100%;
      cursor: pointer; }
    &:hover:before {
      border-color: $primary-100; }

    &_disabled {
      cursor: initial;
      &#{$block}__control {
        &:before {
          border-color: $secondary-15;
          cursor: initial; }

        &_checked:before {
          background-image: radial-gradient(circle closest-corner, $secondary-15 5px, transparent 6px); }

        & > #{$block}__label {
          color: $color-grey-8; } } }

    &_checked {
      &:before {
        background-image: radial-gradient(circle closest-corner, $primary-100 5px, transparent 6px);
        background-size: cover;
        border-color: $primary-100; }

      & > #{$block}__label {
        color: $secondary-100; }

      &#{$block}_disabled {
        &:before {
          background-image: radial-gradient(circle closest-corner, $secondary-15 5px, transparent 6px); }

        & > #{$block}__label {
          color: $secondary-70; } } } }

  &__radio {
    display: none; }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70;
    margin-left: 6px;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } }
  &__additional-label-content {
    display: flex; } }
