@import "../../styles/theme";

.range-step-input {
  margin-bottom: 8px;

  input[type=range] {
    -webkit-appearance: none;
    background: $secondary-5;
    height: 4px;
    --range: calc(var(--max) - var(--min));
    --ratio: calc((var(--value) - var(--min)) / var(--range));
    --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em)); }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: $primary-100;
    width: 19px;
    height: 19px;
    border-radius: 10px;
    border: 1px solid $color-white;
    margin-top: -7px;
    cursor: pointer; }

  input[type=range]::-webkit-slider-runnable-track {
    background: linear-gradient($primary-80, $primary-80) 0/var(--sx) 100% no-repeat, #efefef;
    height: 4px;
    border-radius: 2px;
    cursor: pointer; } }
