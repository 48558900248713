@import "../../styles/mixins";

.not-found {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: $font-family-open-naimix;
  &__main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background-color: $open-naimix-black-5;
    width: 100%;
    max-width: 1320px;
    padding: 32px 16px;
    @include respond-to(md) {
      padding: 64px 48px; }
    @include respond-to(xxl) {
      padding: 120px 224px 128px 224px; } }
  &__content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    padding: 16px 16px 40px 16px;
    @include respond-to(md) {
      padding: 24px 24px 64px 24px; }
    @include respond-to(xxl) {
      padding: 40px 0; } }
  &__status {
    position: relative;
    font-weight: 800;
    font-size: 64px;
    line-height: 78px;
    margin-bottom: 16px;
    color: $open-naimix-black-50;
    @include respond-to(md) {
      margin-bottom: 24px;
      font-size: 96px;
      line-height: 117px; }
    @include respond-to(xxl) {
      margin-bottom: 10px;
      font-size: 144px;
      line-height: 176px; } }
  &__value {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 16px;
    line-height: 30px;
    text-align: center;
    color: $open-naimix-black-100;
    @include respond-to(md) {
      margin-bottom: 24px;
      font-size: 28px;
      line-height: 36px; }
    @include respond-to(xxl) {
      font-size: 36px;
      line-height: 44px; } }
  &__sub-value {
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 20px;
    color: $open-naimix-black-100;
    @include respond-to(md) {
      margin-bottom: 32px;
      font-size: 16px;
      line-height: 24px; }
    @include respond-to(xxl) {
      margin-bottom: 48px;
      font-size: 16px;
      line-height: 24px; } } }
