@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-textarea-v2 {
  $block: &;

  font-family: "Roboto";
  width: 100%;
  position: relative;
  &_fluidHeight {
    flex-grow: 1;
    height: 100%;
    & #{$block}__container {
      height: 100%; } }
  &_error {
    & #{$block}__control {
      border: 1px solid $negative-100;
      background-color: $negative-15;
      &:focus {
        border: 1px solid $negative-100;
        background-color: $color-white; } } }
  &_disabled {
    & #{$block}__control {
      background-color: $secondary-5;
      color: $secondary-40;
      border: 1px solid $secondary-5;
      &:hover {
        border: 1px solid $secondary-5; }
      &::placeholder {
        color: $secondary-15; } }
    & #{$block}__counter {
      color: $secondary-15; } }
  &__control {
    resize: none!important;
    width: 100%;
    color: $secondary-90;
    background-color: $secondary-5;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 14px 12px;
    min-height: 40px;
    @include respond-to(md) {
      padding: 14px 16px;
      min-height: 48px; }
    &:hover {
      border-color: $primary-100; }
    &::placeholder {
      color: $secondary-45;
      text-overflow: ellipsis; }
    &:focus {
      outline: none!important;
      border: 1px solid $primary-100;
      background-color: $color-white; }
    &_active {
      background-color: $color-white;
      border: 1px solid $secondary-70; } }
  &__suggestion {
    display: none; }
  &__container {
    display: flex;
    position: relative;
    &:hover {
      & #{$block}__suggestion {
        display: block; } } }
  &__counter {
    position: absolute;
    right: 4px;
    font-size: 11px;
    line-height: 13px;
    color: $secondary-40;
    bottom: 2px;
    @include respond-to(xxl) {
      right: 4px;
      bottom: 4px; }
    &_right {
      right: 13px; }
    &_background-white {
      background: $color-white; } } }
