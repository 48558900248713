@import "../../styles/theme";

.registry-card-button {
  display: flex;
  color: $primary-100;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  &__icon {
    margin-right: 8px; }
  &_disabled {
    cursor: default;
    color: $secondary-45; } }
