@import "../../../styles/theme";
@import "../../../styles/mixins";

.orders {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  &__sub-header {
    display: flex;
    width: 100%;
    flex-direction: column; }
  &__divider {
    margin: 16px 0;
    display: none;
    @include respond-to(xxl) {
      display: block; } }
  &__content-container {
    width: 100%; }
  &__header {
    display: flex;
    align-items: center; }
  &__selected {
    width: 100%; }
  &_padded {
    padding: 25px; }
  &_project-card-style {
    padding: 25px 0 0 0; }
  &__grid {
    @media screen and (max-width: 1400px) {
      width: 1400px;
      overflow: auto; } }
  &__header {
    flex-shrink: 0;
    margin-bottom: 12px; }
  &__btn {
    border-radius: 7px!important;
    max-height: 39px;
    &_add-mg-left.ui.button {
      margin-left: 16px;
      @media screen and (max-width: 767px) {
        margin-left: 8px; } }
    &_green {
      color: #fff !important;
      background: $grad-primary !important; } }
  &__archive {
    margin-left: 10px!important; }
  &__content {
    position: relative;
    flex-grow: 1;
    display: flex;
    min-height: 150px;
    flex-direction: column;
    &-dimmer {
      z-index: 1111; } }
  &__loader {
    margin-left: 20px;
    min-height: 34px; }
  &__counts {
    cursor: pointer;
    background: #E9F4E5;
    color: #41A140;
    line-height: 16px;
    font-size: 14px;
    border: 1px solid #D8EAD0;
    border-radius: 7px;
    display: inline-flex;
    padding: 8px 20px;
    margin-left: 32px; }
  &__payout {
    color: #333; }
  &__message {
    font-size: 16px; }
  &__history {
    background: url("../../../images/foreman-history-icon.svg") no-repeat;
    cursor: pointer;
    width: 27px;
    opacity: 0.5;
    filter: grayscale(100%);
    height: 24px;
    @media screen and (max-width: 1280px) {
      width: 100%;
      background-position-x: right; }
    &:hover {
      opacity: 1;
      filter: none; } }
  &__header-actions {
    & button {
      margin-right: 0!important;
      margin-left: 10px!important; } }
  &__table {
    @media screen and (max-width: 1400px) {
      overflow: auto; }
    & .ui.grid {
      &:not(.grid-checkbox) .row .column {
        &:nth-child(1) {
          width: 5%; }
        &:nth-child(2) {
          width: 7%; }
        &:nth-child(3) {
          width: 10%; }
        &:nth-child(4) {
          width: 10%; }
        &:nth-child(5) {
          width: 10%; }
        &:nth-child(6) {
          width: 10%; }
        &:nth-child(7) {
          width: unset;
          flex-grow: 1; } }
      & .row .column {
        &:nth-child(1) {
          width: 3%; }
        &:nth-child(2) {
          width: 7%; }
        &:nth-child(3) {
          width: 10%; }
        &:nth-child(4) {
          width: 10%; }
        &:nth-child(5) {
          width: 10%; }
        &:nth-child(6) {
          width: 10%; }
        &:nth-child(7) {
          width: 10%; }
        &:nth-child(8) {
          width: unset;
          flex-grow: 1; } } }
    & .ui.table thead th:nth-child(8) {
      text-align: center; } }
  &__disputes-icon {
    background: url("../../../images/disputes.svg") no-repeat center;
    width: 16px;
    height: 16px; }
  &__icon {
    &_fixed-width {
      width: 30px;
      text-align: center; }
    &-close {
      font-size: 28px; }
    & .material-icons {
      color: #B6BAC3;
      cursor: pointer;
      &:hover {
        color: $primary-100; } } }
  &__warning-dialog-layer {
    z-index: 1100!important; }
  &__group {
    margin-bottom: 34px;
    &-name {
      margin-bottom: 2px;
      word-break: break-all; }
    &-description {
      word-break: break-all;
      margin-bottom: 2px; } } }
