@import "../../styles/theme";

.nm-react-code-input {
  $block: &;

  display: flex;
  flex-direction: column;

  &__input {
    & input {
      font-family: Roboto, serif;
      font-size: 14px;
      border-top: 1px solid $secondary-15;
      border-left: 1px solid $secondary-15;
      border-bottom: 1px solid $secondary-15;
      border-right: none;
      &:last-child {
        border-right: 1px solid $secondary-15; } }
    & input + input {
      border-left: 1px solid $secondary-15; }
    &_focused {
      & input {
        background-color: $color-white!important;
        border-color: $primary-100!important; } }
    & input {
      background-color: $secondary-5; }
    & input:focus + input {
      border-left: 1px solid $primary-100; }
    & input:focus {
      outline: none;
      border-top: 1px solid $primary-100;
      border-left: 1px solid $primary-100;
      border-bottom: 1px solid $primary-100;
      caret-color: $secondary-100;
      border-right: none;
      &:last-child {
        border: 1px solid $primary-100; } }
    &:hover {
      & input {
        border: 1px solid $primary-100;
        border-right: none;
        &:last-child {
          border-right: 1px solid $primary-100; } } }
    &_active {
      & input {
        background-color: $color-white;
        border-color: $secondary-70;
        & + input {
          border-left: 1px solid $secondary-70; }
        &:last-child {
          border-right: 1px solid $secondary-70; } } }
    &_error {
      & input {
        background-color: $negative-15;
        border-color: $negative-100;
        & + input {
          border-left: 1px solid $negative-100; }
        &:last-child {
          border-right: 1px solid $negative-100; } } } } }
