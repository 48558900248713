@import "../../../styles/theme";

.input-with-dropdown-v2 {
  $block: &;
  display: flex;
  flex-direction: column;
  width: 100%;
  &__divider {
    z-index: 1;
    &_disabled {
      min-width: 1px;
      width: 1px;
      background-color: $color-white;
      margin: 1px 0;
      & #{$block}__size_xl {
        height: 46px; }
      & #{$block}__size_lg {
        height: 37px; } } }

  &__dropdown {
    &_active {
      & .nm-dropdown-v2__content {
        background-color: $color-white;
        border-color: $secondary-70; } }

    &_disabled {
      & .nm-dropdown-v2__content {
        background-color: $secondary-5!important; } } }

  &__suggestion {
    display: none; }

  &__inputs {
    width: 100%;
    position: relative;
    display: flex;
    & .nm-dropdown-v2__content {
      border: 1px solid #E0E0E0; }
    &:hover {
      & #{$block}__suggestion {
        display: block; }
      & .nm-input-v2 input {
        border-color: $primary-100; }
      & .nm-dropdown-v2__content {
        border-color: $primary-100; } }
    & .nm-input-v2 input {
      border-right: none;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      &:focus {
        z-index: 50;
        border-right: none;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0; } }
    &:not(#{$block}__inputs_disabled) input {
      border-color: $secondary-15; }

    &_active {
      & .nm-dropdown-v2__content {
        border-color: $secondary-70; }
      & input {
        background-color: $color-white; }
      &#{$block}__inputs {
        & input {
          border-color: $secondary-70; }

        &:not(#{$block}__inputs_error) .nm-dropdown-v2__content {
          background-color: $color-white; } } }

    &_disabled {
      & input:hover {
        border-color: $secondary-5; }
      &#{$block}__inputs {
        & input {
          background-color: $secondary-5;
          border-color: $secondary-5; } }
      & .nm-dropdown-v2__content {
        border-color: $secondary-5;
        background-color: $secondary-5; }
      &:hover {
        & input {
          border-color: $secondary-5!important; }
        & .nm-dropdown-v2__content {
          border-color: $secondary-5!important; } } }

    &_error {
      &#{$block}__inputs {
        & input {
          border-color: $negative-100; } }
      & input {
        background-color: $negative-15; }
      & .nm-dropdown-v2__content {
        background-color: $negative-15;
        border-color: $negative-100; } }

    &_focused {
      &#{$block}__inputs:not(#{$block}__inputs_error) input {
        background-color: $color-white;
        border-color: $primary-100; }
      &:not(#{$block}__inputs_disabled) .nm-dropdown-v2__content {
        background-color: $color-white;
        border-color: $primary-100; }
      &#{$block}__inputs_error#{$block}__inputs {
        & input {
          border-color: $negative-100; }
        & .nm-dropdown-v2__content {
          border-color: $negative-100;
          & .nm-dropdown-v2__list {
            border-color: $negative-100; } } } }

    & .nm-dropdown-v2__content {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0; } } }
