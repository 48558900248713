@import "../../../styles/theme";
@import "../../../styles/mixins";

.contractor-full-registration {
  &__disclaimer {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70; }
  &__disclaimer-required-star {
    color: $negative-50; }
  &__modal-back-icon {
    @include respond-to(md) {
      display: block; } } }
