@import '../../../../../styles/theme';
@import '../../../../../styles/mixins';

.document-management-statement-loading-form {
  $block: &;
  width: 100%;

  &__pdf-viewer {
    margin-top: 8px;
    min-height: calc(100vh - 212px);
    max-height: calc(100vh - 212px);
    @include respond-to(md) {
      margin-top: 16px;
      min-height: calc(100vh - 278px);
      max-height: calc(100vh - 278px); }
    @include respond-to(xxl) {
      min-height: calc(100vh - 394px);
      max-height: calc(100vh - 394px); } }

  &__link-pre-filled-template {
    margin-left: 30px;
    font-size: 12px;
    color: $passive-100;
    cursor: pointer; }

  &__link {
    margin-top: 9px; }

  &__row {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to(md) {
      flex-direction: row; } }

  &__control {
    &:first-child {
      margin-bottom: 12px;
      @include respond-to(md) {
        margin-bottom: 0; } } }

  &__dropdown {
    width: 100%;
    @include respond-to(md) {
      width: calc(50% - 8px); }
    &:first-child {
      margin-right: 0;
      margin-bottom: 12px;
      @include respond-to(md) {
        margin-bottom: 0;
        margin-right: 16px; } } } }
