@import "../../../styles/theme";

.mailings-list-card {
  position: relative;
  width: 100%;
  &__checkbox.ui.checkbox {
    position: absolute;
    left: 0; }
  &__right-column {
    display: flex;
    align-items: flex-end; }
  &__left-column {
    width: 30%; }
  &__box {
    border: 1px solid $secondary-15;
    padding: 14px;
    margin-right: 20px;
    border-radius: 7px;
    min-width: 160px;
    &-title {
      font-size: 12px;
      color: $secondary-70;
      margin-bottom: 5px; }
    &-content {
      font-size: 16px;
      line-height: 19px;
      color: $secondary-90;
      &-category {
        font-size: 16px;
        display: flex;
        flex-direction: row; } }
    &-fact {
      font-weight: 600; }
    &-plan {
      &.multiselect__text {
        padding: 0 !important; } }
    &-period {
      color: $secondary-70; } }
  &__mailing-info {
    height: 70px !important;
    display: flex;
    margin-right: auto;
    padding-left: 70px;
    align-items: center;
    &-files {
      height: 70px !important;
      display: flex;
      padding-left: 13%;
      width: 50px;
      align-items: center; } }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 15px; }
  &__date-begin {
    margin-left: 15px;
    margin-right: 10px;
    font-size: 12px;
    color: $secondary-70;
    line-height: 16px;
    white-space: pre; }
  &__name {
    margin-right: 10px;
    font-size: 12px;
    color: $secondary-70;
    line-height: 16px;
    white-space: pre; }
  &__sender {
    color: $passive-100; }
  &__main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  &__title {
    color: $passive-100;
    cursor: pointer; }
  &__multiselect {
    font-size: 14px;
    word-break: break-word;
    &_w92 {
      width: 92%;
      word-break: keep-all; } }
  .multiselect__dropdown_readonly {
    width: fit-content;
    .multiselect__option {
      word-break: keep-all; } }
  &__file-link {
    cursor: pointer;
    &:not(:last-child) {
      margin-right: 8px; } } }
