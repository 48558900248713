@import "../../styles/theme";
@import "../../styles/mixins";

.contractor-passport-status {
  &__icon {
    &_size-md {
      width: 18px;
      height: 18px;
      min-width: 18px; }
    &_size-lg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      @include respond-to(md) {
        width: 20px;
        height: 20px;
        min-width: 20px; } } } }
