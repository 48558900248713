@import "../../../styles/mixins";

.contractor-order-list {
  &__row {
    & .nm-list-card__secondary-header-value {
      margin-left: unset !important; } }
  &__tabs.ui.attached.menu {
    border: none;
    justify-content: flex-start;
    background-color: transparent;
    position: relative;
    border-radius: 0;
    max-width: max-content;
    & > .item {
      border: none;
      background-color: $primary-10;
      height: 48px;
      padding: 5px 26px 10px 26px;
      &:not(:last-child) {
        margin-right: 1px; }
      &:first-child {
        border-radius: 7px 0 0 7px!important; }
      &:last-child {
        border-radius: 0 7px 7px 0!important; }
      & > .menu-item-name {
        color: $color-black;
        @media screen and (max-width: 1023px) {
          font-size: 14px; } }
      & > .menu-item {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $primary-100;
        & > .menu-item-name {
          &:after {
            min-width: 8px; } } }
      &.active {
        padding: 5px 25px 10px 25px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(63, 155, 20, 0.5);
        &:not(:first-child):not(:last-child) {
          border-radius: 0!important; } } } }
  &__table {
    flex-grow: 1; }
  &_filter {
    &>div {
      &:not(:last-child) {
        margin-right: 15px; } } }
  &-title {
    margin-bottom: 20px; }
  &-select {
    width: 350px; }
  &_header {
    @include flex_row__space-between; }
  &_info {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    padding: 17px 20px 18px 18px; }
  &_elem {
    &:not(:last-child) {
      padding-right: 21px;
      margin-right: 25px;
      border-right: 1px solid #E4E4E4; } }
  &_label {
    font-size: 13px;
    line-height: 15px; }
  &__num {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #999999;
    margin-top: 2px; }
  &__delimiter {
    width: 4px;
    height: 4px;
    border-radius: 10px;
    margin: auto 8px;
    background: $secondary-70; }
  &__logo {
    margin-right: 16px;
    @media screen and (max-width: 767px) {
      margin-top: 24px; }
    &_empty {
      width: 48px;
      height: 48px; } }
  &__primary-header {
    &_mobile {
      margin-left: 62px; } } }
