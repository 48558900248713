@import "../../../styles/theme";
@import "../../../styles/mixins";

.tabs {
  $block: &;

  height: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  @include respond-to(md) {
    height: 48px;
    min-height: 48px; }
  &:not(#{$block}_secondary) .tabs-button {
    width: 24px;
    min-width: 24px;
    height: 24px; }
  &_secondary {
    height: 36px;
    min-height: 36px;
    @include respond-to(md) {
      height: 40px;
      min-height: 40px; }

    & #{$block}__content-container {
      border-bottom: none; } }

  &__content-container {
    display: flex;
    border-bottom: 1px solid $secondary-5;
    &::-webkit-scrollbar {
      display: none; }

    & #{$block}__item-wrapper {
      &:not(:last-child) {
        margin-right: 24px; } }

    &_secondary {
      & #{$block}__item-wrapper {
        &:not(:last-child) {
          margin-right: 8px; } } } } }
