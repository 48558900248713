@import "../../../styles/mixins";

.insurance {
  flex-grow: 1;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  &__pagination {
    margin-top: 24px; }
  &__header {
    display: flex;
    flex-direction: row;
    &-message {
      margin-left: auto; } }
  & .ui.form .field {
    margin: 0; }
  &__history-header {
    margin-bottom: 24px; }
  &__history-message {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #F75E1A; }
  &__history-table {
    flex-grow: 1;
    & .ui.grid .row .column {
      &:nth-child(3) {
        min-width: 120px; }
      &:nth-child(4) {
        min-width: 120px; } } } }
