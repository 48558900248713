@import "../../../styles/theme";

@mixin icon-values {
  width: 18px;
  height: 18px;
  background-size: contain; }
@mixin get-img($svgName) {
  background: url("../../../images/#{$svgName}.svg") no-repeat center;
  @include icon-values; }

.payment-status {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 8px;
    &_error {
      color: $negative-100; }
    &_success {
      color: $primary-100; } }

  &__tooltip {
    margin-left: 4px; }

  &__suggestion-list {
    padding-inline-start: 20px; }

  &__suggestion-item {
    &:not(:last-child) {
      margin-bottom: 10px; } }

  &__status-info {
    margin-left: 5px;
    position: relative; } }

