@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.edm-staff-new-finding-form {
  &__radio-tooltip {
    margin-left: 4px; }
  &__type-block {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } }
  &__type-block-elem {
    display: flex;
    align-items: center;
    margin-right: 16px; }
  &__action {
    width: 100%;
    @include respond-to(md) {
      width: auto; } } }
