@import "../../../../styles/theme";
@import "../../../../styles/mixins";

@mixin containerLgSize() {
  padding: 9px 12px; }

.dropdown-with-input-text-field {
  position: relative;
  width: 100%;
  &__container {
    display: flex;
    &_size-xl {
      @include containerLgSize();
      @include respond-to(md) {
        padding: 13px 16px; } }
    &_size-lg {
      @include containerLgSize(); } }
  &__search {
    padding: 0;
    max-height: 20px;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    &::placeholder {
      color: $secondary-45; }
    &[disabled] {
      &::placeholder {
        color: $secondary-15; } } }

  &__right-content {
    display: flex;
    margin-left: 8px; }
  &__icon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 8px;
    color: $secondary-45; } }
