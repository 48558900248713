@import "../../../styles/theme";
@import "../../../styles/mixins";

.login-title {
  color: $open-naimix-black-100;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  @include respond-to(md) {
    font-size: 28px;
    line-height: 36px; }
  @include respond-to(xxl) {
    font-size: 36px;
    line-height: 44px; }
  &_margin-auto {
    margin-bottom: 16px;
    @include respond-to(md) {
      margin-bottom: 24px; } }
  &_margin-not {
    margin-bottom: 0; }
  &_align-left {
    text-align: left; }
  &_align-center {
    text-align: center; } }
