@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-range-input {
  $block: &;

  &__control {
    position: relative;
    display: flex;
    &:hover {
      #{$block}__start input {
        border-color: $primary-100; }
      #{$block}__end input {
        border-color: $primary-100; } }
    &_active {
      & input {
        background-color: $color-white;
        border-color: $secondary-70; }
      & #{$block}__divider {
        background-color: $secondary-70; } }
    &_focused {
      & input {
        background-color: $color-white;
        border-color: $primary-100; } }
    &_error {
      & #{$block}__divider {
        background-color: $negative-100; }
      & input {
        background-color: $negative-15;
        border-color: $negative-100; } }
    &_disabled {
      & #{$block}__divider {
        background-color: $color-white; }
      &:hover {
        #{$block}__start input {
          border-color: $secondary-5; }
        #{$block}__end input {
          border-color: $secondary-5; } } }
    &_size-lg {
      & #{$block}__divider {
        height: 38px; } }
    &_size-xl {
      & #{$block}__divider {
        height: 38px;
        @include respond-to(md) {
          height: 46px; } } } }


  &__start {
    & input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: none;
      &:focus {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; } } }

  &__end {
    & input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
      &:focus {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } } }

  &__divider {
    position: absolute;
    left: 50%;
    width: 1px;
    z-index: 1;
    bottom: 1px;
    top: 1px;
    background-color: $secondary-15; } }
