@import "../../../styles/theme";
@import "../style";

.joining-request {
  &__content {
    width: 100%;
    @include respond-to(md) {
      width: 372px; }
    @include respond-to(xxl) {
      width: 492px; } }
  &__button {
    margin-top: 16px;
    width: 100%;
    @include respond-to(md) {
      margin-top: 24px;
      width: auto; } } }
