@import "../../../styles/theme";
$dark-blue: #174A97;

.foreman-requests {
  position: relative;
  &__status {
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    width: 105px;
    border: 1px solid;
    border-radius: 7px; }
  &__name {
    color: $dark-blue; }
  &__table {
    & .ui.grid .row .column {
      &:first-child {
        width: 50px;
        padding-right: 0; }
      &:nth-child(2) {
        width: 10%; }
      &:nth-child(3) {
        width: 17%; }
      &:nth-child(4) {
        width: 17%; }
      &:nth-child(5) {
        width: 9%; }
      &:nth-child(6) {
        width: 9%; }
      &:nth-child(7) {
        width: 14%; }
      &:nth-child(8) {
        width: 125px;
        padding-right: 0; }
      &:nth-child(9) {
        width: unset;
        padding-right: 0;
        flex-grow: 1; } } } }
