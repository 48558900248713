@import "../../../styles/theme";
@import "../../../styles/mixins";

.document-management-document-list {
  flex-grow: 1;
  &__card-value-icon {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    @include respond-to(md) {
      width: 24px;
      height: 24px; } }
  &__content-container {
    width: 100%;
    z-index: 1; }

  &-table-row {
    border-top: 1px solid $secondary-15;
    padding: {
      left: 20px; } }

  &-content {
    margin-left: 15px;
    width: 100%;
    display: flex;

    &__left {
      width: 40%;
      margin-right: 10px; }

    &__right {
      width: 60%; }

    &-top {
      display: flex;
      align-items: center;
      margin: {
        bottom: 15px; }

      &__date {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $secondary-70;
        margin: {
          left: 12px; } } }

    &__name {
      font-size: 18px;
      line-height: 18px;
      color: $passive-100;
      margin: {
        bottom: 13px; } }
    &__information {
      word-break: break-word;
      max-width: 554px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $secondary-90;
      margin: {
        bottom: 10px; } }

    &-cards {
      display: flex;
      align-items: flex-end;
      margin: {
        top: 8px; }

      &-card {
        width: 275px;
        margin: {
          right: 20px; }
        &__fullname {
          display: flex;
          align-items: center;
          &-text {
            overflow: hidden;
            text-overflow: ellipsis; } }
        &__icon {
          width: 16px;
          height: 16px; }

        &-bottom {
          display: flex;
          align-items: center;

          &__icon {
            margin-left: 5px; }
          &__icon-blocked {
            margin-left: 8px; } }


        &__icon {
          &_in-progress {
            color: $inert-100; }

          &_on-signing {
            color: $inert-100; }

          &_signed {
            color: $primary-100; }

          &_rejected {
            color: $negative-100; } } } }

    &__author {
      font-weight: 500;
      font-size: 12px;
      line-height: 30px;
      color: $secondary-70;
      &_mr-5 {
        margin: {}
        right: 8px; } }

    &__label {
      font-size: 12px;
      line-height: 14px;
      color: $secondary-70;
      margin: {
        right: 8px; } }


    &__value {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 18px;
      color: $secondary-90; }

    &__item {
      width: 95%;
      &:not(:last-child) {
        margin: {
          bottom: 7px; } } } }


  &__links {
    flex-grow: 1;
    margin-right: 47px;
    min-width: 47%;
    max-width: 47%; }

  &__prompt {
    margin-right: 10px; }

  &__icon {
    height: 32px; }

  &__box {
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 12px 15px;
    margin-right: 47px;
    min-width: 160px;

    &-label {
      color: $secondary-70;
      font-size: 12px;
      margin-bottom: 5px; }

    &-value {
      color: $secondary-90;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; } }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 21px 0 21px 33px; }

  &__client {
    font-size: 14px;
    line-height: 18px;
    color: $passive-100;
    margin-bottom: 21px;
    display: block; }

  &-table {
    &__actions {
      margin-left: 17px; }
    &__checkbox {
      & > input[type=checkbox] {
        border: 2px solid $secondary-15 !important;
        border-radius: 3px !important; } } }


  &__button {
    border: 1px solid $secondary-15;
    background-color: $color-white;
    border-radius: 7px;
    padding: 11px 16px;
    height: 40px;
    color: $secondary-70;
    cursor: pointer;
    font-size: 14px;
    transition: background .1s ease, color .1s ease;

    &:focus {
      outline: none;
      border: 1px solid $secondary-15; }

    &_ml-10 {
      margin-left: 10px; }

    &_normal {
      color: $secondary-70;
      border: 1px solid $secondary-15;
      background: $color-white;

      &:hover {
        background: $color-white; }

      &:focus {
        background: $color-white; } } }

  &__info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3px; }

  &__label {
    margin-right: 5px;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    color: $secondary-70; }


  &__value {
    color: $passive-100;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      color: $passive-100; } } }
