@import "../../../styles/theme";

.nm-mini-info-card {
  border-radius: 8px;
  padding: 16px;
  height: max-content;
  border: 1px solid $secondary-15;
  &_mode {
    &-yellow {
      border-color: $inert-70; }
    &-red {
      border-color: $negative-100; } }
  &__header {
    &_border {
      border-bottom: 1px solid $secondary-5; } }
  &__content {
    &_paddingTop {
      padding-top: 8px; } }
  &__link {
    color: $passive-100;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    margin-left: 16px;
    &:hover {
      opacity: 0.8; } }
  &__action-icon {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
      border: none; } }
  &__mode {
    &-icon {
      height: 20px;
      width: 20px;
      margin-right: 10px; } }
  &__button {
    width: 24px;
    border: none;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    background: transparent;
    &:hover {
      border: none; } } }
