@import "../../../styles/theme";
@import "../../../styles/mixins";

.chat-client-list {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  &_padding {
    padding-left: 24px; }
  &__dialogs {
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: visible;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    @include respond-to(md) {
      width: 400px;
      min-width: 400px;
      max-width: 400px; }
    &_displayNone {
      display: none; } }
  &__filter {
    padding: 24px;
    &_adminPadding {
      padding: 0 24px 24px 0; }
    & .nm-button {
      width: 50%; } }
  &__header {
    display: flex;
    justify-content: space-between; }
  &__messages {
    display: flex;
    position: relative;
    flex: 3;
    flex-direction: column;
    width: 100%;
    height: 100%;
    &_displayNone {
      display: none; } }
  &__area {
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex-grow: 1; }
  & .chat-client-send-message {
    bottom: 0; } }
