@import '../../styles/theme';
@import '../../styles/mixins';

.nm-button-contact {
  &__indicator {
    position: absolute;
    z-index: 99;
    right: -2px;
    top: -2px;
    background-color: $negative-100;
    width: 10px;
    min-width: 10px;
    height: 10px;
    min-height: 10px;
    border-radius: 50%; } }
