@import "../../../../../styles/mixins";
@import "../../../../../styles/theme";

.contractor-verification-check-image {
  &__container {
    width: calc(100% - 24px);
    margin-right: 24px;
    height: 100%;
    position: relative;
    overflow: hidden;
    & .pan-container {
      margin-right: 70px;
      overflow: hidden; } }
  &__open-image {
    cursor: pointer;
    color: $passive-100;
    &:hover {
      text-decoration: underline; } } }
