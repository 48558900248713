@import "../../styles/mixins";

.button-with-context-menu {
  display: flex;
  align-items: center;
  &__button {
    display: flex;
    width: max-content;
    position: relative; }
  &__button-elements {
    overflow: hidden; }
  &__context-menu-content {
    width: 100%;
    &:before {
      display: none; } }
  &__tooltip {
    margin-left: 8px;
    width: 24px;
    height: 24px;
    @include respond-to(md) {
      margin-left: 16px; } } }
