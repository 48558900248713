@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.chat-client-send-message {
  display: flex;
  background-color: $color-white;
  align-items: flex-start;
  padding: 12px 16px 16px 16px;
  @include respond-to(md) {
    padding: 12px 24px 24px 24px; }
  @include respond-to(xxl) {
    padding: 12px 48px 41px 24px; }
  &__text-area {
    flex-grow: 1;
    margin: 0 8px;
    @include respond-to(xxl) {
      margin: 0 12px; } }
  &__footer-send {
    transform: rotate(270deg);
    cursor: pointer; }
  &__files {
    padding: 16px 48px 12px 16px;
    display: flex;
    flex-wrap: wrap;
    @include respond-to(md) {
      padding: 16px 24px 12px 24px; }
    @include respond-to(xxl) {
      padding: 16px 48px 12px 24px; } }
  &__file-preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 8px;
    &:not(:first-child) {
      margin-left: 16px; }
    &-image {
      max-height: 67px;
      max-width: 100px; }
    &-filename {
      margin-top: 4px;
      cursor: pointer; } }
  &__file-remove {
    cursor: pointer;
    margin-left: auto; } }
