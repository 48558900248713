@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-page-card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__controls {
    display: flex;
    &_buttonsMargin button {
      margin-left: 8px;
      @include respond-to(md) {
        margin-left: 16px; } } }
  &__main-content {
    display: flex;
    width: 100%;
    align-items: center;
    overflow: hidden; }
  &__back {
    margin-right: 8px;
    @include respond-to(md) {
      margin-right: 12px; } }
  &__status {
    margin: 0 16px 0 12px;
    &_theme {
      background: $inert-70;
      white-space: nowrap;
      border-radius: 7px;
      color: $secondary-75;
      font-size: 12px;
      line-height: 18px;
      padding: 3px 8px; } }
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    color: $secondary-100;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; } }
