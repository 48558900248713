@import "../../styles/theme";
@import "../../styles/mixins";

.finance {
  &-upload {
    padding: 25px;
    width: 100%;
    &__container {
        padding-left: 0px; }
    &-header {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px; }
    &-card {
        margin-bottom: 24px;

        &-container {
            height: 215px;
            display: flex;
            justify-content: center;
            align-items: center; }
        &-drop-zone {
            @include drop-zone-app; }
        &__img {
            max-width: 100%;
            max-height: 100%; }
        &__text {
            font-size: 24px;
            font-weight: bold; }
        &__label {
            margin-bottom: 11px; }
        &_add-margin {
            margin-bottom: 20px; }
        &__requisites {
            margin-bottom: 9px; }
        display: flex {
            align-items: center; }
        &>div:not(:last-child) {
            margin-right: 82px; }
        &__icon {
            margin-bottom: 20px; } } } }





