@import "../../../../styles/theme";

.contractor-work-experience {
  &-block {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 8px;
    border-bottom: 1px solid $secondary-5;
    &:not(:last-child) {
      margin-bottom: 8px; } }
  &__link {
    color: $passive-100;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
    margin-left: 16px;
    &:hover {
      opacity: 0.8; } } }
