.multiselect {
  $block: &;

  position: relative;
  outline: none;
  align-items: flex-end;
  display: flex;
  &__container {
    position: relative;
    width: 100%;
    display: inline-block;
    background-color: #F1F3F7;
    text-align: left;
    border: 1px solid #e0e0e0;
    border-radius: 7px;
    transition: box-shadow .1s ease,width .1s ease;
    height: 38px;
    &_readonly {
      border: unset;
      .multiselect__text {
        padding-left: 0px; } }
    &_small {
      height: 5px !important; } }
  &__content {
    padding: 10px 0;
    display: inline-block;
    cursor: pointer;
    border: none;
    width: 100%;
    text-align: left;
    background-color: transparent;
    & > i {
      color: #B6BAC3; }
    &-without-padding {
      padding: 0 !important; } }
  &__values {
    margin-left: 10px;
    margin-right: 10px; }
  &__value {
    padding: 2px 5px;
    display: inline-block;
    margin: 3px;
    border: 1px gray solid;
    border-radius: 13px;
    color: gray;
    max-width: 100%;
    word-wrap: break-word;
    .close {
      color: gray;
      margin-top: 5px;
      margin-left: 5px;
      cursor: pointer; } }
  &__search {
    padding: 10px; }
  &__text {
    line-height: 16px;
    font-size: 14px;
    font-weight: normal;
    padding: 0 40px 0 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &-without-padding {
      padding: 0 !important; }
    &-wrap {
      white-space: normal; } }
  &__dropdown {
    position: absolute;
    padding: 10px 0;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 4;
    background-color: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-bottom-right-radius: .28571429rem;
    border-bottom-left-radius: .28571429rem;
    transition: box-shadow .1s ease,width .1s ease;
    border-top: 0px;
    &_readonly {
      word-break: break-word;
      min-width: 200px;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15);
      border-radius: 7px; } }
  &__label {
    margin: 0 0 .28571429rem 0;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #666666;
    opacity: 1; }
  &__options {
    max-height: 300px;
    height: 100%;
    overflow-y: auto; }
  &__option {
    position: relative;
    margin: 0px 15px;
    cursor: pointer;
    display: block;
    text-decoration: none;
    border-top: 1px solid #fafafa;
    padding: .78571429rem 1.14285714rem;
    white-space: normal;
    word-wrap: normal;
    font-family: "Roboto";
    color: #174A97;
    line-height: 16px;
    text-transform: none;
    font-weight: 400;
    padding-left: 30px !important;
    &:hover {
      background: rgba(0,0,0,.05); }
    label {
      &:before {
        position: absolute;
        top: 10px;
        left: 0;
        width: 17px;
        height: 17px;
        content: '';
        background: #fff;
        border-radius: .21428571rem;
        transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
        border: 1px solid #d4d4d5; } }
    &-selected {
      label {
        &:after {
          content: '\e800';
          color: white;
          background: #65AF43;
          border-radius: .21428571rem;
          position: absolute;
          font-size: 14px;
          top: 10px;
          left: 0;
          width: 17px;
          height: 17px;
          text-align: center;
          transition: border .1s ease,opacity .1s ease,transform .1s ease,box-shadow .1s ease;
          font-family: Checkbox; } } }
    &-empty {
      label {
        &:before {
          content: none; }
        &:after {
          content: none; } } } }
  .dropdown {
    position: absolute;
    right: 5px;
    top: 8px;
    font-size: 12px; }
  .info {
    position: absolute;
    right: 4px;
    color: darkgray;
    top: 6px;
    border: 1px solid #555555;
    border-radius: 53px;
    padding: 0px 5px;
    color: #555555;
    &_without-border {
      border: unset !important;
      position: relative !important;
      right: 0 !important;
      top: 0 !important;
      padding: 0 !important; } }
  &__element {
    width: inherit; }
  &--not-empty {
    #{$block}__container {
      background-color: transparent; } } }
