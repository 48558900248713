@import "../../../styles/theme";

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 226px;
  padding: 24px 10px 24px 12px;
  max-height: 84vh;
  @media screen and (max-width: 425px) {
    width: 100%; }
  &__logo {
    width: 48px;
    height: 48px;
    border-radius: 50px;
    margin-bottom: 8px;
    &_admin {
      width: 44px;
      height: 43px;
      background: url("../../../images/nm-logo-rounded.svg") no-repeat center; }
    img {
      height: 100% !important; } }
  &__user-name {
    color: $secondary-100;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center; }
  &__client-name {
    color: $secondary-100;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center; }
  &__role {
    color: $secondary-70;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px; }
  &__divider {
    background: $secondary-5;
    height: 1px;
    width: 100%;
    margin-top: 16px; }
  &__other-account {
    &-title {
      overflow: hidden; }
    &-list {
      overflow: auto;
      width: 100%; }
    &-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 56px;
      border-bottom: 1px solid $secondary-5;
      cursor: pointer; }
    &-logo {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 8px;
      img {
        height: 100% !important; } }
    &-client-name {
      color: $secondary-90;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px; }
    &-client-brand {
      color: $secondary-70;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px; } }
  &__block-wrapper {
    max-width: 155px;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  &__button-logout {
    margin-top: 16px; }
  &__settings {
    margin-top: 16px;
    &-link {
      position: relative; }
    &-counter {
      position: absolute;
      display: block;
      left: 50%;
      top: 40%; } } }
