@import "../../styles/theme";

.nmx-size-pag {
  max-height: 36px;
  display: flex;
  position: absolute;
  left: 0;
  &__label-before {
    font-size: 12px;
    color: #666666;
    line-height: 34px;
    padding-right: 11px; }
  &__buttons {
    background: #F1F3F7;
    padding: 2px; } }

.ui.buttons {
  border-radius: 7px;
  &:before {
    font-size: 12px;
    color: #666666;
    line-height: 34px;
    padding-right: 11px;
    @media screen and (max-width: 1280px) {
      position: unset; } }

  & .ui.basic.button {
    min-width: 36px;
    font-size: 12px;
    padding: 9px 11px;
    background-color: #EBEDF3 !important;

    &:not(.active) {
      color: $primary-100 !important;
 }      //z-index: 10

    &:first-child {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px; }

    &:last-child {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px; } }

  & .ui.basic.active.button {
    background-color: #fff !important;
    padding: 9px 11px;
    border: 1px solid #E9F4E5;
    border-radius: 7px; } }
