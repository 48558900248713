@import "theme";

@mixin respond-to-sensor-device() {
  @media (pointer: coarse) {
    @content; } }

@mixin respond-to($media) {
  @if $media == xs {
    @media only screen and (max-width: 575px) {
      @content; } }
  @else if $media == sm {
    @media only screen and (min-width: 576px) {
      @content; } }
  @else if $media == md {
    @media only screen and (min-width: 768px) {
      @content; } }
  @else if $media == lg {
    @media only screen and (min-width: 992px) {
      @content; } }
  @else if $media == xl {
    @media only screen and (min-width: 1200px) {
      @content; } }
  @else if $media == xxl {
    @media only screen and (min-width: 1920px) {
      @content; } } }

%button {
  border: 1px solid $secondary-15;
  box-sizing: border-box;
  border-radius: 7px;
  cursor: pointer; }

%button-yellow {
  background-color: #FFDB4D;
  padding: 14px 40px;
  font-size: 21px;
  line-height: 21px; }

%button-default {
  background: transparent;
  line-height: 16px;
  font-size: 14px;
  margin-left: 27px;
  color: #333;
  padding: 12px 27px; }

%text-default {
  font-family: $font-family-default; }
%wrapper-flex-column {
  display: flex;
  flex-direction: column; }

%col__mb-70 {
  &:not(:last-child) {
    margin-bottom: 0; } }
%wrapper-flex-column-end {
  @extend %wrapper-flex-column;
  justify-content: flex-end; }

@mixin flex-column__center {
  @extend %wrapper-flex-column;
  align-items: center;
  justify-content: center; }

@mixin flex-row {
  display: flex;
  align-items: center; }

@mixin flex-row__center {
  @include flex-row;
  justify-content: center; }

@mixin flex_column__space-between {
  @extend %wrapper-flex-column;
  justify-content: space-between; }

@mixin flex_row__space-between {
  @include flex-row;
  justify-content: space-between; }

@mixin drop-zone-app {
  background: #F4FAFF;
  border: 1px solid #6FA0EB;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 25px; }

@mixin col__ml($pixel) {
  &:not(:last-child) {
    margin-left: $pixel; } }

@mixin reset-button {
  overflow: visible;
  padding: 0;
  text-align: left;
  color: inherit;
  border: none;
  background: none;
  font: inherit;
  &:focus {
    outline: none; }
  &:active {
    outline: none; } }

@mixin reset-list {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none; }
