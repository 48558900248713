@import "../../../styles/theme";

.projects-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__tabs {
    margin-bottom: 24px!important; }
  &-confirm {
    padding: 20px 130px 20px 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px; }
  &-header {
    display: flex;
    justify-content: space-between;
    &-left {
      max-width: 70%;
      display: flex;
      align-items: center;
      &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        color: #000000;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      &__status {
        margin-left: 17px;
        background: #FFDB4D;
        white-space: nowrap;
        border-radius: 7px;
        color: #555555;
        padding: 7px 10px 8px 5px;
        text-transform: uppercase; } } }
  &__back {
    margin-right: 20px; }
  &__description {
    word-break: break-all; }
  &-general-info {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    padding-left: 60px;
    &-left {
      width: 45%; } } }

.btn {
  margin: 0 !important;
  height: 48px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &_edit {
    padding: 0 15px 0 16px !important;
    background: $grad-primary !important;
    border-radius: 7px !important;
    & > .text-button {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 19px !important;
      color: #FFFFFF !important; }
    & i {
      margin-right: 15px;
      color: #FFFFFF !important;
      font-size: 20px; } } }


