@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-advanced-tooltip {
  width: max-content;
  $block: &;

  position: unset;
  @include respond-to(md) {
    &:not(#{$block}_filterTooltip) {
      position: relative; } }

  &_noAdaptive {
    position: relative; }

  &__tooltip {
    display: none;

    &_isOpen {
      display: block; }

    &_noArrowInMobile {
      &:before {
        @media screen and (max-width: 767px) {
          display: none; } } }

    &_noPointerEvents {
      pointer-events: none; } }

  &__tooltip.nm-tooltip:not(#{$block}__tooltip_noAdaptive) {
    @media screen and (max-width: 767px) {
      top: calc(100% + 4px);
      left: 0;
      right: 0;
      bottom: auto;
      max-width: 100%;
      transform: none;
      width: 100%;
      min-width: 252px; }
    &:before {
      @media screen and (max-width: 767px) {
        display: none; } } }

  &_hover:hover > #{$block}__tooltip {
    display: block; }

  &_noRelative {
    position: unset; } }
