@import "../../../../../styles/mixins";

.contractor-verification-layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
  @include respond-to(xxl) {
    flex-direction: row;
    margin-top: 24px; }
  &__scan {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    @include respond-to(xxl) {
      max-height: unset;
      justify-content: flex-start;
      align-items: flex-start;
      height: auto; } }
  &__data {
    margin-top: 16px;
    @include respond-to(xxl) {
      margin-top: 0; } }
  &__photo-default {
    display: flex;
    width: 95%;
    height: 700px;
    justify-content: center;
    align-items: center;
    background-color: $secondary-5; }
  &__scan-list-warning {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @include respond-to(xxl) {
      margin-top: 12px; }
    &-text {
      color: $secondary-45;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px; } }
  &__loader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; } }
