.horizontal-cotractors-filter {
  display: flex;
  align-items: flex-start;
  width: 100%;

  & > div:not(:last-child) {
    margin: 0 10px 16px 0; }
  &__dropdown {
    &_long {
      width: 325px; }
    &_short {
      width: 170px; } }

  &__range-picker {
    width: 200px; } }

.nm-contractor-list-filter {

  &__buttons {
    margin-bottom: 15px;
    margin-top: 15px; }

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 13px;
    .nm-number-range-picker {
      margin-bottom: 0px !important; }
    .nm-work-schedule-filter {
      width: 100%; } }

  &__dadata-row {
    width: 100%;
    margin-bottom: 13px; }

  &__sort {
    margin-top: 25px;
    &-header {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px; } }
  &__form {
    .nm-label__label {
      white-space: nowrap; } } }
