.brigade {
  padding: 0;
  flex-grow: 1;
  &_mt {
    margin-top: 20px; }
  & .nm-page__content {
    display: flex;
    flex-direction: column; }
  &__tabs {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    & .nm-page {
      flex-grow: 1;
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0; } }
  &__content {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    flex-grow: 1; } }
