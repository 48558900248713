@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.promocode-card-block {
  position: relative;
  display: flex;

  &__input {
    width: 100%;
    margin-right: 15px !important; }

  &__button {
    padding: 12px 14px 15px 14px !important;
    background: #128A10 !important;
    height: 40px !important;
    border-radius: 7px !important;
    color: #FFFFFF !important;
    margin-bottom: 11px !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 16px !important; }

  label {
    display: block; }

  &__ref {
    font-size: 14px;
    flex-wrap: wrap;
    &-rate {
      color: $primary-100;
      margin-left: 5px;
      font-weight: 500;
      word-break: break-word; } }

  .nm-input {
    width: 100%; }

  &__collapse-history {
    display: flex;
    justify-content: center;
    font-size: 11px;
    color: $passive-100;
    margin-bottom: 15px;
    cursor: pointer;
    position: absolute;
    right: 50px;
    top: 25px; }

  &__contractor-ref-block {
    border-radius: 7px 7px 0px 0px;
    position: relative;
    &.card-app.client-card-block {
      margin-bottom: 0px; } }

  &__client-ref-block {
    margin-bottom: 0px;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 0px 9px 23px 1px rgba(0, 0, 0, 0.15);
    position: relative; }

  &__updated-time {
    position: absolute;
    top: 23px;
    right: 45px;
    font-size: 12px;
    color: $secondary-70;
    @media screen and (max-width: 1800px) {
      position: unset;
      margin-bottom: 12px; } }

  &__promocode {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px; }

  &__history-item {
    color: $secondary-70; }

  &__edit-input-promocode {
    margin-bottom: 10px; }

  &__edit-ref-input {
    &.field.nm-form-input {
      input {
        height: 38px;
        width: 150px !important; } } } }

