@import "../../../../styles/theme";

.map-filter {

  &__buttons {
    margin-bottom: 15px;
    margin-top: 15px; }

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 13px;

    .nm-number-range-picker {
      margin-bottom: 0 !important; }

    .nm-work-schedule-filter {
      width: 100%; } }

  &__dadata-row {
    width: 100%;
    margin-bottom: 13px; }

  &__dadata {
    margin-bottom: 16px; }

  &__sort {
    margin-top: 25px;

    &-header {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px; } }

  &__form {
    margin-top: 16px;
    .nm-label__label {
      white-space: nowrap; } }

  &__info {
    margin-top: 12px;
    background-color: $primary-5;
    border-radius: 8px;
    padding: 8px 12px; } }
