@import "../../../../styles/theme";

.ticket-list {
  &__label {
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  &__fio {
    color: $secondary-100;
    margin-left: 4px; } }
