.group-members-list {
  padding: 0;
  &__table {
    & .ui.grid .row .column {
      &:nth-child(1) {
        width: 30% !important; }
      &:nth-child(2) {
        width: 30% !important; }
      &:nth-child(3) {
        width: 30% !important; }
      &:nth-child(4) {
        width: 10% !important; } }
    &-action {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      &-icon {
        cursor: pointer;
        &:not(:first-child) {
          margin-left: 8px; } } } } }
