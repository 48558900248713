@import "../../../../../../../styles/theme";
@import "../../../../../../../styles/mixins";

.crowd-task-edit-general-information-form {
  display: flex;
  width: 100%;
  &__form {
    width: 65% !important; }
  &__calculated-indicators {
    width: 35%;
    margin-left: 40px;
    &-info {
      width: 100%;
      border-radius: 7px;
      box-shadow: 0 4px 30px $secondary-15;
      padding: 30px 27px;
      margin-top: 8px; } }
  &__info {
    &-amount {
      flex-grow: 1;
      text-align: end; } }
  &__error {
    width: 100% !important; } }
