@import "../../styles/theme.sass";
@import "../../styles/mixins.sass";

.nm-title {
    display: flex;
    align-items: flex-start;
    flex-flow: wrap;
    white-space: pre-wrap;
    &_hidden {
      overflow: hidden; }
    &_align-left {
        justify-content: flex-start;
        text-align: left; }
    &_align-right {
        justify-content: flex-end;
        text-align: right; }
    &_align-center {
        justify-content: center;
        text-align: center; }

    &__container {
      display: flex;
      align-items: center;
      // Для обрезания тремя точками заголовка в адаптиве
      @include respond-to(xl) {
        overflow: unset; }
      &_fluid {
        width: 100%; }
      &_noWrap {
        overflow: hidden; } }

    &__value {
        margin: 0;
        font-family: $font-family-default;
        &_size-xl {
            font-size: 22px;
            line-height: 28px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include respond-to(xl) {
              font-size: 24px;
              line-height: 30px;
              word-break: break-all;
              white-space: unset;
              overflow: unset;
              text-overflow: unset; } }

        &_size-lg {
            font-size: 16px;
            line-height: 24px;
            @include respond-to(md) {
              font-size: 18px;
              line-height: 26px; } }

        &_size-md {
            font-size: 16px;
            line-height: 24px; }

        &_noWrapToLines {
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word; }

        &_noWrap {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; } }

    &__count-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 19px;
        width: auto;
        height: 19px;
        padding: 3px 6px;
        background-color: $primary-100;
        border-radius: 10px;
        margin-left: 12px; }

    &__count {
        color: $color-white;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500; }

    &__status-wrapper {
        background-color: $primary-100;
        padding: 6px 8px;
        border-radius: 7px;
        margin-left: 11px; }

    &__status {
        color: $color-white;
        font-size: 12px;
        line-height: 1; }

    &__subtitle {
        color: $secondary-70;
        &_mobile {
          @include respond-to(md) {
            display: none; } } }

    &__tooltip {
      position: relative;
      margin-left: 8px; } }
