@import "../../styles/theme";
@import "../../styles/mixins";

.label-text {
  display: flex;
  flex-direction: column;
  &__name {
    display: flex;
    position: relative;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
    color: $secondary-75;
    &-tooltip {
      margin-left: 4px; } }
  &__content {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: $secondary-100;
    @include respond-to(md) {
      font-size: 16px;
      line-height: 24px; } } }
