@import "../../styles/theme";
@import "../../styles/mixins";

.dropzone-app-mini {
    $block: &;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    min-width: 220px;
    min-height: 184px;
    border: 1px dashed #6FA0EB;
    border-radius: 7px;
    cursor: pointer;
    background-color: #F4FAFF;
    position: relative;
    padding: 16px;
    position: relative;

    &:hover {
        #{$block}__icon-wrapper {
            background-color: #174A97;
            transition: background-color .2s linear; }

        #{$block}__icon {
            color: $color-white;
            transition: color .2s linear; } }

    &_error {
      background-color: $negative-15;
      border: 1px dashed $negative-100; }

    &_disabled {
      cursor: default;
      border: 1px dashed $secondary-45;
      background-color: $color-white;
      & #{$block}__description, #{$block}__accept-types, #{$block}__max-size {
        color: $secondary-45; }
      & #{$block}__icon-wrapper {
        background-color: $secondary-5; }

      &:hover {
        & #{$block}__icon-wrapper {
          background-color: $secondary-5;
          transition: none; }
        & #{$block}__icon {
          color: $secondary-45; } }
      & #{$block}__icon {
        color: $secondary-45; } }

    &_previews {
        padding: 0;
        border: none;
        background-color: transparent; }

    &__description {
        display: block;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        max-width: 180px;
        align-self: center; }

    &__icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: center;
        width: 57px;
        height: 57px;
        background-color: $color-white;
        border-radius: 50%; }

    &__icon {
        color: #174A97;
        width: 14px;
        height: 14px; }

    &__footer {
        display: block;
        font-size: 12px; }

    &__preview {
        width: 128px;
        &:not(:last-child) {
            margin-bottom: 20px; } }

    &__error {
        position: absolute;
        bottom: -36px;
        left: 0; } }

