.finance-payment-list-duplicate {
  &__table {
    width: 100%;
    & .ui.grid .row .column {
      &:nth-child(1) {
        max-width: 40px;
        padding: 0 15px; }
      &:nth-child(2) {
        width: 240px; }
      &:nth-child(3) {
        width: 250px; }
      &:nth-child(4) {
        width: 200px; }
      &:last-child {
        flex-grow: 1;
        width: auto; } } } }
