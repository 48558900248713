.add-group-form {
  width: 587px;
  $block: &;

  &__row {
    display: flex;
    & #{$block}__control, #{$block}__date-picker {
      flex-basis: 50%; }
    & > div:first-child {
      margin-right: 16px; } }
  &__control {
    margin-bottom: 16px; } }
