@import '../../../styles/theme';
@import '../../../styles/mixins';

.task-list {
  width: 100%;
  &__help-tooltip-icon {
    color: $color-white; }
  &__details {
    margin-top: 6px;
    @include respond-to(md) {
      margin-top: 0; } }
  &__loading {
    display: flex;
    align-items: center;
    margin-left: 8px;
    @include respond-to(md) {
      margin-left: 16px; } }
  &__loading-text {
    margin-left: 8px; }
  &__label-text {
    margin-right: 8px;
    margin-bottom: 6px; }
  &__count-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: max-content;
    min-width: 18px;
    height: 18px;
    padding: 2px 4px;
    border-radius: 9px;
    color: $color-white;
    &_success {
      background-color: $primary-100; }
    &_failed {
      background-color: $negative-100; }
    &_left {
      background-color: $secondary-5;
      color: $secondary-45; } }
  &__count {
    font-size: 12px;
    line-height: 18px; }
  &__details-value {
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    color: $passive-100;
    &:hover {
      opacity: 0.8; } }
  &__content {
    display: flex;
    flex-direction: column; }
  &__row {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } } }
