@import "../../styles/theme";

.link-ext {
  color: $passive-100;
  &_noWrap {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  &__caption {
    display: inline-block;
    font-weight: 500;
    font-size: 16px; } }
