.registry-short-items {
  &__content {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: none;
    margin-top: 0;
    bottom: 0;
    margin-bottom: 0;
    top: 0;
    border-radius: .28571429rem .28571429rem 0 0;
    & > .flex {
      margin-right: -140px; } }
  &__column {
    margin-right: 15px;
    &_w160 {
      flex: 0 0 160px;
      max-width: 160px; }
    &_w200 {
      flex: 0 0 200px;
      max-width: 200px; }
    &_w300 {
      flex: 0 0 300px;
      max-width: 300px; } } }
