@import "../../styles/mixins";

.nm-page-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__header {
    margin-bottom: 12px;
    @include respond-to(xl) {
      margin-bottom: 24px; } }
  &__description {
    margin-bottom: 16px;
    @include respond-to(xxl) {
      margin-bottom: 24px; } }
  &__info {
    margin-bottom: 16px;
    @include respond-to(md) {
      margin-bottom: 24px; } }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; } }
