@import "../../styles/theme";

.instruction {
  &__file {
    display: flex;
    cursor: pointer;
    align-items: center; }
  &__file-value {
    font-size: 12px;
    line-height: 18px;
    color: $primary-100;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  &__file-icon {
    color: $primary-100;
    width: 24px;
    height: 24px;
    min-width: 24px; } }
