@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.onboarding-check-design-modal {
  &__header {
    background-color: $color-white;
    border-bottom: 1px solid $open-naimix-black-5;
    height: 56px;
    width: 100%;
    display: flex;
    justify-content: center;
    @include respond-to(md) {
      padding: 0 24px; }
    @include respond-to(xxl) {
      height: 80px; } }

  &__header-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }

  &__logo {
    background-image: url("../../../../images/naimix-logo-mobile.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 40px;
    height: 44px;
    @include respond-to(md) {
      width: 188px;
      height: 32px;
      background-image: url("../../../../images/naimix-logo-with-name.svg"); }
    @include respond-to(xxl) {
      width: 235px;
      height: 40px; } }


  &__horeca-logo {
    width: 49px;
    height: 27px;
    @include respond-to(xxl) {
      width: 81px;
      height: 45px; } }

  &__custom-logo {
    height: 44px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @include respond-to(md) {
      height: 32px; }
    @include respond-to(xxl) {
      height: 40px; } }

  &__phone {
    display: flex;
    align-items: center;
    background-color: $open-naimix-black-5;
    padding: 6px;
    border-radius: 50%;
    @include respond-to(md) {
      padding: 10px 16px 10px 12px;
      border-radius: unset; }

    &_horeca {
      background-color: $horeca-black-5; } }

  &__phone-icon {
    color: $color-black;
    width: 20px;
    height: 20px;
    @include respond-to(md) {
      margin-right: 5px; } }

  &__phone-number {
    display: none;
    color: $color-black;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    &:hover {
      color: $color-black; }
    @include respond-to(md) {
      display: block; } }

  &__footer {
    background: $open-naimix-secondary-100;
    color: $color-white;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 16px;
    @include respond-to(md) {
      padding: 24px; }

    &_horeca {
      background: $horeca-secondary; } }

  &__copyright {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include respond-to(xxl) {
      margin-bottom: 0; } }

  &__privacy {
    color: $color-white;
    margin-bottom: 8px;
    &:hover {
      color: $color-white; }
    @include respond-to(md) {
      margin-bottom: 0;
      margin-right: 96px; }
    @include respond-to(xxl) {
      margin-bottom: 0;
      margin-right: 20px; } }

  &__agreement {
    color: $color-white;
    &:hover {
      color: $color-white; } }

  &__footer-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include respond-to(xxl) {
      flex-direction: row;
      justify-content: space-between; } }

  &__links {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } }

  &__info-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }

  &__info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 144px 16px;
    @include respond-to(md) {
      padding: 88px 24px; } }

  &__info-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    border-radius: 12px;
    background-color: $open-naimix-black-5;
    padding: 16px;
    width: 100%;
    @include respond-to(md) {
      max-width: 720px;
      padding: 40px; }
    @include respond-to(xxl) {
      max-width: 1096px;
      padding: 148px; }

    &_horeca {
      background-color: $horeca-black-5; } }

  &__button {
    width: 100%;
    margin-top: 32px;
    @include respond-to(md) {
      margin-top: 60px;
      max-width: 358px; } } }
