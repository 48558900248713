@import "../../styles/theme";

.table-selected-label {
  display: flex;
  align-items: center;
  margin-right: 24px;
  &__text {
    font-size: 14px;
    line-height: 20px; }
  &__count {
    border-radius: 500px;
    text-align: center;
    min-width: 18px;
    margin-left: 6px;
    font-size: 12px;
    line-height: 14px;
    padding: 2px 5px;
    &_active {
      background: #EE1616;
      color: #fff; }
    &_no-active {
      background-color: $secondary-5;
      color: $secondary-45;
      @media screen and (max-width: 767px) {
        background-color: $color-white; } } } }
