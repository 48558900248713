@import "../../styles/theme";
@import "../../styles/mixins";

.finance-pivot {
  display: flex;
  width: 100%;
  padding: 8px 0 8px 24px;
  flex-grow: 1;
  overflow: auto;
  flex-direction: column;
  @include respond-to(xxl) {
    padding: 24px 48px; }
  @include respond-to(xs) {
    padding: 24px 0 24px 16px; }
  &__controls, &__pagination {
    margin-right: 24px;
    @include respond-to(xxl) {
      margin-right: 0; }
    @include respond-to(xs) {
      margin-right: 16px; } }
  &__content {
    flex-grow: 1; }
  &__overflow-container {
    overflow: auto;
    flex-grow: 1;
    height: 100%;
    @include respond-to-sensor-device() {
      flex-grow: unset; } }
  &__table {
    min-width: 1610px;
    &:has(.row .column:nth-child(12)) {
      min-width: 1754px; }
    @include respond-to(xxl) {
      width: 100%; }
    & .row .column {
      &:nth-child(1) {
        flex-grow: 1; }
      &:not(:nth-child(1)) {
        max-width: 144px;
        min-width: 144px; } }
    .row {
      &:nth-child(2) {
        background: $secondary-5;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        & .column {
          @include respond-to(md) {
            min-height: 32px; } } } }
    .nmx-grid-table__row {
      &:first-of-type {
        background: $secondary-5;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px; } }
    .nmx-grid-table__column:not(:nth-child(3)) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .nmx-grid-table__header {
      .column {
        white-space: pre-line; } } }
  &__replenishment {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    white-space: nowrap; }
  &__client-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $passive-100; } }
