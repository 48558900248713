@import "../../../styles/theme";
@import "../../../styles/mixins";

.menu-items-container {
  flex-grow: 1;
  background-color: $secondary-90;
  @extend %wrapper-flex-column;
  font-size: 14px;
  overflow: auto;
  @include respond-to(xs) {
    display: none; }
  @include respond-to(sm) {
    display: none; }
  @include respond-to(xl) {
    display: block;
    overflow: auto; } }
