@import "../../styles/theme";
@import "../../styles/mixins";

.contractor-edit-payment-info {
  &__name-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 4px;
      flex-direction: row; } }
  &__name {
    color: $secondary-90;
    margin-bottom: 8px;
    @include respond-to(md) {
      margin-bottom: 0; } }
  &__payment-status {
    @include respond-to(md) {
      margin-left: 16px; } } }
