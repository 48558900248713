@import "../../../../../../styles/theme";
@import "../../../../../../styles/mixins";

.onboarding-scenario-recruiter-list {
  &__overflow-container {
    overflow: auto;
    flex-grow: 1;
    @include respond-to-sensor-device() {
      flex-grow: unset; } }
  &__table {
    min-width: 1610px;
    @include respond-to(xxl) {
      width: 100%; }
    & .ui.grid .row .column {
      &:nth-child(1) {
        width: 20%; }
      &:nth-child(2) {
        width: 15%; }
      &:nth-child(3) {
        width: 15%; }
      &:nth-child(4) {
        width: 25%; }
      &:nth-child(5) {
        width: 10%; }
      &:last-child {
        width: unset;
        flex-grow: 1;
        padding: 0; } } }
  &__copy-icon {
    cursor: pointer; } }
