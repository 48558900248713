@import "../../../../../../styles/mixins";

.nm-list-card-avatar {
  min-width: 40px !important;
  min-height: 40px !important;
  width: 40px !important;
  height: 40px !important;
  @include respond-to(md) {
    min-width: 50px !important;
    min-height: 50px !important;
    width: 50px !important;
    height: 50px !important; } }
