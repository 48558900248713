@charset "utf-8";
@import "../../../styles/theme";
@import "../../../styles/mixins";

.promocode-list {
  overflow: auto;
  &__archive {
    margin-left: 16px; }
  &__button-icon {
    margin-right: 12px; }
  &-hired {
    border: 1px solid $color-gray-7;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-weight: bold;
    box-sizing: border-box;
    border-radius: 7px;
    cursor: pointer; }
  &-pag-controls {
    display: flex;
    justify-content: center;
    width: 100%; }
  &-icon {
    color: $secondary-70;
    margin-right: 13px!important; }
  &-edit {
    border: 1px solid rgba(218, 218, 218, 0.5);
    color: $secondary-45;
    cursor: pointer;
    border-radius: 7px;
    padding: 6px;
    text-align: center;
    @media screen and (max-width: 1280px) {
      display: block; } }
  & .cell-edit {
    @media screen and (max-width: 1280px) {
      display: none; } }

  &-row {
    display: flex;
    width: 100%;
    margin: 8px 0; }
  & .ui.buttons .button {
    @media screen and (max-width: 1280px) {
      padding: 7px; } }
  &__action {
    flex-shrink: 0; }
  &-table {
    flex-basis: 80%;
    & .ui.selectable.table tbody tr:hover {
      & td:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px; }
      & td:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px; }
      & .ui.basic.button {
        border-radius: 7px;
        border: 1px solid $color-red-2 !important;
        color: $color-red-70 !important; }
      & .order-micro-list {
        &-edit {
          border: 1px solid $primary-50;
          color: $primary-100; }
        &-history {
          filter: none;
          opacity: 1; } } }
    & .ui.selectable.table tbody tr:hover {
      background: $color-white !important;
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
      border-radius: 7px; }
    @media screen and (max-width: 1280px) {
      margin-top: 0; }
    & table {
      border-collapse: collapse!important;
      border-spacing: 0!important;
      width: 100%!important;
      border: 0!important;
      table-layout: fixed!important; }
    & .ui.basic.button {
      margin: 0;
      box-shadow: none;
      border: 1px solid rgba(218, 218, 218, 0.5);
      color: $secondary-45 !important;
      border-radius: 7px;
      width: 100%;
      &:hover {
        box-shadow: none; }
      @media screen and (max-width: 1280px) {
        width: 100%; } }
    @media screen and (max-width: 1280px) {
      border: 0; }
    & thead {
      @media screen and (max-width: 1280px) {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px; } }
    & .ui.table:not(.unstackable) tr {
      @media screen and (max-width: 1280px) {
        padding: 0;
        box-shadow: 0 0 13px rgba(0, 0, 0, 0.25)!important; } }
    & tr {
      @media screen and (max-width: 1280px) {
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15)!important;
        display: block;
        margin-bottom: 30px;
        border-radius: 7px;
        border: 1px solid rgba(34,36,38,.15)!important; }
      &:last-child {
        @media screen and (max-width: 1280px) {
          margin-bottom: 10px; } } }
    & .ui.table:not(.unstackable) tr>td {
      @media screen and (max-width: 1280px) {
        padding: 5px 10px!important;
        border-bottom: 1px solid rgba(34,36,38,.15)!important; }
      &:last-child {
        @media screen and (max-width: 1280px) {
          border: none!important; } } }
    & td {
      @media screen and (max-width: 1280px) {
        font-weight: normal!important;
        display: block;
        text-align: right!important;
        max-width: unset!important; }
      &:before {
        @media screen and (max-width: 1280px) {
          content: attr(data-label);
          float: left;
          font-weight: 500;
          font-size: 12px;
          color: rgba(22, 22, 2, 0.44); } } } } }
.confirm-content {
  padding: 15px;
  &__question {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 16px; }
  &__checkbox {} }
