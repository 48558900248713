@import '../../styles/theme';
@import '../../styles/mixins';

@mixin icon-values {
  width: 18px;
  height: 18px;
  min-width: 18px;
  cursor: pointer;
  background-size: contain;
  @include respond-to(md) {
    width: 20px;
    height: 20px;
    min-width: 20px; } }

@mixin get-img($svgName) {
  background: url("../../images/#{$svgName}.svg") no-repeat center;
  @include icon-values; }

.nm-check {
  display: flex;
  align-items: center;
  &__success-icon {
    @include get-img("check-bg"); }
  &__wrong-icon {
    @include get-img("fault-bg"); }
  &__warning-icon {
    @include icon-values;
    color: $inert-70; }
  &__not-present-icon {
    @include icon-values;
    color: $secondary-10; } }
