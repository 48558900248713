@import "../../styles/theme";
@import "../../styles/mixins";

.map-objects-list-modal {
  width: 400px;
  min-width: 400px;
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  margin-top: 96px;
  background-color: $color-white;
  box-shadow: -8px 4px 16px rgba(0, 0, 0, 0.05);
  z-index: 8;
  @include respond-to(xl) {
    top: 0;
    left: 72px; }
  @media screen and (min-width: 1600px) {
    left: 204px; }
  @media screen and (max-width: 424px) {
    width: 100%;
    min-width: 100%; }
  &__body {
    height: 100%; }
  &__hide {
    position: absolute;
    right: 24px;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    color: $secondary-45; }
  &__primary-header {
    padding-right: 0; }
  &__fields {
    max-height: calc(100% - 154px);
    overflow: auto;
    margin-bottom: 8px;
    height: 100%;
    padding: {
      left: 24px;
      right: 24px; }
    @media screen and (max-width: 1199px) {
      padding: {
        left: 16px;
        right: 16px; } }
    & .nm-form_addMargin > div:not(:last-child) {
      margin-bottom: 16px; }
    & .filter-buttons-v2 {
      border-top: $secondary-10;
      background-color: $secondary-5;
      justify-content: center;
      padding: 16px 24px 24px 24px;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      margin-bottom: 0!important;
      z-index: 10; } }
  &__title {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 16px 24px;
    background-color: $secondary-5;
    border-bottom: 1px solid $secondary-10; }
  &__list {
    height: calc(100% - 57px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 8px;
    padding: {
      left: 24px;
      right: 24px; }
    @media screen and (max-width: 1199px) {
      padding: {
        left: 16px;
        right: 16px; } }
    &-title {
      margin-top: 8px;
      margin-bottom: 4px; }
    &-label-bold {
      font-weight: 500;
      margin-right: 3px; }
    & .checkbox-list {
      margin-top: 8px;
      min-height: unset;
      &__card {
        width: 100%; }
      &__row {
        padding: 14px 12px; }
      &__avatar {
        position: absolute;
        margin-left: 16px; }
      & .avatar {
        position: absolute;
        width: 40px;
        height: 40px;
        z-index: 99; } }
    & .nm-list-card__secondary-header {
      flex-direction: column-reverse;
      align-items: start; }
    & .nm-list-card__secondary-header-status {
      margin-top: 16px; }
    & .nm-list-card__primary-header_avatar {
      margin-left: 46px; }
    & .contractor-list-info__pr-header {
      display: flex;
      &-spec {
        font-size: 12px;
        line-height: 18px;
        color: $secondary-70;
        margin-bottom: 2px; } }
    & .contractor-list-info__grid-container {
      min-width: 250px;
      max-width: 250px; } } }
