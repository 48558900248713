@import "../../../../../../../styles/theme";
@import "../../../../../../../styles/mixins";

.order-edit-form-publish-client-info-rating {
  display: flex;
  justify-content: space-between;
  &__block {
    flex-direction: row-reverse;
    & .ui.disabled.rating .icon:not(:first-child) {
      display: none;
      @include respond-to(md) {
        display: block; } } }
  &__value {
    font-size: 14px;
    line-height: 20px; }
  &__container {
    display: flex;
    align-items: center; }
  &__reviews {
    font-size: 12px;
    line-height: 14px;
    color: $passive-100;
    margin-left: 8px; } }

