@import "../../../../../../styles/mixins";

.recruitment-directories-funnels-edit-form {
  &__header {
    display: none;
    @include respond-to(md) {
      display: flex; } }

  &__header-name {
    width: 376px; } }
