@import "../../../../../styles/mixins";

.contractor-verification-input-container {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 12px;
    @include respond-to(xxl) {
      margin-bottom: 0;
      margin-right: 16px; } }
  &_full {
    width: 100%;
    max-width: 100%;
    margin-bottom: 12px;
    @include respond-to(xxl) {
      margin-bottom: 16px; } }
  &_rigth {
    margin-left: auto !important; } }
