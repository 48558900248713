@import "../../../styles/theme";
@import "../../../styles/mixins";

.patents-card {
  &__page-button {
    margin-left: 16px;
    @include respond-to(xxl) {
      margin-left: 32px; } }

  &__media-buttons {
    flex-grow: 1;
    justify-content: flex-end; }

  &__media-context-menu {
    @include respond-to(xxl) {
      margin-left: auto!important; } }

  &__list-header {
    display: flex;
    justify-content: space-between;
    @include respond-to(xxl) {
      justify-content: flex-start; } }

  &__payment-ability {
    display: flex; }

  &__payment-ability-tooltip {
    display: flex; }

  &__payment-status {
    display: flex;
    align-items: center; }

  &__action-dropdown {
    background-color: transparent !important;
    &> .dropdown-app-content > i {
      color: $primary-100;
      &:hover {
        color: $secondary-70; } }
    &-border {
      border: 1px solid $secondary-15 !important;
      box-sizing: border-box !important;
      border-radius: 7px !important; } }

  &__info-icon {
    margin-left: 4px; }

  &__actions {
    display: flex;
    align-items: center;
    margin-bottom: 16px; } }
