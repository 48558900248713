@import "../../../../../../../styles/theme";
@import "../../../../../../../styles/mixins";

.crowd-task-edit-additional-terms-form {
  display: flex;
  &__block {
    width: 50%;
    border-radius: 7px;
    border: 1px solid $secondary-45;
    padding: 30px 16px;
    &:not(:first-child) {
      margin-left: 18px; } }
  &__main-checkbox {
    font-size: 16px;
    line-height: 24px; } }
