@import "../../../styles/theme";

@mixin before($border-color) {
  background-color: inherit;
  border-style: solid;
  border-color: $border-color;
  border-top-left-radius: 3px;
  border-width: 1px 0 0 1px;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  top: 0;
  left: 14px;
  position: absolute;
  content: '';
  background-image: none;
  z-index: 2;
  width: .6666em;
  height: .6666em;
  transition: background .1s ease; }

@mixin tooltip($background-color, $color) {
  color: $color;
  background: $background-color;
  width: fit-content;
  margin-top: 10px;
  font-weight: normal;
  position: relative;
  line-height: 18px;
  font-size: 12px;
  vertical-align: baseline;
  padding: 8px 12px;
  text-transform: none;
  border-radius: 4px;
  transition: background .1s ease; }

.error-tooltip {
  white-space: pre-line;
  @include tooltip($color-red-salmon, $color-white);

  &:before {
    @include before($color-red-salmon); }

  &_openNaimix, &_promoPoisk {
    background-color: $open-naimix-negative-100;
    border-radius: 6px;
    padding: 7px 12px;
    margin-top: 0;
    margin-left: 8px;
    position: absolute;
    z-index: 10;
    top: calc(100% - 8px);
    &:before {
      display: none; } }

  &_warning {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    @include tooltip($color-white, $secondary-80);

    &:before {
      border-color: $color-white; } } }
