@import "../../../styles/mixins";

.recruitment-candidates-card-header {
  &__top-content {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include respond-to(xxl) {
      margin-bottom: 24px; } }

  &__avatar-image {
    display: none;
    @include respond-to(xl) {
      display: flex;
      min-width: 130px;
      width: 130px;
      height: 130px; }
    @include respond-to(xxl) {
      min-width: 144px;
      width: 144px;
      height: 144px; } }

  &__creation-date {
    display: block;
    @include respond-to(md) {
      display: none; } }

  &__personal-data {
    width: 100%;
    flex-grow: 1; }

  &__title {
    flex-grow: 1;
    overflow: hidden; } }
