.edm-export-list {
  &__table {
    .row .column {
      align-items: center;
      &:nth-child(1) {
        width: 15% !important; }
      &:nth-child(2) {
        width: 25% !important; }
      &:nth-child(3) {
        width: 15% !important; }
      &:nth-child(4) {
        width: 15% !important; }
      &:nth-child(5) {
        width: 20% !important; }
      &:nth-child(6) {
        width: 10% !important; } } } }
