@import "../../styles/mixins";

.forbidden-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 340px;
    @include respond-to(md) {
      width: 500px;
      padding: 20px; } } }
