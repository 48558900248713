@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.order-edit-additional {
  $block: &;

  display: flex;
  flex-wrap: wrap;

  &__driver {
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin-bottom: 12px;
      margin-right: 16px; } }

  &__row {
    margin-bottom: 16px;
    &_indent {
      @include respond-to(md) {
        margin-left: 20px; } } }

  &__header {
    font-size: 14px;
    line-height: 20px;
    color: $secondary-90;
    font-weight: 500;
    margin-bottom: 18px;
    @include respond-to(md) {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px; }
    @include respond-to(xl) {
      margin-bottom: 10px; }
    &_no-mb {
      margin-bottom: 0; } }

  &__card {
    width: 100%;
    border: 1px solid $secondary-5;
    border-radius: 7px;
    margin-bottom: 8px;
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    &:nth-child(1) {
      margin-right: 0;
      @include respond-to(xl) {
        margin-right: 18px; } }
    @include respond-to(md) {
      padding: 16px 24px; }
    @include respond-to(xl) {
      margin-bottom: 12px;
      width: calc(50% - 10px); }
    &_mobile {
      padding: 0;
      border: none; } } }
