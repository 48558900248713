@import "../../styles/theme";

.templates-client-usage {
  $block: &;

  overflow: unset;

  &__button {
    $button: &;

    cursor: pointer;
    color: $secondary-70;
    &:hover {
      color: $secondary-100; }
    &_green {
      color: $primary-80;
      &:hover {
        color: $primary-100; } }
    &_disabled {
      cursor: default;
      color: $secondary-35;
      &:hover {
        color: $secondary-35; }
      &#{$button}_green {
        color: $primary-50;
        &:hover {
          color: $primary-50; } } } }

  &__list-header-name {
    overflow: unset;
    width: 50%; }

  &__header-row {
    display: flex;
    align-items: center;
    max-width: 100%; }

  &__name-container {
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 100%; }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 6px;
    max-width: 100%; }

  &__content {
    padding-left: 30px;
    & #{$block}__content-item:not(:last-child) {
      margin-bottom: 2px; } }

  &__content-default-button {
    margin-bottom: 6px; }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    margin-left: 6px;
    padding-right: 24px;
    &_noHistory {
      padding-right: 48px; } }

  &__filter-input {
    margin: 0;
    flex-grow: 1; }

  &__filter-search {
    margin-left: 8px; }

  &__tooltip {
    position: absolute;
    top: 0; } }
