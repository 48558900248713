@import "../../../styles/theme";
@import "../../../styles/mixins";

.login-success-notification {
  width: 100%;
  font-family: Montserrat, sans-serif;
  @include respond-to(md) {
    width: 500px; }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $secondary-100;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } }

  &__icon-container {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
    background-color: $open-naimix-black-5; }

  &__icon {
    color: $open-naimix-secondary-100; }

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $open-naimix-black-100;
    @include respond-to(md) {
      font-size: 18px;
      line-height: 26px; } }

  &__message {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    font-weight: 400;
    color: $open-naimix-black-80;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } }

  &__button {
    width: 100%; } }
