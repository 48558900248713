.insurance-history-info {
  margin: 0 0 37px;
  &__item {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    padding: 17px 14px 18px 19px;
    max-width: max-content; }
  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 15px;
    color: #555555;
    margin-bottom: 4px; }
  &__value {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #000000; } }
