@import "../../../styles/theme";
@import "../../../styles/mixins";

.dropdown-with-input {
  $block: &;

  &__control {
    display: flex;
    flex-direction: column;
    border: 1px solid $secondary-15;
    background-color: $secondary-5;
    border-radius: 7px;
    min-height: 40px;
    &_size-lg {
      min-height: 40px; }
    &_size-xl {
      min-height: 40px;
      @include respond-to(md) {
        min-height: 48px; } }
    &_active {
      border-color: $secondary-75;
      background-color: $color-white; }
    &_open {
      border-color: $primary-100;
      background-color: $color-white; }
    &_error {
      border-color: $negative-100;
      &:not(#{$block}__control_open) {
        background-color: $negative-15; } }
    &:hover {
      border-color: $primary-100; }
    &_disabled {
      border-color: $secondary-5;
      &:hover {
        border-color: $secondary-5;
        color: $secondary-45; } } }

  &__chevron-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    min-width: 20px;
    color: $secondary-45;
    &_disabled {
      color: $secondary-15; } }

  &__suggestion {
    color: $secondary-45;
    margin: 7px 12px 10px 12px;
    @include respond-to(md) {
      margin: 2px 16px 12px 16px; } } }
