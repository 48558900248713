@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-dadata-input {
  $block: &;

  width: 100%;
  position: relative;

  &__suggestion {
    display: none; }

  &:hover {
    & #{$block}__suggestion {
      display: block; } }

  & .react-dadata__suggestion:hover {
    background: $primary-10; }
  & .react-dadata--highlighted {
    color: $primary-100; }

  &__control {
    border-radius: 7px;
    border: 1px solid $secondary-15;
    background-color: $secondary-5;
    font-size: 14px;
    &:hover {
      border-color: $primary-100; }
    &:focus {
      box-shadow: none;
      outline: none;
      border-color: $primary-100; }
    &::placeholder {
      font-size: 14px;
      line-height: 18px;
      color: $secondary-45; }
    &[disabled] {
      background-color: $secondary-5;
      border-color: $secondary-5;
      color: $secondary-40;
      &::placeholder {
        color: $secondary-15; } }
    &_active {
      background-color: $color-white;
      border-color: $secondary-70; }
    &_error {
      border-color: $negative-100;
      background-color: $negative-15;
      &:focus {
        background-color: $color-white;
        border-color: $negative-100; } }
    &_size-md {
      height: 36px;
      padding: 8px 18px; }
    &_size-lg {
      height: 40px;
      padding: 10px 16px; }
    &_size-xl {
      height: 40px;
      padding: 10px 12px;
      @include respond-to(md) {
        padding: 10px 16px; }
      @include respond-to(md) {
        padding: 14px 16px;
        height: 48px; } }
    &_changed {
      border: 1px solid $inert-70; } } }
