@import "../../styles/theme";

.table-item-list {
  display: flex;
  align-items: center;

  &__toggle {
    text-decoration: underline;
    color: $passive-100;
    text-underline: $passive-100;
    font-size: 12px;
    line-height: 18px;
    margin-left: 4px;
    cursor: pointer;
    min-width: max-content;

    &:hover {
      opacity: 0.7; } }

  &-modal {
    display: flex;
    width: 100%;
    flex-direction: column;

    &__item {
      display: flex;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      padding: {
        top: 12px;
        bottom: 12px; }
      border-bottom: 1px solid $secondary-5;
      word-break: break-word; }
    &__index {
      margin-right: 16px;
      word-break: initial; } } }
