@import "../../../styles/theme";
@import "../../../styles/mixins";

.client {
  &-contact-info {
    & .card-app {
      margin-bottom: 15px; }
    &__select {
      width: 100%;

      & .ui.multiple.selection {
        width: 100%;
        border: 1px solid $secondary-15;
        border-radius: 7px; }

      .ui.multiple.dropdown > .label {
        background: $secondary-5;
        box-shadow: none;
        font-weight: normal;
        padding: 7px 10px 10px 12px;

        &:hover {
          background: $color-gray-7; } } }

    &__download {
      font-size: 17px;
      margin-top: 2px; } }

  &-card-block {
    &__content {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; }

    &_yellow {
      color: $inert-70; }

    &__bank-address {
      margin-top: -10px;

      & .label-text_text {
        font-size: 14px; } }

    &-requisites .error.field {
      margin-bottom: 0 !important; }

    & .react-dadata {
      margin-bottom: 11px !important; }

    & .ui.input {
      margin-bottom: 0; }

    &_link {
      cursor: pointer;
      margin: 4px 0;
      color: $passive-100;

      &:hover {
        opacity: .6; } }

    &-container {
      height: 215px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      &_scan {
        display: flex;
        flex-direction: row;
        justify-content: center; } }

    &-drop-zone {
      @include drop-zone-app; }

    &__img {
      max-width: 100%;
      max-height: 100%; }

    &__text {
      font-size: 24px;
      font-weight: bold; }

    &__label {
      margin-bottom: 11px;
      width: 100%;

      &_add-margin {
        margin-bottom: 20px; } }

    &__icon {
      margin-bottom: 20px; } }

  &-form {
    &_edit > div:not(.card-app-header) {}
    // margin-bottom: 11px
    &__control {
      width: 100%;

      & > div:not(:last-child) {
 } } } }        //margin1-bottom: 10px

.divider-fluid {
  margin: 17px -20px !important; }

