@import "../../../styles/mixins";

.settings-info {
  &__image-container {
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  &__operator-block {
    @media screen and (max-width: 991px) {
      height: 100%; } }
  &__card {
    &-header {
      align-items: flex-start; } }
  & .divider-fluid {
    margin: 17px 0 !important; } }
