@import "../../../../styles/theme";

.order-list-status {
  padding: 6px 9px;
  border-radius: 7px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  white-space: nowrap;
  &_published {
    color: $color-white;
    background-color: $primary-100; }
  &_contractor-search {
    color: $primary-100;
    background-color: $primary-10; }
  &_draft {
    color: $secondary-70;
    background-color: $color-white;
    border: 1px solid $secondary-15; }
  &_closed {
    color: $secondary-70;
    background-color: $secondary-5; } }
