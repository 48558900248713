.tree-node {
  padding: 0;
  width: 100%;
  &_offset {
    padding-left: 24px; }
  &_noChevronIcon {
    padding-left: 56px; }
  &__row {
    display: flex;
    align-items: center;
    margin: 8px 0;
    &_column {
      flex-direction: column;
      align-items: flex-start; } }
  &__chevron-icon {
    margin-right: 8px;
    min-width: 24px; }
  &__li {
    margin-left: 0;
    list-style-type: none; }
  &__checkbox-label {
    word-break: break-word; } }
