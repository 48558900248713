@import "../../../styles/mixins";

.adaptive-icon {
  display: flex;

  &_size-md {
    width: 18px;
    height: 18px;
    @include respond-to(md) {
      width: 20px;
      height: 20px; } }

  &_size-xl {
    width: 20px;
    height: 20px;
    @include respond-to(md) {
      width: 24px;
      height: 24px; } } }
