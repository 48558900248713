@import "../../../../styles/theme";

.stop-words-list {
  padding: 0;
  flex-grow: 1;
  position: relative;
  flex-shrink: 0;

  &__stop-word {
    &-edit {
      cursor: pointer;
      color: $primary-100;
      &:hover {
        transform: scale(1.1); } }
    &-delete {
      cursor: pointer;
      color: $secondary-45;
      &:hover {
        color: $secondary-40; } } } }
