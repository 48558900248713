@import "../../../styles/theme";

.patents-documents-list {
  $block: &;

  &__button-with-tooltip {
    position: relative;
    width: 100%; }

  &__upload {
    width: 100%; } }
