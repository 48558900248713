@import "../../../styles/theme";

.nm-show-more-text {
  &__anchor_type-list {
    font-size: 12px;
    line-height: 18px; }
  &__anchor_type-page {
    font-size: 14px;
    line-height: 20px; }
  &__anchor_anchor-green {
    color: $primary-100!important;
    cursor: pointer;
    font-weight: 500;
    &:hover {
      color: $primary-100!important; } }
  &__anchor_anchor-blue {
    color: $passive-100;
    text-decoration: underline; }
  &__modal {
    &-content {
      display: unset; }
    &-title {
      word-break: break-word; } } }
