@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.contractor-finance-balance {
  &__bank-id {
    font-size: 12px;
    line-height: 18px; }
  &__tax-offer-info {
    @include respond-to(xxl) {
      white-space: unset; }
    &-value {
      display: flex;
      align-items: start;
      white-space: pre-wrap;
      @include respond-to(xxl) {
        align-items: center; } } }
  &__card-info {
    min-width: 220px; } }
