.joining-requests {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__tooltip {
    left: -14px; }
  &__pagination {
    margin-top: 24px; }
  &__icon {
    &_green {
      color: #3F9B14; }
    &_red {
      color: red; } }
  & table thead tr th:nth-child(7) {
    text-align: center; }
  &__btns {
    margin-top: 15px; }
  &__column {
    width: 190px; }
  &__content {
    position: relative;
    flex-grow: 1; } }
