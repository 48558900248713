@import "../../../../styles/theme";

.ticket-params {
  margin-top: 24px;
  @media screen and (min-width: 1920px) {
    display: flex;
    height: 100%; }
  @media screen and (max-width: 767px) {
    margin-top: 16px; }
  &__edit-block {
    overflow: unset;
    height: max-content;
    @media screen and (min-width: 768px) and (max-width: 1919px) {
      margin-bottom: 24px; }
    @media screen and (min-width: 1920px) {
      height: unset;
      width: 30%;
      margin-right: 24px; } }
  &__comments {
    margin-top: 0;
    @media screen and (max-width: 767px) {
      margin-top: 16px; }
    @media screen and (min-width: 1920px) {
      width: 70%;
      padding-right: 16px; }
    &-list {
      max-height: 450px;
      overflow: auto; }
    & .nm-empty-page {
      border-radius: 8px; } }
  &__form {
    padding: 24px 16px;
    border: 1px solid $secondary-10;
    border-radius: 8px;
    justify-content: space-between;
    &-description {
      white-space: pre-wrap; } }
  &__chat {
    width: 100%;
    &-messages {
      height: 530px; } }
  &__form-description {
    width: 100%; } }
