@import "../../../../styles/mixins";
@import "../../../../styles/theme";

.context-menu-content {
  $block: &;
  &_modal {
    max-width: 246px; }
  max-width: 280px;
  @include respond-to(md) {
    max-width: 340px; }

  &:before {
    display: none;
    @include respond-to(md) {
      display: block; } }

  &_inline {
    @media screen and (max-width: 767px) {
      right: -12px; } }

  &__text-container {
    display: flex;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
    justify-content: space-between; }

  &__item-value {
    display: flex;
    align-items: center; }

  &__selected-icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    color: $primary-100;
    margin-left: 4px;
    @include respond-to(md) {
      width: 20px;
      height: 20px;
      min-width: 20px; } }

  &__item {
    color: $secondary-80;
    padding: 10px 12px 9px 12px;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    &_disabled {
      cursor: default;
      color: $secondary-35; }
    &_link {
      padding: 0; }
    &:not(#{$block}__item_link):first-child:last-child {
      padding: 10px 12px; }
    &:not(:last-child) {
      border-bottom: 1px solid $secondary-5; }
    &:hover:not(#{$block}__item_disabled) {
      background-color: $primary-10;
      border-top: none;
      margin-top: -1px;
      padding-top: 11px;
      padding-bottom: 10px;
      border-bottom: none;
      &:not(#{$block}__item_link):first-child:last-child {
        margin-top: 0;
        padding: 10px 12px; }
      &:first-child {
        border-top-left-radius: 7px;
        border-top-right-radius: 7px; }
      &:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        padding-bottom: 9px; }
      &#{$block}__item_link {
        padding: 0; }
      & #{$block}__icon {
        color: $primary-100; } } }

  &__icon {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 16px; }

  &__link {
    width: 100%;
    padding: 10px 12px 9px 12px;
    display: flex;
    align-items: center;
    color: $secondary-80;
    &:hover:not(#{$block}__item_disabled) {
      background-color: $primary-10;
      color: $secondary-80;
      padding-top: 11px;
      padding-bottom: 10px;
      & #{$block}__icon {
        color: $primary-100; } } } }
