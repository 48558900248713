@import '../../../styles/theme';
@import '../../../styles/mixins';

$backgroundColor: #F9F9F9;
$greenColor: #4CA64B;
$greyColor: #858585;
$textColor: #898989;

.login-auth {
  width: 100%;

  &__form {
    flex-direction: column;
    align-items: flex-start;
    display: flex; }

  &__button {
    width: 100%;
    @include respond-to(md) {
      width: 160px; } }

  &__join-text {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: $open-naimix-black-50;
    margin: 24px 0 16px 0;
    @include respond-to(md) {
      margin: 32px 0 24px 0;
      font-size: 14px;
      line-height: 20px; } } }
