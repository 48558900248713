@import "../../styles/theme";

.receipt {
  background: $primary-10;
  border: 1px solid $primary-10;
  border-radius: 7px;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  cursor: pointer;
  &__icon {
    color: $primary-100;
    display: flex;
    margin-right: 8px; }
  &__text {
    font-size: 12px;
    line-height: 16px;
    color: $primary-100;
    @media screen and (max-width: 1500px) {
      line-height: 13px; } } }
