@import "../../../../styles/theme";

.patents-card-ifns {
  &__more {
    color: $passive-100;
    cursor: pointer;
    text-decoration: underline; }
  &__content {
    flex-direction: column;
    width: 100%; } }
