@import "../../../../styles/theme.sass";
@import "../../../../styles/mixins";

.contractor-list-info {
  &__check {
    display: flex;
    align-items: center;
    margin-right: 10px; }

  &__indicators {
    flex-flow: wrap;
    display: flex;
    margin-bottom: -4px; }

  &__main-speciality {
    display: none;
    @include respond-to(md) {
      display: flex;
      align-items: center; }
    &_mobile {
      display: block;
      color: $secondary-70;
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 2px;
      overflow: hidden;
      line-height: 18px;
      @include respond-to(md) {
        display: none; } } }

  &__speciality-content {
    @media screen and (max-width: 767px) {
      overflow: hidden;
      flex-flow: unset; }
    &_full {
      overflow: unset;
      width: 100%;
      flex-flow: wrap; } }

  &__speciality-item-container {
    overflow: hidden; }

  &__pr-header-spec {
    max-width: 576px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    @include respond-to(xxl) {
      max-width: 800px; }
    &_openFilter {
      max-width: 576px; } }

  &__pr-header-container {
    display: flex;
    align-items: center; }

  &__grid-container {
    display: grid;
    a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }

    @media only screen and (min-width: 1920px) {
      max-width: 600px; } }

  &__pr-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &_speciality {
      margin-top: -9px;
      flex-wrap: wrap;
      @include respond-to(md) {
        margin-top: 0; } }
    @include respond-to(md) {
      align-items: center;
      flex-direction: row; }
    @include respond-to(xxl) {
      align-items: flex-start;
      flex-direction: column; }

    &-link {
      display: grid; } }

  &__selected-list-item {
    @media screen and (max-width: 767px) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } }

  &__primary-header {
    padding-right: 0;
    display: block;
    @include respond-to(md) {
      padding-right: 48px; }
    @include respond-to(xxl) {
      padding-right: 450px; } }

  &__primary-header-value {
    width: 100%; }

  &__delimiter {
    width: 4px;
    height: 4px;
    min-width: 4px;
    background: $secondary-70;
    border-radius: 10px;
    margin: 0 8px; }

  &__all-spec {
    @media only screen and (min-width: 1920px) {
      max-width: 600px; }

    @media only screen and (min-width: 768px) {
      margin-right: 50px; } }

  &__name {
    display: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden !important;
    width: 100%; } }
