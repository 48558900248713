@import "../../../styles/mixins";

.media-buttons {
  display: flex;
  align-items: center;
  &__button-icon {
    &_rotated {
      transform: rotate(180deg); } }
  &__button {
    &_additional {
      @include respond-to(md) {
        &:not(:first-child:last-child) {
          margin-right: 24px; } } }
    &_ml-md:not(:first-child) {
      margin-left: 8px;
      @include respond-to(md) {
        margin-left: 16px; } }
    &_ml-sm:not(:first-child) {
      margin-left: 8px; } } }
