@import "../../../styles/theme";
@import "../../../styles/mixins";

.department-federal-migration-new {
  width: 1250px;
  z-index: 9999;
  & .row {
    height: 100%; }
  @media screen and (max-width: 1280px) {
    width: 100%; }
  &__map {
    height: 472px;
    @media screen and (max-width: 767px) {
      height: 350px; } }
  & textarea {
    resize: none!important; }
  &-textarea {
    margin-bottom: 15px!important; }
  &-row {
    display: flex;
    margin-bottom: 15px!important;
    justify-content: space-between;
    @media screen and (max-width: 1280px) {
      flex-direction: column; } }
  &-dates {
    @media screen and (max-width: 1280px) {
      flex-direction: column; } }
  &-date {
    margin-right: 10px!important;
    width: 150px!important;
    @media screen and (max-width: 1280px) {
      width: 100%!important;
      margin-bottom: 8px!important; } }
  &-container {
    margin-top: 15px;
    justify-content: flex-end;
    @include flex-row;
    @media screen and (max-width: 1280px) {
      flex-direction: column; }
    & .flex {
      @media screen and (max-width: 1280px) {
        flex-direction: column; } }
    & div {
      @media screen and (max-width: 1280px) {
        width: 100%;
        margin-bottom: 5px;
        text-align: center; } }
    & .order-modal__action {
      @media screen and (max-width: 1280px) {
        margin-right: 0; } } }
  &-column {
    @extend %wrapper-flex-column; }
  &-accomodation {
    margin-bottom: 7px; }
  &__select {
    margin-bottom: 15px;
    flex-direction: column;
    width: 100%; } }

.sentence_info-block {
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  padding: 8px 15px;
  border-radius: 7px;
  display: flex; }
.desk__title {
  color: $secondary-75;
  font-size: 13px; }
.info-block__desk {
  display: flex;
  align-items: center;
  &>div {
    margin-right: 15px; } }
.desk__content {
  display: flex;
  font-size: 14px;
  &>div {
    margin-right: 15px; }
  &-name {
    display: inline-block;
    margin-right: 5px; }
  &-volume {
    display: inline-block;
    background-color: $color-white;
    font-size: 12px;
    padding: 0 6px;
    border-radius: 900px; } }
