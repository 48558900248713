@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.onboarding-scenario-edit-form {
  &__preview-box {
    width: 100%;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 16px;
    margin-top: 8px; } }
