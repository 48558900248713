@import "../../../styles/theme";

.order-schedule-add {
  width: 288px;
  &__layer {
    display: flex!important;
    position: fixed!important; }
  &__form {
    display: flex;
    justify-content: center; }
  &__time {
    width: 55px;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    padding: 0 9px 0 6px;
    border-radius: 7px;
    &:not(:last-child) {
      margin-bottom: 19px; }
    &_selected {
      background-color: #DADADA; } }

  &__col {
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    width: 68px;
    overflow: hidden;
    text-align: center;
    position: relative;
    &:not(:last-child) {
      margin-right: 20px; }
    @mixin title {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      background-color: #fff;
      padding: 8px 0 13px 0;
      font-size: 16px;
      line-height: 19px;
      color: $secondary-45; }
    &_start {
      &:before {
        content: "с";
        @include title; } }
    &_end {
      &:before {
        content: "по";
        @include title; } } }
  &__container {
    padding: 46px 7px 8px 7px;
    width: 88px;
    height: 229px;
    overflow: auto; } }

