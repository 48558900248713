.photo-report {
  width: 1000px;
  &__title {
    font-size: 18px;
    line-height: 21px;
    min-height: 21px; }
  &__image {
    margin-right: 15px;
    max-width: 441px;
    &_spaced {
      min-width: 441px; }
    & img {
      width: 100%; } }
  &__row {
    display: flex; }
  &__map {
    width: 100%;
    min-height: 515px;
    &_short {
      width: 50%; } } }
