@import "../../styles/theme";
@import "../../styles/mixins";

.contractor-statistics {
    display: block;

    &__category {
        &:not(:last-child) {
            margin-bottom: 22px; } }

    &__category-name {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 22px; }

    &__subcategories {
        @include reset-list; }

    &__subcategories-item {
        margin-left: 15px;
        position: relative;
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 14px; }
        &::before {
            display: block;
            position: absolute;
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #B6BAC3;
            left: -15px; } }

    &__subcategories-item {
        display: flex;
        justify-content: space-between;
        align-items: center; }

    &__subcategory-decoration {
        display: block;
        border-bottom: 1px solid #E4E4E4;
        flex-grow: 1;
        height: 10px;
        margin: 0 10px 0 7px; } }
