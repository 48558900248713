@import "../../styles/theme";
@import "../../styles/mixins";

.contractor-info-card-content {
  display: flex;
  flex-wrap: wrap;
  &__labels {
    &-item {
      &:not(:last-child) {
        margin-bottom: 8px; } }
    &-row {
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child) {
        margin-bottom: 8px; }
      & .contractor-info-card-content {
        &__labels-item {
          @media screen and (min-width: 768px) {
            margin-bottom: 0; } } } } }
  &__scans {
    &-block {
      &:not(:last-child) {
        margin-right: 80px; }
      &-title {
        margin-bottom: 16px; } }
    &-item {
      &-container {
        max-width: 200px;
        &:not(:last-child) {
          margin-right: 16px; } }
      &-img-container {
        border-radius: 8px;
        padding: 12px;
        height: max-content;
        border: 1px solid $secondary-15;
        cursor: zoom-in;
        & img {
          width: 100%;
          max-height: 100%; } } } }
  &__full-screen-scan {
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 10;
      background: rgba(0, 0, 0, .4);
      backdrop-filter: blur(6px);
      height: 100%;
      overflow: auto;
      img {
        max-height: 100%  !important;
        max-width: 100%;
        cursor: zoom-out; } } } }
