@import "../../../styles/theme";
@import "../../../styles/mixins";

.advertisement-card {
  &__title {
    overflow: hidden; }
  &__name {
    margin-bottom: 6px;
    word-break: break-all; }
  &__info-text {
    margin-bottom: 16px; }
  &__link {
    cursor: pointer; }
  &__selected-list {
    font-size: 12px;
    line-height: 18px;
    @include respond-to(xxl) {
      font-size: 14px;
      line-height: 20px; }
    &-item {
      font-weight: 500; } }
  &__contractors {
    &-title {
      width: 100%; } }
  &__tabs {
    margin-bottom: 16px; } }
