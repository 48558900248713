@import "../../../styles/theme";

.client-member-list {
  position: relative;
  &__add {
    margin-right: 20px; }
  &__title {
    line-height: 36px; }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #777777;
    width: 100%;
    & > i.icon {
      color: $primary-100;
      font-size: 16px; }
    & .material-icons {
      cursor: pointer;
      color: $primary-100;

      &:hover {
        opacity: 0.9; } } }
  &_no-member {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
    &-text {
      font-size: 24px;
      font-weight: bold; } }
  &-add-row {
    border-radius: 7px !important;
    cursor: pointer;
    position: relative !important; }
  &-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    &-header {
      flex-basis: 7%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 38px; }

    &-table {
      flex-basis: 87%;
      flex-grow: 1; }

    &-pagination {
      display: flex;
      justify-content: center; } } }

.add-member-form {
  > div {
    display: flex;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 10px; } } }
