@import "../../styles/theme";
@import "../../styles/mixins";

.nm-color-picker {
  display: flex;
  align-items: center;
  &__label {
    margin-right: 8px; }
  &__current-color {
    border-radius: 50%;
    &_size-xl {
      width: 30px;
      height: 30px; }
    &_size-md {
      width: 24px;
      height: 24px; }
    &_border {
      border: 1px solid #999999; }
    &_cursorPointer {
      cursor: pointer; } } }
