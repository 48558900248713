@import "../../../styles/theme";
@import "../../../styles/mixins";

.box {
  &_theme-default {
    padding: 10px 16px;
    border: 1px solid $secondary-10;
    border-radius: 8px;
    @include respond-to(md) {
      padding: 8px 12px; } }
  &_theme-list {
    position: relative;
    padding: 12px;
    border: 1px solid $secondary-10;
    border-radius: 6px;
    @include respond-to(md) {
      padding: 12px 16px;
      border: 1px solid $secondary-5; }
    @include respond-to(xxl) {
      padding: 24px 16px; } }
  &_theme-page {
    padding: 12px;
    border: 1px solid $secondary-15;
    border-radius: 8px;
    @include respond-to(md) {
      padding: 16px; } }
  &_noBorder {
    border: none; }
  &_noRadius {
    border-radius: 0; }

  &__name {
    font-size: 12px;
    line-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include respond-to(xxl) {
      font-size: 16px;
      line-height: 24px; } }

  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 8px; }

  &__edit {
    margin-left: 8px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    color: $secondary-70;
    cursor: pointer; } }
