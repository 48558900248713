.mailings-list {
  &__filter {
    &-actions {
      margin-bottom: 15px; }
    &-field {
      width: 290px; } }
  &__count {
    width: max-content; }
  &__table {
    @media screen and (max-width: 1710px) {
      width: 1710px; }
    & .row {
      flex-wrap: nowrap!important; }
    & .row .column {
      &:nth-child(1) {
        width: 8%!important; }
      &:nth-child(2) {
        width: 15%!important; }
      &:nth-child(3) {
        width: 12%!important;
        min-width: 150px; }
      &:nth-child(4) {
        width: 18%!important; }
      &:nth-child(5) {
        flex-grow: 1; }
      &:nth-child(6) {
        width: 10%!important;
        min-width: 107px; }
      &:nth-child(7) {
        width: 10%!important; }
      &:nth-child(8) {
        width: 10%!important;
        min-width: 107px; } } } }
