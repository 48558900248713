@import "../../../../styles/theme.sass";

.invite-contractor-to-order-form {
  &__pagination {
    margin-top: 32px; }
  &__table {
    height: unset !important; }
  .nmx-grid-table {
    margin-bottom: 15px;
    .grid-checkbox {
       min-width: 600px !important; }
    .row .column {
      &:nth-child(1) {
        width: 50px !important; }
      &:nth-child(2) {
        width: 180px !important; }
      &:nth-child(3) {
        width: 180px !important; }
      &:nth-child(4) {
        width: 190px !important; } } }
  &__status-field {
    &_green {
      color: $primary-100; }
    &_gray {
      color: $secondary-45; } }
  &__order-num-field {
    &-date {
      font-size: 12px;
      color: $secondary-100; } }
  &__warning {
    margin: 10px; } }
