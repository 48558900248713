.invite-contractor-to-fc-registry-filter {
  &__buttons {
    margin-bottom: 15px;
    margin-top: 15px; }

  &__field {
    &_mb {
      margin-bottom: 35px !important; } }

  &__row {
    display: flex;
    & > div {
      width: 50%;
      &:first-child {
        margin-right: 8px; } } }

  &.ui.form .field .ui.input input {
    border-radius: 7px; }

  .nm-label__label {
    white-space: nowrap; } }
