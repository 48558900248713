@import "../../styles/theme";

.nm-empty-page {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E4E4E4;
  height: 80vh;
  &__icon {
    color: rgba(153, 153, 153, 0.1);
    width: 64px;
    height: 64px; }
  &__text {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: $secondary-100;
    &_mt {
      margin-top: 26px; } } }
