@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.migration-card {
  &_border {
    border: 5px solid $inert-70; }
  &__dates {
    & > div:nth-child(2) {
      padding-left: 0; } }
  &__divider {
    display: flex;
    height: 1px;
    background-color: rgba(34, 36, 38, .15);
    margin: 12px 0;
    @include respond-to(md) {
      margin: 16px 0; }
    &_yellow {
      background-color: $inert-70; } } }
