@import "../../../../styles/mixins";

.not-loaded-numbers {
  &__list {
    overflow: auto;
    max-height: calc(100vh - 188px);
    @include respond-to(md) {
      max-height: calc(100vh - 210px); }
    @include respond-to(xxl) {
      max-height: calc(100vh - 322px); } } }
