@import "../../styles/theme";
@import "../../styles/mixins";

.code-confirm {
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }

  &__timer {
    margin-top: 8px;
    color: $primary-100;
    @include respond-to(md) {
      margin-top: 12px; }
    @include respond-to(xxl) {
      margin-top: 16px; } }

  &__send-button {
    cursor: pointer; }

  &__confirm-button {
    width: 100%;
    @include respond-to(md) {
      width: auto; } }

  &__title {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $secondary-100; }

  &__code {
    display: flex;
    justify-content: center; }

  &__phone-info {
    text-align: center;
    margin-bottom: 16px; }

  &__send-code-buttons {
    .nm-button {
      width: 100%; } } }
