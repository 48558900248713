@import '../../../styles/theme';
@import '../../../styles/mixins';

.document-management-loading-document {
  $block: &;
  width: 100%;

  &__pdf-viewer {
    margin-top: 8px;
    min-height: calc(100vh - 260px);
    max-height: calc(100vh - 260px);
    @include respond-to(md) {
      margin-top: 16px;
      min-height: calc(100vh - 278px);
      max-height: calc(100vh - 278px); }
    @include respond-to(xxl) {
      min-height: calc(100vh - 394px);
      max-height: calc(100vh - 394px); } }

  &__dynamic-form-card {
    margin-top: 12px;
    @include respond-to(md) {
      margin-top: 16px; }
    &:not(:last-child) {
      padding-bottom: 16px;
      border-bottom: 1px solid $secondary-15; } }

  &__link-pre-filled-template {
    margin-left: 30px;
    font-size: 12px;
    color: $passive-50;
    cursor: pointer; }

  &__link-pdf-helper {
    font-size: 12px;
    line-height: 20px; }

  &__date-picker {
    .datepicker-range__row {
      width: 100%; }
    .datepicker-range, .field {
      width: 100%; } }

  &__apply-buttons {
    width: 100%;
    justify-content: space-between; } }
