@import "../../../styles/mixins";

.order-edit {
  $block: &;

  background: #FFFFFF;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  position: relative;
  z-index: 9999;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @include respond-to(xl) {
    max-width: 1202px;
    max-height: calc(100vh - 120px); }

  &__fields {
    width: 100%;
    @include respond-to(xl) {
      margin-right: 24px;
      min-width: 640px;
      width: 640px; } }

  &__label-container {
    display: flex;
    justify-content: space-between; }

  &__add-document {
    justify-content: center; }

  &__popup-content {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1; }

  &__main-content {
    @include respond-to(xl) {
      padding: 0 20px 10px 0;
      overflow: auto; } }
  &__main-content-row {
    display: flex;
    flex-direction: column;
    @include respond-to(xl) {
      flex-direction: row; } }

  &__suggestion {
    &_main {
      transform: unset;
      top: 33px;
      max-width: calc(100% - 32px);
      left: 16px;
      &:before {
        display: none; }
      @include respond-to(md) {
        max-width: 320px;
        left: auto;
        right: 32px; }
      @include respond-to(xxl) {
        left: calc(100% + 10px);
        right: -250px;
        top: 55px;
        transform: unset;
        &:before {
          display: block;
          top: auto;
          bottom: 18px; } } } }

  &__keywords-suggestion {
    top: calc(50% + 12px); }
  &__hover-suggestion {
    display: flex; }
  &__input {
    width: 100%; }
  &__work-init-price-divider {
    right: 100px;
    left: auto!important; }
  &__description-counter {
    position: absolute;
    right: 8px;
    font-size: 11px;
    line-height: 13px;
    color: #979797;
    bottom: 3px; }
  &__project {
    width: 100%;
    margin-bottom: 16px;
    @include respond-to(md) {
      width: 50%;
      margin-bottom: 0;
      margin-right: 16px; }
    @include respond-to(xl) {
      min-width: 312px;
      width: 312px; } }

  &__count {
    margin-right: 20px!important; }
  &__prompt {
    position: absolute;
    right: 52px;
    top: 15px;
    cursor: pointer;
    &-suggestion {
      right: -143px;
      top: 36px; }
    &_on {
      width: 18px;
      height: 18px;
      background: url("../../../images/prompt-on-icon.svg") center no-repeat; }
    &_off {
      width: 18px;
      height: 20px;
      background-size: contain;
      background: url("../../../images/prompt-off-icon.svg") center no-repeat; } }
  &__work-unit-price {
    width: 136px;
    min-width: 136px;
    @include respond-to(md) {
      width: 100%;
      min-width: unset; } }
  &__error-keywords {
    width: max-content; }
  &__form {
    position: unset;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    @include respond-to(xl) {
      max-height: calc(100vh - 362px); } }
  &__header {
    margin-bottom: 16px;
    @include respond-to(md) {
      margin-bottom: 24px; } }
  &__salary-contractor {
    padding-left: 20px;
    background: url("../../../images/order-cost-per-one-icon.svg") no-repeat;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #128A10;
    height: 14px; }
  &__steps {
    margin-bottom: 16px;
    @include respond-to(md) {
      margin-bottom: 20px; }
    @include respond-to(xl) {
      margin-bottom: 24px; } }
  &__i-header {
    color: $secondary-90;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 4px; }
  &__col {
    display: flex;
    flex-direction: column;
    width: 100%; }
  &__file-zone {
    display: flex;
    flex-direction: column;
    &_uploaded {
      & div:first-child {
        flex-grow: 1; } }
    & div:first-child {
      overflow: hidden; }
    & > div {
      &:focus {
        outline: none; } } }
  &__file-zone-content {
    display: flex;
    align-items: center; }
  &__file-zone-error {
    color: $negative-100; }
  &__loader.ui.loader {
    z-index: 990; }
  &__document {
    align-items: center;
    display: flex;
    color: $primary-100; }
  &__document-name {
    color: $primary-100;
    margin-left: 4px;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  &__document-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    min-width: 20px;
    color: $primary-100;
    &_ml {
      margin-left: 4px; }
    &_red {
      color: $negative-100; } }
  &__specialties {
    &-additional {
      font-size: 12px;
      line-height: 16px;
      color: $secondary-70;
      background-color: #F9FAFB;
      border-radius: 7px;
      padding: 20px;
      &:first-child {
        margin-bottom: 10px; } }
    &-icon {
      margin-left: 5px;
      display: inline-block;
      vertical-align: bottom; }
    &-p:not(:last-child) {
      margin-bottom: 10px; } } }
