@import "../../../styles/theme";

.advertisement-edit-modal {
  &__file {
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  &__description {
    margin-right: 4px;
    white-space: nowrap;
    color: $secondary-75; }
  &__file-icon {
    color: $secondary-45;
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-left: 4px;
    cursor: pointer; }
  &__file-name {
    text-overflow: ellipsis;
    overflow: hidden;
    color: $passive-100;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: $passive-100;
      text-decoration: underline; } } }
