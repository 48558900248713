@import "../../../styles/theme";
@import "../../../styles/mixins";

.order-analytics {
  &__table-link {
    color: $passive-100;
    cursor: pointer; }

  &__button {
    width: 100%;
    @include respond-to(md) {
      width: auto; } }

  &__button-container {
    margin-top: 12px;
    width: 100%;
    @include respond-to(md) {
      width: auto;
      margin-top: 24px; }
    @include respond-to(xxl) {
      margin-top: 32px; } }

  &__list {
    min-height: auto; } }
