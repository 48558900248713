@charset "utf-8";
@import "../fonts/roboto/style.css";
@import "../fonts/dinPro/stylesheet.css";
@import "../fonts/Monteserrat/style.css";
@import "theme";
@import "mixins";
@import "ui";
@import "icon";

html,
body,
#index {
  height: 100%; }

*, :after, :before {
  box-sizing: border-box; }

body {
  padding: 0;
  margin: 0;
  background-color: $background-color-default;
  font-family: $font-family-default; }
.full-height {
  height: 100%; }
h3 {
  font-family: $font-family-default; }

button {
  font-family: $font-family-default; }

.scroll-hidden {
  height: 100%;
  overflow: hidden; }

.mg-right-20 {
  margin-right: 20px !important; }

.mg-top-36 {
  margin-top: 36px; }

.mg-top-20 {
  margin-top: 20px; }

.pd-right_16 {
  padding-right: 16px; }

.pd-top_15 {
  padding-top: 15px; }

.pd-top_42 {
  padding-top: 42px; }

.w-47 {
  width: 47%; }

.button-download {
  button {
    background: #E9F4E5 !important;
    border: 1px solid #D8EAD0 !important;
    box-sizing: border-box;
    border-radius: 7px !important;
    color: #128A10 !important;
    height: 32px;
    font-size: 12px !important; } }

.form > .input:not(:last-child) {
  margin-bottom: 10px; }

.field > label {
  opacity: 0.7; }
.no-gutters-app {
  margin-right: 0;
  margin-left: 0; }

.react-datepicker-wrapper {
  width: 100%; }

.nm-border-bottom {
  &:not(:last-child) {
    border-bottom: 1px solid $secondary-5; } }

.react-datepicker__input-container {
  width: 100%; }

.margin-none {
  margin: 0 !important; }

.label-app {
  opacity: 0.7;
  padding-left: 0;
  padding-right: 0;
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none; }
.nm-icon {
  &_gray {
    color: $secondary-45; } }
.item {
  font-family: $font-family-default !important;
  color: $passive-100 !important;
  line-height: 16px !important;

  &:hover {
    font-family: $font-family-default !important; } }

.header-app {
  font-family: $font-family-default !important;

  &:hover {
    font-family: $font-family-default !important; } }

.text-app {
  font-family: $font-family-default !important;
  &_right {
    text-align: right; } }

.text-card-value {
  font-family: $font-family-default !important;
  color: #666666;
  font-weight: 600; }

.text-card {
  &_label {
    font-family: $font-family-default !important; }

  &_value {
    font-family: $font-family-default !important;
    color: #666666;
    font-weight: 600; } }

.text-button {
  font-family: $font-family-default !important;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px; }

.text-button__small {
  font-family: $font-family-default !important;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px; }

.container-app {
  padding-right: 0;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0; }

.default-custom-tabs {
  margin-bottom: 28px; }

.react-datepicker-popper {
  z-index: 22; }

.icon-plus-app__orange {
  color: #B99736; }

.icon-plus-app__blue {
  color: #174A97; }

.icon-plus-app__white {
  color: #FFFFFF; }

.icon-app {
  cursor: pointer;
  width: 20px;
  height: 20px;

  &:hover {
    opacity: 0.6; } }
.page {
  .modal {
    .content {
      white-space: pre-wrap; } } }

.fluid-flex-grow {
  flex-grow: 1; }

.table-actions {
  margin-bottom: 16px; }

// стили для openNaimix
.Toastify__toast--info {
  color: $open-naimix-secondary-100;
  background-color: $open-naimix-black-5;
  box-shadow: 0 0 0 1px $open-naimix-secondary-100 inset, 0 0 0 0 transparent!important;
  & .Toastify__toast-body {
    font-size: 16px;
    line-height: 24px; } }

.Toastify__toast--success {
  box-shadow: 0 0 0 1px #a3c293 inset, 0 0 0 0 transparent;
  background-color: #fcfff5;
  color: #2c662d;
  word-break: break-word; }

.card-header-block {
  display: flex;
  align-items: center;

  & > div:not(:last-child) {
    margin-right: 10px; }

  &_title {
    font-family: $font-family-default;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px; } }

.list-add-block {
  margin-top: 18px !important;
  padding: 16px 20px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15) !important;
  border-radius: 7px !important;
  cursor: pointer;
  border: none !important; }

.background-color__transparent {
  background-color: transparent !important; }

.button {
  &_transparent {
    background-color: transparent !important;
    border: 1px solid #D2D2D2 !important;
    box-sizing: border-box !important;
    border-radius: 7px !important;
    padding: 10px 17px !important;
    cursor: pointer;
    line-height: 20px !important;
    color: #666666 !important;

    & > i {
      margin-right: 12px !important; } }

  &_invite {
    font-size: 12px;
    line-height: 14px;
    border: 1px solid #65AF43;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 10px 29px 12px 29px;
    cursor: pointer; }

  &_transparent-small {
    background: transparent;
    line-height: 16px;
    font-size: 14px;
    color: #333;
    padding: 12px 27px;
    @extend %button; }

  &_yellow-big {
    background-color: #FFDB4D;
    padding: 14px 40px;
    font-size: 21px;
    line-height: 21px;
    @extend %button; }

  &_green {
    background: #65AF43;
    padding: 12px 16px;
    border: 1px solid #65AF43;
    box-sizing: border-box;
    border-radius: 7px;
    color: #fff;
    cursor: pointer; }

  &_green-big {
    background-color: #128A10;
    padding: 12px 27px;
    font-size: 21px;
    color: #fff;
    line-height: 16px;
    @extend %button; } }

.flex-aligned-start {
  align-items: start; }

.flex-aligned-center {
  align-items: center; }

.flex-aligned-end {
  align-items: flex-end; }

.flex-content-center {
  justify-content: center; }

.flex-content-spaced {
  justify-content: space-between; }

.flex-content-start {
  justify-content: flex-start; }

.flex-content-end {
  justify-content: flex-end; }

.flex-content-auto {
  flex: auto; }

.flex-flow-wrap {
  flex-flow: wrap; }

.flex-flow-row-reverse {
  flex-flow: row-reverse; }

.no-padding-container {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.scrolled {
  overflow: auto; }

.relative {
  position: relative; }

.fluid {
  width: 100%; }

.pointer {
  cursor: pointer; }
.mr-10 {
  margin-right: 10px; }
.mr-15 {
  margin-right: 15px; }
.mr-6 {
  margin-right: 6px; }
.ml-15 {
  margin-left: 15px !important; }
.mr-12 {
  margin-right: 12px; }
.ml-12 {
  margin-left: 12px; }
.ml-16 {
  margin-left: 16px; }

.mb-35 {
  margin-bottom: 35px; }
.mb-15 {
  margin-bottom: 15px; }
.mb-20 {
  margin-bottom: 20px; }
.mr-20 {
  margin-right: 20px; }

.mb-10 {
  margin-bottom: 10px; }

.mr-2 {
  margin-right: 2px; }

.ml-2 {
  margin-left: 2px; }

.separator {
  display: flex;
  height: 1px;
  background: #E0E0E0;
  &-380 {
    width: 380px; } }

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.ui.radio.checkbox input:checked ~ label:after {
  border-radius: 50%; }


.segment-button {
  margin-top: 0 !important;
  padding: 16px 20px !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15) !important;
  border-radius: 7px !important;
  cursor: pointer;
  border: none !important; }

.no-shadow {
  box-shadow: none !important; }

.modal-layer {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 999;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);

  &_fixed {
    position: fixed !important; }

  &_flex {
    display: flex !important; }

  &_centered {
    justify-content: center !important;
    align-items: center !important; }

  &-fixed {
    position: fixed;
    overflow: auto;
    padding: 10px;
    left: 0;
    right: 0;
    z-index: 1000;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25); } }

.count-list {
  font-size: 14px;
  font-weight: normal;
  vertical-align: super;
  &_green {
    background: green !important;
    padding: 2px 5px;
    color: white !important;
    border-radius: 1000px; } }
.empty-select {
  &.field {
    .dropdown {
      background: $secondary-5; } } }
.mt-40 {
  margin-top: 40px; }
.mt-10 {
  margin-top: 10px; }
.mt-15 {
  margin-top: 15px; }
.mt-2 {
  margin-top: 2px; }

.flex-column {
  flex-direction: column; }
.material-icons.icon-comment {
  cursor: pointer;
  &_empty {
    color: #DADADA; }
  &_filled {
    color: #128A10; } }
.flex {
  display: flex; }

.overflow_y__auto {
  overflow-y: auto; }

.flex-vertical-center {
  align-items: center; }

.flex-vertical-start {
  align-items: flex-start !important; }

.flex-vertical-end {
  align-items: flex-end !important; }

.add-margin-right {
  margin-right: 15px !important; }

.nmx-list {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-direction: column;
  overflow: auto;

  &_title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px; }

  &_header {
    @include flex_row__space-between;
    flex-basis: 7%; }

  &_filter {
    & > div:not(:last-child) {
      margin-right: 5px; } }

  &_table {
    margin-top: 25px;
    flex-basis: 80%;
    flex-grow: 1; }

  &_pagination {
    @include flex-row__center;
    padding: 20px 0; } }

.datepicker-input {
  &::-webkit-input-placeholder {
    color: rgba(34, 36, 38, .35); }

  font-family: $font-family-default !important;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  tap-highlight-color: hsla(0, 0%, 100%, 0);
  line-height: 1.21428571em;
  padding: .67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  box-shadow: inset 0 0 0 0 transparent;
  -webkit-transition: color .1s ease, border-color .1s ease;
  transition: color .1s ease, border-color .1s ease; }

.green {
  &-border {
    border: 1px solid $primary-100; }

  &-color {
    color: $primary-100; } }

.edit-icon {
  color: $primary-100;
  cursor: pointer;
  &:hover {
    opacity: 0.7; } }

.nmx-btn {
  border: 1px solid !important;
  border-radius: 7px !important;
  cursor: pointer !important;
  box-shadow: none !important;
  line-height: unset !important;
  font-weight: normal !important;
  background: transparent !important;
  text-align: center;

  &-default {
    border-color: #D2D2D2 !important; }

  &-sm-size {
    padding: 5px 13px !important; }

  &-md-size {
    padding: 11px 23px !important;
    @media screen and (max-width: 800px) {
      width: 100%; } }

  &-grey {
    border: 1px solid #D2D2D2 !important; }

  &-yellow {
    &-filled {
      background-color: #FFDB4D !important; } }

  &-green {
    color: #128A10 !important;
    border-color: #128A10 !important;

    &-filled {
      background-color: #128A10 !important;
      color: #fff !important; } }

  &-blue {
    &-filled {
      background-color: #174A97 !important;
      color: #fff !important;
      font-weight: normal !important;

      & .button-icon {
        color: #fff; } } }

  &-red {
    color: #DD4A4A !important;
    border-color: #DD4A4A !important; }

  &-transparent {
    background-color: transparent !important; } }

.mr-36 {
  margin-right: 36px;
  @media screen and (max-width: 800px) {
    margin-right: 0; } }

.mr-96 {
  margin-right: 96px;
  @media screen and (max-width: 800px) {
    margin-right: 0; } }

.bold {
  font-weight: 600; }

.tr-clickable {
  &:hover {
    cursor: pointer; } }

td {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px !important; }

.Toastify__toast {
  font-size: 14px;
  position: relative;
  min-height: 64px;
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 1px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  max-height: 800px;
  overflow: hidden;
  font-family: sans-serif;
  cursor: pointer;
  direction: ltr;
  top: 64px !important;
  white-space: pre-wrap; }

#zingchart-react-0-license-text {
  display: none !important; }
.column {
  &-5 {
    width: 5%; }

  &-7 {
    width: 7%; }

  &-10 {
    width: 10%; }

  &-20 {
    width: 20%; }

  &-25 {
    width: 25%; }

  &-45 {
    width: 45%; }

  &-35 {
    width: 35%; }

  &-40 {
    width: 40%; }

  &-15 {
    width: 15%; }

  &-10 {
    width: 10%; }

  &-50 {
    width: 50%; }

  &-70 {
    width: 70%; }

  &-100 {
    width: 100%; } }

.text-center {
  text-align: center; }

.mg {
  &-right {
    &-10 {
      margin-right: 10px; } } }

.ml-15 {
  margin-left: 15px; }

.app-button-primary {
  background: $grad-primary !important;
  border-radius: 7px !important;
  color: #FFFFFF !important;
  padding: 11px 25px !important;
  &__save {
    &.ui.button {
      font-family: $font-family-default;
      font-weight: normal;
      background: red;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff; }
    &.ui.disabled.button {
      opacity: 1!important;
      background: $grad-primary !important; } } }

.app-check-button {
  color: #128A10 !important;
  border-radius: 0 !important;
  border-bottom:  1px dashed #128A10 !important;
  padding: 0 !important;
  background: transparent !important;
  &:hover {
    opacity: 0.7; } }
.icon-cancel {
  color: #B6BAC3; }
.app-button-cancel {
  padding: 11px 25px !important;
  border-radius: 7px !important;
  &_border {
    border: 1px solid #DDDDDD; }
  &:hover {
    opacity: 0.7; }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: #000;
    line-height: 21px; } }

.app-button-archive {
  border: 1px solid #D2D2D2 !important;
  background: white !important;
  border-radius: 7px !important; }


/* TABLE adaptive*/
.button-archive {
  width: 100%;
  padding: 6px !important;
  border: 1px solid rgba(218, 218, 218, 0.5) !important;
  box-sizing: border-box !important;
  border-radius: 7px !important;
  color: #979797 !important;
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 14px !important;
  background: white !important; }

//toDo добавить пропс loading и соотвсетвенно спиннер в компонент <NmButton />
.button-reject {
  background: #FFDDCE !important;
  border: 1px solid #FFCFBB !important;
  border-radius: 7px !important;
  //font-family: Roboto !important
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  text-align: center !important;
  color: $negative-100 !important; }

.access-message {
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #F75E1A; }

.adaptive {
  & thead {
    @media screen and (max-width: 1000px) {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px; } }

  & td {
    @media screen and (max-width: 1000px) {
      text-align: right !important;
      width: 100% !important;
      margin: 0;
      max-width: none !important; } }

  & td:before {
    @media screen and (max-width: 1000px) {
      content: attr(data-label);
      float: left;
      font-weight: 500;
      font-size: 12px;
      color: rgba(22, 22, 2, 0.44); } } }

.nmx {
  &-pagination-row {
    position: relative;
    margin-top: 30px;
    min-height: 36px; } }

.app-link {
  color: $passive-100;
  text-decoration: none;
  cursor: pointer;
  max-width: max-content;
  &:hover {
    opacity: 1; } }

.app-link_transparent {
  color: rgba(0, 0, 0, .87);
  &:hover {
    color: rgba(0, 0, 0, .87);
    opacity: 1; } }


.react-dadata {
  & label {
    font-weight: normal !important;
    opacity: 1;
    font-size: 1em !important; } }

.label-required:after {
  content: "*";
  margin: -.2em 0 0 .2em;
  color: #db2828; }

.nmx-row {
  margin: 8px 0; }

.not-found-text {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #174A97; }

.nmx-i-fluid {
  width: 100%;

  & .ui.input {
    width: 100%; } }

.ellipsis.icon {
  margin-right: 0; }

.nmx-table {
  &_settings {
    & tr td {
      & i {
        color: #3F9B14; }

      &:last-child {
        min-width: 100px; } }

    & tr:hover {
      & .settings-members-list__button-edit {
        display: inline-flex;
        margin-right: 6px;
        background: #E9F4E5;
        border: 1px solid #D8EAD0;
        border-radius: 7px;
        padding: 5px 10px; }

      & .settings-members-list__button-label {
        display: block; } }

    & .ui.table {
      border-top: none;
      border-right: 1px solid rgba(34, 36, 38, .15);
      border-left: 1px solid rgba(34, 36, 38, .15);
      border-bottom: 1px solid rgba(34, 36, 38, .15); } } }

.filter-container {
  align-items: flex-end;
  margin-bottom: 20px;
  &_full-width {
    width: 100%; }
  &.ui.form .react-datepicker__input-container input {
    border-radius: 7px;
    border: 1px solid #e0e0e0; }
  &.ui.form .field {
    margin: 0 10px 15px 0;
    &.filter-container__datepicker_start {
      margin-right: 0; }
    &.filter-container__datepicker_end {
      margin: 0 10px 15px 0; } }
  & .filter-buttons {
    margin: 0 10px 15px 0; }
  & .field {
    display: flex;
    flex-direction: column; }
  &__dropdown {
    width: 100%; }
  &__column {
    display: flex;
    flex-direction: column;
    margin: 0 10px 15px 0;
    @media screen and (max-width: 1600px) {
      margin-top: 4px; }
    & .ui.input {
      width: 100%; }
    & .ui.selection.dropdown {
      width: 100%;
      min-width: 100px; }
    &_w10 {
      width: 5.5%;
      @media screen and (max-width: 1600px) {
        min-width: 90px; } }
    &_w15 {
      width: 7.5%;
      @media screen and (max-width: 1600px) {
        min-width: 120px; } }
    &_w20 {
      width: 11.5%;
      @media screen and (max-width: 1600px) {
        min-width: 160px; } }
    &_w30 {
      width: 17.5%;
      @media screen and (max-width: 1600px) {
        min-width: 260px; } } }
  &__label {
    font-size: 12px;
    color: #666666; }
  &_no-margin-bottom {
    margin-bottom: 0; }
  & textarea {
    border-radius: 7px!important; }
  & .ui.form .field {
    margin: 0;
    &>label {
      margin-bottom: 4px;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: #666666;
      opacity: 1; } }
  & .ui.selection.dropdown {
    border: 1px solid rgb(224,224,224);
    border-radius: 7px; }
  &__start-interval {
    & input {
      border-right: none!important;
      border-top-right-radius: 0!important;
      border-bottom-right-radius: 0!important; } }
  &__end-interval {
    & input {
      border-top-left-radius: 0!important;
      border-bottom-left-radius: 0!important; } }
  & .ui.input input {
    border: 1px solid rgb(224,224,224);
    border-radius: 7px; }
  & .datepicker-custom-input, .custom-date-input {
    border-radius: 7px;
    border: 1px solid rgb(224,224,224); }
  &__datepicker {
    &_end {
      & .datepicker-custom-input, .custom-date-input {
        border-radius: 0 7px 7px 0; } }

    &_start {
      & .datepicker-custom-input, .custom-date-input {
        border-right: none;
        border-radius: 7px 0 0 7px; } } } }

.grid-header-divided {
  & div {
    line-height: 16px;
    @media screen and (max-width: 1500px) {
      line-height: 10px; } } }

.link-blue {
  cursor: pointer;
  color: $passive-100; }

.bank-card-number {
  margin-left: 11px; }

.grey-color {
  color: $secondary-45; }


.flex-hard {
  display: flex!important; }

.text-no-wrap {
  white-space: nowrap; }

.hidden-ellipsis-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.nmx-icon {
  cursor: pointer;
  font-size: 18px;
  &_green {
    color: #3F9B14; } }

.nmx-rub {
  font-family: arial, serif;
  font-size: 14px; }

.create-white-icon {
  background: url("../images/create-icon.svg") no-repeat center;
  height: 16px;
  width: 16px; }

.button-clear {
  border: 1px solid #DDDDDD;
  border-radius: 7px;
  height: 36px;
  width: 80px;
  cursor: pointer;
  padding: 11px 14px;
  @include flex-row__center;
  &:hover {
    opacity: .8; }
  &_mg-top {
    margin-top: 18px; }
  &_mg-top-20 {
    margin-top: 20px; }
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #666666; } }
.button-send {
  background: #128A10;
  border: 1px solid #128A10;
  border-radius: 7px;
  padding: 12px 24px;
  height: 38px;
  cursor: pointer;
  margin-right: 10px;
  @include flex-row__center;
  &:hover {
    opacity: .8; }
  &_mg-top {
    margin-top: 17px; }
  &_mg-top-19 {
    margin-top: 19px; }
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF; } }

.flex-shrink-zero {
  flex-shrink: 0; }

.icon-chart {
  cursor: pointer;
  background: #E9F4E5;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 4px 4px 0 4px;
  border: 2px solid #d8ead0;
  & > i {
    color: #128A10;
    font-size: 18px; } }
.b24-widget-button-position-bottom-right {
  bottom: 120px!important; }

input::-ms-reveal {
  display: none; }

.container-fluid {
  padding-right: 0;
  padding-left: 0; }

// from bootstrap reboot
svg {
  overflow: hidden;
  vertical-align: middle; }

.nm-line {
  height: 1px;
  background-color: $secondary-5;
  width: 100%; }

.overflow-hidden {
  overflow: hidden; }

.cursor-pointer {
  cursor: pointer; }

button, input, textarea {
  font-family: "Roboto"; }

.text-break-all {
  word-break: break-all; }

.mb-xl-24px {
  @include respond-to(md) {
    margin-bottom: 24px !important; } }
