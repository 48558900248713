@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-page-vertical-filter {
  width: $filter-width;
  min-width: $filter-width;
  position: fixed;
  z-index: 1100;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: $color-white;
  box-shadow: -8px 4px 16px rgba(0, 0, 0, 0.05);
  &_open {
    z-index: 1300;
    display: block; }
  &_hidden {
    display: none; }
  @media screen and (max-width: 424px) {
    width: 100%;
    min-width: 100%; }

  &__body {
    height: 100%; }

  &__hide {
    position: absolute;
    right: 24px;
    cursor: pointer;
    font-size: 12px;
    line-height: 14px;
    color: $secondary-45; }

  &__title {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 8px;
    padding: 16px 24px;
    background-color: $secondary-5;
    border-bottom: 1px solid $secondary-10; }

  &__layer {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1200;
    background-color: rgba(0, 0, 0, 0.4);
    &_hidden {
      display: none; }
    @include respond-to(xl) {
      display: none; } }

  &__fields {
    max-height: calc(100% - 154px);
    overflow: auto;
    margin-bottom: 8px;
    height: 100%;
    padding: {
      left: 24px;
      right: 24px; }
    @media screen and (max-width: 1199px) {
      padding: {
        left: 16px;
        right: 16px; } }
    & .nm-form_addMargin > div:not(:last-child) {
      margin-bottom: 16px; }
    & .filter-buttons-v2 {
      border-top: $secondary-10;
      background-color: $secondary-5;
      justify-content: center;
      padding: 16px 24px 24px 24px;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      margin-bottom: 0!important;
      z-index: 10; } } }
