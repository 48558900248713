@import "../../../styles/theme";
@import "../../../styles/mixins";

.login-cookie {
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 20;
  width: 100%;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $open-naimix-black-50;
    background-color: $open-naimix-black-5;
    position: relative; }
  &__terms-content {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-100; }
  &__message-terms {
    color: $passive-100;
    text-decoration: underline; }
  &__message {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    padding: 16px;
    width: 100%;
    @include respond-to(md) {
      padding: 24px; }
    @include respond-to(xxl) {
      width: 1320px;
      padding: 31px 0; }
    &_mobile {
      @include respond-to(md) {
        display: none; } }
    &_desktop {
      display: none;
      @include respond-to(md) {
        display: block; } } }
  &__button {
    cursor: pointer;
    white-space: nowrap;
    border-radius: 4px 4px 0 0;
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: $open-naimix-black-15;
    color: $open-naimix-secondary-100;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0;
    width: 200px;
    height: 40px;
    top: -40px;
    left: calc(50% - 100px);
    @include respond-to(xxl) {
      padding: 14px 0;
      font-size: 14px;
      height: 48px;
      top: -48px;
      width: 216px;
      left: calc(50% - 113px); }
    &_promoPoisk {
      background-color: $promo-poisk-color-2;
      color: $promo-poisk-color-4; } } }
