.export-document-filter {
  display: flex;
  align-items: flex-end;
  &__item {
    width: 290px;
    &_pos-relative {
      position: relative; } }
  &__error {
    position: absolute;
    top: 60px; } }
