@import "../../styles/theme";

.map {
  & a:hover {
    color: $secondary-100; }
  &__header {
    z-index: 10;
    margin-left: auto;
    &_listPage {
      position: fixed;
      right: 32px; } }
  &__loader.ui.active.loader {
    &:before {
      border-width: 3.5px!important; }
    &:after {
      border-width: 3.5px!important; } }
  & .leaflet-left {
    position: absolute;
    margin-top: 100px; }
  &__controls {
    margin-top: 12px;
    margin-left: auto; } }
