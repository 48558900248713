@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.settings-directories-flash-call {
  &__table {
    .row .column {
      align-items: baseline;
      &:nth-child(1) {
        width: 200px !important; }
      &:nth-child(2) {
        width: 200px !important; }
      &:nth-child(3) {
        width: unset!important;
        flex-grow: 1; } } } }
