.nm-number-range-picker.flex.flex-aligned-end {
  .nm-input-v2:first-child {
    width: 50%;
    margin-right: 0px !important;
    margin-bottom: 0px;
    input {
      border-radius: 7px 0px 0px 7px !important; } }
  .nm-input-v2:last-child {
    width: 50%;
    margin-bottom: 0px;
    input {
      border-radius: 0px 7px 7px 0px !important; } } }
