@import "../../../../styles/theme";

.settings-log-list {
  & .ui.form, & .ui.dropdown {
    width: 100%; }
  &__fullname {
    color: $passive-100;
    cursor: pointer; }
  &__not-found {
    font-size: 18px;
    margin-top: 50px; }
  &__buttons {
    align-items: baseline;
    margin-top: 20px; }
  &__date-pickers .react-datepicker-wrapper:nth-child(1) {
    margin-right: 20px; }
  &__filter-name {
    margin-bottom: 5px;
    font-size: 13px;
    font-weight: 700;
    opacity: 0.7; }
  .datepicker-range {
    margin-right: 15px; }
  .nm-multiselect {
    width: 300px;
    margin-top: 5px !important;
    margin-right: 15px !important; } }

