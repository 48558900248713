@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-label {
  margin-bottom: 4px;
  position: relative;
  &__label {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 0;
    color: $secondary-75;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; }
    &_disabled {
      color: $secondary-45; } }
  &__required {
    display: inline-block;
    line-height: 18px;
    color: $color-red-salmon;
    margin-left: 4px;
    @include respond-to(md) {
      line-height: 20px; } }
  &__label-tooltip {
    display: inline-block;
    vertical-align: middle;
    margin-left: 4px;
    & .help-tooltip {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
      @include respond-to(md) {
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px; } }
    &-icon {
      color: $secondary-45; } } }
