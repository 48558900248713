@import "../../../../../styles/theme";

.error-archive-confirm-modal {
  display: flex;
  flex-direction: column;
  &__description {
    font-size: 14px;
    line-height: 20px;
    color: $secondary-45;
    margin-bottom: 16px;
    width: 582px; }

  &__footer {
    display: flex;
    justify-content: flex-end; }

  &-objects {
    display: flex;
    flex-direction: column;
    max-height: 640px;

    &__item {
      &:not(:last-child) {
        margin-bottom: 16px; } } } }

/* Inside Auto Layout */
//margin: 16px 0px;
