@import '../../../../../../styles/theme';
.order-event-log-history {
  max-width: 404px;

  &__comment {
    color: $secondary-45;
    margin-top: 6px; }

  &__container:not(:last-child) {
    margin-bottom: 8px; }

  &-not-info {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 6px; }
    &__icon {
      color: $secondary-40; }
    &__label {
      margin-left: 6px;
      font-size: 14px;
      line-height: 20px;
      color: #222222; } }
  &-content {
    & > div:nth-child(odd) {
      margin-bottom: 8px; }
    & > div:nth-child(even):not(:last-child) {
      margin-bottom: 16px; }

    &__title {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $secondary-90; } } }
