@import "theme";
@import "mixins";

// Стили для .ui

.ui.form .field.field input,
.ui.input>input {
  &:-webkit-autofill {
    box-shadow: 0 0 0 100px inset white !important;
    border-color: #F1F3F7 !important;
    &:focus {
      box-shadow: 0 0 0 100px inset white !important;
      border-color: #85b7d9 !important; } } }

.ui.compact.search.selection.dropdown {
  display: flex;
  width: 100%;
  padding: 0 14px;
  align-items: center; }

.ui.selection.dropdown {
  border-radius: 7px; }

.ui.selection.list .list > .item:active, .ui.selection.list > .item.active {
  background: #FCD519;
  color: #000000; }

.ui.star.rating .icon {
  &:focus {
    outline: none !important; } }

.ui.table thead th {
  color: #959397;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 30px; }

.ui.input .field {
  width: 100%; }

.ui.menu {
  flex-direction: row;
  background: rgba(235, 237, 243, 0.7); }

.ui.tab.active,
.ui.tab.open {
  border: none; }

.ui.tabular.menu {
  background: rgba(235, 237, 243, 0.7);
  justify-content: space-between;
  & .item {
    padding: 10px;
    @media screen and (max-width: 1367px) {
      font-size: 0.8em; }
    @media screen and (min-width: 1368px) and (max-width: 1500px) {
      font-size: 0.9em; } } }

.ui.selection.list.list > .item:hover,
.ui.selection.list > .item:hover {
  background: #FCD519;
  opacity: 0.7;
  color: $passive-100; }

.ui.divider {
  margin: 5px 0; }

.ui.input > input {
  font-family: $font-family-default !important; }

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: white !important;
  background: #65AF43 !important;
  border-radius: .21428571rem; }

.ui.modal {
  height: auto;
  position: unset;
  & > .content {
    word-break: break-word; } }

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: .85 !important;
  background-color: #F1F3F7 !important; }

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: .85 !important; }

.ui.form {
  & .field {
    & label {
      color: #666666;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      opacity: 1; } } }

.ui.dropdown>.text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%; }

.ui.multiple.dropdown > .label {
  max-width: 100%; }

.ui.table {
  border-top: none;
  border-left: none;
  border-right: none;
  color: #555;
  & td {
    @media screen and (max-width: 1500px) {
      padding: 5px; } }
  & thead th {
    border-top: none;
    font-size: 13px;
    background: rgba(235, 237, 243, 0.7);
    @media screen and (max-width: 1500px) {
      line-height: 16px; } }
  & tbody tr td:first-child {
    border-left: 1px #E4E4E4 solid; }
  & tbody tr td:last-child {
    border-right: 1px #E4E4E4 solid; } }

.ui.dropdown {
  & .dropdown.icon {
    color: #B6BAC3; } }

.ui.checkbox {
  & label:before {
    border-radius: 3px;
    border: 2px solid #555555; }
  & input:checked ~ label:after {
    background: #128A10 !important; }
  & input[disabled]~label {
    opacity: 0.25; } }

.ui.secondary.pointing.menu {
  border-bottom: none; }
.ui.selectable.table tbody tr:hover {
  background: #FFFFFF !important;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  & td:first-child {
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px; }

  & td:last-child {
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px; }

  & .ui.basic.button {
    //border-radius: 7px
    //border: 1px solid #F9D4D4
    color: #DD4A4A; }

  & .order-micro-list {
    &-edit {
      border: 1px solid #8BCA8A;
      color: #3F9B14; }

    &-history {
      filter: none;
      opacity: 1; } } }
.ui.form .field .prompt.label {
  display: block;
  width: fit-content; }
.nm-tabs-custom {
  > .ui.tabular.menu {
    justify-content: flex-start;
    background-color: transparent;
    border-bottom: 1px solid rgba(63, 155, 20, 0.5);
    position: relative;
    & .menu-item {
      display: flex;
      align-items: center;
      position: relative;
      font-weight: normal;
      font-size: 12px;
      line-height: 21px;
      color: #dff5b3;
      &-name {
        &:after {
          content: attr(data-counter);
          position: absolute;
          bottom: 9px;
          left: 103%; } } }

    & .active.item {
      background: rgba(255, 255, 255, 1) !important;
      border: 1px solid rgba(63, 155, 20, 0.5) !important;
      border-bottom: 1px solid transparent !important;
      & .menu-item-icon {
        color: #128A10; }
      & .menu-item-name {
        color: #000;
        @media screen and (max-width: 1023px) {
          font-size: 12px; } } }

    & .item {
      padding: 13px 36px;
      border-left: 1px solid transparent !important;
      border-right: 1px solid transparent!important;
      border-top: 0 !important;
      border-bottom-width: 3px !important;
      border-bottom-color: transparent !important;
      background-color: #E9F4E5;
      border-radius: 7px 7px 0 0 !important;
      &:first-child {
        @media screen and (max-width: 1023px) {
          margin-left: 10px; } }
      & .menu-item-icon {
        color: #000; }
      & .menu-item-icon-rub-black {
        background-color: #000; }
      & .menu-item-icon-rub-grey {
        background-color: #B9DE8B; }
      & .menu-item-name {
        color: $primary-100;
        @media screen and (max-width: 1023px) {
          font-size: 12px; } }
      & .menu-item-counter {
        color: #128A10;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin-top: -20px;
        line-height: 30px; } } } }
