@import "../../styles/theme";

.nm-accordion-v2 {
  $block: &;

  cursor: pointer;
  position: relative;
  border-radius: 7px;
  background: $secondary-5;
  padding: 19px;
  width: 100%;
  @media screen and (min-width: 768px) and (max-width: 1919px) {
    padding: 12px 16px; }
  &_noStyles {
    padding: 0;
    background-color: unset;
    &#{$block}_open {
      border: none; } }

  &_open {
    border: 1px solid $secondary-45;
    background: $color-white;
    border-radius: 7px;
    & #{$block}__icon {
      transform: rotate(180deg); }
    & #{$block}__name {
      margin-bottom: 16px; } }

  &__icon {
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    right: 22px;
    text-align: center;
    &_noStyles {
      right: 0; }
    &_withColor {
      color: $secondary-75; } }

  &__name {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 18px; } }

