@import "../../styles/theme";

.order-keywords {
  $block: &;

  display: flex;
  flex-direction: column;
  width: 100%;

  &__wrapper {
    margin-top: 16px;
    height: 24px;
    overflow: hidden; }

  &__container {
    display: flex;
    height: 34px;
    overflow: auto;
    align-items: flex-start; }

  &__keyword {
    display: flex;
    align-items: center;
    padding: 3px 8px;
    border-radius: 6px;
    margin-right: 8px;
    background-color: $secondary-5; }

  &__text {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70;
    margin-right: 4px; }

  &__close-icon {
    color: $secondary-45; }

  &__control {
    &.ui.selection.dropdown {
      min-height: 48px;
      height: auto;
      padding: 14px 44px 14px 16px;
      background-color: $secondary-5;
      border: 1px solid $secondary-15;
      & > .text {
        margin: 12px 10px; }
      & > input {
        margin: 0!important;
        line-height: 18px!important;
        padding: 0 !important;
        width: auto !important; }
      &:hover {
        border: 1px solid $primary-100; }
      &.error {
        background-color: $negative-15;
        border-color: $negative-100; }
      &.upward.visible {
        border-radius: 0 0 7px 7px!important;
        border: 1px solid $primary-100;
        & .menu {
          border-radius: 7px 7px 0 0!important;
          border: 1px solid $primary-100; } }
      & > .menu .item {
        color: $primary-100!important;
        &.selected {
          background-color: $primary-10; }
        &:hover {
          background-color: $primary-10; } } }

    &_active.ui.selection.dropdown {
      border: 1px solid $secondary-70;
      background-color: $color-white; } }
  &_disabled {
    & #{$block}__control.ui.selection.dropdown {
      opacity: 1;
      border: 1px solid $secondary-5;
      background-color: $secondary-5; }
    & #{$block}__icon {
      color: $secondary-40; } }

  &__icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    color: $secondary-45;
    transform: rotate(0deg);
    &_open {
      transform: rotate(180deg); } } }
