@import "../../../styles/theme.sass";

.nm-contractor-list {
  width: 100%;
  $block: &;
  .modal-layer, .dimmer {
    position: fixed;
    textarea {
      border-radius: 7px;
      width: 100%; } }
  &__text-invite {
    min-width: max-content; }
  &-actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
    &_in-card {
      align-items: flex-start;
      margin-top: 15px; } }
  &__title {
    width: 72%; }
  &_client {
    .nm-page {
      padding: 0; } }
  &__mass-button {
    margin-right: 8px; }
  &__modal {
    width: 800px; }
  &__invite-icon.icon {
    margin-right: 15px; }
  &__empty-list {
    display: flex;
    flex-grow: 1;
    height: 100%;
    border: 1px solid $secondary-10;
    white-space: pre-wrap;
    justify-content: center;
    align-items: center;
    &-text {
      font-size: 24px;
      line-height: 30px;
      text-align: center; } }
  &__favorite-btn {
    background: url("../../../images/favorite.svg") no-repeat center;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 15px;
    cursor: pointer;
    &_checked {
      background: url("../../../images/favorite-checked.svg") no-repeat center; } }
  &__selected-info {
    margin-top: 15px;
    align-items: center;
    height: 40px;
    &-label {
      font-size: 14px;
      font-weight: normal;
      margin-right: 10px; }
    &-counter {
      border: 1px solid $secondary-75;
      border-radius: 20px;
      min-width: 20px;
      text-align: center;
      padding: 1px; }
    button {
      margin-left: 15px; } }
  &__popup {
    top: 29px; }

  &__conf-popup {
    width: 700px; }
  &__elem-label {
    font-size: 14px;
    margin-bottom: 0; }
  &__dropdown {
    background-color: transparent !important;
    &> .dropdown-app-content > i {
      color: $primary-100;
      &:hover {
        color: #C4C4C4; } } }
  .modal-layer {
    .ui.dimmer {
      position: absolute; } }
  .ui.dimmer {
    position: fixed; }
  .popup {
    white-space: pre-line;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    padding: 45px;
    .popup-header__title {
      text-align: center;
      font-weight: 500;
      width: 100%; }
    .popup-sub-header {
      font-size: 16px;
      font-weight: normal; } }

  &__operator-in-progress {
    border: $primary-100 2px solid; }

  &__warning-row {
    border: $inert-70 2px solid; }

  &__schedule-link {
    margin-top: 10px;
    color: $passive-100;
    text-decoration: underline;
    cursor: pointer; }

  &__schedule-day {
    text-align: left;
    display: flex;
    &:not(:last-child) {
      margin-bottom: 10px; }
    &-name {
      margin-right: 10px;
      white-space: nowrap; } }

  .nm-dadata-multiselect__selected-item {
    min-width: unset; }

  .field {
    input {
      border-radius: 7px !important; } }

  &__filtered-info {
    display: flex;
    align-items: center;
    height: 40px;
    white-space: nowrap;

    &-count {
      margin-left: 10px;
      border-radius: 50%;
      background: $primary-100;
      color: $color-white;
      font-size: 12px;
      text-align: center;
      height: 25px;
      min-width: 25px;
      padding: 3px; } }

  &__adv-search-info {
    width: 100%;
    border: 1px solid $secondary-5;
    padding: 24px;
    margin-bottom: 32px;
    border-radius: 6px;
    &-header {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 8px; }
    &-text {
      font-size: 14px;
      color: $secondary-45; } }

  &__confirm {
    &-reason {
      & .nm-textarea-v2__control {
        overflow: hidden; } } }

  .nm-list-card__secondary-header-value, .nm-label-text__text {
    overflow: unset; } }

