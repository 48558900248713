@import "../../styles/theme";
@import "../../styles/mixins";

.job-evaluation-rating {
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: 12px; }

  &__control {
    width: 100%;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row; } }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-100;
    margin-right: 10px;
    @include respond-to(xxl) {
      font-size: 14px;
      line-height: 20px; } }

  &__error {
    max-width: max-content;
    white-space: normal;
    background: #fff!important;
    border: 1px solid #e0b4b4!important;
    color: #9f3a38!important; }

  & .ui.star.rating {
    margin-top: 2px;
    @include respond-to(md) {
      margin-top: 0; }
    & .icon.selected {
      color: $inert-70!important;
      text-shadow: 0 -1px 0 $inert-100,-1px 0 0 $inert-100,0 1px 0 $inert-100,1px 0 0 $inert-100!important;
      &.active {
        color: $inert-70!important;
        text-shadow: 0 -1px 0 $inert-100,-1px 0 0 $inert-100,0 1px 0 $inert-100,1px 0 0 $inert-100!important; } }
    & .active.icon {
      text-shadow: none!important;
      color: $inert-100!important; } } }
