@import "../../../styles/theme";
@import "../../../styles/mixins";

.crowd-task-registry {
  &__context-menu-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    &_fluid {
      flex-grow: 1; } } }
