@import "../../styles/mixins";

.citizenship-with-flag {
  display: flex;
  align-items: center;
  &__image {
    width: 100%; }
  &__image-container {
    display: flex;
    align-items: center;
    margin-right: 8px;
    &_end {
      margin-right: 0;
      margin-left: 8px; }
    &_level-3 {
      width: 18px;
      min-width: 18px;
      height: 18px;
      @include respond-to(md) {
        width: 20px;
        min-width: 20px;
        height: 20px; } }
    &_level-4 {
      width: 18px;
      min-width: 18px;
      height: 18px;
      @include respond-to(md) {
        width: 24px;
        min-width: 24px;
        height: 24px; } } } }
