@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.order-edit-header {
  display: flex;
  flex-direction: column;

  &__title-row {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    @include respond-to(xl) {
      flex-direction: row; } }

  &__title-container {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    order: 2;
    @include respond-to(md) {
      flex-direction: row; }
    @include respond-to(xl) {
      order: 1; } }

  &__label {
    font-size: 16px;
    line-height: 19px;
    color: $secondary-40;
    &_active {
      color: $primary-100; } }

  &__name-responsible {
    margin-left: 6px;
    color: $secondary-100;
    max-width: 225px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; }

  &__type {
    order: 1;
    margin-bottom: 16px;
    @include respond-to(xl) {
      margin-bottom: 0;
      margin-left: 32px;
      order: 2; } }

  &__responsible {
    display: flex;
    color: $secondary-45;
    font-size: 12px;
    line-height: 18px;
    align-items: baseline;
    margin-top: 6px;
    @include respond-to(md) {
      font-size: 16px;
      line-height: 24px;
      margin-top: 0;
      margin-left: 24px; } }

  &__dropdown {
    max-width: 164px;
    margin-left: 6px;
    position: relative;
    @include respond-to(xl) {
      max-width: 380px; } }

  &__dropdown-content {
    width: 179px;
    @include respond-to(md) {
      width: 230px; } } }
