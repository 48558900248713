@import "../../../styles/theme";
@import "../../../styles/mixins";

.registry-list {
  $block: &;

  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__details-item:first-child:not(:last-child) {
    margin-bottom: 16px;
    @include respond-to(md) {
      margin-bottom: 24px; } }
  &__archive {
    margin-left: 16px; }
  &__status {
    border-radius: 7px;
    width: max-content;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    padding: 4px 9px; }
  &__not-available-registry-text {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #F75E1A;
    margin-top: 25px; }
  &__cards-container {
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-left: 50px; }
  &__list-dropdown {
    margin-left: auto; } }
