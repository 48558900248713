@import "../../../../styles/mixins";

.edm-staff-personal-data {
  display: flex;
  &__item {
    width: 100%;
    &:not(:first-child) {
      margin-top: 16px;
      @include respond-to(md) {
        margin-top: 24px; } } }
  &__row {
    display: flex; }
  &__column {
    width: 50%;
    &_ml {
      margin-left: 20px; } } }
