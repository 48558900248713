.pdf-viewer {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  &__document {
    flex-grow: 1;
    position: relative; }
  &__container-navigation {
    display: flex;
    flex-direction: column;
    align-items: center; }
  &__info-pages {
    margin-bottom: 10px; }
  &__actions-page {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ebedf3;
    border-radius: 7px;
    width: 200px;
    padding: 5px; }
  &__navigation-button {
    border: none;
    border-radius: 7px;
    color: #128a10;
    display: flex;
    &:focus-visible {
      outline: none; }
    &:focus {
      outline: none; }
    &_disabled {
      color: grey;
      opacity: .7; } }
  &__icon {
    &:focus-visible {
      outline: none; } } }

