@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.contractor-verification-info-block {
  width: 100%;
  background: $secondary-5;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  @include respond-to(md) {
    padding: 12px 24px; }
  @include respond-to(xxl) {
    padding: 24px; }
  &__title {
    padding-bottom: 5px;
    &:not(:first-child) {
      margin-top: 24px; } }
  &__field {
    color: $secondary-75;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include respond-to(xxl) {
      display: flex; }
    &:not(:first-child) {
      margin-top: 8px; }
    &_name {
      @include respond-to(xxl) {
        width: 30%;
        min-width: 30%; } }
    i.flag {
      margin: 0 0 0 .5em !important; } } }
