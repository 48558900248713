@import '../../../styles/theme';
@import '../../../styles/mixins';

.login-recovery {
  font-family: Montserrat, sans-serif;

  &__title {
    color: $open-naimix-black-100;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    @include respond-to(md) {
      font-size: 24px;
      line-height: 30px; }
    @include respond-to(xxl) {
      font-size: 28px;
      line-height: 34px; } }

  &__button {
    width: 100%;
    @include respond-to(xxl) {
      width: auto; } }

  &__p {
    color: $open-naimix-black-80;
    font-weight: 500;
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } } }
