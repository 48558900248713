$colorOk: #8FC748;
$colorOkBackground: rgba(143, 199, 72, 0.2);

.order-create-success {
  &__content {
    margin-top: 0; }
  &__actions {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center; }
  &__apply-buttons {
    width: 100%;
    justify-content: center; }
  &__layer.modal-layer.modal-layer_fixed {
    display: flex; }
  &__submit {
    width: auto; }
  &__header {
    display: flex;
    justify-content: center; }
  &__header-content {
    margin-top: 36px;
    margin-bottom: 25px;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: #333; }
  &__message {
    font-size: 16px;
    line-height: 24px;
    color: #000; }
  &__message {
    word-break: break-word;
    text-align: center; }
  &__header {
    text-align: center; }
  &__ok {
    display: flex;
    width: 100%;
    justify-content: center;
    &-icon {
      color: $colorOk;
      font-size: 60px;
      position: relative;
      &:before {
        position: absolute;
        display: block;
        content: "";
        width: 66px;
        height: 66px;
        left: -15px;
        top: 5px;
        border-radius: 50%;
        background: $colorOkBackground; } } } }
