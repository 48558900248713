@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-modal-card {
  border: 1px solid $secondary-15;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  @include respond-to(md) {
    padding: 12px 16px;
    flex-direction: row; }
  @include respond-to(xxl) {
    padding: 24px 16px; }

  &__icon {
    margin-bottom: 12px;
    @include respond-to(md) {
      margin: 0 16px 0 0; } }

  &__actions {
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 0; } }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 8px; } }

  &__main-content {
    display: flex;
    flex-direction: column;
    width: 100%; }

  &__title-value {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-100;
    @include respond-to(md) {
      font-size: 16px;
      line-height: 24px; } }

  &__title-additional {
    font-size: 16px;
    line-height: 24px;
    color: $primary-100;
    margin-left: 16px;
    font-weight: bold;
    white-space: nowrap; }

  &__block {
    display: flex;
    margin-right: 0;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 18px;
    @include respond-to(md) {
      margin-bottom: 0;
      margin-right: 8px; } }

  &__single-block {
    display: flex;
    font-size: 12px;
    line-height: 18px;
    &:not(:last-child) {
      margin-bottom: 8px;
      @include respond-to(md) {
        margin-bottom: 2px; } } }

  &__column {
    display: flex;
    flex-direction: column; }

  &__row {
    display: flex;
    flex-direction: column;
    max-width: 656px;
    @include respond-to(md) {
      align-items: center;
      flex-direction: row;
      &:not(:last-child) {
        margin-bottom: 2px; } } }

  &__label {
    color: $secondary-70;
    margin-right: 4px; }

  &__value {
    color: $secondary-100; } }
