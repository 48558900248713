@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-input-v2 {
  $block: &;

  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  &_size-md {
    label {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 4px; } }
  &__suggestion {
    display: none; }
  &__container {
    position: relative;
    &:hover {
      & #{$block}__suggestion {
        display: block; } } }
  &__clear {
    color: $secondary-45;
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px; }
  &__counter {
    position: absolute;
    right: 5px;
    font-size: 11px;
    line-height: 13px;
    color: $secondary-40;
    bottom: 2px;
    @include respond-to(md) {
      bottom: 4px; }
    &_disabled {
      color: $secondary-15; } }
  &__control {
    width: 100%;
    font-family: Roboto, sans-serif;
    color: $secondary-90;
    background-color: $secondary-5;
    border-radius: 7px;
    border: 1px solid $secondary-15;
    font-size: 14px;
    line-height: 20px;
    &_openNaimix, &_promoPoisk {
      color: $open-naimix-black-100;
      border: 1px solid $open-naimix-black-15;
      font-family: $font-family-open-naimix;
      border-radius: 6px;
      background-color: $color-white; }
    &:focus {
      outline: none;
      border-color: $primary-100;
      border-radius: 7px;
      background-color: $color-white; }
    &:hover {
      border-color: $primary-100; }
    &::placeholder {
      font-size: 14px;
      line-height: 18px;
      color: $secondary-45; }
    &_openNaimix, &_promoPoisk {
      &:focus {
        border-color: $open-naimix-black-15;
        border-radius: 6px; }
      &#{$block}__control_active {
        background-color: $color-white;
        border-color: $open-naimix-black-80; }
      &:hover {
        border-color: $open-naimix-primary-80; }
      &::placeholder {
        font-size: 12px;
        line-height: 18px;
        color: $open-naimix-black-50;
        @include respond-to(md) {
          font-size: 14px;
          line-height: 20px; } }
      &_error {
        border-color: $open-naimix-negative-100;
        background-color: $open-naimix-negative-10;
        &:focus {
          background-color: $open-naimix-negative-10;
          border-color: $open-naimix-negative-100; } } }
    &[disabled] {
      background-color: $secondary-5;
      border-color: $secondary-5;
      color: $secondary-40;
      &::placeholder {
        color: $secondary-15; } }
    &_active {
      background-color: $color-white;
      border-color: $secondary-70; }
    &_changed {
      border: 1px solid $inert-70; }
    &_error {
      border-color: $negative-100;
      background-color: $negative-15;
      &:focus {
        background-color: $color-white;
        border-color: $negative-100; } }
    &_size-md {
        height: 36px;
        padding: 8px 18px; }
    &_size-lg {
        height: 40px;
        padding: 10px 16px; }
    &_size-xl {
      height: 40px;
      padding: 10px 12px;
      @include respond-to(md) {
        padding: 10px 16px; }
      @include respond-to(md) {
        padding: 14px 16px;
        height: 48px; } }
    &_clearable {
      padding: 14px 45px 14px 16px; } } }
