@import "../../styles/theme";
@import "../../styles/mixins";

.nm-accordion {
  $block: &;

  position: relative;
  border-radius: 8px;
  border: 1px solid $secondary-5;
  padding: 12px;
  width: 100%;
  @media screen and (min-width: 768px) and (max-width: 1919px) {
    padding: 12px 16px; }
  &_noBorder {
    border: none; }
  &_noPadding {
    padding: 0; }
  &_open {
    & #{$block}__icon {
      transform: rotate(180deg); }
    & #{$block}__name {
      margin-bottom: 16px; } }

  &__icon {
    z-index: 10;
    cursor: pointer;
    color: $secondary-45;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 20px;
    height: 20px;
    @include respond-to(md) {
      top: 14px;
      right: 16px; } }

  &__name {
    font-size: 12px;
    line-height: 20px;
    padding-right: 20px;
    @include respond-to(md) {
      line-height: 24px; } } }
