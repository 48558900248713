@import "../../../styles/theme";
@import "../../../styles/mixins";

.order-templates {
  &__act-template {
    cursor: pointer;
    color: $passive-100; }

  &__list-links a {
    &:last-child {
      &::after {
        content: "."; } }
    &:not(:last-child) {
      margin-right: 4px;
      &::after {
        content: ",  "; } } }

  &-table {
    width: 100%;
    &-row {
      display: flex;
      justify-content: space-between;
      &-content {
        width: 100%;
        @include respond-to(xxl) {
          width: 50%; }
        &__header {
          color: $secondary-90;
          margin-bottom: 6px; } } } }

  &__add {
    margin-left: 16px; }
  &__edit-steps {
    width: 100%; }
  &__delimiter {
    width: 4px;
    height: 4px;
    background: $secondary-70;
    border-radius: 10px;
    margin: 0 7px; }
  &__more-count {
    cursor: pointer; } }
