@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-list-card-title {
  display: block;
  font-size: 16px;
  line-height: 24px;
  overflow-wrap: break-word;
  & a {
    width: 100%;
    overflow-wrap: break-word;
    color: $passive-100; }
  @include respond-to(md) {
    font-size: 18px;
    line-height: 26px; } }
