@import "../../../styles/theme";
@import "../../../styles/mixins";

.finance-orders {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  &_overflow {
    overflow: auto; }
  &_fluid {
    flex-grow: 1;
    width: 100%; }
  &_pd-top {
    padding-top: 25px; }
  &__filter {
    flex-shrink: 0; }
  &__dimmer {
    z-index: 1001!important; }
  &__header {
    min-height: 33px;
    margin-bottom: 17px; }
  &__table {
    flex-grow: 1;
    position: relative;
    &_aligned-headers {
      & .ui.table thead th:nth-child(7) {
        text-align: center; } }
    &-workers {
      text-align: center; }
    &-name {
      text-decoration: underline; }
    & .ui.loader {
      z-index: 998; } }
  &__action {
    padding: 8px 20px;
    color: $primary-100;
    font-size: 12px;
    line-height: 16px;
    border-radius: 7px;
    background: $primary-10;
    border: 1px solid $color-green-3;
    text-align: center;
    cursor: pointer;
    &-history-icon {
      width: unset !important;
      height: unset !important; } }
  &__status {
    &_published {
      color: $secondary-45;
      font-size: small;
      margin-left: 8px;
      white-space: nowrap; } } }
