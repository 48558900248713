@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-datepicker-range-v2 {
  $block: &;

  width: 100%;
  &__label {
    font-size: 14px;
    line-height: 20px; }
  &__suggestion {
    display: none; }
  &__control {
    position: relative;
    display: flex;
    align-items: flex-end;
    width: 100%;
    border-radius: 7px;
    outline: none;
    &:hover {
      & #{$block}__suggestion {
        display: block; }
      & .nm-date-picker {
        &:first-child .nm-datepicker-input {
          border-top: 1px solid $primary-100;
          border-left: 1px solid $primary-100;
          border-bottom: 1px solid $primary-100;
          &#{$block}__start-date_focused#{$block}__start-date_error {
            border-color: $negative-100; } }
        &:last-child .nm-datepicker-input {
          border-top: 1px solid  $primary-100;
          border-right: 1px solid  $primary-100;
          border-bottom: 1px solid  $primary-100;
          &#{$block}__end-date_focused#{$block}__end-date_error {
            border-color: $negative-100; } } } }
    &_disabled:hover .nm-datepicker-input {
      border-color: $secondary-5!important; }
    &_size-lg {
      & #{$block}__divider {
        height: 38px; } }
    &_error {
      & #{$block}__divider {
        background-color: $negative-100; } }
    &_disabled {
      & #{$block}__divider {
        background-color: $color-white; } } }
  &__start-date {
    margin-right: 0;
    margin-bottom: 0;
    border-radius: 7px 0 0 7px;
    border-right: none!important;
    & ~ button {
      padding: 0 13px 0 0; }
    &_focused {
      border: 1px solid $primary-100;
      background-color: $color-white; }
    &_active {
      border: 1px solid $secondary-70;
      border-right: none;
      background-color: $color-white; }
    &_error {
      background-color: $negative-15;
      border-color: $negative-100;
      &#{$block}__start-date_focused {
        background-color: $color-white;
        & ~ button:after {
          color: $color-white; } } } }
  &__end-date {
    margin-bottom: 0;
    border-radius: 0 7px 7px 0;
    border-left: none!important;
    padding: 0 0 0 40px;
    & ~ button {
      padding: 0 17px 0 0; }
    &_focused {
      border: 1px solid $primary-100;
      background-color: $color-white; }
    &_active {
      border: 1px solid $secondary-70;
      border-left: none;
      background-color: $color-white; }
    &_error {
      background-color: $negative-15;
      border-color: $negative-100;
      &#{$block}__end-date_focused {
        background-color: $color-white;
        & ~ button:after {
          color: $color-white; } } }
    & svg {
      left: 13px; } }
  &__divider {
    position: absolute;
    left: 50%;
    width: 1px;
    z-index: 1;
    bottom: 1px;
    top: 1px;
    height: 38px;
    background-color: $secondary-15;
    @include respond-to(md) {
      height: 46px; } } }
