@import "../../styles/theme";

.registry-payment-error {
  display: flex;
  align-items: center;

  &__status-value {
    margin-left: 5px;
    cursor: pointer;
    color: $negative-100;
    width: 18px;
    height: 18px; }

  &__error-tooltip {
    right: -13px;
    @media screen and (max-width: 767px) {
      top: auto !important;
      bottom: 90% !important; } } }
