@charset "utf-8";
@import "../../../styles/theme";
@import "../../../styles/mixins";

.notifications-settings-list {
  &__header-value {
    @include respond-to(md) {
      padding-right: 100px; }
    @include respond-to(xxl) {
      padding-right: 0; } }

  &__divider {
    margin-top: 2px; }

  &__notification-type-block {
    display: flex;
    margin-bottom: -8px;
    flex-direction: column;
    @include respond-to(xxl) {
      flex-direction: row;
      flex-wrap: wrap; } }

  &__icon {
    color: $color-white;
    background-color: $secondary-15;
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 3px;
    margin-right: 6px; }

  &__type {
    margin-bottom: 8px;
    max-height: max-content;
    max-width: max-content;
    display: flex;
    align-items: flex-start;
    &:not(:last-child) {
      margin-right: 8px; } } }
