@import "../../styles/theme";
@import "../../styles/mixins";

.order-schedule {
  $block: &;

  &__row {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    @include respond-to(xxl) {
      flex-wrap: nowrap; } }
  &__close {
    width: 14px;
    height: 14px;
    cursor: pointer;
    margin-left: 6px; }
  &__control {
    margin-bottom: 8px;
    align-items: center;
    display: flex; }
  &__container {
    margin-right: 0;
    width: 100%;
    max-width: 100%;
    &:nth-child(2n) {
      @include respond-to(md) {
        margin-left: 8px; }
      @include respond-to(xxl) {
        margin-left: 0; } }
    &:not(:last-child) {
      margin-bottom: 8px;
      @include respond-to(xxl) {
        margin-bottom: 0; } }
    @include respond-to(md) {
      width: calc(50% - 4px);
      max-width: 50%; }
    @include respond-to(xxl) {
      width: 134px;
      min-width: 134px;
      max-width: 134px;
      margin-right: 8px; } }

  &__col {
    border-radius: 8px;
    padding: 9px 5px;
    border: 1px solid $secondary-15; }
  &__error {
    color: #EE1616;
    line-height: 15px;
    padding-bottom: 3px; }
  &__timetable {
    margin-top: 19px;
    & > #{$block}__time {
      color: $secondary-45; } }
  &__time {
    color: $secondary-100;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    width: 103px;
    &-start {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 14px 5px;
      border-right: 1px solid $secondary-15; }
    &-end {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 14px 5px; } } }
