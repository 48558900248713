@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-label-text {
  $block: &;

  position: relative;
  display: flex;
  align-items: baseline;
  &_alignItems-baseline {
    align-items: baseline; }
  &_alignItems-flex-end {
    align-items: flex-end; }
  &_alignItems-flex-start {
    align-items: flex-start; }
  &_alignItems-center {
    align-items: center; }
  &_inline {
    display: inline-block;
    &#{$block}_columnOnMobile {
      display: flex;
      @include respond-to(md) {
        display: inline; } } }
  &_columnOnMobile {
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; }
    // для корректного отображения в ситуациях, когда в лейбле есть тултип
    & #{$block}__label {
      margin-right: 0;
      width: 100%;
      justify-content: space-between;
      // для корректного позиционирования тултипа
      position: relative;
      @include respond-to(md) {
        position: unset;
        margin-right: 4px;
        width: auto;
        justify-content: flex-start; } } }
  &_flexWrap {
    flex-wrap: wrap; }
  &_columnOnTablet {
    flex-direction: column;
    @include respond-to(xl) {
      flex-direction: row; } }

  &__tooltip {
    margin-left: 4px;
    height: 18px;
    width: 18px; }

  &__label {
    display: flex;
    align-items: baseline;
    margin-right: 4px;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70;
    &_bold {
      color: $secondary-100;
      font-weight: 700; }
    &_inline {
      display: inline; }
    &_noWrap {
      white-space: nowrap; }
    &_noWrapOnMobile {
      white-space: unset;
      @include respond-to(md) {
        white-space: nowrap; } }
    &_type-page {
      font-size: 12px;
      line-height: 18px;
      @include respond-to(xl) {
        font-size: 14px;
        line-height: 20px; } } }

  &__text-tooltip {
    margin-left: 4px; }
  &__text {
    font-size: 12px;
    color: $secondary-100;
    line-height: 18px;
    display: flex;
    &_inline {
      display: inline; }
    &_noWrap {
      display: unset;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden !important;
      width: 100%; }
    &_wrapped {
      text-overflow: unset;
      overflow: unset;
      white-space: unset;
      display: flex;
      flex-wrap: wrap;
      word-break: break-word; }
    &_bold {
      color: $secondary-100;
      font-weight: 500; }
    &_fluid {
      width: 100%; }
    &_color-blue {
      color: $passive-100; }
    & a {
      color: $passive-100; }
    &_color-primary {
      color: $primary-100; }
    &_color-red {
      color: $negative-100; }
    &_cursorPointer {
      cursor: pointer; }
    &_maxWidth {
      max-width: max-content; }
    &_overflowUnset {
      overflow: unset !important; }
    &_type-page {
      font-size: 12px;
      line-height: 18px;
      @include respond-to(xl) {
        font-size: 14px;
        line-height: 20px; } } } }
