@import "../../../../../styles/theme";

.deposit-tab-list {
  &__title {
    width: 100%; }
  &__edit-icon-container {
    display: flex;
    align-items: center;
    margin-left: 6px; }
  &__edit-icon {
    height: 15px;
    width: 15px;
    color: $secondary-70; }
  &__patents {
    &-primary-header {
      overflow: unset; } } }
