$border: 1px solid rgba(34,36,38,.15);

.search-filter-input {
  position: relative;
  &__icon {
    cursor: pointer; }
  & .material-icons {
    margin-left: 0!important; }
  & .icon-search {
    pointer-events: none;
    position: absolute;
    margin-left: 11px;
    left: 0;
    bottom: 0;
    display: flex;
    top: 0;
    align-items: center; }
  & .icon-sort {
    background-color: #fff;
    padding: 2px 4px;
    border-top: $border;
    border-bottom: $border;
    border-right: $border;
    border-radius: 0 7px 7px 0;
    border-top: {}
    display: flex;
    align-items: center;
    & .material-icons {
      border: 1px solid #D2D2D2;
      border-radius: 7px; } }
  &-current {
    width: 73%;
    padding-left: 35px!important;
    &-no-sort {
      border-radius: 7px!important;
      border-right: 1px solid rgba(34,36,38,.15); }
    &-sort {
      border-right: 0!important;
      border-right-color: #85b7d9!important;
      border-radius: 7px 0 0 7px!important;
      &:focus {
        border-right-width: 1px!important;
        border-right-style: solid!important; } } } }

.search-filter-no-active {
  border: 1px solid transparent;
  margin-left: auto;
  & .icon-sort {
    margin-right: 5px; } }

.td-container {
  & .transformed {
    transform: scaleX(-1) rotate(180deg); } }
