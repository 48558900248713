@import "../../../styles/theme";

.nm-hint-button {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  cursor: pointer;
  &_color {
    &-primary {
      color: $primary-100; }
    &-blue {
      color: $passive-100; }
    &-gray {
      color: $secondary-45; }
    &-gray {
      color: $color-black; } }
  &__icon {
    margin-left: 0;
    &_open {
      transform: rotate(180deg); }
    &_isActions {
      margin-left: 12px; } }
  &__actions {
    display: flex;
    align-items: center;
    margin-left: 4px;
    &_fullWidth {
      margin-left: auto; } } }
