.test-predict-amount-form {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  .nm-dropdown-v2, .nm-input-v2, .nm-date-picker {
    width: 200px;
    margin-right: 16px; }

  .nm-dropdown-v2__content {
    height: 36px; } }
