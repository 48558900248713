@import "../../styles/theme";
@import "../../styles/mixins";

$opacity-hover: 0.65;

.nmx-menu {
  $block: &;
  z-index: 999;
  @extend %wrapper-flex-column;
  background-color: $secondary-90;
  @media screen and (max-width: 1199px) {
    position: fixed;
    width: 100%; }
  @include respond-to(xl) {
    min-width: unset;
    max-width: unset; }
  @media screen and (min-width: 1600px) {
    min-width: 204px;
    max-width: 204px; }
  // Открытое состояние в мобильном виде <1200
  &_open {
    z-index: 1400;
    @media screen and (max-width: 1199px) {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      & #{$block}__container {
        flex-direction: column;
        min-height: 100%; }
      & #{$block}__logo {
        margin-right: 0; }
      & #{$block}__list-container {
        display: block;
        @media screen and (min-height: 600px) {
          margin-bottom: 43px; } }
      & #{$block}__version {
        display: block; }
      & #{$block}__maintenance-container {
        display: block;
        width: 100%; }
      & #{$block}__info {
        padding: 24px 10px;
        flex-direction: column;
        margin: 0;
        @media screen and (max-height: 600px) {
          padding: 12px 10px; } }
      & #{$block}__bottom-container {
        flex-direction: column; }
      & #{$block}__settings {
        padding: 16px; } }
    @media screen and (max-width: 424px) {
      width: 100%; }
    @media screen and (min-width: 425px) {
      max-width: 372px; } }

  &_client-admin {
    & .nmx-menu__link {
      padding: 7px 26px 8px 16px;
      @media screen and (max-height: 910px) {
        padding: 5px 12px 5px 11px; } }

    & .child-menu__item {
      padding: 7px 10px 7px 38px;
      @media screen and (max-height: 911px) {
        padding: 5px 5px 5px 38px; } } }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    @media screen and (min-width: 1200px) {
      height: 100%;
      display: flex;
      flex-direction: column; } }

  &__canceled-checks-indicator {
    position: absolute;
    left: 28px;
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
      left: 35px; } }

  &__layer {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 998;
    background-color: rgba(0, 0, 0, 0.4);
    @media screen and (max-width: 1199px) {
      display: block; } }

  &__logo {
    @media screen and (max-width: 1199px) {
      background-size: contain;
      margin-right: 6px; }
    &_naimix {
      background: url("../../images/logo-mobile.svg") no-repeat center;
      width: 108px;
      height: 28px;
      @include respond-to(xl) {
        background: url("../../images/logo.svg") no-repeat center;
        margin-bottom: 8px;
        width: 68px;
        height: 65px; } }
    &_ros-kap-stroi {
      margin-right: 0;
      background: url("../../images/ros-kap-stroi-logo-mobile.svg") no-repeat center;
      width: 108px;
      height: 22px;
      @include respond-to(xl) {
        margin-bottom: 8px;
        background: url("../../images/ros-kap-stroi-logo.svg") no-repeat center;
        width: 32px;
        height: 32px; }
      @media screen and (min-width: 1600px) {
        background: url("../../images/ros-kap-stroi-logo-desktop.svg") no-repeat center;
        width: 168px;
        height: 60px; } }
    &_help-resource {
      margin-right: 0;
      background: url("../../images/help-resource-logo-mobile.svg") no-repeat center;
      width: 168px;
      height: 20px;
      @include respond-to(xl) {
        margin-bottom: 8px;
        background: url("../../images/help-resource-logo.svg") no-repeat center;
        width: 34px;
        height: 37px; }
      @media screen and (min-width: 1600px) {
        background: url("../../images/help-resource-logo-desktop.svg") no-repeat center;
        width: 124px;
        height: 60px; } }
    &_promopoisk {
      margin-right: 0;
      background: url("../../images/promopoisk-logo-mobile.svg") no-repeat center;
      width: 108px;
      height: 22px;
      @include respond-to(xl) {
        margin-bottom: 8px;
        background: url("../../images/promopoisk-logo.svg") no-repeat center;
        width: 40px;
        height: 40px; }
      @media screen and (min-width: 1600px) {
        background: url("../../images/promopoisk-logo-desktop.svg") no-repeat center;
        width: 120px;
        height: 62px; } }
    &_mts {
      background: url("../../images/logo-mts-bank-white.svg") no-repeat center;
      width: 140px;
      height: 23px;
      margin-bottom: 8px;
      @media screen and (max-width: 1199px) {
        margin-bottom: 0; }
      @media screen and (min-width: 1200px) and (max-width: 1599px) {
        background: url("../../images/logo-mts-bank-white-pre-desktop.svg") no-repeat center;
        width: 100%;
        height: 26px; } }
    &_hoReCa {
      color: $color-white;
      width: 58px;
      height: 33px;
      @include respond-to(xl) {
        width: 38px;
        height: 22px;
        margin-bottom: 4px; }
      @media screen and (min-width: 1600px) {
        width: 70px;
        height: 38px;
        margin-bottom: 8px; } } }

  &__name {
    background: url("../../images/label-naimix-admin.svg") no-repeat center;
    background-size: contain;
    width: 84px;
    height: 18px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    font-size: 18px;
    margin: 0;
    display: none;
    @media screen and (min-width: 768px) {
      display: block; }
    @media screen and (min-width: 1200px) {
      width: 50px;
      margin: 5px 0; }
    @media screen and (min-width: 1600px) {
      width: 80px;
      margin: 12px 0 8px 0; } }

  &__info {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    background-color: $secondary-80;
    border-bottom: 1px solid #555555;
    padding: 24px 0;
    flex-direction: column;
    border-bottom: 0;
    @media screen and (max-width: 1599px) {
      padding: 16px 0; }
    @media screen and (max-width: 1199px) {
      background-color: unset;
      flex-direction: row;
      padding: 0 10px 0 56px;
      margin: 10px 0; }
    &_maintenance {
      position: relative;
      padding-top: 24px;
      padding-bottom: 0;
      @media screen and (max-width: 1599px) {
        padding-top: 16px; }
      @media screen and (max-width: 1199px) {
        padding: 0 16px 0 0; } } }

  &__maintenance-icon {
    @media screen and (max-width: 1199px) {
      width: 24px;
      height: 24px; } }

  &__version-word {
    @media screen and (max-width: 1599px) {
      display: none; } }

  &__version {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    color: $secondary-45;
    margin-bottom: 0;
    @include respond-to(xs) {
      display: none; }
    @include respond-to(sm) {
      display: none; }
    @include respond-to(xl) {
      display: block;
      font-size: 10px;
      line-height: 16px; }
    @media screen and (min-width: 1600px) {
      display: block;
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 18px; } }

  &__maintenance-container {
    white-space: break-spaces;
    display: flex;
    justify-content: center;
    text-align: center;
    @include respond-to(xs) {
      display: none; }
    @include respond-to(sm) {
      display: none; }
    @include respond-to(xl) {
      display: none; }
    @media screen and (min-width: 1600px) {
      display: block;
      margin-bottom: 8px;
      font-size: 12px;
      line-height: 18px; } }

  &__maintenance-description {
    position: static;
    margin-left: 10px;
    @include respond-to(xl) {
      position: static;
      margin-top: 10px;
      margin-left: 0; }
    @media screen and (min-width: 1600px) {
      display: block;
      position: absolute;
      bottom: 29px;
      right: 30px;
      margin-left: 0;
      margin-top: 0; } }

  &__bottom-container {
    @extend %wrapper-flex-column-end;
    background-color: $secondary-90;
    @media screen and (max-width: 1199px) {
      background-color: $secondary-80;
      flex-direction: row-reverse; } }

  &__settings {
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1199px) {
      padding: 16px 15px 5px 5px; }

    &:hover {
      opacity: $opacity-hover; } }

  &__settings-link {
    position: relative; }

  &__settings-counter {
    position: absolute;
    display: block;
    left: 50%;
    top: 40%; } }


