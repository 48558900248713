@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.dropdown-with-input-options-container {
  border-top: 1px solid $secondary-15;
  margin-top: 2px;
  max-height: 280px;
  overflow: auto;
  &_notEmptyValues {
    margin-top: 4px; }

  &__option {
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 10px 12px;
    color: $secondary-90;
    @include respond-to(md) {
      padding: 10px 16px; }
    &:hover {
      background-color: $primary-10;
      color: $primary-100;
      &:last-child {
        border-radius: 0 0 7px 7px; } } } }
