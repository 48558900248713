@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.chat-dispute-header {
  &__text {
    color: $secondary-70;
    flex-basis: 0;
    flex-grow: 1;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical; }

  &__close {
    margin-left: 24px;
    margin-right: 8px;
    @include respond-to(xxl) {
      margin-left: 40px; } } }
