@import "../../../styles/mixins";

.nm-form {
  &_fullWidth {
    width: 100%; }
  &_filter {
    // высота контролов в фильтрах = 40px
    & .datepicker-custom-input {
      height: 40px; }
    & .custom-dropdown__content {
      height: 40px;
      padding: 10px 40px 10px 18px; }
    & .nm-date-picker {
      height: 40px; } }
  &_addMargin {
    & > div:not(:last-child) {
      margin-bottom: 12px;
      @include respond-to(md) {
        margin-bottom: 16px; } } }

  &_horizontal {
    display: flex;
    flex-wrap: wrap;
    & > div {
      &:not(:last-child) {
        margin-bottom: 8px;
        @include respond-to(md) {
          margin: 0 8px 16px 0; } }
      &:last-child {
        @include respond-to(md) {
          margin-bottom: 16px; } } } } }
