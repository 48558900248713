@import "../../../styles/theme";
@import "../../../styles/mixins";

$padding-right-tablet: $filter-width + $page-horizontal-padding-tablet;
$padding-right-desktop: $filter-width + $page-horizontal-padding-desktop;

.scrollable-container {
  overflow-y: auto;
  overflow-x: hidden;
  &_fullHeight {
    height: 100%; }
  &_barOffset {
    padding-right: 8px; }
  &_absoluteFill {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  &_openFilter {
    @include respond-to(md) {
      padding-right: $padding-right-tablet!important; }
    @include respond-to(xxl) {
      padding-right: $padding-right-desktop!important; } } }
