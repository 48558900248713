@import "../../styles/theme";
@import "../../styles/mixins";

.order-dropdown {
  $block: &;

  display: flex;
  flex-direction: column;
  &_fullWidth {
    width: 100%; }
  &_open {
    z-index: 1050; }
  &__buttons {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    background-color: $secondary-5;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 10px 15px 0 15px; }
  &__selected-text {
    margin-right: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $secondary-15; }
  &__selected-icon {
    color: $color-white;
    &_open {
      transform: rotate(180deg); } }
  &__selected-container {
    padding: 0 16px 16px 16px; }
  &__selected-area {
    border-bottom: 1px solid #ECECEC;
    &_empty {
      border-bottom: none; } }
  &__selected {
    background-color: $secondary-5;
    color: $secondary-45;
    width: max-content;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 13px;
    padding: 4px 10px 4px 14px;
    display: flex;
    height: 28px;
    align-items: center;
    cursor: default;
    &_active {
      cursor: pointer;
      background-color: $primary-100;
      color: $color-white;
      & #{$block}__selected-text {
        color: $color-white; } } }
  &__input-icon-container {
    display: inline-block;
    padding: 9px 8px 9px 16px;
    height: 100%;
    @include respond-to(md) {
      padding: 14px 8px 14px 16px; } }
  &__input-icon {
    color: $secondary-45; }
  &__relative-container {
    position: relative;
    height: 100%;
    width: 100%; }
  &__input-container {
    position: absolute;
    top: 0;
    width: 100%;
    bottom: 0;
    left: 0; }
  &__submit {
    margin-right: 20px;
    margin-bottom: 10px; }
  &__cancel {
    margin-bottom: 10px; }
  &__words {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px; }
  &__word {
    border-radius: 13px;
    background-color: $primary-10;
    display: flex;
    align-items: center;
    padding: 4px 10px 4px 14px;
    margin-right: 4px;
    margin-bottom: 4px;
    max-width: 100%; }
  &__word-icon {
    min-width: 18px;
    color: $secondary-100;
    cursor: pointer; }
  &__word-text {
    color: $secondary-90;
    font-size: 14px;
    line-height: 16px;
    margin-right: 15px;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  &_error {
    & #{$block}__list {
      border-color: $negative-100; }
    & #{$block}__prompt {
      color: $negative-100 !important;
      border: 1px solid $negative-100 !important;
      font-weight: 500;
      background: $color-white !important;
      width: fit-content;
      margin-top: 1em;
      font-size: .85714286rem;
      position: relative;
      display: inline-block;
      line-height: 1;
      vertical-align: baseline;
      padding: .5833em .833em;
      text-transform: none;
      border-radius: .28571429rem;
      transition: background .1s ease;
      &:before {
        background-color: inherit;
        border-style: solid;
        border-color: inherit;
        border-width: 1px 0 0 1px;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        top: 0;
        left: 50%;
        position: absolute;
        content: '';
        background-image: none;
        z-index: 2;
        width: .6666em;
        height: .6666em;
        transition: background .1s ease; } }
    & #{$block}__content {
      border-color: $negative-100;
      background-color: $negative-15;
      &_open {
        background-color: $color-white; }
      &#{$block}__content_active#{$block}__content_open:hover {
        border-color: $negative-100; } } }

  &__list {
    overflow: hidden;
    position: absolute;
    background-color: $color-white;
    width: calc(100% + 2px);
    left: 0;
    top: 100%;
    z-index: 20;
    border-left: 1px solid $primary-100;
    border-right: 1px solid $primary-100;
    border-bottom: 1px solid $primary-100;
    border-radius: 0 0 7px 7px;
    margin: 0 -1px;
    &-wrapper {
      display: flex;
      flex-direction: column;
      overflow: auto;
      max-height: 320px; }
    &-container {
      padding-bottom: 4px;
      & #{$block}__item {
        &:hover:first-child #{$block}__item-content:after {
          display: none; }
        &:not(:first-child) > #{$block}__item-content:after {
          display: block;
          content: "";
          width: 100%;
          height: 1px;
          background-color: $secondary-15;
          position: absolute;
          top: 0; } } } }

  &__content {
    $contentBlock: &;

    position: relative;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    background-color: $secondary-5;
    padding: 10px;
    outline: none;
    &:hover {
      border-color: $primary-100; }
    &_size {
      &-lg {
        height: 40px;
        padding: 10px 40px 10px 16px;
        & #{$block}__input-icon-container {
          padding: 9px 8px 9px 16px; }
        & input {
          padding: 10px 38px 10px 44px; } }
      &-xl {
        height: 40px;
        padding: 9px 44px 9px 12px;
        @include respond-to(md) {
          height: 48px;
          padding: 13px 44px 13px 16px; }
        & input {
          padding: 10px 38px 10px 44px;
          @include respond-to(md) {
            padding: 13px 44px 13px 44px; } } } }
    &_active {
      background-color: $color-white;
      border-color: $secondary-70; }
    &_open {
      background-color: $color-white;
      border: 1px solid $primary-100;
      &#{$contentBlock}_size-md {
        padding: 8px 40px 8px 16px;
        &#{$contentBlock}_search {
          padding: 8px 40px 8px 45px; } }
      &#{$contentBlock}_direction-bottom {
        border-radius: 7px 7px 0 0; }
      &#{$contentBlock}_direction-top {
        border-radius: 0 0 7px 7px; }
      &#{$contentBlock}_size-lg {
        padding: 10px 40px 10px 16px;
        &#{$contentBlock}_search {
          padding: 10px 38px 10px 45px; } }
      &#{$contentBlock}_direction-top #{$block}__list {
        border-radius: 7px 7px 0 0;
        bottom: 100%;
        top: auto;
        border-top: 1px solid $primary-100;
        border-right: 1px solid $primary-100;
        border-bottom: none;
        border-left: 1px solid $primary-100; }

      &#{$contentBlock}_size-xl {
        padding: 10px 40px 10px 16px;
        @include respond-to(md) {
          padding: 13px 44px 13px 16px; }
        &#{$contentBlock}_search {
          padding: 10px 44px 10px 44px;
          @include respond-to(md) {
            padding: 14px 44px 14px 44px; } } } }
    &_disabled {
      background-color: $secondary-5;
      border: 1px solid $secondary-5;
      & #{$block}__icon {
        color: $secondary-40; }
      & #{$block}__text {
        color: $secondary-40; }
      &:hover {
        border-color: $secondary-5; } }
    & input {
      font-family: Roboto!important;
      font-size: 14px;
      line-height: 20px;
      outline: 0;
      cursor: text;
      border: none;
      background: none;
      box-shadow: none;
      position: absolute;
      width: 100%;
      left: 0; } }

  &__icon-container {
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 1; }

  &__icon {
    color: $secondary-45;
    transform: rotate(0deg);
    &_open {
      transform: rotate(180deg); } }

  &__checkbox {
    margin-right: 9px; }
  &__checkbox-label {
    padding: 10px 0; }

  &__item {
    padding: 0 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    word-break: break-word;
    &_multiple #{$block}__item-content {
      padding: 0; }
    &:not(#{$block}__item_multiple) {
      &#{$block}__item_selected {
        font-weight: normal;
        background-color: $primary-10;
        & > #{$block}__item-content:after {
          display: none!important; }
        & + #{$block}__item > #{$block}__item-content:after {
          display: none!important; }
        & #{$block}__item-content {
          color: $primary-100; } }
      &:hover {
        background-color: $primary-10;
        & > #{$block}__item-content:after {
          display: none!important; }
        & + #{$block}__item > #{$block}__item-content:after {
          display: none!important; } } } }

  &__item-content {
    width: 100%;
    padding: 10px 0;
    position: relative;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

  &__text {
    color: $secondary-45;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 20px;
    &_active {
      color: $secondary-90; } }

  &__label {
    display: flex;
    color: $secondary-75;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    align-items: baseline;
    &-tooltip {
      display: inline-block;
      vertical-align: middle;
      margin-left: 3px;
      &-icon {
        width: 15px;
        margin-bottom: 5px; } } } }
