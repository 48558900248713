@import "../../../styles/theme";
@import "../../../styles/mixins";

.maintenance-banner {
  display: flex;
  align-items: center;
  background: #FFECC9;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 8px;
  width: 100%;
  @include respond-to(md) {
    max-width: 720px;
    padding: 16px; }
  @include respond-to(xxl) {
    max-width: 1096px;
    padding: 18px 16px; } }
