@import "../../styles/theme";

.nm-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 8px;
  border-radius: 6px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 18px;
  color: $secondary-90;

  &_pointer {
    cursor: pointer; }

  &_maxContent {
    max-width: max-content; }

  &_noWrap {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }

  &__left-icon {
    margin-right: 4px; }

  &__right-icon {
    display: flex;
    margin-left: 4px; }

  &__tooltip-icon {
    color: $color-white; }

  &_mod-light-green {
    background-color: $primary-10;
    color: $primary-80; }

  &_mod-gray {
    background: $secondary-5;
    color: $secondary-75; }

  &_mod-orange {
    background-color: $inert-100;
    color: $color-white; }

  &_mod-green {
    background-color: $primary-100;
    color: $color-white; }

  &_mod-red {
    background-color: $negative-100;
    color: $color-white; }

  &_mod-white {
    background-color: $color-white;
    color: $secondary-75;
    border: 1px solid $secondary-15; }

  &_mod-light-red {
    background-color: $negative-50;
    color: $color-white; }

  &:not(.nm-badge_condition) {
    &_mod-gray {
      background: $secondary-5;
      color: $secondary-70; }

    &_mod-orange {
      background: $color-red-3; }

    &_mod-green {
      background: $color-green-5; }

    &_mod-red {
      background: $negative-15; } }

  &_disabled {
    color: $secondary-35; }

  &__advancedTooltip {
    margin-left: 4px; }

  &__badge-tooltip {
    &_bottomLeft {
      left: -14px; }
    &_bottomRight {
      right: -14px; }
    &_topRight {
      right: -14px; }
    &_topLeft {
      left: -14px; }
    &_rightBottom {
      bottom: -10px; }
    &_rightTop {
      top: -10px; }
    &_leftBottom {
      bottom: -10px; }
    &_leftTop {
      top: -10px; } }

  &__tooltip-icon {
    color: $secondary-40; } }
