.nm-base-invite-contractor-list-filter {
  &__status {
    width: 325px; }
  &__input {
    width: 200px; }
  &__dadata {
    margin-bottom: 16px; }
  &__filter-buttons {
    margin-top: 24px;
    align-items: flex-start; } }
