@import "../../../styles/mixins";
@import "../../../styles/theme";

.text {
  $block: &;
  font-weight: normal;
  &_preWrap {
    white-space: pre-wrap; }
  &_cursorPointer {
    cursor: pointer; }
  &_noWrap {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  &_inline {
    display: inline-flex; }
  &_bold {
    font-weight: bold; }
  &_medium {
    font-weight: 500; }
  &_italyc {
    font-style: italic; }
  &_underline {
    text-decoration: underline; }
  &_disabled {
    color: $secondary-45; }
  &_type-title {
    font-weight: bold;
    &.text_level-1 {
      font-size: 22px;
      line-height: 28px;
      @include respond-to(md) {
        font-size: 24px;
        line-height: 30px; } }
    &.text_level-2 {
      font-size: 18px;
      line-height: 26px;
      @include respond-to(md) {
        font-size: 20px;
        line-height: 28px; } }
    &.text_level-3 {
      font-size: 16px;
      line-height: 24px;
      @include respond-to(md) {
        font-size: 18px;
        line-height: 26px; } }
    &.text_level-4 {
      font-size: 14px;
      line-height: 20px;
      @include respond-to(md) {
        font-size: 16px;
        line-height: 24px; } } }
  &_type-sub {
    &.text_level-1 {
      font-size: 12px;
      line-height: 18px;
      @include respond-to(md) {
        font-size: 14px;
        line-height: 20px; } }
    &.text_level-2 {
      font-size: 14px;
      line-height: 20px;
      @include respond-to(md) {
        font-size: 16px;
        line-height: 24px; } } }
  &_type-text {
    &.text_level-1 {
      font-size: 11px;
      line-height: 16px; }
    &.text_level-2 {
      font-size: 12px;
      line-height: 18px; }
    &.text_level-3 {
      font-size: 12px;
      line-height: 18px;
      @include respond-to(md) {
        font-size: 14px;
        line-height: 20px; } }
    &.text_level-4 {
      font-size: 14px;
      line-height: 20px;
      @include respond-to(md) {
        font-size: 16px;
        line-height: 24px; } }
    &.text_level-5 {
      font-size: 18px;
      line-height: 26px; } } }


