@import "../../styles/mixins";
@import "../../styles/theme";

.contractor-verification-date-form {
  $block: &;

  &__status-inn-wrapper {
    width: 100%;
    border: 1px solid $secondary-10;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 16px;
    @include respond-to(md) {
      padding: 12px 24px; }
    @include respond-to(xxl) {
      padding: 24px; } }

  &__header {
    width: 100%;
    display: flex;
    align-items: center;
    &-container {
      overflow: unset;
      width: 100%; }
    &-button {
      margin-left: 16px; } }

  &__header-status {
    &:not(#{$block}__header-status_desktop) {
      margin-bottom: 16px;
      @include respond-to(md) {
        display: none; } }
    &_desktop {
      display: none;
      @include respond-to(md) {
        display: flex; } } }

  &__header-media-controls {
    @include respond-to(md) {
      margin-left: 40px; } }

  &__button-back {
    display: none;
    @include respond-to(xxl) {
      display: flex; } }

  &__header-title {
    flex-grow: 1;
    overflow: hidden;
    margin-right: 8px;
    @include respond-to(xxl) {
      margin-left: 12px; } }

  &__title-container {
    width: 100%;
    display: flex;
    justify-content: space-between; }

  &__scan-solution {
    &:not(:first-child) {
      margin-top: 16px; } } }
