.document-tables-modal {
  &__table {
    margin-top: 16px;
    & .row .column {
      &:nth-child(1) {
        max-width: 270px;
        min-width: 270px; }
      &:nth-child(2) {
        max-width: 100px;
        min-width: 100px; }
      &:nth-child(3) {
        max-width: 100px;
        min-width: 100px; }
      &:nth-child(4) {
        max-width: 260px;
        min-width: 260px; }
      &:nth-child(5) {
        max-width: 124px;
        min-width: 124px; }
      &:nth-child(6) {
        max-width: 124px;
        min-width: 124px; } }
    &_civil {
      & .row .column {
        &:nth-child(1) {
          max-width: 754px;
          min-width: 754px; }
        &:nth-child(2) {
          max-width: 224px;
          min-width: 224px; } } } } }
