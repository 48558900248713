@import "../../../../styles/mixins";
@import "../../../../styles/theme";

.order-edit-tariff-scale-indicator {
  cursor: pointer;
  width: 18px;
  height: 18px;
  &__icon {
    color: $inert-100;
    &_matchTariff {
      color: $primary-100; } }
  @include respond-to(md) {
    width: 20px;
    height: 20px; }

  &__amount {
    font-weight: 700; }

  &__tooltip {
    left: -12px;
    max-width: 550px; } }
