@import "../../styles/theme.sass";
@import "../../styles/mixins";

.nm-date-picker-range {
  &__input {
    position: relative; }

  & .rdrStaticRange .rdrStaticRangeLabel {
    font-size: 12px;
    line-height: 18px;
    padding: 10px 12px;
    font-weight: 400;
    color: $secondary-70; }

  & .rdrDefinedRangesWrapper .rdrStaticRangeSelected .rdrStaticRangeLabel {
    font-weight: 500;
    color: $primary-100; }

  & .rdrInputRanges {
    padding: 0; }

  & .rdrStaticRange {
    border-bottom: none;
    &:not(:last-child):after {
      content: "";
      display: block;
      width: calc(100% - 24px);
      height: 1px;
      margin-left: 12px;
      background-color: $secondary-15; } }

  & .rdrDayPassive .rdrDayNumber span {
    color: $secondary-15; }

  & .rdrDayNumber {
    font-weight: 400;
    top: 6px;
    left: 5px;
    @include respond-to(md) {
      left: 0; } }

  & .rdrDayNumber span {
    color: $secondary-45; }

  & .rdrMonthName {
    text-align: center;
    color: $secondary-45;
    font-weight: 400;
    padding: 0 12px 4px 12px;
    background: $secondary-5; }

  & .rdrWeekDays {
    background: $secondary-5;
    color: $secondary-70;
    padding: 0 12px; }

  & .rdrWeekDay {
    line-height: 36px; }

  & .rdrMonthAndYearWrapper {
    background: $secondary-5; }

  & .rdrMonths {
    font-size: 11px;
    line-height: 16px;
    font-weight: 400; }

  & .rdrDay {
    height: 36px;
    & .rdrStartEdge.rdrEndEdge {
      height: 28px;
      width: 28px;
      border-radius: 50%;
      left: 4px;
      top: 4px;
      @include respond-to(md) {
        left: 8px; } } }

  & .rdrMonth {
    width: 100%;
    padding: 0 0 12px 0;
    @include respond-to(md) {
      width: 324px; }
    & .rdrDays {
      padding: 0 12px; } }

  & .rdrMonthAndYearPickers select {
    font-weight: 500;
    font-family: $font-family-default;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-100; }

  & .rdrMonthAndYearWrapper {
    height: 42px; }

  & .rdrMonthPicker, .rdrYearPicker {
    margin: 0; }

  & .rdrMonthAndYearPickers select {
    padding: 3px 25px 3px 10px; }

  & .rdrNextPrevButton {
    border: 1px solid $secondary-15;
    border-radius: 7px;
    background: $color-white;
    &:hover {
      border: 1px solid $primary-100;
      & i {
        border-color: $primary-100; } } }


  & .rdrPprevButton i {
    border-color: $secondary-45;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 7px;
    width: 7px;
    margin-left: 9px;
    transform: rotate(225deg); }

  & .rdrNextPrevButton {
    border: 1px solid $secondary-15;
    border-radius: 7px;
    background: $color-white; }

  & .rdrNextButton i {
    border-color: $secondary-45;
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: "";
    display: block;
    height: 7px;
    width: 7px;
    margin: 0 0 0 6px;
    transform: rotate(45deg); }

  & .rdrEndEdge ~ .rdrDayNumber span:after {
    display: none; }

  & .rdrDefinedRangesWrapper {
    width: 100%;
    @include respond-to(md) {
      width: 226px; } }

  & .rdrDateRangePickerWrapper {
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } }

  & .rdrDayToday .rdrDayNumber span:after {
    background: $primary-100;
    bottom: 4px; }

  &__calendar {
    position: absolute;
    z-index: 2000;
    border: 1px solid $secondary-15;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    margin-top: 4px;
    right: 24px; }

  &__label {
    margin: 0 0 .28571429rem 0;
    font-size: 12px;
    font-weight: normal;
    color: $secondary-70;
    line-height: 14px;
    opacity: 1; } }
