@import "../../../../../styles/theme";

.chat-client-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  &_outgoing {
    align-items: flex-end; }
  &__content {
    position: relative;
    max-width: 520px;
    min-width: 180px;
    justify-content: space-between;
    background: $color-white;
    border-radius: 8px;
    padding: 12px 8px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.16);
    word-break: break-word;
    &_outgoing {
      background: $primary-10; }
    &_notification {
      background: $passive-5; }
    &-message {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      white-space: pre-wrap;
      word-break: break-word; }
    &-files {
      display: flex;
      flex-wrap: wrap;
      align-items: start; } }
  &__info {
    display: inline-flex;
    line-height: 1;
    pointer-events: none;
    white-space: pre-wrap;
    word-break: break-word;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none; }
  &__info-container {
    pointer-events: all;
    position: absolute;
    right: 16px;
    visibility: visible;
    white-space: nowrap;
    bottom: 8px;
    display: flex;
    align-items: center; }
  &__info-hidden {
    padding-right: 22px;
    margin-left: 12px;
    visibility: hidden; }
  &__time {
    color: $secondary-45;
    font-size: 12px;
    line-height: 14px;
    padding-right: 4px; }
  &__user-info {
    display: flex;
    align-items: center;
    margin-bottom: 8px; }
  &__user-name {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: $secondary-45;
    margin-left: 6px; }
  &__logo-empty {
    color: $secondary-45; }
  &__file-preview-name {
    margin-top: 4px;
    cursor: pointer; }
  &__message-document {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin-top: 16px;
    text-align: center; }
  &__message-img {
    margin-top: 8px;
    margin-right: 8px;
    img {
      cursor: pointer;
      max-width: 252px;
      max-height: 352px; } }
  &__message-img-loader.ui.loader {
    z-index: 999; }
  &__avatar {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 20px;
    height: 20px;
    border-radius: 50px; }
  &__layer {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top: 0;
    bottom: 0;
    background-color: $color-white; }
  &__image-full-screen {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer; } }
