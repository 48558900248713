@import "../../../../styles/mixins";

.nm-modal-card-list {
  $block: &;

  display: flex;
  flex-direction: column;

  &__content {
    & #{$block}__card {
      &:not(:last-child) {
        margin-bottom: 8px; } } }

  &__pagination {
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 24px; }
    @include respond-to(xxl) {
      margin-top: 32px; } } }
