@import "../../../styles/theme";

.promocode-channel-card {
  &__filter-input {
    width: 220px; }
  &__export {
    color: $secondary-90;
    border-color: $primary-100;
    margin-right: 16px;
    white-space: nowrap;
    &:hover, &:focus {
      color: $secondary-90;
      border-color: $primary-100; } }
  &__back {
    margin-right: 20px; }
  &__header {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    &-row {
      display: flex;
      align-items: center;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; } }
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  &__info {
    margin: 0 24px;
    display: flex;
    padding-top: 5px;
    &>div {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      &:not(:last-child) {
       margin-right: 38px; } } } }
