@import "../../../styles/theme";
@import "../../../styles/mixins";

.vertical-tabs {
  display: flex;
  width: 100%;
  &__title {
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 16px; } }
  &__tabs-container {
    width: 100%;
    @include respond-to(md) {
      width: auto;
      padding-right: 24px;
      border-right: 1px solid $secondary-10; } }
  &__tabs {
    width: 100%;
    @include respond-to(md) {
      min-width: 244px;
      width: 244px; } }

  &__tab {
    padding: 10px 16px;
    color: $secondary-100;
    cursor: pointer;
    &_active {
      border-radius: 6px;
      @include respond-to(md) {
        color: $primary-100;
        background-color: $secondary-5; } } }
  &__content {
    flex-grow: 1;
    position: relative;
    padding-left: 0;
    @include respond-to(md) {
      padding-left: 24px; } } }
