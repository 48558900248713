@import "../../../../styles/mixins";

.registry-duplicate-payments-list {
  &__container {
    width: 100%; }
  &__tooltip {
    height: 18px; }
  &__content {
    padding-right: 8px;
    overflow: auto;
    max-height: calc(100vh - 344px);
    @include respond-to(md) {
      max-height: calc(100vh - 312px); }
    @include respond-to(xxl) {
      max-height: calc(100vh - 400px); } } }
