@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-date-picker {
  $block: &;
  display: flex;
  flex-direction: column;
  width: 100%;

  &_relative {
    position: relative; }

  & .react-datepicker__header__dropdown {
    color: $secondary-70; }

  & .react-datepicker__current-month {
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center; }

  & .react-datepicker__day {
    color: $secondary-100;
    &:hover {
      border-radius: 50px; } }

  & .react-datepicker__day--disabled {
    color: #ccc; }

  & .react-datepicker__day--keyboard-selected {
    color: $color-white; }
  & .react-datepicker__day--selected {
    color: $color-white; }

  & .react-datepicker__current-month {
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $secondary-100; }

  & .react-datepicker__day--outside-month {
    color: $secondary-35!important; }

  & .react-datepicker__day-name {
    color: $secondary-70;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin: 2.5px; }

  & .react-datepicker__year-read-view--down-arrow {
    border-width: 2px 2px 0 0;
    height: 7px;
    width: 7px;
    top: 4px; }

  & .react-datepicker__month-read-view--down-arrow {
    border-width: 2px 2px 0 0;
    height: 7px;
    width: 7px;
    top: 4px; }

  & .react-datepicker__year-dropdown-container {
    margin-left: 16px; }

  & .react-datepicker__day--keyboard-selected, & .react-datepicker__day--selected {
    background-color: $primary-100;
    border-radius: 50%;
    outline: none;
    font-weight: normal; }

  & .react-datepicker__header {
    background: $secondary-5; }

  & .react-datepicker__navigation:not(.react-datepicker__navigation--years) {
    width: 24px;
    height: 24px;
    background: $color-white;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    &:hover {
      border: 1px solid $primary-100;
      & .react-datepicker__navigation-icon::before {
        border-color: $primary-100; } } }

  & .react-datepicker__header {
    border-bottom: none; }

  & .react-datepicker__navigation {
    top: 8px; }

  & .react-datepicker__navigation--next {
    right: 8px; }

  & .react-datepicker__navigation--previous {
    left: 8px; }

  & .react-datepicker__navigation-icon {
    top: 2px; }

  & .react-datepicker__navigation-icon::before {
    border-width: 2px 2px 0 0;
    width: 7px;
    height: 7px; }

  & .react-datepicker__navigation-icon--previous {
    right: 3px; }

  & .react-datepicker__navigation-icon--next {
    left: 2px; }

  & .react-datepicker {
    font-family: Roboto, serif;
    border: 1px solid $secondary-15;
    border-radius: 7px; }

  & .react-datepicker__month-dropdown-container {
    margin-right: 18px; }

  &__suggestion {
    display: none; }

  &:hover {
    & #{$block}__suggestion {
      display: block; } }
  &_size {
    &-xl .react-datepicker-wrapper {
        height: 40px;
        @include respond-to(md) {
          height: 48px; } }
    &-lg .react-datepicker-wrapper {
      height: 40px; } }

  & .react-datepicker__close-icon:after {
    background-color: $secondary-45;
    color: $color-white; }
  &_error .react-datepicker__close-icon:after {
    color: $negative-15; }

  &_time {
    & .react-datepicker__navigation--next--with-time {
      right: 92px; } }

  & .react-datepicker-time__header {
    text-transform: lowercase;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500; }

  & .react-datepicker__time-container {
    & .react-datepicker__time {
      & .react-datepicker__time-box {
        & ul.react-datepicker__time-list {
          & li.react-datepicker__time-list-item--selected {
            background-color: $primary-100;
            font-weight: normal; } } } } } }
