@import "../../styles/theme";
@import "../../styles/mixins";

@mixin size($height, $padding) {
    height: $height;
    padding: $padding; }

.nm-button {
    $block: &;

    display: flex;
    border: none;
    border-radius: 7px;
    align-items: center;
    line-height: 14px;
    font-size: 14px;
    box-sizing: border-box;
    justify-content: center;
    position: relative;
    min-width: fit-content;

    &:hover {
      cursor: pointer; }

    &:focus {
        outline: none; }

    &_openNaimix {
      border-radius: 4px; }

    &__icon-container {
      margin-right: 4px;
      @media screen and (max-width: 1199px) {
        margin-right: 0;
        margin-left: 0; }
      &_onlyIcon {
        margin-right: 0; }
      &_iconPositionEnd {
        margin-right: 0;
        margin-left: 4px; } }

    &__count {
        padding: 2px 5.5px;
        font-size: 12px;
        border-radius: 9px;
        line-height: 14px;
        min-height: 18px;
        min-width: 18px;
        margin-left: 4px; }

    &__icon-wrapper ~ #{$block}__text {
        margin-left: 15px; }
    &__content {
        display: flex;
        align-items: center;
        white-space: nowrap;
        &_hidden {
            visibility: hidden; }
        &_fullWidth {
            width: 100%; } }
    &__text-with-icon-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        &_iconPositionEnd {
          flex-direction: row-reverse; } }
    &__loader.ui.inline.loader {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        top: 0;
        margin: auto; }
    &__text {
        font-family: $font-family-default;
        &_openNaimix {
          font-weight: 500;
          font-family: $font-family-open-naimix;
          font-size: 14px!important;
          line-height: 20px!important; }
        &_alwaysDisplayText {
          display: block !important; } }
    &_size-sm {
        @include size(32px, 0 10px);
        min-width: 32px;
        & #{$block}__icon-container svg {
          width: 18px;
          height: 18px; }
        &#{$block}_clearWidth {
          width: auto;
          min-width: auto;
          min-height: auto;
          height: auto; } }
    &_size-md {
        @include size(36px, 0 12px);
        & #{$block}__icon-container svg {
          width: 20px;
          height: 20px; }
        &#{$block}_clearWidth {
          width: auto;
          min-width: auto;
          min-height: auto;
          height: auto; } }
    &_size-lg {
        @include size(40px, 0 15px);
        min-width: 40px;
        &:not(#{$block}_onlyIcon) {
          min-height: 40px; }
        & #{$block}__icon-container svg {
          width: 20px;
          height: 20px; }
        &#{$block}_clearWidth {
          width: auto;
          min-width: auto;
          min-height: auto;
          height: auto; } }
    &_size-xl {
        @include size(40px, 0 24px);
        min-width: 40px;
        font-size: 14px;
        @include respond-to(md) {
          @include size(48px, 0 24px);
          min-width: 48px;
          font-size: 16px; }
        &#{$block}_clearWidth {
          width: auto;
          min-width: auto;
          min-height: auto;
          height: auto; }
        & #{$block}__text {
          line-height: 20px;
          @include respond-to(md) {
            line-height: 24px; } }
        & #{$block}__icon-container svg {
          width: 24px;
          height: 24px; }
        &#{$block}_openNaimix {
          padding: 0 16px;
          @include respond-to(md) {
            padding: 0 24px; } } }

    &_icon {
      @media screen and (max-width: 767px) {
        padding: 0; }
      & #{$block}__text {
        @media screen and (max-width: 767px) {
          display: none; } } }

    &_onlyIcon {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center; }

    &_color-horeca {
      font-family: $font-family-open-naimix;
      border-radius: 4px;
      color: $color-white;
      background: $hoReCa-grad-primary;
      &:hover {
        background: $hoReCa-grad-primary; } }

    &_color-open-naimix-primary {
        font-family: $font-family-open-naimix;
        border-radius: 4px;
        color: $color-white;
        background-color: $open-naimix-primary-100;
        &:hover {
          background-color: $open-naimix-primary-80; } }


    &_color-promo-poisk-primary {
        font-family: $font-family-open-naimix;
        border-radius: 4px;
        color: $color-white;
        background-color: $promo-poisk-color-4;
        &:hover {
          background-color: $promo-poisk-color-5; } }

    &_color-open-naimix-secondary {
        font-family: $font-family-open-naimix;
        border-radius: 4px;
        color: $color-white;
        background-color: $open-naimix-secondary-100;
        &:hover {
          color: $color-white;
          background-color: $open-naimix-black-80; } }

    &_color-promo-poisk-secondary {
        font-family: $font-family-open-naimix;
        border-radius: 4px;
        color: $promo-poisk-color-4;
        background-color: $color-white;
        &:hover {
          color: $color-white;
          background-color: $promo-poisk-color-2; } }

    &_color-green {
        background-color: $primary-100;
        color: $color-white;
        &:focus {
          background-color: $primary-80; }
        &:hover {
          background-color: $primary-80; }

        & #{$block}__count {
          background-color: $color-white;
          color: $primary-100; } }

    &_color-red {
        color: $negative-100;
        background-color: $negative-15;
        border: 1px solid $negative-20;
        &:hover {
            color: $negative-100;
            background-color: $negative-15;
            border: 1px solid $negative-100; }
        &:focus {
            background-color: $negative-100;
            color: $color-white;
            border: 1px solid $negative-100;
            & #{$block}__count {
              background-color: $color-white;
              color: $negative-100; } }

        & #{$block}__count {
          background-color: $negative-100;
          color: $color-white; } }

    &_color-grey {
        color: $secondary-45;
        border: 1px solid $secondary-15;
        background-color: $color-white;
        &:hover {
            border: 1px solid $primary-100;
            color: $primary-100; }
        &:focus {
            border: 1px solid $primary-100;
            color: $primary-100; }

        & #{$block}__count {
          background-color: $primary-100;
          color: $color-white; } }

    &_color-white {
        color: #999999;
        border: 1px solid $secondary-15;
        background-color: $color-white;
        &:hover {
            border: 1px solid $primary-50;
            color: $primary-100; }
        &:focus {
            border: 1px solid $primary-100;
            color: $primary-100; } }

    &_color-light-green {
        background-color: $primary-10;
        border: 1px solid $color-green-3;
        color: $primary-100;
        &:hover {
            color: $primary-100;
            border: 1px solid $primary-50; }
        &:focus {
            border: 1px solid $primary-100; }

        & #{$block}__count {
              background-color: $primary-100;
              color: $color-white; } }

    &_disabled {
        border: none;
        background-color: $secondary-5;
        color: $secondary-45;

        &:hover {
            cursor: default;
            border: none;
            opacity: 1;
            background-color: $secondary-5;
            color: $secondary-45; }

        #{$block}__icon {
            color: $secondary-45; }

        &#{$block}_openNaimix {
          background-color: $open-naimix-black-15; }

        &#{$block}_promoPoisk {
          background-color: $promo-poisk-color-3; } }
    &_round {
      border-radius: 50%; }
    &_noBorder {
      border: none;
      &:hover {
        border: none; }
      &:focus {
        border: none; } } }

