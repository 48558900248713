@import "../../../../styles/theme";

.map-osm {
  min-height: 100%;
  z-index: 1;
  &__cluster {
    width: 44px !important;
    height: 44px !important;
    background: white;
    border-radius: 50px;
    border: 1px solid #128A10;
    &_selected {
      background: #128A10;
      color: white; }
    &-label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      width: 100%;
      font-size: 16px; } }
  &__counter {
    position: absolute;
    border-radius: 50%;
    height: 18px;
    font-size: 10px;
    width: 18px;
    line-height: 18px;
    color: $color-white;
    background: $primary-100;
    text-align: center;
    top: -3px;
    right: -6px;
    &_selected {
      width: 17px;
      height: 17px;
      border: 1px solid $color-white;
      line-height: unset; }
    &_gray {
      background: $secondary-70; }
    &_blue {
      background: $passive-100; } }
  & .leaflet-div-icon {
    background: transparent !important;
    border: unset !important; } }
