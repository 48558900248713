@import "../../../../../styles/theme";

.contractor-event-log-order-history-card {
  width: 100%;
  &-loader-wrapper {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  &-offset {
    margin-left: 6.25%; }
  &-scans-block {
    &:not(:last-child) {
      margin-bottom: 16px; }
    &-img-block {
      padding: 1px;
      border: 1px solid $secondary-5;
      text-align: center;
      img {
        width: 145px;
        height: 160px;
        cursor: zoom-in; } } }
  &__title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-100;
    font-weight: bold; }
  &__item {
    &:not(:last-child) {
      margin-bottom: 8px; } }
  &-block {
    padding: {
      bottom: 12px; }
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid $secondary-15; }
    &:not(:first-child) {
      padding: {
        top: 12px; } } } }
