$font-family-default: "Roboto";
$font-family-open-naimix: "Montserrat", "sans-serif";

$landing-padding-desktop: 165px;
$landing-padding-tablet: 39px;
$landing-padding-mobile: 14px;
$landing-offset-pre-desktop: 114px;

// цвета Внештат
$hoReCa-primary-100: #B13A88;
$hoReCa-grad-primary: linear-gradient(to right, #B13A88, #DF2D39);
$hoReCa-primary-80: #C968A7;
$hoReCa-primary-50: #D89DC4;
$hoReCa-primary-20: #EFD8E7;
$hoReCa-primary-10: #F8ECF4;
$hoReCa-primary-5: #FBF5F9;
$horeca-black-5: #F5F7F9;
$horeca-secondary: #14060F;

// цвета внешний сайт
$open-naimix-black-5: #F0F6FA;
$open-naimix-black-15: #DAE5EE;
$open-naimix-black-50: #7B92A4;
$open-naimix-black-80: #263742;
$open-naimix-black-100: #242729;
$open-naimix-primary-80: #FF9929;
$open-naimix-primary-100: #F25430;
$open-naimix-secondary-100: #0E6666;
$open-naimix-negative-10: #FFF0F0;
$open-naimix-negative-100: #F20000;


// цвета промопоиск
$promo-poisk-color-1: #ECF3F8;
$promo-poisk-color-2: #D0DAEC;
$promo-poisk-color-3: #E8EDF6;
$promo-poisk-color-4: #6282C1;
$promo-poisk-color-5: #375185;

// Цвет меню РосКапСтрой
$color-ros-kap-stroi-menu: #007BFF;

// Цвет меню HelpResource
$color-help-resource-menu: #FF5502;

// Цвет меню Promopoisk
$color-promopoisk-menu: #719AE3;

// цвета базовые (при наличии оттенков чем выше номер - тем светлее цвет)
$color-black: #000000;

$color-white: #FFFFFF;

$inert-100: #FFB713;

$inert-70: #FFDB4D;

$inert-20: #FFF1D0;

$color-yellow-2: #FCFBE6;

// Основной красный
$negative-100: #EE1616;
$color-red-salmon: #FA8072;
$negative-15: #FFDDCE;
$negative-20: #FFCFBB;
$negative-50: #FA8072;
$color-red-2: #F0D1CA;
$color-red-3: #FFF1D0;
// Не должно быть
$color-red-70: #DD4A4A;

//фон поля в форме когда есть ошибка
$color-red-background-error: #FFDDCE;

$secondary-100: #222222;
$secondary-90: #333333;
$secondary-70: #666666;
$secondary-45: #999999;
$secondary-35: #B2B2B2;
$secondary-40: #B6BAC3;
$secondary-15: #E0E0E0;
$secondary-5: #F1F3F7;
$color-grey-8: #B2B2B2;
$secondary-10: #E4E4E4;
$secondary-80: #444444;
$secondary-75: #555555;
// Не должно быть
$color-gray-13: #8B8B8B;
$color-gray-7: #DCE5F7;

$primary-100: #128A10;
$primary-80: #3C9C3B;
$primary-50: #88C487;
$primary-20: #D0E8CF;
$primary-10: #E9F4E5;
$primary-5: #F0F7ED;
$color-green-3: #D8EAD0;
$color-green-5: #F0F7ED;

// Не должно быть
$grad-primary: linear-gradient(180deg, #51B44F 0%, #128710 100%);

$passive-100: #174A97;
$passive-5: #F4FAFF;

// Не должно быть
$passive-50: #4183C4;
$passive-20: #85B7D9;

$color-orange: #FD550C;

$background-color-default: $color-white;
$background-color-menu: $secondary-100;

$filter-width: 400px;
$page-horizontal-padding-tablet: 24px;
$page-horizontal-padding-desktop: 48px;
