@import "../../../styles/theme";
@import "../../../styles/mixins";

.disputes-new {

  &__layer {
    display: flex;
    justify-content: center;
    align-items: center; }

  &__header-row {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      align-items: center;
      flex-direction: row; } }

  &__img {
    max-width: 100%;
    max-height: 100%;
    cursor: zoom-in;
    &_zoom {
      cursor: zoom-out; } }
  @media screen and (max-width: 1280px) {
    width: 100%; }
  &__form {
    &-block {
      margin-bottom: 16px;
      &-header, &-header-title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
        &-title {
          font-size: 18px;
          margin-bottom: 15px; } } } }
  &__actions {
    &-chat {
      position: relative;
      float: right;
      padding: 10px 15px !important;
      font-size: 16px !important;
      display: inline-flex !important;
      color: $color-white !important;
      background: $grad-primary !important;
      i {
        font-size: 20px; }
      span {
        line-height: 20px;
        padding-left: 5px; }
      &-counter {
        position: absolute;
        right: -3px;
        top: -8px;
        background: $color-orange;
        padding: 3px;
        border-radius: 50%;
        min-width: 23px;
        font-size: 12px; } } }
  &__close-icon {
    position: absolute;
    right: 18px;
    font-size: 20px;
    top: 18px;
    color: $color-orange;
    cursor: pointer; }
  &__responsible-text {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    font-size: 14px; }
  &__responsible-value {
    margin-left: 5px;
    font-weight: bold; }
  &__footer-buttons {
    width: max-content;
    margin-left: auto; }
  &__header {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    margin-bottom: 9px; }
  &__select {
    margin-bottom: 15px;
    flex-direction: column;
    width: 100%;
    background-color: $secondary-5 !important; } }
