.abstract-invite-contractors-page {
  width: 100%;

  &__table {
    margin-top: 15px;
    .row .column {
      align-items: baseline;
      &:nth-child(1) {
        width: 90% !important; }
      &:nth-child(2) {
        width: 10% !important; } } } }
