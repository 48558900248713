.image-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background: rgba(0, 0, 0, .4);
  backdrop-filter: blur(6px);
  height: 100%;
  overflow: auto;
  img {
    max-height: 100% !important;
    max-width: 70%;
    cursor: zoom-out; } }
