@import "../../styles/theme";

.image-crop-form {
  background: $color-white;
  &__image-zone {
    display: flex;
    width: 100%; }
  & .ReactCrop {
      &__drag-handle::after {
        width: 14px;
        height: 14px; }
      // маркеры для сайзинга, начиная от левого верхнего по часовой стрелке
      & .ord-nw {
        margin-top: 0;
        margin-left: 0;
        &:after {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%, 59% 40%, 0 40%);
          background-color: $secondary-15;
          border: none;
          transform: rotate(270deg); } }
      & .ord-n {
        display: none;
        margin-top: -7px;
        margin-left: -7px; }
      & .ord-ne {
        margin-top: 0;
        margin-right: 0;
        &:after {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%, 59% 40%, 0 40%);
          background-color: $secondary-15;
          border: none; } }
      & .ord-e {
        display: none;
        margin-bottom: -7px;
        margin-right: -7px; }
      & .ord-se {
        margin-bottom: 0;
        margin-right: 0;
        &:after {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%, 59% 40%, 0 40%);
          background-color: $secondary-15;
          border: none;
          transform: rotate(90deg); } }
      & .ord-s {
        display: none;
        margin-bottom: -7px;
        margin-left: -7px; }
      & .ord-sw {
        margin-bottom: 0;
        margin-left: 0;
        &:after {
          clip-path: polygon(0 0, 100% 0, 100% 100%, 60% 100%, 59% 40%, 0 40%);
          background-color: $secondary-15;
          border: none;
          transform: rotate(180deg); } }
      & .ord-w {
        display: none;
        margin-top: -7px;
        margin-left: -7px; }
      &__crop-selection {
        border: unset !important;
        border-image-source: unset !important;
        box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.6) !important; } } }
