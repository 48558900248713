@import "../../../styles/theme";

.scan-solution-item {
  display: flex;
  width: 100px;
  height: 64px;
  background: $secondary-5;
  border-radius: 6px;
  padding: 6.5px;
  cursor: pointer;
  margin: auto;
  &_active {
    border: 1px solid $secondary-70; }
  &__icon {
    margin-left: 11.5px;
    &-check {
      position: relative;
      top: -14px;
      right: -33px; } }
  &:not(:last-child) {
 }    //margin-right: 106px
  &:hover {
    border: 1px solid $primary-100;
    .nm-radio-v2__control:before {
      border-color: $primary-100 !important; } }
  &__title {
    width: 100%;
    margin-top: 6px;
    text-align: center !important; } }
