@import "../../../../styles/mixins";

.order-edit-actions {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  @include respond-to(md) {
    margin-top: 24px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; }
  @include respond-to(xl) {
    margin-top: 32px; }

  &__column {
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    @include respond-to(md) {
      flex-direction: row;
      justify-content: flex-end; } }

  &__button {
    justify-content: center;
    margin-top: 8px;
    &_no-mt {
      margin-top: 0; }
    @include respond-to(md) {
      margin-top: 0;
      margin-left: 12px; } }

  &__cancel {
    display: none;
    @include respond-to(md) {
      display: block; } }

  &__back {
    display: none;
    @include respond-to(md) {
      display: block; } } }
