@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.documents-edo-registries-card {
  &__link-file {
    color: $passive-100;
    cursor: pointer;
    margin-left: 6px; }
  &__page {
    & .nm-page__controls {
      @media screen and (min-width: 1200px) {
        width: 100%; } } }
  &__label {
    &_wrap {
      white-space: unset; } }
  &__text {
    width: 20%; }
  &__card-main-content {
    width: 100%;
    & .nm-tooltip {
      z-index: 999; } }
  &__avatar {
    &_withStatus {
      top: 80px !important; } }
  &__badge-container {
    &_withSecondaryHeader {
      margin-top: 0 !important; } } }
