@import "../../../styles/mixins";

@mixin cardContainerDesktopView($param) {
  @include respond-to($param) {
    margin-bottom: 0; } }

@mixin cardContainerDesktopViewNoBootstrap($param) {
  @include respond-to($param) {
    margin-right: 24px; } }

@mixin customContentDesktopView($param) {
  @include respond-to($param) {
    margin-top: 0; } }

.nm-page-info-cards-accordion {
  $block: &;

  display: flex;

  &__accordion:not(:last-child) {
    margin-bottom: 10px; }

  &__card-container {
    &:not(:last-child) {
      margin-bottom: 4px; }

    &:not(#{$block}__card-container_bootstrap):not(:last-child) {
      @include respond-to(xxl) {
        margin-right: 24px; } }

    &_desktopViewFrom-xxl:not(:last-child) {
      @include cardContainerDesktopView(xxl);
      &:not(#{$block}__card-container_bootstrap) {
        @include cardContainerDesktopViewNoBootstrap(xxl); } }

    &_desktopViewFrom-md:not(:last-child) {
      @include cardContainerDesktopView(md);
      &:not(#{$block}__card-container_bootstrap) {
        @include cardContainerDesktopViewNoBootstrap(md); } }

    &_desktopViewFrom-xl:not(:last-child) {
      @include cardContainerDesktopView(xl);
      &:not(#{$block}__card-container_bootstrap) {
        @include cardContainerDesktopViewNoBootstrap(xl); } } }

  &__custom-content {
    display: flex;
    margin-top: 16px;
    &_desktopViewFrom-xxl {
      @include customContentDesktopView(xxl); }
    &_desktopViewFrom-xl {
      @include customContentDesktopView(xl); }
    &_desktopViewFrom-md {
      @include customContentDesktopView(md); } } }
