@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-list-accordion-card {
  $block: &;
  &__icon {
    margin-right: 8px;
    &_open {
      transform: rotate(180deg); } }
  &__header {
    display: flex;
    align-items: center;
    &_pointer {
      cursor: pointer; } }
  &__content {
    margin-top: 16px; } }
