@import "../../../styles/mixins";

.nm-pagination {
  justify-content: flex-end;
  display: flex;
  position: relative;
  min-height: 36px;

  &__page-size {
    display: none;
    @include respond-to(md) {
      display: flex; } } }
