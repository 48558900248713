@import "../../styles/theme";

.order-speciality-paragraph {
  display: flex;
  &__label {
    color: $secondary-45;
    white-space: nowrap;
    margin-right: 8px;
    &_active {
      color: $secondary-70; }
    &_activeCardStyle {
      font-weight: normal;
      color: $secondary-75; } }
  &__value {
    color: $secondary-45;
    word-break: break-word;
    &_active {
      color: $secondary-90; }
    &_croppedValue {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap; }
    &_activeCardStyle {
      font-size: 14px;
      line-height: 20px;
      color: $secondary-100;
      font-weight: 500;
      @media screen and (max-width: 1919px) {
        font-size: 12px; } } }
  &__wrapped {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; } }
