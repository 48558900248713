@import "../../../../../../../styles/theme";
@import "../../../../../../../styles/mixins";

.crowd-task-edit-publishing-form {
  $block: &;

  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $secondary-90;
    font-weight: 400;
    margin-bottom: 26px;
    @include respond-to(md) {
      font-size: 18px;
      line-height: 26px; }
    @include respond-to(xl) {
      font-size: 24px;
      line-height: 28px; } }
  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    & #{$block}__column:first-child {
      margin-right: 16px; }
    @include respond-to(xl) {
      flex-direction: row; } }
  &__column {
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to(md) {
      margin-bottom: 24px; }
    @include respond-to(xl) {
      margin-bottom: 0;
      width: 375px; }
    &:last-child {
      margin-bottom: 0; } }
  &__description {
    width: 100%;
    color: $secondary-90;
    margin-bottom: 18px;
    padding: 16px;
    border: 1px solid $secondary-5;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
    border-radius: 7px;
    position: relative;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xl) {
      padding: 8px 16px;
      border: 1px solid $secondary-15;
      margin-bottom: 20px; }
    &:before {
      width: 0;
      height: 0;
      position: absolute;
      z-index: 21;
      content: '';
      border: 8px solid transparent;
      border-top: 10px solid $secondary-15;
      bottom: -18px;
      left: 50%; }
    &_full {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
    &-text {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 8px;
      @include respond-to(md) {
        font-size: 14px;
        line-height: 20px; }
      @include respond-to(xl) {
        margin-bottom: 0; }
      &_no-mb {
        margin-bottom: 0; } } }
  &__card-app {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 8px;
    max-width: 375px;
    width: 100%;
    max-height: 500px;
    overflow-y: auto;
    &_row {
      display: flex;
      flex-direction: row; }
    &_padding {
      padding: 16px; }
    &_mobile {
      padding: 0; }
    &_full {
      padding-bottom: 22px;
      & #{$block}__card-app-name {
        width: 100%; } }
    &-line {
      left: 0;
      background-color: $passive-50;
      border-radius: 7px 0 0 7px;
      width: 8px;
      height: 100%; }
    &-content {
      width: 100%;
      padding: 12px 16px 16px 8px; } }
  &__amount {
    font-size: 26px; } }
