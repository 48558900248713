@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.order-card-header-label-text {
  width: 100%;
  position: relative;
  color: $secondary-75;

  &_noWrap {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } }

  &__name {
    white-space: nowrap;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-75;
    padding-right: 8px;
    @media screen and (max-width: 1919px) {
      font-size: 12px; } }

  &__value {
    word-break: break-word;
    font-size: 14px;
    color: $secondary-100;
    line-height: 20px;
    font-weight: 500;
    @media screen and (max-width: 1919px) {
      font-size: 12px; }
    & a {
      color: $passive-100; }


    &_noWrap {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; } } }
