@import "../../../styles/mixins";

.edit-field-access {
  display: flex;
  flex-direction: column;
  @include respond-to(md) {
    flex-direction: row; }

  &_column {
    flex-direction: column; }

  &__target {
    flex-grow: 1; }

  &__manage {
    min-width: 220px;
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 0;
      margin-left: 16px; }

    &_column {
      margin-top: 8px;
      margin-left: 0; } } }
