@import "../../../../../styles/mixins";

.contractor-verification-row-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
  @include respond-to(xxl) {
    margin-bottom: 16px;
    flex-wrap: unset; }
  & .contractor-verification-date__input_full {
    margin-bottom: unset; } }
