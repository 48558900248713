
.document-management-statement-filter {
  $block: &;

  &__buttons {
    margin: {
      top: 14px;
      bottom: 17px; } }

  &__row {
    display: flex;
    & > div:first-child {
      margin-right: 8px; } }

  &__dropdown {
    width: 50%; } }
