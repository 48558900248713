@import "../../styles/theme";
@import "../../styles/mixins";

.act-registry-log {
  $block: &;

  position: relative;
  width: 100%;
  max-width: 1100px;

  & .checkbox-list {
    min-height: unset !important; }

  &__pagination {
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 24px; }
    @include respond-to(xxl) {
      margin-top: 32px; } }

  &__form {
    margin-bottom: 8px;
    @include respond-to(md) {
      width: 800px;
      margin-bottom: 0; }
    @include respond-to(xxl) {
      width: 100%; } }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column; }

  &__input {
    width: 100%;
    @include respond-to(md) {
      width: 320px; }
    @include respond-to(xxl) {
      width: 190px; } }

  &__date {
    width: 100%;
    @include respond-to(md) {
      width: 320px; }
    @include respond-to(xxl) {
      width: 280px; } } }
