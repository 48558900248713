.federal-migration-list {
  padding: 0;
  flex-grow: 1;
  &__dropdown {
    width: 340px; }
  &__label {
    margin-bottom: 4px;
    font-size: 12px; }
  &__filter-wrapper {
    display: flex;
    justify-content: space-between; }
  &__add-button {
    width: 275px; }
  &__table {
    flex-grow: 1; } }
