@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.document-management-positions {
  &__button {
    width: 100%;
    @include respond-to(md) {
      width: auto; } }
  &__header {
    align-items: flex-start;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row;
      align-items: center; } }
  &__controls {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
    @include respond-to(md) {
      width: auto;
      margin-top: 0; } } }
