@import "../../styles/theme";

.toast-message {
  &__link {
    &_type-error {
      color: $color-white;
      text-decoration: underline;
      &:hover {
        color: $color-white;
        text-decoration: underline; } } } }
