@import "../../../styles/mixins";
@import "../../../styles/theme";

.deposit-list {
  display: flex;
  flex-grow: 1;
  width: 100%;
  flex-shrink: 0;
  flex-direction: column;
  &_client-admin {
    padding: 0; }

  &__rating.ui.star.rating {
    margin-top: 0;
    & .icon {
      width: 18px;
      height: 18px;
      min-width: 18px;
      font-size: 18px;
      @include respond-to(md) {
        height: 24px;
        width: 24px;
        min-width: 24px;
        font-size: 24px; } } }

  &__primary-header-link {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 4px; }

  &__checkbox-list-avatar {
    @media screen and (max-width: 767px) {
      top: auto!important; } }

  &__primary-header {
    @media screen and (max-width: 767px) {
      margin-top: 10px!important; } }

  &_overflow {
    overflow: auto; }

  &__rate {
    cursor: pointer;
    color: $passive-100;
    text-decoration: underline; }

  &__logo {
    width: 100%;
    height: 100%;
    &_empty {
      width: 100%;
      height: 100%; } }

  &__item-cards-container {
    & .row {
      justify-content: flex-start !important; } }

  &__min-com-link {
    cursor: pointer;
    color: $passive-100; } }
