@import "../../styles/theme";
@import "../../styles/mixins";

.nm-page {
  $block: &;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  overflow: auto;
  @include respond-to(md) {
    padding: 12px $page-horizontal-padding-tablet; }
  @include respond-to(xxl) {
    padding: $page-horizontal-padding-tablet $page-horizontal-padding-desktop; }
  &_noPadding {
    padding: 0; }
  &_overflowUnset {
    overflow: unset; }
  &_heightUnset {
    height: unset; }
  &_relative {
    position: relative; }

  &__title {
    // Для обрезания тремя точками заголовка в в адаптиве
    &_mobileHidden {
      overflow: hidden;
      @include respond-to(xl) {
        overflow: unset; } }
    &_overflow-visible {
      overflow: visible; }
    &_hidden {
      overflow: hidden; } }

  &__button-dropdown {
    margin-left: 8px;
    @include respond-to(md) {
      margin-left: 16px; } }

  &__toggle-filter {
    margin-left: 8px;
    @include respond-to(md) {
      margin-left: 16px; } }

  &__controls {
    min-height: 40px;
    margin-left: 16px;
    display: flex;
    @include respond-to(md) {
      min-height: 48px; } }

  &__content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &_width-by-filter {
      @include respond-to(xl) {
        flex-grow: unset;
        width: calc(100% - 400px); } } }

  &__all-content {
    display: flex;
    flex-grow: 1;
    &_right-filter {
      display: flex; } }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }

  &__header {
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    @include respond-to(xl) {
      margin-bottom: 24px; }

    &_controls-only {
      justify-content: flex-end; } }

  &__sub-header {
    margin-bottom: 16px;
    @include respond-to(xl) {
      margin-bottom: 24px; } }

  &__filters-additional {
    margin-bottom: 30px; }

  &__filters-base {
    display: flex; }

  &__footer {
    position: relative;
    margin-top: 24px;
    min-height: 36px;
    z-index: 1;
    @media screen and (max-width: 1919px) {
      justify-content: flex-end; }
    &_paddingBottom {
      @include respond-to(md) {
        padding-bottom: 12px; }
      @include respond-to(xxl) {
        padding-bottom: 24px; } } }

  &__page-size {
    position: absolute;
    left: 0;
    margin-bottom: 8px;
    @media screen and (max-width: 767px) {
      display: none; } }

  &__pagination {
    display: block; } }
