@import "../../../styles/theme";
@import "../../../styles/mixins";

.registry-edit-contractor-payment {
  $block: &;

  &__info-block-name {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 4px;
      flex-direction: row; } }

  &__payment-status {
    @include respond-to(md) {
      margin-left: 16px; } }

  &__full-name {
    color: $secondary-90;
    margin-bottom: 8px;
    @include respond-to(md) {
      margin-bottom: 0; } }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    width: 100%; }

  &__form-item {
    margin-bottom: 12px;
    @include respond-to(xxl) {
      margin-bottom: 0; } }

  &__payment-status {
    display: flex;
    align-items: center; } }
