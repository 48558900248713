@import '../../../styles/theme';
@import '../../../styles/mixins';

@keyframes spin {
  to {
    transform: rotate(360deg); } }

.mailings-edit {
  display: flex;
  flex-wrap: wrap;
  &__row {
    display: flex;
    align-items: center; }
  &__assigned-block {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: start; }
    &-item {
      display: flex;
      align-items: center;
      margin-left: 18px;
      @media screen and (max-width: 767px) {
        margin-left: 0; } } }
  &__confirm {
    width: 500px;
    &-footer {
      display: flex;
      width: 100%;
      justify-content: center; } }
  &-card {
    background: $color-white;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    padding: 30px 60px 60px 60px;
    height: max-content;
    width: 50%;
    &__sub-header {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 5px; }
    &__refresh {
      cursor: pointer; }
    &__tooltip {
      margin-left: 5px; }
    &__footer {
      margin-top: 50px; }
    &__datepicker {
      width: 300px;
      position: relative; }
    &__checkbox {
      &_checked {
        & label {
          color: black !important;
          font-weight: bold !important; } } }
    & .ui.checkbox input:checked ~ label:before {
      border-color: $primary-100 !important; } }
  &-segmentation {
    background: $color-white;
    border: 1px solid $secondary-15;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 30px 60px 60px 60px;
    height: max-content;
    width: 600px;
    margin-left: 30px;
    top: 0;
    &__params {
      width: 100%;
      & .field:last-child {
        margin-bottom: 1em !important; }
      &-row {
        display: flex; }
      &-multi-dropdown {
        width: 100%; } }
    &__sub-header {
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 5px; }
    &__refresh {
      cursor: pointer; }
    &__footer {
      margin-top: 50px; }
    &__checkbox {
      &_checked {
        & label {
          color: $color-black !important;
          font-weight: bold !important; } } }
    & .ui.checkbox input:checked ~ label:before {
      border-color: $primary-100 !important; }
    &__counter {
      display: inline-block;
      font-weight: bolder;
      margin-left: 4px; } }
  &__modal {
    @extend %wrapper-flex-column;
    position: absolute;
    width: 550px;
    min-height: 160px;
    height: auto;
    margin: 0 auto;
    background: $color-white;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
    &-content {
      padding: 35px;
      &-text {
        text-align: center;
        font-size: 18px;
        line-height: 24px; }
      &-button {
        @include flex_row__space-between;
        justify-content: center;
        margin-top: 15px; } } }
  &__upload {
    white-space: nowrap;
    margin-left: 8px;
    @media screen and (max-width: 767px) {
      width: 100%; } }
  &__add {
    @media screen and (max-width: 767px) {
      width: 50%; } } }

.spin-animation {
  animation: spin 2s linear infinite; }
