@import "../../../styles/theme";
@import "../../../styles/mixins";

.menu-hamburger {
  position: absolute;
  left: 24px;
  top: 16px;
  color: $secondary-45;
  @include respond-to(xl) {
    display: none; }
  &__hamburger {
    display: none;
    &_visible {
      display: block; } }
  &__close {
    display: none;
    font-size: 34px;
    &_visible {
      display: block; } } }
