@import "../../../../styles/theme";

.fc-registry-card {
  &__action-cell {
    width: 100%;
    display: flex;
    justify-content: flex-end; }

  &__mass-button {
    margin-left: 8px; }

  &__header-button {
    margin-right: 16px; }

  &__info-name {
    font-size: 16px;
    font-weight: bold; }

  &__info-other {
    font-size: 14px;
    font-weight: normal;
    margin-top: 6px;
    align-items: center; }

  &__info-icon {
    flex-basis: 13%;
    margin-left: 10px; }

  &__phone {
    font-size: 14px; }

  &__func-link {
    display: flex;
    align-items: center;
    color: $primary-100;
    margin-left: 36px;
    font-size: 16px;
    cursor: pointer;

    &-icon {
      margin-right: 14px; } }
  &__table {
    & .grid-checkbox .row .column {
      &:nth-child(1) {
        width: 3%!important; }
      &:nth-child(2) {
        width: 6%!important; }
      &:nth-child(3) {
        width: 15%!important; }
      &:nth-child(4) {
        width: 10%!important; }
      &:nth-child(5) {
        width: 15%!important; }
      &:nth-child(6) {
        width: 10%!important; }
      &:nth-child(7) {
        width: 15%!important; }
      &:nth-child(8) {
        width: 10%!important; }
      &:nth-child(9) {
        width: 10%!important; }
      &:nth-child(10) {
        width: unset!important;
        flex-grow: 1; } }
    &-row {
      &_red {
        background-color: $color-red-2; } } }

  &__popup {
    white-space: pre-line;
    width: 800px; }
  &__popup-content {
    font-size: 18px; }

  &__item-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }

  &__signed-date {
    margin-left: 7px; }

  &__status {
    max-width: 400px;
    bottom: calc(100% + 10px);
    top: unset !important;
    margin: 0 16px;
    &_red {
      color: $negative-100; }
    &_green {
      color: $primary-100; }
    .advanced-tooltip__black-popup-content:before {
      display: none; } }

  .nm-list-card__secondary-header-value {
    overflow: unset; }

  &__subheader {
    &-menu {
      margin-left: auto; } }

  //TO DO необходимо кастомизировать компонент под такие изменения
  .nm-page__title {
    overflow: hidden; }
  .nm-title__container {
    overflow: hidden; }
  .nm-title__value {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; } }



