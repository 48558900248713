@import "../../styles/theme";

.views-count {
  display: flex;
  font-size: 12px;
  line-height: 14px;
  color: $secondary-45;
  align-items: center;
  &__value {
    margin-left: 4px; } }
