@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.agency-contract-file-link {
  color: $passive-100;
  cursor: pointer;
  white-space: pre-wrap;
  display: block;
  @include respond-to(md) {
    display: unset; } }
