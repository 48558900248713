@import "../../styles/theme";
@import "../../styles/mixins";

.avatar {
  display: flex;
  justify-content: center;
  position: relative;
  &__image-container {
    width: 100%;
    overflow: hidden;
    border-radius: 50%;
    background-size: contain; }
  &__indicator {
    position: absolute;
    bottom: -10px;
    @include respond-to(md) {
      bottom: -14px; }
    &-icon {
      min-width: 22px; } }
  &__tooltip-indicator {
    left: -12px;
    @media screen and (max-width: 767px) {
      width: 248px!important;
      max-width: 248px!important;
      left: -9px!important; } }
  &__mode-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 18px;
    height: 18px;
    background: $primary-100;
    border-radius: 50%;
    cursor: pointer;
    color: $color-white;
    padding: 2px;
    &_topRight {
      top: 0;
      bottom: unset; } }
  &__counter {
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    background: $negative-100;
    font-size: 12px;
    text-align: center;
    width: 18px;
    height: 18px;
    color: $color-white;
    z-index: 9999;
    top: 21px;
    left: 50px;
    @media screen and (max-width: 767px) {
      top: -4px;
      left: 27px; }
    @media screen and (min-width: 768px) and (max-width: 1919px) {
      top: 9px;
      left: 48px; }
    &_showCheckBox {
      left: 94px;
      @media screen and (max-width: 767px) {
        left: 27px; }
      @media screen and (min-width: 768px) and (max-width: 1919px) {
        left: 82px; } } }
  &_size-xl {
    min-width: 63px;
    width: 63px;
    height: 63px; }
  &_size-lg {
    min-width: 50px;
    height: 50px;
    width: 50px; }
  &_size-md {
    min-width: 34px;
    height: 34px;
    width: 34px; }
  &_size-sm {
    max-width: 20px;
    max-height: 20px;
    min-height: 20px;
    min-width: 20px; }
  &__empty {
    width: 100%;
    height: 100%;
    background: url("../../images/avatar-empty.svg");
    background-size: contain; }
  &__img {
    &_cursorZoomIn {
      cursor: zoom-in; } }
  &__original-img {
    width: 100% !important; }

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
    border-radius: inherit; }

  svg {
    contain: size; } }
