@import "../../../styles/mixins";
@import "../../../styles/theme";

.order-card {
  width: 100%;
  padding: 0;
  flex-grow: 1;
  display: flex;
  position: relative;
  flex-direction: column;

  &__status-row {
    display: flex;
    align-items: center; }

  &__title {
    display: flex;

    &-row {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
        align-items: flex-start; } } }

  &__row {
    margin-bottom: 16px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      align-items: flex-end;
      margin-bottom: 10px; } }

  &__status {
    margin-right: 16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    padding: 4px 9px;
    border-radius: 7px; }

  &__speciality {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    @media screen and (max-width: 1919px) {
      margin-bottom: 4px;
      font-size: 12px; } }

  &__keywords {
    display: flex;
    align-items: center;
    margin-top: 16px;
    flex-wrap: wrap;
    @media screen and (max-width: 1919px) {
      margin-top: 12px; }

    &-item {
      padding: 3px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $secondary-5;
      border-radius: 6px;
      color: $secondary-75;
      font-size: 12px;
      line-height: 18px;
      word-break: break-word;
      margin-bottom: 8px;
      margin-right: 8px; } }
  &__back {
    margin-right: 12px;
    @media screen and (max-width: 767px) {
      display: none; } }
  &__payout-info {
    height: 100%; }
  &__share {
    position: relative; }
  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    @include respond-to(xxl) {
      margin-bottom: 32px; } }
  &__end-action {
    @media screen and (max-width: 767px) {
      display: none; } }
  &__actions {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 0; }
    &-icon-play {
      background: url("../../../images/play_ico.svg") no-repeat left center;
      width: 42px;
      height: 19px; }
    &-button {
      margin-left: 16px; }
    &-dropdown {
      background-color: transparent !important;
      margin-left: 20px!important;
      padding: 18px 15px 15px !important;
      &_border {
        border: 1px solid $secondary-15 !important;
        border-radius: 7px !important; }
      &> .dropdown-app-content > i {
        color: $primary-100;
        &:hover {
          color: $secondary-40; } } } }
  &__info-contractors {
    margin-top: 16px;
    @include respond-to(xl) {
      margin-top: 8px; } }
  &__information {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 24px;
    @media screen and (max-width: 767px) {
      padding-top: 0; }
    &-container {
      display: flex; } }
  &__documentation {
    white-space: nowrap;
    display: flex;
    &-link {
      overflow: hidden;
      text-overflow: ellipsis;
      color: $passive-100;
      text-decoration: underline;
      &:hover {
        color: $passive-100;
        text-decoration: underline; } } }

  &__category {
    margin-top: 29px;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-90; }

  &__label {
    &:not(:last-child) {
      margin-bottom: 6px;
      @media screen and (max-width: 1919px) {
        margin-bottom: 4px; } } }

  &__name {
    word-break: break-word;
    font-weight: 500;
    line-height: 24px;
    font-size: 16px;
    color: $secondary-100;
    margin-bottom: 8px; }

  &__icon-confirm {
    margin-left: 10px; }

  &__num {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-right: 12px;
    @media screen and (max-width: 767px) {
      margin: 19px 0; } }

  &__modal-desk {
    &-content {
      max-height: 500px;
      overflow: auto;
      word-break: break-word; } }

  &__description {
    word-break: break-word;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-90;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; }

  &__shore-more-anchor {
    color: $primary-100; }

  &__description-general {
    position: relative;
    margin-bottom: 16px;
    @include respond-to(xl) {
      padding-right: 37px; }
    @include respond-to(xxl) {
      padding-right: 116px; }

    &-sub {
      & .flex {
        margin-bottom: 4px; } }

    &-money {
      min-width: 120px; } }

  &__read {
    color: $primary-100;
    cursor: pointer;
    margin-top: 6px;
    display: block; }

  &__tabs {
    margin-bottom: 24px; }

  &__tabs-container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    @media screen and (max-width: 1023px) {
      padding: 0; }

    & .active.tab {
      flex-grow: 1; }

    & > .ui.tabular.menu {
      justify-content: flex-start !important;
      background-color: transparent !important;
      border-bottom: 1px solid rgba(63, 155, 20, 0.5) !important;
      position: relative;
      & > .item {
        border-radius: 7px 7px 0 0 !important;
        padding: 13px 36px;
        border-left: 1px solid transparent !important;
        border-right: 1px solid transparent !important;
        border-top: 0 !important;
        border-bottom-width: 3px !important;
        border-bottom-color: transparent !important;
        background-color: #E9F4E5 !important;
        & > .menu-item-name {
          color: #000 !important;
          @media screen and (max-width: 1023px) {
            font-size: 14px; } }
        & > .menu-item {
          display: flex;
          align-items: center;
          position: relative;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: #128A10 !important; } }
      & > .active.item {
        background: rgba(255, 255, 255, 1) !important;
        border: 1px solid rgba(63, 155, 20, 0.5) !important;
        border-bottom: 1px solid transparent !important;
        border-radius: 7px 7px 0 0 !important;
        & > .menu-item {
          margin-bottom: 1px; } }

      &-name {
        &:after {
          content: attr(data-counter);
          position: absolute;
          bottom: 9px;
          left: 103%; } }

      & .menu-item-icon {
        color: #128A10 !important; } } } }
