@import "../../styles/theme";
@import "../../styles/mixins";

.contractor-bank-sender {
    $block: &;

    display: flex;
    align-items: start;
    flex-direction: column;
    @include respond-to(xl) {
      flex-direction: row;
      align-items: center; }

    &__checkbox-group {
      display: flex;
      align-items: start;
      flex-direction: column;
      @include respond-to(md) {
        flex-direction: row;
        align-items: center; } }

    &__checkbox {
      &:not(:last-child) {
        @include respond-to(md) {
          margin-right: 20px; }
        &:last-child {
          margin-top: 8px;
          @include respond-to(xl) {
            margin-top: 0; } } } }

    &__button-container {
      width: 100%;
      @include respond-to(xl) {
        width: unset;
        margin-top: unset;
        margin-left: 24px; }

      &:not(:first-child) {
        margin-top: 8px;
        @include respond-to(xl) {
          margin-top: 0;
          margin-left: 24px; } } } }
