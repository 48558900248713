@import "../../styles/theme";
@import "../../styles/mixins";

.nm-modal-prompt {
  position: absolute !important;
  top: 0;
  right: 0;
  &__tooltip {
    right: -10px !important;
    @media screen and (max-width: 767px) {
      right: 0 !important;
      left: -170px !important;
      width: 200px !important;
      max-width: 200px !important; } }
  &__icon {
    background-size: cover;
    background-position: center;
    cursor: pointer;
    color: $secondary-40;
    &_on {
      color: $primary-100; } } }
