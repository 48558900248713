.settings-directories-tariff-grid {
  overflow: unset;
  &__table {
    & .ui.grid .row .column {
      &:nth-child(1) {
        width: 20%; }
      &:nth-child(2) {
        width: 20%; }
      &:nth-child(3) {
        width: 20%; }
      &:nth-child(4) {
        width: 10%; }
      &:nth-child(5) {
        width: 15%; }
      &:last-child {
        width: unset;
        flex-grow: 1; } } } }
