@import "../../styles/mixins";

.layouts {
    display: flex;
    height: 100%;
    @include respond-to(md) {
      height: 100vh; }
    &__content {
        position: relative;
        display: flex;
        width: 100%;
        overflow: auto;
        @media screen and (max-width: 1199px) {
          margin-top: 56px; } } }


