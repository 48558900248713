@import "../../../styles/theme";

.str-param-editor {
  $block: &;
  &__param {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  &__icon {
    height: 20px;
    margin-left: 6px;
    width: 20px;
    color: $secondary-45;
    cursor: pointer;
    &_edit {
      &:hover {
        color: $primary-100;
        transition: color ease-in-out .1s; } }
    &_del {
      &:hover {
        color: $negative-100;
        transition: color ease-in-out .1s; } }
    &_confirm {
      width: 18px;
      height: 18px;
      color: $primary-100; }
    &_ml {
      margin-left: 6px; } }
  &__icons {
    display: flex;
    align-items: center; }
  &__row {
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding: 2px 0;
    align-items: flex-start; }
  & #{$block}__row:not(:last-child) {
    margin-bottom: 8px; }
  &__row-new {
    display: flex;
    align-items: flex-start;
    margin-bottom: 8px; }
  &__icon-container {
    height: 40px;
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 24px;
    &_ml {
      margin-left: 8px; } } }
