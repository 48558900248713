.signing-documents-log-filter {
  margin-bottom: 20px;
  &__actions {
    margin-bottom: 15px; }
  &__period {
    width: 280px; }
  &__field {
    width: 200px; }
  &__customer {
    width: 300px; } }
