@import '../../styles/theme';
@import '../../styles/mixins';

.rejection-reason-form {
  height: auto;
  &__title {
    position: relative;
    font-style: normal;
    width: 100%;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px; } }
