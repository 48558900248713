@import '../../../styles/theme';
@import '../../../styles/mixins';

.task-details-modal {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; }
  &__list {
    padding-right: 8px;
    overflow: auto;
    max-height: calc(100vh - 146px);
    @include respond-to(md) {
      max-height: calc(100vh - 190px); }
    @include respond-to(xxl) {
      max-height: calc(100vh - 302px); } } }
