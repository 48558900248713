.fc-registry-card-filter {
  display: flex;
  align-items: flex-end;
  margin-bottom: 4px;

  &__dropdown {
    width: 220px; }

  &__input {
    width: 260px; } }

