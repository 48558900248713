@import "../../../styles/mixins";

.count-refresh-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @include respond-to(md) {
    justify-content: flex-end; }

  &__label {
    margin-right: 6px; }

  &__button-container {
    display: flex;
    align-items: center; }

  &__count {
    margin-right: 6px; }

  &__refresh {
    width: 20px;
    height: 20px; } }
