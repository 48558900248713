@import "../../styles/theme";

.contractor-header {
    $block: &;

    display: flex;
    width: 100%;
    justify-content: space-between;

    &__name-wrapper {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &-col {
          max-width: 100%; } }

    &__statuses-wrapper {
        display: flex;
        align-items: center; }

    &__name {
        margin-right: 15px;
        overflow: hidden;
        text-overflow: ellipsis; }

    &__icon-lock {
        color: $negative-100;
        align-self: center;
        margin-right: 15px; }

    &__blacklist-status-wrapper,
    &__block-status-wrapper {
        position: relative;
        width: 32px;
        margin-right: 15px;
        &:hover {
            #{$block}__blacklist-reason,
            #{$block}__block-reason {
                display: block; } } }

    &__blacklist-reason,
    &__block-reason {
        display: none;
        position: absolute;
        top: 25px;
        left: 0;
        z-index: 1;
        width: 150px; }

    &__last-order-date {
        background-color: #E9F4E5;
        border-radius: 7px;
        padding: 4px 10px;
        font-size: 12px;
        height: 24px; }

    &__online-indicator-wrapper {
        min-width: 235px; }

    &__online-indicator {
        color: $secondary-70;
        &_online {
            position: relative;
            display: flex;
            align-items: center;
            margin-left: 15px;
            &::before {
                position: absolute;
                content: '';
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: $primary-100;
                left: -15px;
                top: 3px; } } }

    &__controls {
        display: flex;
        margin-left: auto;
        align-items: center; }

    &__controls-item {
        margin-left: 16px; }

    &__buttons-apply {
        justify-content: flex-end; }

    &__textarea {
        &_error {
            border: 1px solid #e0b4b4 !important; } }

    &__error-label {
        visibility: hidden;
        background-color: $color-white !important;
        border: 1px solid #e0b4b4 !important;
        color: #9f3a38 !important;
        &_shown {
            visibility: visible; } }

    &__textarea-claim {
        position: relative; }

    &__archive-modal-apply-buttons {
        justify-content: center; }

    &__claim-message-counter {
        position: absolute;
        right: 10px;
        bottom: 45px;
        color: $secondary-70;
        font-size: 12px; }

    &__claim-reason-popup {
        width: 600px; }

    &__archive-icon {
        color: $secondary-45;
        &:hover {
            color: $primary-100; } }

    &__claim-reason-radio {
        & label {
            color: $secondary-70;
            font-size: 16px !important;
            line-height: 17px !important; }
        &_checked {
            & label {
                color: $secondary-100;
                font-weight: 500 !important; } } }
    &__modal {}

    &__blocked-control-wrapper {
      display: flex; }

    &__blocked-status {
      font-size: 16px;
      margin-right: 10px;
      color: $secondary-45; }

    &__blocked-warning-msg {
      white-space: pre-wrap; } }
