@import "../../styles/theme";

.executor-check-status {
  display: flex;
  &__label {
    size: 14px;
    white-space: nowrap;
    line-height: 20px;
    margin-right: 10px;
    &_approved {
      color: $primary-100; }
    &_rejected {
      color: $negative-100; }
    &_not-verified {
      color: $inert-70; } }
  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-5;
    border-radius: 50%;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    &:not(:last-child) {
      margin-right: 8px; }
    &_approved {
      background-color: $primary-100; }
    &_rejected {
      background-color: $negative-100; }
    &_not-verified {
      background-color: $inert-70; }
    &-elem {
      max-width: 16px;
      overflow: hidden;
      color: $color-white; } } }
