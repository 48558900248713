@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.object-edit-form {
  &__confirm {
    width: 500px; }
  &__confirm-layer.modal-layer {
    z-index: 1050; }
  & .react-dadata {
    z-index: 1001; }
  &__dropdown {
    min-width: 120px;
    @include respond-to(md) {
      margin-left: 20px; } }
  &__dropdown-icon {
    cursor: pointer;
    color: #D2D2D2;
    transform: rotate(90deg);
    line-height: 10px; }
  &__dadata {
    & .nm-dadata-select__form-element {
      background-color: #fff!important; } }
  &__header {
    display: flex;
    align-items: baseline;
    white-space: nowrap;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } }
  &__block-header {
    font-size: 16px; }
  &__row {
    display: flex;
    align-items: flex-start; }
  &__field {
    &_short {
      width: 35%; }
    &_long {
      width: 65%; }
    &_mr {
      margin-right: 16px; }
    &_full {
      width: 100%; } }
  &__actions {
    margin-top: 30px; }
  .ui.form {
    .field {
      margin-bottom: 0;
      >label, label {
        font-size: 12px;
        font-weight: normal; }
      input, .ui.compact.search.selection.dropdown {
        border-radius: 7px; }
      .checkbox {
        margin-left: 15px;
        margin-bottom: 5px; } } }
  &__icon {
    color: $secondary-45;
    position: absolute;
    z-index: 1;
    top: 36px;
    &_start {
      left: 6px; }
    &_end {
      left: 181px; } }
  &__budget {
    display: flex; }
  &__budget-checkbox {
    margin-top: 37px;
    margin-left: 16px; }
  &__distance-block {
    .field {
      width: 78px; }
    &-label {
      margin: 8px 0 10px; } }
  &__map {
    height: 280px;
    margin: 10px 0; }
  &__address {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; }
    &-input {
      width: 100%;
      @include respond-to(md) {
        width: 65%; } } }
  &__coords {
    width: 100%;
    @include respond-to(md) {
      margin-left: 16px;
      width: 35%; } }
  &__coord-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end; }
  &__coord {
    width: 130px; }
  &__coord-first {
    input {
      border-radius: 7px 0 0 7px !important;
      border-right: none!important; } }
  &__coord-second {
    input {
      border-radius: 0 7px 7px 0 !important; } }
  .react-dadata {
    label {
      font-size: 12px !important;
      font-weight: normal; } }
  &__select {
    flex-direction: column;
    max-width: 494px;
    & .ui.active.selection.dropdown {
      z-index: 1050; }
    &_sub-category {
      & .ui.selection.dropdown {
        &>.text {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; } } }
    &_responsible {
      &.error {
        & .text {
          background-color: #fff!important; } }
      & .ui.selection.dropdown {
        display: flex;
        align-items: center;
        border: none!important;
        padding-top: 0!important;
        height: unset!important;
        background: #fff!important;
        min-height: unset!important;
        padding-bottom: 0!important;
        & > .text {
          min-height: 18px; }
        & .text {
          color: #174a97!important; }
        & .dropdown.icon {
          padding: 0!important; } }
      & .ui.active.visible.selection.dropdown {
        box-shadow: none;
        padding-top: 10px;
        border: 0;
        & .visible.menu.transition {
          border-radius: 7px;
          top: 30px;
          & > .item:first-child {
            border-top: 1px #96c8da solid; } } } } } }
