@import "../../../styles/theme";

.document-management-personnel {
  &__icon-blocked {
    margin-left: 8px;
    min-width: 24px; }

  &__fio {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__fio-container {
    display: flex;
    width: 100%; } }
