@import "../../styles/theme";
@import "../../styles/mixins";

.card-app {
  $block: &;

  background: #FFFFFF;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  padding: 12px;
  height: max-content;
  @include respond-to(md) {
    padding: 16px; }

  &__loader {
    display: flex !important;
    justify-content: center !important; }

  &__control {
    color: $secondary-45;
    &:hover {
      color: $secondary-70; } }

  &__download-icon {
    &:hover {
      color: $primary-100; } }

  &_scans {
    box-shadow: none;
    border-radius: 0;
    border: 1px solid $secondary-15; }

  &_border {
    border: 5px solid $inert-70; }

  &__header-controls {
    display: flex;
    align-items: center;
    cursor: pointer;
    & #{$block}__control:not(:first-child) {
      margin-left: 8px; } }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    min-height: 22px;
    @include respond-to(md) {
      margin-bottom: 16px; }
    &-title {
      &-tooltip {
        margin-right: 8px;
        display: inline-block;
        &-revers {
          display: inline-block;
          margin-left: 10px; } } } } }
