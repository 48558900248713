@import "../../../styles/theme";
@import "../../../styles/mixins";

.login-change {
  width: 100%;
  font-family: Montserrat, sans-serif;
  @include respond-to(md) {
    width: 500px; }

  &__title {
    color: $open-naimix-black-100;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    @include respond-to(md) {
      font-size: 24px;
      line-height: 30px; }
    @include respond-to(xxl) {
      font-size: 28px;
      line-height: 34px; } }

  &__button {
    width: 100%; }

  &__form {
    width: 100%; } }
