@import "../../../../styles/theme";
@import "../../../../styles/mixins";

%border {
  border: 5px solid $inert-70; }
.passport-data {
  &__item {
    margin-bottom: 8px; } }

.contractor-form-card {
  position: relative;
  &__item {
    margin-bottom: 8px; } }
.full-personal-data {
  &__divider {
    display: flex;
    height: 1px;
    background-color: $secondary-10;
    margin-top: 12px;
    @include respond-to(md) {
      margin-top: 16px; }
    &:not(:last-child) {
      margin-bottom: 12px;
      @include respond-to(md) {
        margin-bottom: 16px; } }
    &_yellow {
      background-color: $inert-70; } }
  &__submission-date-time {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    color: $primary-100;
    margin-top: 2px;
    @include respond-to(md) {
      margin-top: 0;
      margin-left: 8px; } }
  &__card {
    position: relative;
    &_border {
      @extend %border; } }
  &__sub-block {
    &_border {
      @extend %border; } }

  &__general-data {
    width: 50%;
    margin-right: 45px; }
  &-payment-data {
    position: relative;
    width: 50%; }
  &__content {
    display: flex; }
  &__form {
    width: unset; }
  &__header {
    z-index: 1;
    position: absolute;
    right: 20px;
    top: 20px; }
  &__title {
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 26px;
      flex-direction: row;
      align-items: center; }
    &_column-in-mobile {
      flex-direction: column;
      @include respond-to(md) {
        flex-direction: row; } } }
  &__tooltip {
    margin-left: 10px;
    color: red; }
  &__item {
    margin-bottom: 8px; }
  &__phone-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px; }
  &__phone-icon-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: -7px;
    top: 30px;
    width: 15px;
    height: 15px;
    background-color: $inert-70;
    cursor: pointer;
    align-self: flex-end;
    margin-bottom: 2px;
    margin-left: 7px;
    padding-left: 3px;
    &_edit {
      align-self: center; } }
  &__input-phone-wrapper {
    position: relative;
    display: flex;
    & .full-personal-data__phone-icon-wrapper {
      position: relative;
      top: auto;
      right: auto; } }
  &__phone-icon {
    color: $color-black; }
  &__phones-popup {
    display: inline-block;
    position: absolute;
    list-style: none;
    background-color: $color-white;
    border: 1px solid $secondary-15;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    top: 20px;
    left: -80px;
    z-index: 1;
    padding: 5px 3px;
    border-radius: 6px; }
  &__phones-popup-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    color: $secondary-40;
    padding: 0 7px;
    &:not(:last-child) {
      border-bottom: 1px solid $secondary-15;
      margin-bottom: 5px;
      padding-bottom: 2px; } }
  &__phones-popup-item-info {
    white-space: nowrap;
    &:not(:last-child) {
      margin-right: 15px; } }
  &__apply-buttons {
    justify-content: center; }
  &__phone-confirm-modal {
    text-align: center; }
  &__hide-desktop {
    display: none;
    @media screen and (max-width: 1920px) {
      display: flex; } } }
