.contractor-card {
  &__tabs {
    margin-bottom: 24px!important;
    flex-shrink: 0;
    flex-wrap: wrap; }
  &__title {
    width: 100%;
    overflow: unset !important; }
  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      color: #000000; }
    &__full-name {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
      &_bold {
        font-weight: bold; } }
    &__date {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      &_bold {
        font-weight: bold; } } }
  &__info {
    padding-left: 60px;
    margin-bottom: 44px; }

  &__button-back {
    margin-right: 20px !important; } }

