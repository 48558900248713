@import "../../../../styles/theme";

.order-card-info {
  border-radius: 7px;
  padding: 12px;
  height: auto;
  width: 100%;
  @media screen and (max-width: 1199px) {
    width: 100%;
    border: none;
    padding: 0; }
  &__plan {
    font-size: 16px;
    line-height: 24px;
    color: $secondary-90;
    @media screen and (max-width: 1199px) {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px; } }
  &__payed {
    color: $secondary-100;
    font-weight: 500; }
  &__title {
    font-weight: 500;
    color: $secondary-70;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 6px; }
  &__sub-volume {
    color: $secondary-45;
    font-size: 12px;
    line-height: 18px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 16px; } }
  &__per-one {
    color: $secondary-45;
    font-size: 12px;
    line-height: 18px;
    &_divider {
      padding-bottom: 12px;
      margin-bottom: 14px;
      border-bottom: 1px solid $secondary-15;
      @media screen and (max-width: 1199px) {
        margin-bottom: 16px; } } }
  &__row {
    display: flex;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 4px; } }
  &__label {
    color: $secondary-70;
    font-size: 12px;
    line-height: 18px; }
  &__value {
    color: $secondary-100;
    font-size: 14px;
    line-height: 20px; } }
