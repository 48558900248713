@import "../../../../styles/theme";

.user-edit-modal {
  &__toggle-password {
    margin-bottom: 16px; }

  &__buttons-apply {
    width: 100%;
    justify-content: flex-end; }

  &__form {
    width: 100%;

    &-sub-title {
      margin-bottom: 16px;
      &:not(:first-child) {
        margin-top: 32px; } } } }

