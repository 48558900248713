@import '../../styles/theme';
@import '../../styles/mixins';

.apply-buttons {
  $block: &;

  display: flex;
  align-items: center;
  flex-direction: column;

  &_mobile-row {
    flex-direction: row;
    & #{$block}__cancel {
      width: 50%;
      @include respond-to(md) {
        width: auto; } }
    & #{$block}__submit {
      width: 50%;
      margin-left: 8px;
      @include respond-to(md) {
        margin-left: 12px;
        width: auto; } } }

  &_mobile-column {
    flex-direction: column-reverse;
    @include respond-to(md) {
      flex-direction: row; }

    & #{$block}__additional {
      margin-top: 8px;
      width: 100%;
      @include respond-to(md) {
        margin-bottom: 0;
        width: auto; } }

    & #{$block}__cancel {
      width: 100%;
      @include respond-to(md) {
        width: auto;
        margin-bottom: 0; } }

    & #{$block}__submit {
      width: 100%;
      margin-bottom: 8px;
      margin-left: 0;
      @include respond-to(md) {
        margin-left: 12px;
        width: auto; } } }

  &_align {
    &-right {
      justify-content: flex-end; }
    &-left {
      justify-content: flex-start; }
    &-center {
      justify-content: center; } }

  &_spaced {
    justify-content: space-between; }

  &__additional {
    &_hiddenOnMobile {
      display: none;
      @include respond-to(md) {
        display: block; } } }

  &__main-buttons {
    display: flex;
    width: 100%;
    @include respond-to(md) {
      width: auto; }
    &_mobile-column {
      flex-direction: column-reverse;
      @include respond-to(md) {
        flex-direction: row; } } }

  &__cancel {
    &_hiddenOnMobile {
      display: none;
      @include respond-to(md) {
        display: block; } } }

  &__submit {
    margin-left: 12px;
    width: auto; } }
