@import "../../../styles/mixins";

.notification-card {
  &__button {
    width: 100%;
    @include respond-to(md) {
      width: auto; } }

  &__description-icon {
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-right: 4px; }

  &__content {
    display: block;
    white-space: pre-line; }

  &__files-block {
    display: flex;
    align-items: center;
    margin-top: 8px; } }
