@import "../../../styles/theme";
@import "../../../styles/mixins";

.bg-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;
  padding: 20px 16px;
  background: rgba(0, 0, 0, .4);
  backdrop-filter: blur(6px);
  @include respond-to(md) {
    padding: 20px 24px; }
  @include respond-to(xxl) {
    padding: 60px; }
  &_hidden {
    visibility: hidden; } }

.nm-modal-v2 {
  $block: &;

  position: relative;
  width: fit-content;
  border-radius: 12px;
  background: $color-white;
  padding: 32px 16px 16px 16px;
  @include respond-to(md) {
    padding: 40px 32px 32px 32px; }
  @include respond-to(xxl) {
    padding: 48px; }
  &_align {
      &-top {
        margin-top: 0; }
      &-center {
        margin: auto; } }
  &_loading {
    position: relative; }
  &_size-md {
    width: 100%;
    max-width: 700px; }
  &_size-lg {
    width: 100%;
    max-width: 1080px; }
  &_size-sm {
    width: 100%;
    max-width: 500px; }
  &_openNaimix {
    width: 100%;
    padding: 24px 16px;
    @include respond-to(md) {
      width: 580px;
      padding: 40px; }
    @include respond-to(xxl) {
      width: 720px; } }

  &__steps-progress {
    display: flex; }

  &__search-content {
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 16px; } }

  &__back-icon {
    cursor: pointer;
    position: absolute;
    left: 8px;
    top: 8px;
    color: $secondary-40;
    @include respond-to(md) {
      display: none; }

    &_visible {
      display: block; } }

  &__dimmer {
    border-radius: 12px; }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 8px;
    color: $secondary-40;
    &_openNaimix {
      color: $open-naimix-black-50;
      right: 5px;
      top: 5px; } }

  &__content {
    $contentBlock: &;

    display: flex;
    justify-content: center;
    overflow-wrap: break-word;
    &_align-left {
      justify-content: flex-start;
      text-align: left; }
    &_align-center {
      text-align: center;
      justify-content: center; }
    &_align-right {
      text-align: right;
      justify-content: flex-end; }
    &_breakSpaces {
      white-space: break-spaces; }
    &_fixedHeight {
      max-height: 60vh; }
    &_autoSize {
      overflow: auto;
      max-height: calc(100vh - 120px);
      @include respond-to(md) {
        max-height: calc(100vh - 154px); }
      @include respond-to(xxl) {
        max-height: calc(100vh - 266px); }
      &#{$contentBlock}_search {
        max-height: calc(100vh - 332px);
        @include respond-to(md) {
          max-height: calc(100vh - 242px); }
        @include respond-to(xxl) {
          max-height: calc(100vh - 354px); } }
      &#{$contentBlock}_search#{$contentBlock}_footer {
        max-height: calc(100vh - 388px);
        @include respond-to(md) {
          max-height: calc(100vh - 318px); }
        @include respond-to(xxl) {
          max-height: calc(100vh - 412px); } } } }

  &__header {
    width: 100%;
    margin-bottom: 8px;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xxl) {
      margin-bottom: 24px; }
    &_openNaimix {
      margin-bottom: 16px;
      @include respond-to(md) {
        margin-bottom: 24px; } }
    &_noBody {
      margin-bottom: 0; } }

  &__footer {
    width: 100%;
    margin-top: 16px;
    @include respond-to(md) {
      margin-top: 24px; }
    @include respond-to(xxl) {
      margin-top: 32px; } }

  &__prompt {
    right: 40px;
    top: 8px; }

  &__additionElement {
    margin-left: 20px; }

  &__warning {
    margin-bottom: 24px; } }

