@import "../../../styles/theme";
@import "../../../styles/mixins";

.registry-card {
  position: relative;

  &__bank-possible {
    display: flex; }

  &__act {
    margin-bottom: 2px; }

  &__page-controls {
    flex-grow: 1;
    justify-content: flex-end; }

  &__controls-container {
    display: flex;
    flex-grow: 1; }

  &__page-button {
    margin-left: 16px;
    @include respond-to(xxl) {
      margin-left: 32px; } }

  &__page-button-icon {
    width: 24px;
    height: 24px;
    margin-right: 8px; }

  &__updating {
    z-index: 1;
    align-items: center;
    margin-left: 10px;
    display: none;
    @include respond-to(md) {
      display: flex; }
    &-loader.ui.loader.active.tiny {
      margin-right: 5px; } }

  &__payment-loader {
    display: flex;
    align-items: center;
    &.ui.loader {
      z-index: 990; } }

  &__tooltip {
    margin-left: 8px; }

  &__zero {
    height: 100%;
    font-size: 18px;
    color: $secondary-40;
    display: flex;
    justify-content: center;
    align-items: center; }

  &__name {
    margin-bottom: 2px;
    word-break: break-all; }

  &__description {
    word-break: break-all;
    margin-bottom: 2px; }

  &__create-type {
    margin-bottom: 2px; }

  &__fc-status {
    display: flex;
    align-items: center; }

  &__fc-status-done {
    color: $primary-100;
    width: 18px;
    height: 18px; }

  &__fc-status-error {
    color: $negative-100;
    width: 18px;
    height: 18px; }

  &__link-table-modal {
    margin-bottom: 2px;
    text-decoration: underline; } }
