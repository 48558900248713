@import "../../../styles/theme";
@import "../../../styles/mixins";

.contractor-verification-date {
  $block: &;

  &__page {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    @include respond-to(xxl) {
      flex-direction: row;
      margin-top: 24px; }
    &-scan {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-start;
      @include respond-to(xxl) {
        max-height: unset;
        justify-content: flex-start;
        align-items: flex-start;
        height: auto;
        width: 40%; } }
    &-data {
      width: 100%;
      margin-top: 16px;
      @include respond-to(xxl) {
        margin-top: 0;
        width: 60%;
        max-width: 1006px; }
      &-selfie-info {
        padding: 15px;
        background: $secondary-5;
        border-radius: 7px;
        margin-bottom: 15px;
        color: $secondary-45; } } }
  &__modal {
    &-msg {
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      text-align: center;
      &_confirmed {
        color: $primary-100; }
      &_rejected {
        color: $color-red-salmon; } } }
  &__block {
    margin-bottom: 25px; }
  &__fields-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & > div:not(:last-child) {
      margin-bottom: 16px; }

    &_short {
      width: 325px;
      display: block; } }

  &__loader-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }

  &__status-inn-container {
    display: flex;
    align-items: center; }

  &__scan-list-warning {
    display: flex;
    align-items: center;
    margin-top: 24px;
    @include respond-to(xxl) {
      margin-top: 12px; }
    &-text {
      color: $secondary-45;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px; } }
  &__photo-default {
    display: flex;
    width: 95%;
    height: 700px;
    justify-content: center;
    align-items: center;
    background-color: $secondary-5; }

  & .ui.secondary.pointing.menu {
    font-size: 16px;
    line-height: 24px;
    .item {
      color: $secondary-45 !important; }
    .active.item {
      color: $secondary-90 !important; } } }
