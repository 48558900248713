@import "../../styles/theme";
@import "../../styles/mixins";

.instrument-payment-indicator {
  display: flex;
  align-items: center;
  &__icon {
    &_size-md {
      width: 18px;
      height: 18px;
      min-width: 18px; }
    &_size-lg {
      width: 18px;
      height: 18px;
      min-width: 18px;
      @include respond-to(md) {
        width: 20px;
        height: 20px;
        min-width: 20px; } } }
  &__advanced-tooltip {
    display: flex; }
  &__text {
    margin-left: 8px; } }
