@import "../../styles/theme";
@import "../../styles/mixins";

.bank-indicator {
  display: flex;
  align-items: center;
  &__icon {
    color: $secondary-10;
    &_failed {
      color: $negative-100; } }
  &__icon-container {
    display: flex;
    &_size-md {
      min-width: 18px; }
    &_size-lg {
      min-width: 18px;
      @include respond-to(md) {
        min-width: 20px; } } }
  &__advanced-tooltip {
    display: flex; }
  &__loader {
    &.ui.inline.loader {
      margin-left: 4px;
      z-index: 10;
      width: 16px;
      height: 16px;
      &:before {
        width: 16px;
        height: 16px; }
      &:after {
        width: 16px;
        height: 16px; } } }
  &__ul {
    padding-inline-start: 20px !important; }
  &__error {
    &:not(:last-child) {
      margin-bottom: 10px; } }
  &__warning {
    margin-top: 15px; }
  &__text {
    margin-left: 8px; } }
