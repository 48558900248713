.dropzone-uploaded-area {
  &__uploaded-container {
    display: flex;
    flex-direction: column;
    &_error {
      padding-bottom: 12px; } }

  &__uploaded-files {
    display: flex;
    flex-wrap: wrap;
    align-items: center; } }
