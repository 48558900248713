@import "../../../styles/theme";
@import "../../../styles/mixins";

.login-card {
  display: flex;
  position: relative;
  border-radius: 12px;
  background-color: $open-naimix-black-5;
  padding: 16px;
  width: 100%;
  @include respond-to(md) {
    max-width: 720px;
    padding: 40px; }
  @include respond-to(xxl) {
    max-width: 1096px;
    padding: 40px; }
  &_promoPoisk {
    background-color: $promo-poisk-color-1; }
  &_login {
    @include respond-to(md) {
      background-image: url("../../../images/login__background.svg");
      background-repeat: no-repeat;
      background-position: 280px;
      background-size: 692px 100%; }
    @include respond-to(xxl) {
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain; } }
  &_loginPromoPoisk {
    background-image: url("../../../images/login__background-promopoisk.svg"); }
  &_join {
    @include respond-to(md) {
      background-image: url("../../../images/login__join-background.svg");
      background-repeat: no-repeat;
      background-position: 318px;
      background-size: 631px 100%; }
    @include respond-to(xxl) {
      background-position: right bottom;
      background-size: contain; } }
  &_joinPromoPoisk {
    background-image: url("../../../images/login__join-background-promopoisk.svg"); } }
