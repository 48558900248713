@import "../../../../../styles/mixins";

.client-list-new-inn {
  &__input {
    width: 100%;
    @include respond-to(md) {
      width: auto;
      flex-grow: 1; } }
  &__button {
    width: 100%;
    @include respond-to(md) {
      width: auto; } } }
