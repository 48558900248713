@import "../../styles/theme.sass";
@import "../../styles/mixins.sass";

$content-font-size: 12px;
$content-line-height: 18px;

$content-font-size-type-page: 14px;
$content-line-height-type-page: 20px;

@mixin type-page-font-sizes() {
  font-size: $content-font-size;
  line-height: $content-line-height;
  @include respond-to(xl) {
    font-size: $content-font-size-type-page;
    line-height: $content-line-height-type-page; } }

.nm-selected-list {
  $block: &;

  display: flex;
  width: 100%;
  position: relative;
  &__item-container {
    display: flex;
    align-items: center; }
  &__label {
    color: $secondary-70;
    font-size: $content-font-size;
    line-height: $content-line-height;
    margin-right: 8px;
    &_type-page {
      @include type-page-font-sizes(); } }
  &__comma-delimiter {
    margin-right: 8px; }
  &__delimiter {
    width: 4px;
    height: 4px;
    background: $secondary-70;
    border-radius: 10px;
    margin: 0 4px; }
  &__show-btn {
    font-size: $content-font-size;
    line-height: $content-line-height;
    color: $passive-100;
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    margin-left: 4px;
    &_type-page {
      @include type-page-font-sizes(); } }
  &__content {
    display: flex;
    align-items: baseline;
    flex-flow: wrap; }
  &__dropdown {
    position: absolute;
    padding: 10px 10px;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 4;
    background-color: #fff;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: 7px;
    transition: box-shadow .1s ease,width .1s ease;
    box-shadow: 0 2px 3px 0 rgba(34,36,38,.15);
    color: #000000;
    max-height: 200px;
    overflow: auto; }
  &__showed-list-item {
    font-size: $content-font-size;
    line-height: $content-line-height;
    font-weight: normal;
    &_highlighted {
      font-weight: 500; }
    &_type-page {
      @include type-page-font-sizes(); } } }

