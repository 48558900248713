@import "../../../styles/theme";
@import "../../../styles/mixins";

.client-member-new {
  padding: 22px 16px 28px;
  background: $color-white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  width: 100%;
  position: absolute !important;
  z-index: 100;
  margin-top: 100px;

  & .field.error input {
    &::placeholder {
      color: $negative-100 !important; } }

  &-header {
    @include flex_row__space-between;

    &_icon {
      color: $secondary-45;
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.6; } } }

  &-body {
    padding-top: 24px;
    border-bottom: 1px solid $secondary-15;
    padding-bottom: 41px;

    &_label {
      font-size: 14px;
      line-height: 16px;
      color: $color-black; }

    &-btn {
      &-save {
        &__text {
          font-family: $font-family-default;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: $color-white; } }

      &_password {
        min-width: 106px !important;
        margin-top: 30px !important;
        background-color: inherit !important;
        border: 1px solid $passive-100 !important;
        color: $passive-100 !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important; } } }

  &__footer {
    &-actions {
      padding: 27px 15px 0 15px;
      display: flex;
      justify-content: flex-end; } }

  &__password {
    & .ui.action.input:not([class*="left action"]) {
     & input {
       border-radius: 7px !important;
       border-color: $secondary-15 !important;
       &:focus {
         border-color: $passive-20 !important; } } } } }

