@import "../../../../styles/theme.sass";

.contractor-resources-filter {
  &__row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 13px;
    .nm-number-range-picker {
      margin-bottom: 0px !important; }
    .nm-work-schedule-filter {
      width: 100%; } } }
