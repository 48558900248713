@import "../../../styles/theme";

.advertisement-list {
  &__show-more-container {
    width: 100%; }
  &__fias-id {
    display: inline-flex; }
  &__more {
    color: $passive-100;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      text-decoration: underline; } } }
