@import "../../../styles/theme";
@import "../../../styles/mixins";

.contractor {
  &-info-scans {
    display: flex;
    flex-direction: column;

    &__card-container {
      &:not(:last-child) {
        margin-bottom: 16px; } }

    &__card {
      border-radius: 7px;
      @include respond-to(md) {
        height: 100%; }
      @include respond-to(xxl) {
        height: auto; }
      &_changed {
        border: 5px solid $inert-70; } }

    &__general {
      background: $color-white;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.16);
      border-radius: 7px;
      padding: 12px;
      display: flex;
      flex-direction: column;
      width: 100%;
      &_border {
        border: 5px solid $inert-70; }
      @include respond-to(md) {
        padding: 16px; } }
    &__accordion-icon {
      @include respond-to(xxl) {
        display: none; } }
    &__no-upload {
      font-size: 16px;
      font-weight: bold;
      color: $secondary-45; }
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $color-black;
      margin-bottom: 46px;
      margin-left: 20px; }
    &__nopadding {
      padding-left: 0;
      padding-right: 0; } }
  &-scan {
    margin-bottom: 24px;
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      &__img {
        max-height: 300px;
        width: 100%; }
      &_full {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background-color: rgba(0,0,0,0.5);
        height: 100%;
        overflow: auto;
        img {
          max-height: 100%  !important;
          max-width: 100%; }
        &_img {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          width: 100%; } } } } }



