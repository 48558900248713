.promo-code-contractor-modal {
  width: 700px;
  &__pagination {
    margin-top: 32px; }
  &__content {
    flex-direction: column; }
  &__pagination {
    display: flex; } }
.promocode-client-list {
  margin-top: 20px; }
.promocode-client-pagesize {
  left: auto !important; }
