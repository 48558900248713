@import "../../../styles/theme";
@import "../../../styles/mixins";

.patent-pivot-table {
  &__client-name {
    color: $passive-100; }

  &__replenishment {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    white-space: nowrap; }

  &__overflow-container {
    overflow: auto;
    flex-grow: 1; }

  &__table {
    width: 1625px;
    @include respond-to(xxl) {
      width: 100%; }
    & .row {
      &:nth-child(2) {
        background: $secondary-5;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        & .column {
          @include respond-to(md) {
            min-height: 32px; } } } }
    & .row .column {
      &:nth-child(1) {
        flex-grow: 1; }
      &:nth-child(2) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(3) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(4) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(5) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(6) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(7) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(8) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(9) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(10) {
        max-width: 144px;
        min-width: 144px; }
      &:nth-child(11) {
        max-width: 144px;
        min-width: 144px; } } } }
