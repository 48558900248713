@import "../../styles/theme";

.export-document {
  &__logo {
    margin-right: 16px;
    &_empty {
      width: 24px;
      height: 24px; } }
  &__label-count {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $color-white;
    background-color: $primary-100;
    border-radius: 9px;
    height: 18px;
    max-width: max-content;
    min-width: 18px;
    padding: 2px 4px;
    font-size: 12px;
    text-align: center; } }
