@import '../../../../styles/theme';

.registry-frame-contract-notification-data {
  &__full-name {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px; }
  &__number {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 4px; }
  &__warning-msg {
    position: relative;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    margin-bottom: 8px !important;
    &-attention {
      color: $inert-100;
      padding-right: 5px; } }
  &__label {
    font-size: 16px;
    line-height: 20px;
    margin-top: 16px;
    margin-bottom: 10px; }
  &__tooltip-label {
    overflow: unset; } }
