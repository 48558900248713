@import "../../../styles/theme";
@import "../../../styles/mixins";

.input-with-icon {
  position: relative;
  width: 100%;
  &__icon {
    color: $secondary-40;
    position: absolute;
    bottom: 10px;
    right: 16px;
    @include respond-to(xxl) {
      bottom: 14px;
      right: 16px; } } }
