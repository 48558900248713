@import "../../../../styles/mixins";

.templates-versions {
  &__empty-page {
    margin: 0;
    max-width: 100%; }
  &__title {
    display: flex;
    position: relative; }
  &__list {
    width: 100%; }
  &__primary-header-value {
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @include respond-to(md) {
      display: unset;
      white-space: nowrap;
      text-overflow: ellipsis; } } }
