@import "../../../../../styles/theme";

.dropzone-uploaded-file {
  &__single-file {
    margin-right: 8px;
    color: $secondary-90;
    &_mt {
      margin-top: 8px; } }

  &__file-action {
    width: 16px;
    min-width: 16px;
    color: $secondary-45;
    height: 16px;
    margin-left: 4px;
    cursor: pointer; }

  &__file {
    display: flex;
    align-items: center;
    margin-right: 8px;
    border-radius: 6px;
    padding: 3px 8px;
    max-width: 155px;
    background-color: $secondary-5;
    position: relative;
    &_mt {
      margin-top: 8px; }
    &_error {
      background-color: $negative-15; } }

  &__file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $secondary-70; }

  &__tooltip {
    z-index: 1; } }
