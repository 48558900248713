@import "../../../../../styles/theme";

.settings-users-tabs.ui.attached.menu {
    border: none;
    justify-content: flex-start;
    background-color: transparent;
    position: relative;
    border-radius: 0;
    max-width: max-content;
    & > .item {
      border: none;
      background-color: $primary-10;
      height: 48px;
      padding: 5px 26px 10px 26px;
      &:not(:last-child) {
        margin-right: 1px; }
      &:first-child {
        border-radius: 7px 0 0 7px!important; }
      &:last-child {
        border-radius: 0 7px 7px 0!important; }
      & > .menu-item-name {
        color: $color-black;
        @media screen and (max-width: 1023px) {
          font-size: 14px; } }
      & > .menu-item {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $primary-100;
        & > .menu-item-name {
          &:after {
            min-width: 8px; } } }
      &.active {
        padding: 5px 25px 10px 25px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(63, 155, 20, 0.5);
        &:not(:first-child):not(:last-child) {
          border-radius: 0!important; } } } }
