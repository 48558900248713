@import "../../../styles/mixins";
@import '../../../styles/theme';

@mixin status-color($background-color, $color) {
  background-color: $background-color;
  color: $color; }

.finance-payment-list {
  width: 100%;
  flex-grow: 1;
  &__show-more-anchor {
    text-decoration: underline; }
  &__actions {
    justify-content: space-between; }
  &__checks {
    display: flex;
    align-items: center; }
  &__sum-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  &__modal {
    &-info-card {
      height: unset !important;
      width: 50%; } }
  &__table {
    color: $secondary-70;
    @media screen and (max-width: 1200px) {
      overflow: auto; }
    &_admin-theme {
      @media screen and (max-width: 1710px) {
        overflow-x: auto;
        width: 1710px; }
      & .ui.grid .row .column {
        padding: 0 5px;
        &:first-child {
          padding-left: 7px; }
        &:nth-child(1) {
          width: 2%; }
        &:nth-child(2) {
          width: 5%; }
        &:nth-child(3) {
          width: 9%; }
        &:nth-child(4) {
          width: 11%; }
        &:nth-child(5) {
          width: 7%; }
        &:nth-child(6) {
          width: 8%; }
        &:nth-child(7) {
          width: 7%; }
        &:nth-child(8) {
          width: 11%; }
        &:nth-child(9) {
          width: 11%; }
        &:nth-child(10) {
          width: 8%; }
        &:nth-child(11) {
          width: 8%;
          word-break: normal !important; }
        &:nth-child(12) {
          padding-right: 0;
          width: 8%; }
        &:nth-child(13) {
          flex-grow: 1;
          width: auto; } } }
    &-foraman {
      @media screen and (max-width: 1200px) {
        width: 1200px; }
      & .grid .row {
        &:first-child .column:last-child {
          justify-content: flex-end; }
        & .column {
          &:nth-child(1) {
            width: 2%!important; }
          &:nth-child(2) {
            justify-content: center;
            width: 6%!important; }
          &:nth-child(3) {
            width: 10%!important; }
          &:nth-child(4) {
            width: 10%!important;
            @media screen and (max-width: 1500px) {
              width: 66px!important; } }
          &:nth-child(5) {
            width: 15%!important; }
          &:nth-child(6) {
            width: 17%!important;
            @media screen and (max-width: 1500px) {
              width: 88px!important; } }
          &:nth-child(7) {
            width: 25%!important;
            word-break: break-all;
            @media screen and (max-width: 1500px) {
              width: 125px!important; } }
          &:nth-child(8) {
            flex-grow: 1;
            width: unset!important; } } } }
    &-client-role {
      @media screen and (max-width: 1200px) {
        width: 1200px; }
      & .grid .row {
        &:first-child .column:last-child {
          justify-content: flex-end; }
        & .column {
          &:nth-child(1) {
            width: 2%!important; }
          &:nth-child(2) {
            justify-content: center;
            width: 6%!important; }
          &:nth-child(3) {
            width: 10%!important; }
          &:nth-child(4) {
            width: 10%!important;
            @media screen and (max-width: 1500px) {
              width: 66px!important; } }
          &:nth-child(5) {
            width: 10%!important; }
          &:nth-child(6) {
            width: 14%!important;
            @media screen and (max-width: 1500px) {
              width: 88px!important; } }
          &:nth-child(7) {
            width: 25%!important;
            word-break: break-all;
            @media screen and (max-width: 1500px) {
              width: 125px!important; } }
          &:nth-child(8) {
            width: 10%!important; }
          &:nth-child(9) {
            flex-grow: 1;
            width: unset!important; } } } }
    &-client {
      @media screen and (max-width: 1200px) {
        width: 1200px; }
      & .grid .row {
        &:first-child .column:last-child {
          justify-content: flex-end; }
        & .column {
          &:nth-child(1) {
            width: 2%!important; }
          &:nth-child(2) {
            justify-content: center;
            width: 6%!important; }
          &:nth-child(3) {
            width: 10%!important; }
          &:nth-child(4) {
            width: 10%!important;
            @media screen and (max-width: 1500px) {
              width: 66px!important; } }
          &:nth-child(5) {
            width: 10%!important; }
          &:nth-child(6) {
            width: 10%!important;
            @media screen and (max-width: 1500px) {
              width: 88px!important; } }
          &:nth-child(7) {
            width: 21%!important;
            word-break: break-all;
            @media screen and (max-width: 1500px) {
              width: 125px!important; } }
          &:nth-child(8) {
            width: 9%!important; }
          &:nth-child(9) {
            width: 13% !important; }
          &:nth-child(10) {
            flex-grow: 1;
            width: unset!important; } } } } }
  &_overflow {
    overflow: auto; }
  &_pd-top {
    padding-top: 25px; }
  & .ui.form .field {
    margin: 0; }
  &-filter {
    display: flex;
    margin-bottom: 10px;
    &__dropdown {
      & > .dropdown > .text {
        max-width: 180px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; } }
    & > .field > label {
      font-style: normal;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 14px;
      color: $secondary-70 !important;
      opacity: 1; }
    &-actions {
      margin-bottom: 16px;
      cursor: pointer;
      outline: none;
      max-width: max-content;
      -ms-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      @include flex-row;
      &_color {
        color: $primary-100; } } }
  &-table-order-info {
    width: 100%;
    &__description {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $secondary-90; }
    &__link {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      text-decoration-line: underline;
      text-overflow: ellipsis;
      overflow: hidden;
      color: $passive-100; } }
  &__table {
    flex-grow: 1; }
  &-table-receipts {
    display: flex;
    align-items: center;
    word-break: normal !important;
    &__item {
      margin-right: 10px;
      &:not(:last-child) {
        @include respond-to(xxl) {
          margin-right: 24px; } } }
    &_pagination {
      margin-top: 15px;
      @include flex-row__center; }
    &-header {
      @include flex_row__space-between;
      flex-basis: 7%; } }

  &__contractor-name {
    display: flex;
    align-items: center; }

  &__icon-block {
    width: 20px;
    margin-right: 10px; }

  &__block-header {
    font-size: 16px;
    font-weight: 500;
    margin-top: 15px; }

  .nm-list-card__status {
    overflow: unset; }

  &__mass-actions {
    width: unset; } }
