@import '../../styles/theme';
@import '../../styles/mixins';

.nm-reason-completion {
    min-height: 260px;
    height: auto;
    &__close {
      position: absolute;
      right: 18px;
      font-size: 20px;
      top: 16px;
      color: #B6BAC3;
      cursor: pointer;
      &:hover {
        color: #FD550C; } }
    &__title {
      position: relative;
      font-style: normal;
      width: 100%;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px; }
    &__radio {
      margin-bottom: 8px; }
    &__answer {
      position: relative;
      padding-top: 20px;
      font-style: normal;
      width: 100%;
      font-weight: 500;
      font-size: 16px !important;
      line-height: 16px;
      &-item {
        &-description {
          padding: 0 0 0 20px;
          @include respond-to(md) {
            padding: 0; }
          @include respond-to(xxl) {
            padding: 0 0 0 20px; } } } }
    &__buttons {
      @include flex_row__space-between;
      padding-top: 20px;
      &-container {
        margin-left: auto; } } }
