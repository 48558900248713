@import "../../../styles/theme";

.tag-select {
  $block: &;

  &_direction-bottom {
    padding: 0 16px 16px 16px; }
  &_direction-top {
    padding: 16px 16px 16px 16px; }

  &__info {
    background-color: $secondary-5;
    color: $secondary-45;
    width: max-content;
    font-size: 13px;
    line-height: 15px;
    font-weight: 500;
    border-radius: 13px;
    padding: 4px 10px 4px 14px;
    display: flex;
    height: 28px;
    align-items: center;
    cursor: default;
    &_active {
      cursor: pointer;
      background-color: $primary-100;
      color: $color-white;
      & #{$block}__stat {
        color: $color-white; } } }

  &__stat {
    margin-right: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: $secondary-15; }

  &__icon {
    color: $color-white;
    &_open {
      transform: rotate(180deg); } }

  &__wrapper {
    margin-bottom: -4px; }

  &__container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px; }

  &__item {
    margin-right: 4px;
    margin-bottom: 4px; } }
