@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-info-card-text {
  font-size: 14px;
  line-height: 20px;
  color: $secondary-90;
  white-space: nowrap;
  &_fluid {
    width: 100%; }
  &_wrap {
    white-space: unset; }
  @include respond-to(md) {
    font-size: 16px;
    line-height: 24px; }
  &_bold {
    color: $secondary-100;
    font-weight: 500; }
  &_overflowEllipse {
    overflow: hidden;
    text-overflow: ellipsis; } }
