@import "../../../../styles/theme";

.dropdown-selected-value {
  border-radius: 13px;
  background-color: $primary-10;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 14px;
  max-width: 100%;
  &__icon {
    min-width: 18px;
    color: $secondary-100;
    cursor: pointer;
    margin-left: 4px; }
  &__text {
    color: $secondary-90;
    font-size: 14px;
    line-height: 16px;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; } }
