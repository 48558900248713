@import "../../../styles/theme";

.nm-deposit-history-window {
  &__header {
    display: flex;
    font-size: 12px;
    color: $secondary-45;
    margin-bottom: 10px; }
  &__row {
    display: flex; }
  &__body {
    width: 220px;
    min-width: 220px;
    min-height: 60px; }
  &__dimmer {
    min-height: 26px; }
  &__loader {
    width: 18px!important;
    height: 18px!important;
    &:before, &:after {
      width: 18px!important;
      height: 18px!important;
      margin: 0 0 0 -9px!important; } }
  &__tooltip-text {
    overflow: auto;
    max-height: 160px; } }

