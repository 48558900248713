@import "../../../styles/theme";
@import "../../../styles/mixins";

.create-edit-patent-payment {
  $block: &;

  &__header {
    font-size: 18px;
    line-height: 21px; }

  &__contractor-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    @include respond-to(md) {
      flex-direction: row; } } }
