@import "../../../styles/theme";

.badge-alert {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background-color: $negative-15;
  display: flex;
  justify-content: center;
  align-items: center;
  &_success {
    background-color: $primary-10; } }
