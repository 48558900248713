@import "../../../styles/theme";

.contractor-reviews-page {
  overflow: unset;
  &__info-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    border: 1px solid $primary-50;
    border-radius: 8px;
    padding: 14px 16px;
    &_mobile {
      display: unset; }
    &-label {
      width: 230px; }
    &-button {
      &_mobile {
        margin-top: 12px;
        width: 100%; } } } }
