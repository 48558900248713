@import "../../styles/theme";
@import "../../styles/mixins";

.nm-status-passport-inn-contractor {
  $block: &;

  display: flex;
  align-items: center;
  &_verificationData {
    & #{$block}__icon {
      margin-right: 6px;
      width: 24px;
      height: 24px; } }
  &__icon {
    margin-right: 8px;
    width: 18px;
    height: 18px;
    cursor: pointer;
    @include respond-to(md) {
      width: 20px;
      height: 20px; }
    &_inert {
      color: $inert-100; }
    &_negative {
      color: $negative-100; }
    &_secondary {
      color: $secondary-10; } }
  &__refresh {
    margin-left: 8px;
    display: flex;
    align-items: center; }
  &__label {
    white-space: nowrap; } }
