@import "../../../../../../styles/mixins";

.recruitment-observer-edit {
  &__list {
    max-height: calc(100vh - 414px);
    overflow: auto;
    @include respond-to(md) {
      max-height: calc(100vh - 426px); }
    @include respond-to(xxl) {
      max-height: calc(100vh - 546px); } } }
