@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-divider {
  width: 100%;
  height: 1px;
  background-color: $secondary-15;
  &_vertical {
    &-md {
      @include respond-to(md) {
        width: 1px;
        height: unset; } } } }
