@import "../../../../styles/theme";

.order-list-notification-icon {
  position: relative;
  &__counter {
    position: absolute;
    z-index: 1;
    right: -8px;
    top: -8px;
    color: $color-white;
    border-radius: 50%;
    padding: 0 2px;
    min-width: 16px;
    text-align: center;
    line-height: 16px;
    font-size: 10px;
    background-color: $negative-100; } }
