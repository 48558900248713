@import '../../../styles/theme';
@import '../../../styles/mixins';

.stop-list-loading-file {
  @extend %wrapper-flex-column;
  position: absolute;
  width: 600px;
  min-height: 300px;
  height: auto;
  margin: 0 auto;
  background: $color-white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  &__modal-upload {
    &-content {
      flex-direction: column; } }
  &__content {
    padding: 45px; }
  &__close {
    position: absolute;
    right: 18px;
    font-size: 20px;
    top: 16px;
    color: $secondary-40;
    cursor: pointer;
    &:hover {
      color: $color-orange; } }
  &__title {
    position: relative;
    font-style: normal;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: left; }
  &__input {
    padding: 15px 0 40px 0; }
  &__buttons {
    @include flex_row__space-between;
    justify-content: flex-end; }
  & .dropzone-app {
    &__body {
      &_upload {
        flex-direction: row-reverse;
        padding: 20px; }
      & .fluid {
        width: auto; } }
    &__content {
      width: 65%; } } }
