@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.order-templates-object-list {
  &__content {
    width: 100%; }
  &__object-list {
    &-item {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &:not(:last-child) {
        margin-bottom: 8px; } } }
  &__filter {
    @media screen and (max-width: 768px) {
      margin-bottom: 16px; }
    &-input {
      flex-grow: 1; } }
  &__pagination {
    margin-top: 16px; } }
