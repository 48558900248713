@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-empty-page-v2 {
  $block: &;

  &__empty-page {
    max-width: 800px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 80px auto 0 auto;
    &_clearStyles {
      margin: 0;
      max-width: unset; } }

  &__search-off {
    width: 40px;
    height: 40px;
    border-radius: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $secondary-5;
    &:before {
      content: "";
      width: 20px;
      height: 20px;
      display: block;
      background-image: url("../../../images/search_off.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      @include respond-to(md) {
        width: 32px;
        height: 32px; } }

    &_blocked {
      &:before {
        background-image: url("../../../images/block_24.svg"); } }

    @include respond-to(md) {
      width: 62px;
      height: 62px; } }

  &__title {
    margin-top: 16px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-80;
    text-align: center;
    &_error {
      color: $negative-100; }
    @include respond-to(md) {
      font-size: 18px;
      line-height: 26px; } }

  &__description {
    margin-top: 8px;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-45;
    text-align: center;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } }

  &__action {
    margin-top: 16px;
    width: 100%;
    @include respond-to(md) {
      width: auto; } }

  &__link {
    margin-top: 24px;
    display: block;
    width: max-content;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-decoration: underline;
    color: $secondary-45; }

  &__empty-search {
    border: 1px solid $secondary-5;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 24px;

    &-title {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      color: $secondary-80; }

    &-list {
      margin: 8px 0 0 0;
      padding-left: 20px;
      color: $secondary-45;
      font-size: 14px;
      line-height: 20px; } } }
