@import "../../../../../styles/mixins";

.contractor-verification-info-block-row {
  display: flex;
  flex-direction: column;
  @include respond-to(md) {
    flex-wrap: wrap;
    flex-direction: row; }
  &__item {
    flex-wrap: wrap;
    margin-bottom: 8px;
    @include respond-to(md) {
      display: flex;
      &:not(:last-child) {
        margin-right: 16px; } } } }
