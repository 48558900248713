@import "../../../../styles/mixins";

.order-card-contractors {
  padding: 25px 0 0 0;
  flex-grow: 1;
  &__find-performers {
    margin-left: 0;
    width: 100%;
    @include respond-to(md) {
      width: auto; }
    @include respond-to(xxl) {
      margin-left: 8px; } }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column; } }
