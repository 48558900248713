.dialog-list {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-left: 24px;
  &__item {
    &:not(:last-child) {
      margin-bottom: 10px; } } }
