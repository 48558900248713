@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.import-from-file-pattern-form {
  &__link {
    margin-left: 5px;
    color: $passive-100;
    @include respond-to(md) {
      text-decoration: underline; }
    &:hover {
      color: $passive-100;
      text-decoration: underline; } }
  &__text-tooltip {
    margin-left: 4px; } }
