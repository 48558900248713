@import "../../../styles/mixins";

.counter {
  position: relative;
  border-radius: 50%;
  pointer-events: none;
  background: $negative-100;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: white;
  height: 18px;
  max-height: 18px;
  min-height: 18px;
  width: 18px;
  max-width: 18px;
  min-width: 18px;
  margin-left: auto;
  &_big {
    font-size: 10px;
    line-height: 18px; }
  &_avatarCounter {
    bottom: 40px; }
  @media screen and (min-width: 1199px) and (max-width: 1599px) {
    position: absolute;
    height: 6px;
    max-height: 6px;
    min-height: 6px;
    width: 6px;
    max-width: 6px;
    min-width: 6px;
    left: 42px;
    top: 10px;
    font-size: 0; } }
