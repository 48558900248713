@import "../../styles/theme";

.textarea-emoji {
  display: flex;
  position: relative;
  &__smile {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center; } }
