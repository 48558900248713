@import "../../styles/theme";

.nm-status {
    display: flex;

    &__icon {
        color: $primary-100;
        cursor: pointer;
        margin-right: 8px;
        padding-left: 0 !important;
        &_error {
            color: $negative-100; } }

    &__popup {
        position: $negative-100;
        left: 0;
        top: 20px;
        z-index: 1;
        width: 250px; } }
