.client-card {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  padding: 24px 48px;
  &_without-padding {
    padding: 0 !important;
    overflow: unset !important; }
  @media screen and (min-width: 768px) and (max-width: 1919px) {
    padding: 24px; }
  @media screen and (max-width: 767px) {
    padding: 12px 16px; }
  &__input {
    width: 200px; }
  &__content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    &_no-offset {
      padding: 0; }
    &_full {
      height: 100%;
      &-admin {
        max-height: calc(100vh - 220px); } } }
  & .nm-page {
    padding: 0;
    overflow: unset; }
  &_not-overflow {
    overflow: hidden; }
  &__tabs {
    flex-shrink: 0; }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px; } }
