.orders-filter {
  flex-shrink: 0;
  width: 100%;
  &__with-buttons {
    display: flex; }
  &__fields-container {
    display: flex;
    flex-wrap: wrap;
    &_project-page-style {
      width: 1200px; }
    &_default-style {
      width: 100%; } }
  &__column {
    margin: 0 10px 16px 0;
    &_w1 {
      width: 105px; }
    &_w2 {
      width: 240px; }
    &_status {
      width: 260px; }
    &_date {
      width: 280px; }
    &_w3 {
      width: 335px; } }
  &__actions {
    margin: 0 10px 15px 0; } }
