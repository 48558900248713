.patents-list-filter {
  display: flex;
  flex-wrap: wrap;
  & .filter-buttons {
    min-height: 49px; }
  &__custom-dropdown, .nm-input-v2 {
    margin-bottom: 12px; }
  &__num {
    width: 220px;
    margin-right: 15px!important; }
  &__name {
    width: 100%; }
  &__period {
    margin-right: 0!important;
    margin-bottom: 12px; }
  &__buttons {
    width: 100%;
    margin-bottom: 17px; } }
