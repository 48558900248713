@import "../../styles/theme";

.nm-input {
  &.error .ui.input input {
    &::placeholder {
      color: #9f3a38; } }
  &.ui.input input[type="text"] {
    border-radius: 7px;
    border-color: $secondary-15 !important;
    &:focus {
      border-color: #85b7d9!important; }
    &:focus {
      border-color: #85b7d9!important; }
    &:focus {
      border-radius: 7px; }
    &::placeholder {
      font-size: 14px;
      line-height: 18px;
      color: #979797; } }
  &.ui.disabled.input {
    border-radius: 7px; }
  &_no-active {
    &.ui.input input {
      background: #F1F3F7!important; } }
  &_active {
    &.ui.input input {
      background: #fff!important; } }
  //filter-size = 40px
  &_size-md {
    &.ui.input {
      height: 38px; } }
  &_size-lg {
    &.ui.input {
      height: 40px; } }
  &_size-xl {
    &.ui.input {
      height: 48px;
      &.icon input {
        padding-left: 46px!important; }
      & .material-icons {
        color: $secondary-45;
        position: absolute;
        bottom: 0;
        margin: auto;
        top: 0;
        display: flex;
        align-items: center;
        left: 17px; } } } }
