@mixin background($icon) {
  background-image: url("../../images/#{$icon}.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }

@mixin size($width, $height) {
  width: $width;
  height: $height; }

.nm-icon {
  &_name {
    &-question {
      @include background(question-icon);
      @include size(20px, 20px); }

    &-dispute {
      @include background(order-dispute-icon);
      @include size(20px, 20px); }

    &-blocked {
      @include background(blocked-icon);
      @include size(15px, 19px); }

    &-blacklisted {
      @include background(blacklisted-contractor);
      @include size(32px, 22px); }

    &-semi-hamburger {
      @include background(semi-hamburger-icon);
      @include size(16px, 12px); }

    &-clip {
      @include background(clip);
      @include size(24px, 24px); }

    &-favorite {
      @include background(favorite);
      @include size(14px, 18px); }

    &-favorite-checked {
      @include background(favorite-checked);
      @include size(14px, 18px); }

    &-blacklist {
      @include background(blacklist);
      @include size(18px, 18px); }

    &-blacklist-checked {
      @include background(blacklist-checked);
      @include size(18px, 18px); }

    &-cross {
      @include background(cross);
      @include size(16px, 16px); }

    &-lock {
      @include background(lock);
      @include size(11px, 14px); }

    &-filter {
      @include background(filter);
      @include size(15px, 14px); }

    &-green-check {
      @include background(check-bg);
      @include size(18px, 18px); }

    &-lock-out {
      @include background(lock-out);
      @include size(13px, 17px); }

    &-lock-red {
      @include background(lock-red);
      @include size(15px, 22px); }

    &-green-plus {
      @include background(icon-plus);
      @include size(18px, 18px); }

    &-download {
      @include background(icon-download);
      @include size(10px, 11.5px); }

    &-close {
      @include background(registry__reject-icon);
      @include size(11px, 11px); }

    &-history-icon {
      @include background(foreman-history-icon);
      @include size(22px, 22px); }

    &-sberbank {
      background-image: url("../../images/sberbank.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 16px;
      height: 16px; }

    &-excel {
      @include background(excel);
      @include size(20.5px, 20px); }
    &-load {
      @include background(upload_ico);
      @include size(32px, 32px); }

    &-load-list {
      @include background(registry-card__load-list);
      @include size(18px, 18px); }

    &-like {
      @include background(like);
      @include size(16px, 16px); }

    &-green-people {
      @include background(registry-card__invite-via-email);
      @include size(18px, 18px); }

    &-dislike {
      @include background(dislike);
      @include size(16px, 16px); }

    &-search {
      @include background(search);
      @include size(17px, 17px); }

    &-plus-green-bg {
      @include background(registry-card__invite);
      @include size(18px, 18px); }

    &-canceled-checks-indicator {
      @include background(canceled-checks-indicator);
      @include size(18px, 18px); }

    &-registry__reject-icon {
      @include background(registry__reject-icon);
      @include size(19px, 19px); }

    &-registry__ok-icon {
      @include background(registry__ok-icon);
      @include size(22px, 22px); } } }
