.send-modal {
  width: 700px;

  &__buttons-apply {
    width: 100%;
    justify-content: flex-end;
    margin-top: 24px; }
  &__content {
    display: flex;
    flex-direction: column;
    width: 80%; }
  &__title-response {
    margin: {
      top: 10px;
      bottom: 5px; } } }
