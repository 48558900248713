@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.order-edit-calculator {
  $block: &;

  width: 100%;
  &__button {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center; }
  &__accordion {
    &_open {
      padding: 12px;
      background-color: $color-white;
      @include respond-to(md) {
        box-shadow: 0px -1px 34px rgba(0, 0, 0, 0.1);
        padding: 16px 24px; } } }
  &__commission-label {
    max-width: 184px; }
  &__header {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
    color: $secondary-90; }
  &__container {
    width: 100%;
    background: $color-white;
    box-shadow: 0px -1px 34px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    &_mobile {
      box-shadow: none; }
    & #{$block}__section:not(:last-child) {
      border-bottom: 1px solid $secondary-15; }
    & #{$block}__section:first-child #{$block}__row:first-child #{$block}__label {
      width: 152px;
      @include respond-to(md) {
        width: auto; }
      @include respond-to(xl) {
        width: 152px; } }
    & #{$block}__section:nth-child(2) #{$block}__row:first-child #{$block}__label {
      width: 127px; } }

  &__row {
    $blockRow: &;

    flex-wrap: wrap;
    color: $secondary-70;
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    position: relative;
    &:not(:first-child):not(#{$blockRow}_subTitle) {
      margin-top: 15px; }
    &_alignItems-center {
      align-items: center; }
    &_subTitle {
      font-size: 14px;
      line-height: 16px;
      padding-bottom: 11px;
      margin-top: -20px;
      border-bottom: 1px solid #E0E0E0;
      color: $secondary-70;
      & #{$block}__value {
        color: $secondary-45; } } }
  &__label {
    display: flex;
    margin-right: 8px;
    align-items: flex-end;
    flex: 1 0 auto; }
  &__icon {
    min-width: 16px; }
  &__tooltip {
    margin-left: 6px;
    display: inline-block;
    vertical-align: bottom; }
  &__value {
    text-align: right;
    white-space: pre-wrap;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: $secondary-80;
    font-size: 14px;
    line-height: 16px; }
  &__sub-value {
    color: $secondary-45; }
  &__section {
    padding: 16px 0;
    @include respond-to(xl) {
      padding: 16px; }
    & #{$block}__row:first-child {
      margin-bottom: 18px;
      align-items: center;
      & #{$block}__label {
        color: $secondary-90;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 5px;
        flex: 0 1 auto; }
      & #{$block}__value {
        font-weight: bold;
        white-space: pre-wrap;
        font-size: 18px;
        line-height: 26px;
        color: $color-black; } } } }


