@import "../../styles/mixins";
@import "../../styles/theme";

.payout-history {
  $block: &;

  width: 100%;
  max-width: 1101px;

  &__pagination {
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 24px; }
    @include respond-to(xxl) {
      margin-top: 32px; } }

  &__message {
    margin-bottom: 50px; }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column; }

  &__period {
    width: 100%;
    @include respond-to(md) {
      width: 300px; } }

  &__header {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 19px; } }
