@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.call-history {
  &__phone {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    @include respond-to(xs) {
      width: 18px;
      height: 18px; }
    &_green {
      color: $primary-100; }
    &_yellow {
      color: $inert-100; } }
  &__create-date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-90; }
  &__fio {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-90;
    margin-right: 8px;
    @media only screen and (min-width: 768px) and (max-width: 850px) {
      max-width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } } }
