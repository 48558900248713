.document-management-document-list-filter {
  height: 100%;

  $block: &;
  &__buttons {
    margin: {
      top: 14px;
      bottom: 17px; } }

  &__row {
    display: flex;
    & > div:first-child {
      margin-right: 8px; } }
  &__dropdown {
    width: 50%; } }
