@import "../../../styles/theme";

.objects-card {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__tab-content {
    flex-grow: 1;
    margin-top: 25px;
    display: flex;
    flex-direction: column; }
  &__create-invoice {
    margin-right: 10px;
    white-space: nowrap; } }

//toDo: удалить
.btn {
  margin: 0 !important;
  height: 48px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  &_edit {
    padding: 0 15px 0 16px !important;
    background: $grad-primary !important;
    border-radius: 7px !important;
    & > .text-button {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 16px !important;
      line-height: 19px !important;
      color: $color-white !important; }
    & i {
      margin-right: 15px;
      color: $color-white !important;
      font-size: 20px; } } }


