@import "../../../styles/mixins";

.marker-list-modal {
  &__content {
    display: flex;
    justify-content: flex-start;
    overflow: auto;
    max-height: calc(100vh - 122px);
    @include respond-to(md) {
      max-height: calc(100vh - 156px); }
    @include respond-to(xxl) {
      max-height: calc(100vh - 268px); } } }
