@import "../../../../styles/mixins";
@import "../../../../styles/theme";

.reviews-list {
  &__image {
    width: 100%;
    height: 100%; }
  &__readonly-rating-value {
    font-weight: 400; }
  &__comment {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    margin-top: 6px;
    word-break: break-all; }
  &__mode-icon {
    cursor: pointer;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    &:hover {
      color: $primary-100; }
    @include respond-to(md) {
      width: 24px;
      height: 24px;
      min-width: 24px; } } }
