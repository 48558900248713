@import "../../../styles/theme";

.ml-model-list {
  &__row {
    padding: 16px;
    border-radius: 7px;
    margin-bottom: 16px;
    border: 1px solid $secondary-45;
    &_main {
      border: 3px solid $primary-80; } } }
