@import "../../styles/theme";

.refresh-button {
  cursor: pointer;
  &_animate {
    animation: spin 1s linear infinite; }
  &_disabled {
    opacity: 0.6; }
  &_containerIcon {
    color: $secondary-45;
    &:hover {
      color: $primary-100; } } }
