.tech-support-list {
  &__table {
    flex-grow: 1; }
  &__filter {
    &-date {
      width: 360px; }
    &-contractor {
      width: 230px; }
    &-actions {
      margin-bottom: 15px; } } }
