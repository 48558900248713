@import "../../../../../../../styles/mixins";
@import "../../../../../../../styles/theme";

.order-edit-form-publish-label-text {
  &__label {
    font-size: 12px;
    font-weight: bold;
    line-height: 18px;
    color: $secondary-100;
    margin-bottom: 4px;
    @include respond-to(md) {
      font-weight: 500;
      font-size: 14px;
      line-height: 22px; } }
  &__p {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
    color: $secondary-70;
    word-break: break-word;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 18px; } } }
