@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-datepicker-input {
  $block: &;

  max-width: 100%;
  min-width: 123px;
  padding: 0 0 0 40px;
  border: 1px solid $secondary-15;
  overflow: hidden;
  background-color: #F1F3F7;
  border-radius: 7px;
  position: relative;
  @include respond-to(md) {
    padding: 0 0 0 44px; }
  &:hover {
    border-color: $primary-100; }
  & ~ button {
    padding: 0 17px 0 0; }
  &_active {
    border: 1px solid $secondary-70;
    background-color: $color-white; }
  &_focused {
    border: 1px solid $primary-100;
    background-color: $color-white;
    & #{$block}__icon {
      color: $primary-100; }
    &#{$block}_error:hover {
      border-color: $negative-100; } }
  &_changed {
    border: 1px solid $inert-70; }
  &_error {
    border: 1px solid $negative-100;
    background-color: $negative-15;
    &#{$block}_focused {
      background-color: $color-white; } }
  &_warning {
    border: 1px solid #FFB713;
    &#{$block}_focused {
      background-color: $color-white; } }
  &_disabled {
    border-color: $secondary-5!important;
    color: $secondary-15;
    background-color: $secondary-5!important;
    & #{$block}__icon {
      color: $secondary-40;
      cursor: default; }
    & input {
      color: $secondary-40;
      &::placeholder {
        color: $secondary-15; } }
    & ~ button {
      color: $secondary-15; }
    &:hover {
      border-color: $secondary-5; } }
  &_size-lg {
    height: 40px;
    & #{$block}__icon {
      top: 9px;
      font-size: 22px; } }
  &_size-sm {
    height: 36px; }
  &_size-md {
    height: 38px; }
  &_size-xl {
    height: 40px;
    @include respond-to(md) {
      height: 48px; } }
  &__input {
    &_clearStyles {
      font-family: 'Roboto';
      border: none!important;
      padding: 0 21px 0 0 !important;
      height: 100%;
      background-color: transparent!important;
      outline: none;
      width: 100%; } }
  &__field {
    background-color: transparent; }
  &__icon {
    color: #8B8B8B;
    cursor: pointer;
    margin-right: 10px;
    position: absolute;
    left: 12px;
    @include respond-to(md) {
      left: 17px; }
    &_size-sm {
      top: 8px; }
    &_size-md {
      top: 8px; }
    &_size-xl {
      top: 10px;
      @include respond-to(xxl) {
        top: 12px; } } } }

