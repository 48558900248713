@import "../../../styles/theme";
@import "../../../styles/mixins";

.context-menu {
  $block: &;
  position: relative;
  cursor: pointer;

  &_disabled {
    cursor: default; }

  &_table {
    & #{$block}__button {
      border: none;
      background-color: transparent; } }

  &_inline {
    & #{$block}__button {
      &_no-border {
        border: none; }
      @media screen and (max-width: 767px) {
        border: none;
        padding: 0;
        height: 24px;
        min-width: 24px;
        width: 24px;
        background-color: transparent; }
      &:focus, &:hover {
        @media screen and (max-width: 767px) {
          border: none; } } } }

  &__button .nm-button__icon-container svg {
    height: 24px;
    width: 24px; }

  &__tooltip {
    max-width: 280px; }

  &__context-menu-content {
    $content: &;
    &_clearWidth {
      &#{$content}_position-bottom-right {
        right: -10px; } } }

  &__icon {
    &_horizontal {
      transform: rotate(90deg); }
    &_transform {
      @media screen and (max-width: 1919px) {
        transform: rotate(90deg); } } } }
