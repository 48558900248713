@import "../../../styles/mixins";

.registry-search-contractor {
  &__control {
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xxl) {
      margin-bottom: 0;
      margin-right: 16px; } }
  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to(xxl) {
      flex-direction: row; } } }
