@import "../../styles/theme.sass";
@import "../../styles/mixins.sass";

.steps {
  $block: &;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;

  &__number {
    display: block;
    &_completed {
      @include respond-to(xl) {
        display: none; } } }

  &__name {
    display: none;
    white-space: nowrap;
    @include respond-to(xl) {
      display: block; }
    &_active {
      @include respond-to(md) {
        display: block; } }
    &_completed {
      display: none;
      @include respond-to(xl) {
        display: block; } } }

  &__icon-check {
    margin-right: 0;
    display: none;
    @include respond-to(xl) {
      display: block;
      margin-right: 6px; } }

  &__point {
    z-index: 10;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    color: $color-white;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    padding: 2px 8px;
    border-radius: 80px;
    @include respond-to(md) {
      padding: 6px 16px; }
    @include respond-to(xl) {
      padding: 4px 16px;
      font-size: 16px;
      line-height: 24px; }
    &_completed {
      @include respond-to(md) {
        padding: 6px 12px;
        max-width: 32px; }
      @include respond-to(xl) {
        padding: 4px 16px;
        max-width: unset; } }
    &_no-active {
      color: $secondary-45;
      border: 1px solid $secondary-5;
      padding: 1px 8px;
      min-width: 24px;
      @include respond-to(md) {
        min-width: 32px; }
      @include respond-to(xl) {
        padding: 3px 16px; } }
    &_active {
      background: $primary-100;

      & #{$block}__symbol-point {
        @include respond-to(md) {
          display: block; } } } }

  &__line {
    position: absolute;
    background-color: $secondary-5;
    height: 1px;
    width: 100%;
    top: 50%; }

  &__symbol-point {
    display: none;
    @include respond-to(xl) {
      display: block; } }

  &__space {
    display: none;
    @include respond-to(md) {
      display: block; } } }

