@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.review-reply {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid $secondary-15;
  padding: 0 16px;
  &__mode-icon {
    cursor: pointer;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    min-width: 20px;
    &:hover {
      color: $primary-100; }
    @include respond-to(md) {
      width: 24px;
      height: 24px;
      min-width: 24px; } } }
