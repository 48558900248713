@import "../../../../../../styles/theme";
@import "../../../../../../styles/mixins";

.onboarding-scenario-contractor-list {
  &__tooltip {
    width: 180px; }
  &__additional-documents-tooltip-content {
    gap: 12px;
    display: flex;
    flex-direction: column; }
  &__table {
    & .ui.grid .row .column {
      &:nth-child(1) {
        width: 10%; }
      &:nth-child(2) {
        width: 6%; }
      &:nth-child(3) {
        width: 10%; }
      &:nth-child(4) {
        width: 8%; }
      &:nth-child(5) {
        width: 6%; }
      &:nth-child(6) {
        width: 6%; }
      &:nth-child(7) {
        width: 6%; }
      &:nth-child(8) {
        width: 6%; }
      &:nth-child(9) {
        width: 8%; }
      &:nth-child(10) {
        width: 7%; }
      &:nth-child(11) {
        width: 10%; }
      &:nth-child(12) {
        width: 8%; }
      &:nth-child(13) {
        width: 5%; }
      &:last-child {
        width: unset;
        flex-grow: 1;
        padding: 0;
        justify-content: end; } } } }
