.fc-registry-list-filter {
  &__buttons {
    margin-bottom: 15px;
    margin-top: 15px; }

  &__row {
    display: flex;
    &_align-baseline {
      align-items: baseline; } }

  &__dropdown {
    width: 100%;
    outline: none;

    .custom-dropdown__content_size-md {
      height: 38px; } } }
