@import "../../../../styles/theme";

.order-list-table {
  position: relative;
  &__notification {
    margin-left: 15px;
    &:hover .icon-dispute__path {
      fill: $primary-100; } }
  &__button-dropdown {
    margin-left: 15px; }
  &__context-menu-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    &_fluid {
      flex-grow: 1; } }
  &__indicator {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 5px;
    @media screen and (max-width: 1199px) {
      top: 40px; }
    @media screen and (max-width: 767px) {
      top: 40px; }
    &-icon {
      width: 30px;
      height: 30px; }
    &-speciality {
      font-size: 12px;
      line-height: 16px; }
    &-title {
      font-size: 14px;
      line-height: 16px;
      font-weight: bold;
      margin-bottom: 10px; }
    &-value {
      word-break: break-word;
      font-weight: 500; } }
  &__actions {
    margin-left: 9px; }
  &__close {
    border: 1px solid $secondary-15;
    background-color: $color-white;
    border-radius: 7px;
    padding: 12px 16px;
    color: $secondary-70;
    cursor: pointer;
    font-size: 14px;
    &:focus {
      outline: none;
      border: 1px solid $secondary-15; } }

  & .nm-list-card__actions {
    justify-content: end; } }
