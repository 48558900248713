@import "../../../styles/theme";
@import "../../../styles/mixins";

.checkbox-list-extended-content {
  height: 100%;
  &__left-content {
    overflow: auto;
    height: calc(100vh - 48px);
    position: sticky;
    top: 0; }
  &__scrollable-container {
    position: relative; }
  &__inner-container {
    position: absolute;
    left: 0;
    right: 0; }
  &__right-content {
    height: 100%;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      padding: 20px 16px;
      border: 1px solid $secondary-5; }
    @include respond-to(xl) {
      padding: 24px; } } }
