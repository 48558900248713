@import "../../styles/theme";
@import "../../styles/mixins";

.stop-list {
  &__actions {
    margin-bottom: 15px; }
  &__filter-field {
    width: 292px; }
  &__controls {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    &-icon {
      &_rotated {
        transform: rotate(180deg) !important; } }
    &-button {
      margin-left: 16px !important; }
    & .button {
      padding: 15px !important; } }
  &__admin-filter {
    margin-top: 15px; }
  &__table {
    .ui.grid .row .column {
      &:nth-child(1) {
        width: 3%; }
      &:nth-child(2) {
        width: 12%; }
      &:nth-child(3) {
        width: 20%; } }
    & .grid-checkbox .row .column {
      &:nth-child(1) {
        width: 3%!important; }
      &:nth-child(2) {
        width: 12%!important; }
      &:nth-child(3) {
        width: 12%!important; } } }
  &__info-text {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70; }
  &__mass-actions-block {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    &__counter {
      background-color: $color-white !important;
      color: $secondary-75 !important;
      border: 1px solid $secondary-75 !important; }
    &-buttons {
      display: flex;
      align-items: center;
      margin-left: 20px;
      &__button {
        border-radius: 7px !important;
        color: $secondary-70 !important;
        padding: 15px 25px!important;
        border: 1px solid rgba(218, 218, 218, 0.5) !important;
        background: $color-white !important;
        font-style: normal !important;
        font-weight: normal !important; } } } }
