@import "../../../../../../styles/theme";

.order-history-item {
  &-personal-data {
    width: 25%;

    &:not(:last-child) {
      margin-right: 21px; } }

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-80; }

  &-additional-title {
    margin-left: 18px;
    display: flex;
    align-items: center;
    &__text {
      margin-left: 7px;
      color: $secondary-45;
      font-size: 14px;
      line-height: 20px; } }



  &-info {
    display: flex;

    &__link {
      width: max-content;
      color: $passive-100;
      font-size: 14px;
      line-height: 20px;
      border-bottom: 1px dashed $passive-100;
      margin-top: 7px;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
        border-bottom: 1px dashed $passive-100; } }


    &__item {
      font-size: 14px;
      line-height: 20px;
      color: $secondary-45;

      &_bold {
        color: $secondary-90; }

      &:not(:last-child) {
        margin-bottom: 7px; } } }

  &-status {
    margin: {
      top: 7px;
      bottom: 7px; }
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__line {
      width: 52px;
      height: 1px;

      &_green {
        background-color: $primary-100; }

      &_gray {
        background-color: $secondary-15; } } } }
