@import "../../../styles/theme";

.document-export-list {
  &__list {
    flex-grow: 1; }
  &__filter {
    margin-top: 17px; }
  &__count {
    background: transparent;
    border-radius: 9px;
    height: unset;
    left: -15px;
    top: -5px;
    & .multiselect__content {
      padding: unset; }
    & .info {
      color: $color-white;
      background-color: $primary-100;
      border: unset;
      height: 18px;
      min-width: 18px; } }
  &__label {
    &-documents {
      overflow: unset !important; } }
  & .multiselect__option {
    border-top: unset;
    margin: unset;
    padding: 0 12px;
    color: $secondary-80;
    font-size: 12px;
    line-height: 18px; } }
