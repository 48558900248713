@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.promocode-info {
  &__edit-icon {
    cursor: pointer;
    margin-left: 4px;
    color: $secondary-45;
    width: 18px;
    height: 18px;
    &:hover {
      color: $secondary-70; }
    @include respond-to(xxl) {
      width: 20px;
      height: 20px; } } }
