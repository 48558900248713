@charset "utf-8";
@import "../../styles/theme";
@import "../../styles/mixins";

.menu-item {
  $block: &;
  position: relative;

  &:hover #{$block}__icon {
    @media screen and (min-width: 1200px) and (max-width: 1599px) {
      color: $color-white; } }
  &_opened {
    background-color: $secondary-100; }
  &_activeWithHiddenChild {
    &#{$block}_hoReCa {
      &:before {
        background: $hoReCa-grad-primary; } }
    &#{$block}_rosKapStroi {
      &:before {
        background-color: $color-ros-kap-stroi-menu; } }
    &#{$block}_mts {
      &:before {
        background-color: $negative-100; } }
    &#{$block}_helpResource {
      &:before {
        background-color: $color-help-resource-menu; } }
    &#{$block}_promopoisk {
      &:before {
        background-color: $color-promopoisk-menu; } }
    &:before {
      content: "";
      display: block;
      background-color: $primary-100;
      position: absolute;
      left: 0;
      width: 4px;
      height: 100%; } }
  &_activeWithoutChild {
    color: $color-white;
    background: $primary-100 !important;
    &#{$block}_hoReCa {
      background: $hoReCa-grad-primary !important; }
    &#{$block}_mts {
      background-color: $negative-100 !important; }
    &#{$block}_helpResource {
      background-color: $color-help-resource-menu !important; }
    &#{$block}_promopoisk {
      background-color: $color-promopoisk-menu !important; } }

  &__icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    color: $secondary-45;
    display: flex;
    pointer-events: none;
    justify-content: center;
    @media screen and (max-width: 1599px) {
      margin-right: 8px; }
    @include respond-to(xl) {
      margin-right: 0; }
    @media screen and (min-width: 1600px) {
      margin-right: 8px; } }

  &__icon-svg {
    color: $secondary-45;
    &_active {
      color: $color-white; } }

  &__arrow {
    width: 20px;
    height: 20px;
    min-width: 20px;
    position: relative;
    color: $secondary-45;
    margin-left: auto;
    &_open {
      transform: rotate(180deg); }
    @include respond-to(xl) {
      display: none; }
    @media screen and (min-width: 1600px) {
      display: block; } }

  &__link {
    @extend %col__mb-70;
    position: relative;
    padding: 10px 26px 10px 16px;
    display: flex;
    cursor: pointer;
    align-items: center;
    @include respond-to(xl) {
      padding: 10px 24px; }
    @media screen and (min-width: 1600px) {
      padding: 10px 16px; }
    & .material-icons {
      margin-top: 0;
      font-size: 24px; }
    &_active {
      color: $color-white;
      & .nmx-ellipse {
        color: $color-white; }
      & #{$block}__link-content {
        color: $color-white; }
      & #{$block}__icon {
        color: $color-white; }
      & #{$block}__arrow {
        color: $color-white; }
      @include respond-to(xl) {
        background-color: $primary-100; }
      @media screen and (min-width: 1600px) {
        background-color: transparent; }
      &#{$block}__link_hoReCa {
        @include respond-to(xl) {
          background: $hoReCa-grad-primary; }
        @media screen and (min-width: 1660px) {
          background: transparent; } }
      &#{$block}__link_mts {
        @include respond-to(xl) {
          background-color: $negative-100; }
        @media screen and (min-width: 1600px) {
          background-color: transparent; } }
      &#{$block}__link_rosKapStroi {
        @include respond-to(xl) {
          background-color: $color-ros-kap-stroi-menu; }
        @media screen and (min-width: 1600px) {
          background-color: transparent; } }
      &#{$block}__link_helpResource {
        @include respond-to(xl) {
          background-color: $color-help-resource-menu; }
        @media screen and (min-width: 1600px) {
          background-color: transparent; } }
      &#{$block}__link_promopoisk {
        @include respond-to(xl) {
          background-color: $color-promopoisk-menu; }
        @media screen and (min-width: 1600px) {
          background-color: transparent; } } }
    &_filledActive {
      background-color: $primary-100;
      &#{$block}__link_hoReCa {
        background: $hoReCa-grad-primary; }
      &#{$block}__link_mts {
        background-color: $negative-100; }
      &#{$block}__link_rosKapStroi {
        background-color: $color-ros-kap-stroi-menu; }
      &#{$block}__link_helpResource {
        background-color: $color-help-resource-menu; }
      &#{$block}__link_promopoisk {
        background-color: $color-promopoisk-menu; } }
    &_hoReCaActive {
      @include respond-to(xl) {
        background: $hoReCa-grad-primary; } }
    &_mtsActive {
      color: $color-white;
      & .nmx-ellipse {
        color: $color-white; }
      & #{$block}__link-content {
        color: $color-white; }
      & #{$block}__icon {
        color: $color-white; }
      & #{$block}__arrow {
        color: $color-white; }
      @include respond-to(xl) {
        background-color: $negative-100; }
      @media screen and (min-width: 1600px) {
        background-color: transparent; } }
    &_rosKapStroiActive {
      @include respond-to(xl) {
        background-color: $color-ros-kap-stroi-menu; } }
    &_helpResourceActive {
      @include respond-to(xl) {
        background-color: $color-help-resource-menu; } }
    &_promopoiskActive {
      @include respond-to(xl) {
        background-color: $color-promopoisk-menu; } } }

  &__link-content {
    color: $secondary-45;
    line-height: 20px;
    @include respond-to(xl) {
      display: none; }
    @media screen and (min-width: 1600px) {
      display: block; } }

  &__ellipse {
    margin-right: 8px; }

  &__child-menu-header {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-100;
    padding: 12px 16px 12px 24px;
    @media screen and (max-width: 1199px) {
      display: none; }
    @include respond-to(xl) {
      display: block; }
    @media screen and (min-width: 1600px) {
      display: none; } }
  &__child-menu-container {
    @include respond-to(md) {
      max-height: 220px;
      overflow: auto; }
    @include respond-to(xxl) {
      max-height: unset;
      overflow: unset; } }
  &__child-menu {
    list-style: none;
    &:before {
      @include respond-to(xl);
      transform: rotate(270deg);
      content: '';
      width: 20px;
      height: 8px;
      left: -14px;
      top: 19px;
      background-position: center;
      background-size: contain;
      background-image: url("../../images/tip-element-light.svg");
      position: absolute; }
    @include respond-to(xl) {
      width: 227px;
      position: fixed;
      left: 100%;
      background: white;
      z-index: 10;
      border-radius: 6px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 32px rgba(0, 0, 0, 0.16);
      top: 0; }
    @media screen and (min-width: 1600px) {
      width: auto;
      border-radius: unset;
      box-shadow: none;
      background: transparent;
      position: unset;
      left: unset;
      top: unset; } }
  &__child-menu-item {
    cursor: pointer;
    color: $secondary-45;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 16px 12px 24px;
    & #{$block}__ellipse {
      background-color: $secondary-45; }
    &:hover {
      color: $secondary-45; }
    &_active {
      color: $color-white;
      background: $primary-100 !important;
      &#{$block}__child-menu-item_mts {
        background: $negative-100 !important; }
      &#{$block}__child-menu-item_hoReCaActive {
        background: $hoReCa-grad-primary !important; }
      &#{$block}__child-menu-item_rosKapStroiActive {
        background: $color-ros-kap-stroi-menu !important; }
      &#{$block}__child-menu-item_helpResourceActive {
        background: $color-help-resource-menu !important; }
      &#{$block}__child-menu-item_promopoiskActive {
        background: $color-promopoisk-menu !important; }
      & .nmx-ellipse {
        background-color: $color-white; }
      &::before {
        color: $inert-70; }
      &:hover {
        color: $color-white; } } }
  &__child-menu-item-content {
    font-size: 14px;
    line-height: 20px; }

  &__canceled-checks-indicator {
    position: absolute;
    left: 18px;
    top: 23px;
    @media screen and (max-width: 1199px) {
      top: 13px; } } }
