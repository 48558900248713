.project-team-list {
  &__add-row {
    width: 100%;
    display: flex;
    align-items: center; }
  &__table {
    & .ui.grid .row .column {
      &:nth-child(1) {
        width: 20%; }
      &:nth-child(2) {
        width: 20%; }
      &:nth-child(3) {
        width: 20%; }
      &:nth-child(4) {
        width: 15%; }
      &:nth-child(5) {
        width: 12%; }
      &:last-child {
        width: unset;
        flex-grow: 1; } } }
  &__icon {
    cursor: pointer;
    &:hover {
      opacity: 0.7; }
    &_check {
      color: #128A10;
      position: absolute;
      z-index: 5;
      bottom: 5px; }
    &_close {
      color: #B6BAC3; } } }
