@import "../../../styles/theme.sass";

.contractor-resources {
  &__history {
    cursor: pointer;
    color: $passive-100;
    text-decoration: underline; }

  &__client-list {
    display: flex;
    align-items: center;
    &-button {
      border: 1px solid $secondary-5;
      background-color: $secondary-5;
      color: $secondary-45;
      border-radius: 7px;
      margin-left: 5px;
      padding: 3px 10px;
      cursor: pointer;
      white-space: nowrap; } } }
