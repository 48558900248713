.onboarding-scenario-lead-list {
  &__table {
    .row .column {
      &:nth-child(1) {
        width: 296px!important;
        max-width: 306px!important; }
      &:nth-child(2) {
        width: 138px!important; }
      &:nth-child(3) {
        width: 150px!important; }
      &:nth-child(4) {
        width: 160px!important; }
      &:nth-child(5) {
        width: 190px!important; }
      &:nth-child(6) {
        width: 138px!important; }
      &:nth-child(7) {
        width: 164px!important; }
      &:nth-child(8) {
        width: 160px!important; }
      &:nth-child(9) {
        width: 138px!important; }
      &:nth-child(10) {
        flex-grow: 1;
        width: unset!important;
        padding: 0;
        justify-content: end; } } } }
