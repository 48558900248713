.filter {
  &_wrap {
    width: 100%; }

  &__row {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 16px; }
    &_divide {
      & > div {
        width: 50%;
        &:first-child {
          margin-right: 8px; } } } }

  &__actions {
    margin-bottom: 16px;
    &_wrap {
      margin-bottom: 0; } }

  &__wrap-item {
    margin-bottom: 16px; } }
