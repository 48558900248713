@import '../../../styles/theme';

.settings-client-group-list {
  &-row {
    padding: {
      top: 24px;
      bottom: 20px; }
    display: flex;
    align-items: flex-end;
    border-bottom: 1px solid $secondary-5;
    &:first-child {
      border-top: 1px solid $secondary-5; }
    &-actions {
      display: flex;
      justify-content: flex-end;
      width: 50%;
      &__item {
        margin-right: 19px; } }
    &-content {
      width: 50%;
      display: flex;
      flex-direction: column;
      &__date {
        color: $secondary-70;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 16px; }
      &__name {
        color: $passive-100;
        font-size: 18px;
        line-height: 26px;
        margin-bottom: 8px;
        cursor: pointer; }
      &__description {
        color: $secondary-70;
        margin-bottom: 8px;
        font-size: 12px;
        line-height: 18px; }
      &-cards {
        display: flex;
        align-items: center;
        &-item {
          display: flex;
          flex-direction: column;
          border: 1px solid $secondary-10;
          box-sizing: border-box;
          border-radius: 8px;
          padding: 8px 12px;
          &:not(:last-child) {
            margin-right: 16px; }
          &__label {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: $secondary-70;
            margin-bottom: 6px; }
          &__value {
            font-size: 16px;
            line-height: 24px;
            color: $secondary-90; } } } } } }
