@import "../../styles/theme";
@import "../../styles/mixins";

.checkbox-list {
  $block: &;

  &_fullMinHeight {
    min-height: 100%; }

  &__infinite-loader {
    display: flex;
    justify-content: center; }

  &__sort {
    margin-bottom: 8px;
    width: 100%;
    @include respond-to(md) {
      margin-bottom: 16px; } }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    justify-content: space-between;
    @media screen and (max-width: 767px) {
      background-color: $secondary-5;
      border-radius: 8px;
      padding: 7px 12px;
      border: 1px solid $secondary-5; } }

  &__media-buttons {
    flex-grow: 1;
    justify-content: flex-end;
    @include respond-to(md) {
      justify-content: flex-start; } }

  &__wrapper {
    width: 100%;
    position: relative; }

  &__header-row {
    display: flex;
    align-items: center;
    width: 100%; }

  &__header-row-checkbox {
    margin-right: 6px; }

  &__avatar {
    position: absolute;
    width: 40px;
    height: 40px;
    min-width: 40px;
    top: 48px;
    z-index: 99;
    @include respond-to(md) {
      position: unset;
      width: 50px;
      min-width: 50px;
      height: 50px; }
    & .avatar {
      height: 100%;
      width: 100%; }
    &_checkbox {
      @include respond-to(md) {
        margin-left: 8px; }
      @include respond-to(xxl) {
        margin-left: 16px; } }

    &_withSubheader {
      top: 76px; } }

  &__row-checkbox-label {
    @include respond-to(md) {
      display: none; } }

  &__row-checkbox-container {
    min-width: 24px;
    position: absolute;
    display: flex;
    align-items: center;
    left: 12px;
    top: 12px;
    z-index: 1;
    @include respond-to(md) {
      position: unset;
      left: auto;
      top: auto; } }

  &__card {
    position: relative;
    width: 100%;

    &_avatar {
      @include respond-to(md) {
        padding-left: 8px; }
      @include respond-to(xxl) {
        padding-left: 16px; } }

    &_withCheckbox {
      width: 100%;
      @include respond-to(md) {
        width: calc(100% - 24px);
        padding-left: 16px; }
      &.checkbox-list__card_avatar {
        @include respond-to(md) {
          width: calc(100% - 82px); }
        @include respond-to(xxl) {
          width: calc(100% - 94px); } }
      & .nm-list-card__secondary-header-value {
        margin-left: 32px;
        width: calc(100% - 32px);
        @include respond-to(md) {
          margin-left: 0; } } } }

  &__row {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;

    &_warning {
      background-color: $inert-20;
      border: 1px solid $inert-20; }

    &_error {
      background-color: $negative-15;
      border: 1px solid $negative-20; }

    &_errors {
      margin-bottom: 8px;
      border: 1px solid $negative-100;
      & #{$block}__row-checkbox-container {
        top: 44px; }
      & #{$block}__avatar {
        top: 80px; } }

    &_active {
      background-color: $secondary-5; }

    &:not(:last-child) {
      margin-bottom: 8px;
      @include respond-to(xxl) {
        margin-bottom: 16px; } }

    &_rightContent:not(:last-child) {
      margin-bottom: 8px; }

    &_hover {
      &:hover {
        border: 0;
        background: $color-white;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
        border-radius: 7px;
        cursor: pointer; } } } }

