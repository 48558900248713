@import "../../styles/theme";
@import "../../styles/mixins";

.add-items-form {
  &__add-form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } }

  &__button {
    width: 100%;
    margin-top: 8px;
    @include respond-to(md) {
      width: auto;
      margin-top: 24px;
      margin-left: 16px; } }

  &__icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
    margin-left: 4px;
    cursor: pointer; }

  &__item:not(:last-child) {
    margin-right: 8px; }

  &__email {
    background-color: $secondary-5;
    display: flex;
    align-items: center;
    padding: 3px 8px;
    max-width: 144px;
    border-radius: 6px; }

  &__email-container {
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    height: 34px; }

  &__scroll-container {
    margin-top: 8px;
    &::-webkit-scrollbar {
      display: none; }
    &_edit {
      margin-top: 0; } } }
