@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-input-password {
  width: 100%;
  &_openNaimix, &_promopoisk {
    font-family: $font-family-open-naimix; }
  &__label-container {
    display: flex;
    justify-content: space-between; }
  &__forgot {
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    color: $open-naimix-secondary-100;
    margin-bottom: 4px;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; }
    &_promoPoisk {
      color: $promo-poisk-color-4; } }
  &__container {
    width: 100%;
    position: relative; }
  &__icon-see {
    color: $secondary-45;
    position: absolute;
    top: 12px;
    cursor: pointer;
    margin: auto;
    right: 12px;
    width: 24px;
    height: 24px;
    &_openNaimix, &_promopoisk {
      top: 11px;
      right: 8px;
      width: 20px;
      height: 20px;
      color: $open-naimix-black-50;
      @include respond-to(md) {
        top: 14px;
        right: 12px; } } }
  &__password input {
    padding: 14px 40px 14px 16px; }
  &__row {
    display: flex; } }
