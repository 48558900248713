.edo-documents-registries-add-items-list {
  &__header {
    & .nm-page__title {
      overflow: hidden; }
    & .nm-title__container {
      overflow: hidden; }
    & .nm-title__value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } } }
