@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.recruitment-vacancy-job-board-list {
  position: relative;
  flex-grow: 1;
  &__item {
    &-primary-header {
      @media screen and (max-width: 768px) {
        margin-top: 4px !important; } }
    &-logo {
      width: 40px;
      height: 40px;
      top: 8px;
      @include respond-to(md) {
        width: 48px;
        height: 48px;
        top: unset; } } }
  &__advertisement-card {
    border: 1px solid $secondary-5;
    border-radius: 6px;
    padding: 24px 16px;
    margin-top: 16px;
    margin-left: 12px;
    &-loader {
      display: flex;
      justify-content: center; } }
  &__info-text {
    width: 100%;
    text-align: center;
    padding: 24px 48px; } }
