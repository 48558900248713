@import "../../../../../../styles/theme";
@import "../../../../../../styles/mixins";

.crowd-task-contractor-image-block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 96px;
  cursor: pointer;
  border: 1px solid $secondary-45;
  border-radius: 7px;
  &:hover {
    border-color: $primary-100; } }
