@import "../../../styles/mixins";

.operator-reports {
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  overflow: auto;
  &_client-content {
    padding: 0; } }




