@import "../../styles/theme";
@import "../../styles/mixins";

@keyframes spin {
  to {
    transform: rotate(360deg); } }

.order-contractors {
  padding: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &__actions {
    display: flex;
    margin-top: 16px;
    @include respond-to(md) {
      justify-content: flex-end; }
    @include respond-to(xxl) {
      align-items: center;
      margin-top: 0;
      flex-direction: row; } }
  &__refresh-container {
    display: flex;
    align-items: center;
    @include respond-to(xxl) {
      flex-direction: row; } }
  &__refresh {
    margin-right: 8px;
    margin-left: 8px;
    @include respond-to(xxl) {
      margin-right: 0; }
    &_animate {
      animation: spin 1s linear infinite; } }
  &__ul-error {
    padding-inline-start: 20px !important; }
  &__error-payment {
    &:not(:last-child) {
      margin-bottom: 10px; } }
  &__order {
    margin-top: 20px;
    margin-left: 70px;
    font-size: 13px;
    line-height: 18px;
    &-link {
      color: $passive-100;
      cursor: pointer; } }
  &__content-dimmer.ui.dimmer {
    z-index: 999; }
  &__container {
    position: relative;
    flex-grow: 1; }
  &__icon-blocked.nm-icon {
    height: 22px;
    margin-left: 1px; }
  &__header {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 16px;
    flex-direction: column;
    @include respond-to(xxl) {
      flex-direction: row; }
    &-actions {
      display: flex;
      justify-content: space-between;
      flex-grow: 1; } }
  &__action {
    margin-left: 10px; }
  &__migration-confirm {
    width: 605px;
    &-content {
      text-align: justify;
      white-space: pre-wrap; } } }
