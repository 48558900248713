@import "../../../styles/theme";
@import "../../../styles/mixins";

.deposit-calc {
  &__content {
    flex-direction: column; }
  &__radio-container {
    display: flex;
    align-items: center;
    position: relative; }
  &__info-tooltip {
    margin-left: 6px; }
  &__box-tooltip {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    @include respond-to(md) {
      margin-left: 0;
      position: absolute;
      right: 12px;
      top: 12px; } }
  &__radio {
    display: flex;
    align-items: center; }
  &__header {
    font-size: 20px; }
  &__agreement-type {
    display: none;
    font-size: 16px;
    @include respond-to(xxl) {
      display: block; } }
  &__row {
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 16px;
      @include respond-to(md) {
        margin-bottom: 24px; } }
    @include respond-to(xxl) {
      flex-direction: row; }
    &_wrapped {
      flex-wrap: wrap;
      @include respond-to(md) {
        flex-direction: row; } } }
  &__amount {
    width: 100%;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xxl) {
      margin-bottom: 0;
      margin-right: 16px;
      width: 33%; } }
  &__commission {
    width: 100%;
    margin-bottom: 12px;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xxl) {
      margin-bottom: 0;
      margin-right: 16px;
      width: 33%; } }
  &__cntr-commission {
    width: 100%;
    @include respond-to(xxl) {
      width: 33%; } }
  &__field {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    background: $color-white;
    box-sizing: border-box;
    border-radius: 7px;
    padding: 0;
    position: relative;
    flex: 1 0 41%;
    @include respond-to(md) {
      border: 1px solid $secondary-5;
      padding: 12px 16px;
      align-items: flex-start;
      flex-direction: column;
      width: 50%; }
    &:not(:last-child) {
      margin-bottom: 4px; }
    &:nth-child(odd) {
      @include respond-to(md) {
        margin: 0 16px 16px 0; } }
    &:nth-child(even) {
      @include respond-to(md) {
        margin-bottom: 16px; } } }
  &__field-label {
    color: $secondary-80;
    font-weight: normal;
    font-size: 12px;
    margin-right: 6px;
    line-height: 18px;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px;
      color: $secondary-70; }
    @include respond-to(xxl) {
      color: $secondary-80; } }
  &__field-value {
    color: $secondary-90;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    @include respond-to(md) {
      margin-left: 0;
      font-weight: 500;
      margin-top: 16px;
      font-size: 16px; } }

  &__tooltip {
    position: absolute;
    background: #FFFFFF;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    padding: 26px 22px;
    z-index: 30;
    top: 30px;
    right: 15px;
    &-close-icon {
      position: absolute;
      right: 5px;
      top: 10px;
      cursor: pointer; } } }

