@import "../../../styles/theme";

.menu-profile {
  $block: &;

  min-width: 168px;
  max-width: 168px;
  width: 100%;
  height: 56px;
  min-height: 56px;
  display: flex;
  margin: 12px 16px 28px 16px;
  align-items: center;
  @media screen and (max-width: 1599px) {
    margin-right: 24px;
    min-width: unset;
    max-width: unset;
    width: auto; }
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    margin: 22px 20px; }
  @media screen and (max-width: 1199px) {
    margin: 0 24px 0 42px; }

  &_open {
    @media screen and (max-width: 1199px) {
      margin: 40px 16px 16px 16px; }
    & #{$block}__container-button {
      @media screen and (max-width: 1199px) {
        display: block; } }
    & #{$block}__title {
      @media screen and (max-width: 1199px) {
        display: block; } }
    & #{$block}__info {
      @media screen and (max-width: 1199px) {
        justify-content: space-between; } }
    & #{$block}__suggestion {
      @media screen and (max-width: 1199px) {
        right: -10px;
        top: calc(100% + 10px)!important; }
      &:before {
        @media screen and (max-width: 1199px) {
          display: block!important; } } }
    & #{$block}__icon:hover {
      box-shadow: none; } }

  &__suggestion {
    @media screen and (max-width: 1199px) {
      top: calc(100% + 18px); }
    @media screen and (min-width: 1200px) {
      left: -6px; }
    @media screen and (min-width: 1600px) {
      left: -10px; }
    &:before {
      display: none;
      @media screen and (min-width: 1200px) {
        display: block; } }
    &_fixed {
      @media screen and (max-width: 425px) {
        position: fixed;
        left: 18px;
        right: 18px;
        top: 62px;
        max-width: 100%;
        width: calc(100% - 36px); } } }

  &__info {
    width: 100%;
    display: flex; }
  &__icon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: $color-white;
    cursor: pointer;
    &_admin {
      background: url("../../../images/nm-logo-rounded.svg") no-repeat center; }
    @media screen and (min-width: 1600px) {
      cursor: default; }
    &:hover {
      @media screen and (max-width: 1199px) {
        box-shadow: 0 0 12px rgba(255, 255, 255, 0.3); } }
    img {
      height: 100% !important; } }
  &__container-button {
    position: relative;
    @media screen and (max-width: 1599px) {
      display: none; } }
  &__button {
    height: 24px;
    width: 24px;
    margin: 0;
    min-width: 24px;
    background-color: inherit!important; }
  &__title {
    margin: 0 8px;
    max-width: 96px;
    @media screen and (max-width: 1599px) {
      display: none; }
    &-user {
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: $secondary-45; }
    &-client {
      word-wrap: break-word;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: $secondary-70; } } }


