@import "../../../styles/theme";
@import "../../../styles/mixins";

.chat {
  background-color: $secondary-5;
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  &__layer {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    z-index: 999;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.25); }

  &__image-full-screen {
    max-width: 100%;
    max-height: 100%;
    cursor: pointer; }

  &__footer-textarea {
    flex-grow: 1;
    margin: 0 8px;
    @include respond-to(xxl) {
      margin: 0 12px; } }

  &__footer {
    padding: 0 16px 16px 16px;
    @include respond-to(md) {
      padding: 0 24px 24px 24px; }
    @include respond-to(xxl) {
      padding: 0 48px 28px 48px; } }

  &__footer-container {
    display: flex;
    background-color: $color-white;
    align-items: flex-start;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    padding: 12px 16px; }

  &__footer-send {
    transform: rotate(270deg);
    cursor: pointer; }

  &__check {
    position: absolute;
    right: 4px;
    top: 9px;
    width: 20px;
    height: 20px; }

  &__body {
    background: $secondary-5;
    flex-grow: 1;
    overflow: auto;
    padding: 16px;
    @include respond-to(md) {
      padding: 24px; }
    @include respond-to(xxl) {
      padding: 48px; }
    &_empty {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%; } }

  &__system-message-container {
    display: flex;
    justify-content: center; }

  &__system-message {
    padding: 12px 8px;
    border: 1px solid $secondary-10;
    border-radius: 8px;
    display: flex;
    align-items: center; }

  &__system-message-logo {
    min-width: 20px;
    width: 20px;
    height: 20px;
    margin-right: 10px; }

  &__body-row:not(:first-child) {
    margin-top: 24px; }

  &__message {
    background: $color-white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 7px;
    min-height: 70px;
    padding: 12px 8px;
    clear: both;
    width: 100%;
    max-width: 278px;
    @include respond-to(md) {
      width: max-content;
      max-width: 308px; }
    @include respond-to(xxl) {
      width: max-content;
      max-width: 594px; }
    &_my {
      margin-left: auto;
      background: $primary-10;
      position: relative; } }

  &__message-img {
    margin-top: 16px;
    img {
      cursor: pointer;
      max-width: 100%; } }

  &__message-img-loader.ui.loader {
    z-index: 999; }

  &__message-text {
    margin-top: 8px;
    word-wrap: break-word; }

  &__message-document {
    max-width: 150px;
    margin-top: 16px; }

  &__message-header {
    display: flex;
    align-items: center; }

  &__message-header-date {
    display: inline-block;
    white-space: nowrap;
    font-size: 12px;
    line-height: 14px;
    float: right;
    margin-left: 8px;
    margin-right: 8px; }

  &__message-header-name-container {
    display: flex;
    align-items: center; }

  &__message-header-name {
    font-size: 12px;
    line-height: 14px;
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__images {
    padding: 16px 16px 12px 16px;
    @include respond-to(md) {
      padding: 16px 24px 12px 24px; }
    @include respond-to(xxl) {
      padding: 16px 48px 12px 48px; } }

  &__images-remove {
    cursor: pointer; }

  &__images-preview-image {
    max-height: 67px;
    max-width: 100px; }

  &__images-preview-file-icon {
    font-size: 67px!important; }

  &__images-preview-filename {
    margin-top: 4px;
    cursor: pointer;
    word-break: break-word; } }
