@import "../../../styles/theme";
@import "../../../styles/mixins";

.dropzone-v2 {
  $block: &;
  width: 100%;

  &__dropzone-uploaded-area {
    &_image {} }


  &__image {
    max-width: 100%;
    max-height: 100%; }

  &__target {
    padding: 16px;
    border-radius: 6px;
    border: 1px dashed $passive-100;
    background-color: $passive-5;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    @include respond-to(md) {
      align-items: center;
      flex-direction: row; }
    &_error {
      background-color: $negative-20;
      border: 1px dashed $negative-100; }
    &_disabled {
      cursor: default;
      border: 1px dashed $secondary-45;
      background-color: $color-white;
      & #{$block}__info-title, #{$block}__info-text {
        color: $secondary-45; } }
    &_example {
      flex-direction: column !important;
      align-items: center; }
    &-replace {
      margin-top: 16px;
      height: max-content;
      width: max-content; } }

  &__info-title {
    color: $secondary-100;
    margin-bottom: 6px;
    &_example {
      margin-top: 16px; } }

  &__info-text {
    color: $secondary-70; }

  &__note-text {
    color: $secondary-70;
    margin-top: 8px; }

  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &_example {
      align-items: center;
      max-width: 100%; } }

  &__button {
    padding: 10px 20px;
    height: 40px;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    color: $color-white;
    border-radius: 7px;
    background-color: $passive-100;
    margin-top: 16px;
    text-align: center;
    @include respond-to(md) {
      margin-top: 0;
      margin-left: 16px; }
    &_disabled {
      background-color: $secondary-5;
      color: $secondary-45; }
    &_example {
      margin-top: 16px !important; } }

  &__tooltip {
    z-index: 1;
    &_fixed {
      position: absolute; } }

  &__file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $secondary-70; }

  &__example {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-img-container {
      position: relative;
      width: max-content;
      height: max-content;
      padding: 6px; }
    &-image {
      margin: 6px; } }
  &__border-elem {
    position: absolute;
    height: 12px;
    width: 12px;
    &_top {
      top: 0;
      border-top: 4px solid $primary-100; }
    &_left {
      left: 0;
      border-left: 4px solid $primary-100; }
    &_bottom {
      bottom: 0;
      border-bottom: 4px solid $primary-100; }
    &_right {
      right: 0;
      border-right: 4px solid $primary-100; } } }
