@import "../../styles/theme";

.nm-work-schedule-filter {
  $block: &;

  display: flex;
  align-items: flex-end;
  &__modal {
    width: 1153px; }
  &__clear {
    margin-left: 10px;
    color: $secondary-45; }
  &__icon {
    color: $secondary-45; }
  &__popup {
    width: 1024px; }
  &__header {
    font-size: 18px;
    line-height: 21px;
    color: $secondary-90;
    font-weight: 500;
    margin-bottom: 16px; }
  &__text {
    color: $secondary-90;
    opacity: 0.6;
    margin-left: 8px; }
  &__elem {
    cursor: pointer;
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 10px;
    background-color: $secondary-5;
    &:hover {
      border-color: $primary-100; }
    &_active {
      background-color: $color-white;
      border-color: $secondary-70;
      & #{$block}__text {
        color: $secondary-90;
        opacity: 1; } }
    &_size-lg {
      height: 40px;
      padding: 10px 16px; }
    &_size-xl {
      padding: 14px 16px;
      height: 48px; } }
  .field {
    width: 100%;
    cursor: pointer;
    min-width: 150px; } }
