@import "../../../../../../../styles/theme";
@import "../../../../../../../styles/mixins";

.order-edit-publish-client-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__main-content {
    display: flex;
    flex-direction: column;
    overflow: hidden; }
  &__icon {
    transform: rotate(270deg);
    color: $secondary-45;
    min-width: 24px;
    width: 24px;
    height: 24px; }
  &__customer {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    line-height: 24px;
    color: $secondary-100; }
  &__client {
    overflow: hidden; }
  &__client-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    color: $secondary-90;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;
    &_thin {
      font-weight: 400; }
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } }
  &__contact-person-label {
    white-space: nowrap;
    margin-right: 8px;
    color: $secondary-70; }
  &__contact-person-text {
    color: $secondary-90;
    font-weight: 500; }
  &__contact-person {
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } } }
