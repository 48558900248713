@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.tabs-item {
  $block: &;

  white-space: nowrap;
  padding: 0 0 12px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  @include respond-to(md) {
    padding: 12px 0; }
  &_active {
    border-bottom: 2px solid $secondary-90;
    padding: 0 0 10px 0;
    @include respond-to(md) {
      padding: 12px 0 10px 0; }
    &#{$block}_secondary#{$block}_noCount {
      padding: 8px 13px;
      @include respond-to(md) {
        padding: 9px 15px; } } }
  &_secondary {
    padding: 9px 10px 9px 16px;
    border-radius: 6px;
    background-color: $secondary-5;
    @include respond-to(md) {
      padding: 10px 12px 10px 15px; }
    &#{$block}_active {
      padding: 8px 9px 8px 15px;
      border: 1px solid $secondary-90;
      background-color: $color-white;
      @include respond-to(md) {
        padding: 9px 11px 9px 14px; } } }
  &_noCount {
    padding: 9px 14px;
    @include respond-to(md) {
      padding: 10px 16px; } }

  &__text {
    $block: &;

    font-weight: normal;
    opacity: 0.5;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-90;
    @include respond-to(md) {
      font-size: 16px;
      line-height: 24px; }
    &_secondary {
      opacity: 0.5;
      font-size: 12px;
      line-height: 18px;
      @include respond-to(md) {
        font-size: 14px;
        line-height: 20px; } }
    &_active {
      opacity: 1; } }

  &__counter {
    background-color: $primary-100;
    padding: 2px 5.5px;
    font-size: 12px;
    min-width: 18px;
    line-height: 14px;
    text-align: center;
    border-radius: 9px;
    color: $color-white;
    margin-left: 4px;
    @include respond-to(md) {
      margin-left: 6px; } } }
