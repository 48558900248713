@import "../../../styles/theme";

.nm-tooltip {
  $block: &;
  border-radius: 6px;
  background-color: $secondary-90;
  width: max-content;
  max-width: 400px;
  color: $color-white;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 4px 32px rgba(0, 0, 0, 0.16);
  position: absolute;
  z-index: 199;
  min-width: 80px;


  &__header {
    padding-bottom: 9px;
    padding-right: 20px;
    display: flex;
    align-items: center;

    &-icon {
      height: 16px;
      width: 16px; }

    &-title {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $color-white;
      position: relative;

      &:not(:first-child) {
        margin-left: 5px; } }

    &-close {
      height: 16px;
      width: 16px;
      cursor: pointer;
      position: absolute;
      right: 2px;
      top: 2px;
      color: $color-white; }

    &_type {
      &-light {
        & #{$block}__header {
          &-title {
            color: $secondary-100; }
          &-icon {
            color: $secondary-45; }
          &-close {
            color: $secondary-45; } } } } }



  &__body {
    &-item {
      &_postScripts {
        font-style: italic; }
      &:not(:last-child) {
        margin-bottom: 6px; } } }

  &__subtitle {
    font-weight: bold; }

  &:before {
    content: '';
    width: 20px;
    height: 8px;
    background-position: center;
    background-size: cover;
    background-image: url("../../../images/tip-element-dark.svg");
    position: absolute; }

  &_error {
    &:before {
      content: '';
      width: 20px;
      height: 8px;
      background-position: center;
      background-size: cover;
      background-image: url("../../../images/tip-element-error.svg");
      color: $negative-50;
      position: absolute; } }

  &__content {
    position: relative;
    padding: 8px 12px;
    word-break: break-word;
    &_error {
      background: $negative-50;
      border-radius: 4px;
      text-align: center; }
    &_noPadding {
      padding: 0; }
    &_withCloseButton {
      padding: 16px 12px; } }

  &__title {
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px; }

  &__paragraph {
    font-size: 13px;
    line-height: 16px;
    &:not(:last-child) {
      margin-bottom: 7px; } }

  &__content {
    white-space: break-spaces; }

  &__example {
    &-title {
      margin-top: 11px;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 4px; }
    &-item {
      font-style: italic;
      font-size: 13px;
      line-height: 16px;
      &:not(:last-child) {
        margin-bottom: 3px; } } }

  &_type {
    &-light {
      background-color: $color-white;
      color: $secondary-80;
      &:before {
        background-image: url("../../../images/tip-element-light.svg"); }

      & #{$block}__subtitle {
        color: $secondary-100; }
      & #{$block}__header-close {
        color: $secondary-45; } }
    &-warning {
      background-color: $color-white;
      color: $secondary-80;
      &:before {
        background-image: url("../../../images/tip-element-light.svg"); }

      & #{$block}__subtitle {
        color: $secondary-100; }
      & #{$block}__header-close {
        color: $secondary-45; } }
    &-dark {
      color: $color-white;
      background-color: $secondary-80;
      &:before {
        background-image: url("../../../images/tip-element-dark.svg"); }

      & #{$block}__subtitle {
        color: $color-white; }
      & #{$block}__header-close {
        color: $color-white; } }

    &-error {
      background-color: $color-red-salmon;
      &:before {
        background-image: url("../../../images/tip-element-error.svg"); } } }

  &_size {
    &-sm {
      max-width: 200px; }
    &-md {
      max-width: 400px; }
    &-lg {
      max-width: 600px; } }

  &_position {
    &-bottom {
      top: calc(100% + 10px);
      left: 50%;
      transform: translate(-50%, 0);
      &:before {
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0); }

      &-left {
        top: calc(100% + 10px);
        left: 0;
        &:before {
          bottom: 100%;
          left: 12px; } }

      &-right {
        top: calc(100% + 10px);
        right: 0;
        &:before {
          bottom: 100%;
          right: calc(0px + 12px); } } }

    &-top {
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translate(-50%, 0);
      &:before {
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0) rotate(180deg); }

      &-left {
        bottom: calc(100% + 10px);
        left: 0;
        &:before {
          top: 100%;
          left: 12px;
          transform: rotate(180deg); } }

      &-right {
        bottom: calc(100% + 10px);
        right: 0;
        &:before {
          top: 100%;
          right: calc(0px + 12px);
          transform: rotate(180deg); } } }

    &-left {
      top: 50%;
      right: calc(100% + 10px);
      transform: translate(0, -50%);
      &:before {
        top: 50%;
        right: calc(0px - 14px);
        transform: translate(0, -50%) rotate(90deg); }

      &-bottom {
        bottom: 0;
        right: calc(100% + 10px);
        &:before {
          bottom: calc(0px + 13px);
          right: calc(0px - 14px);
          transform: rotate(90deg); } }

      &-top {
        top: 0;
        right: calc(100% + 10px);
        &:before {
          top: calc(0px + 13px);
          right: calc(0px - 14px);
          transform: rotate(90deg); } } }

    &-right {
      top: 50%;
      left: calc(100% + 10px);
      transform: translate(0, -50%);
      &:before {
        top: 50%;
        left: calc(0px - 14px);
        transform: translate(0, -50%) rotate(-90deg); }

      &-bottom {
        bottom: 0;
        left: calc(100% + 10px);
        &:before {
          bottom: calc(0px + 13px);
          left: calc(0px - 14px);
          transform: rotate(-90deg); } }

      &-top {
        top: 0;
        left: calc(100% + 10px);
        &:before {
          top: calc(0px + 13px);
          left: calc(0px - 14px);
          transform: rotate(-90deg); } } } }

  &_triggerListCardIcon {
    left: -12px; }

  &_filterTooltip {
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    bottom: auto;
    max-width: 100%;
    transform: none;
    width: 100%;
    min-width: 252px;
    &:before {
      display: none; } } }
