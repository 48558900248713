@import "../../styles/theme";

.logo-thumbnail {
  display: flex;
  border: 1px solid $secondary-5;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  &_noBorder {
    border: unset; }
  &_clickable {
    cursor: zoom-in; }
  &_size-xl {
    width: 128px;
    height: 128px; }
  &_size-sm {
    height: 48px;
    width: 48px; }
  &_size-md-list-card {
    height: 50px;
    width: 50px; }
  &_size-md {
    min-width: 24px;
    width: 24px;
    height: 24px; }

  img {
    display: block;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
    border-radius: inherit; } }
