@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.nm-list-card {
  $block: &;

  display: flex;
  flex-grow: 1;
  width: 0;
  align-items: flex-end;
  @include respond-to(md) {
    align-items: stretch; }
  @include respond-to(xxl) {
    align-items: flex-end; }

  &_alignItems-stretch {
    align-items: stretch; }
  &_alignItems-flex-start {
    align-items: flex-start; }
  &_alignItems-flex-end {
    align-items: flex-end; }

  &__errors {
    margin-bottom: 8px;
    display: none;
    @include respond-to(md) {
      display: block; }
    @include respond-to(xxl) {
      position: absolute;
      top: 0;
      right: 0; }
    &_isMobile {
      display: block;
      @include respond-to(md) {
        display: none; } } }

  &__errors-tooltip {
    right: -14px; }

  &__status {
    overflow: hidden; }

  &__info-box {
    margin-bottom: 4px;
    &_last {
      margin-bottom: 0; }
    @include respond-to(md) {
      margin-bottom: 0; } }

  &__divider {
    margin: 8px 12px;
    background-color: $secondary-5;
    height: 1px;
    width: calc(100% - 24px);
    @include respond-to(md) {
      display: none; } }

  &__cards-container-row {
    &_align-left {
      justify-content: flex-start; }
    &_align-right {
      justify-content: flex-start;
      @include respond-to(xxl) {
        justify-content: end; } }
    &_wrapReverse {
      flex-wrap: wrap-reverse; } }

  &__primary-header {
    $primaryHeader: &;

    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    @include respond-to(md) {
      margin-bottom: 8px; }
    @include respond-to(xxl) {
      margin-bottom: 12px; }
    &_noSecondaryHeader#{$primaryHeader}_checkbox {
      margin-top: 32px;
      @include respond-to(md) {
        margin-top: 0; } }

    &_withCheckboxLabel {
      margin-top: 36px;
      @include respond-to(md) {
        margin-top: 0; } }
    &_avatar {
      margin-top: 24px;
      margin-left: 46px;
      @include respond-to(md) {
        margin-top: 0;
        margin-left: 0; }
      &#{$primaryHeader}_noSecondaryHeader {
        margin-top: 44px;
        @include respond-to(md) {
          margin-top: 0; } }
      &#{$primaryHeader}_secondaryHeaderStatus#{$primaryHeader}_secondaryHeader:not(#{$primaryHeader}_checkbox) {
        @media only screen and (max-width: 767px) {
          margin-top: 20px; } }
      &:not(#{$primaryHeader}_checkbox) {
        @media only screen and (max-width: 767px) {
          margin-top: 28px; } } }
    &_offsetRight {
      padding-right: 36px;
      @include respond-to(md) {
        padding-right: 52px; } } }

  &__primary-header-value {
    margin-right: 6px;
    &_wordBreak {
      word-break: break-word;
      hyphens: auto; }
    &_fluid {
      width: 100%; }
    &_noWrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    &_twoLines {
      display: -webkit-box;
      overflow: hidden;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; }
    &_link {
      cursor: pointer;
      color: $passive-100;
      font-size: 18px;
      line-height: 26px;
      &:hover {
        opacity: 0.8; } } }

  &__secondary-header {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    &_secondaryHeaderRelative {
      position: relative; }
    &_secondaryHeaderNoReverse {
      flex-direction: column;
      @include respond-to(md) {
        flex-direction: row; } }
    @include respond-to(md) {
      flex-direction: row;
      align-items: center; }
    &_checkbox {
      margin-top: 4px; } }

  &__secondary-header-value {
    color: $secondary-70;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 30px;
    width: 100%;
    &_minHeight {
      min-height: 16px; }
    &_secondaryHeaderNoReverse {
      margin-top: 12px;
      @include respond-to(md) {
        margin-top: 0; } }
    @include respond-to(md) {
      white-space: unset;
      padding-right: 0; } }

  &__secondary-header-loading {
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 0;
      margin-left: 16px;
      padding-right: 56px; }
    @include respond-to(xxl) {
      padding-right: 0; } }

  &__secondary-additional-status {
    margin-right: 16px; }

  &__secondary-header-status {
    &:not(#{$block}__secondary-header-status_onlyStatus) {
      margin-right: 16px;
      margin-top: 12px;
      @include respond-to(md) {
        margin-top: 0; } } }

  &__primary-header-status-tooltip {
    margin-left: 4px; }

  &__labels {
    & #{$block}__label-text:not(:last-child) {
      margin-bottom: 6px;
      @include respond-to(xxl) {
        margin-bottom: 4px; } }
    &_onlyLabels {
      &#{$block}__labels_checkbox {
        margin-top: 36px;
        @include respond-to(md) {
          margin-top: 0; } } }
    &_avatar {
      margin-top: 14px; }
    &_clientLogo {
      margin-top: 62px;
      @include respond-to(md) {
        margin-top: 0; } } }

  &__actions {
    &_fixed {
      position: absolute;
      top: 0;
      right: 0;
      width: auto; }
    &:not(#{$block}__actions_fixed) {
      @include respond-to(md) {
        position: unset;
        flex-grow: 1;
        display: flex;
        justify-content: space-between; }
      @media screen and (max-width: 1919px) {
        padding-right: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: auto; } } }
  &__avatar {
    display: none;
    @include respond-to(md) {
      display: flex;
      min-width: 50px;
      width: 50px;
      height: 50px;
      margin-right: 16px; } }
  &__avatar_mobile {
    display: flex;
    min-width: 40px;
    width: 40px;
    height: 40px;
    margin-right: 8px;
    @include respond-to(md) {
      display: none; } } }
