@import "../../../../../../../styles/mixins";

.add-group-member-form {
  padding: 0;
  &__filter {
    margin-bottom: 16px;
    @include respond-to(md) {
      flex-wrap: nowrap;
      margin-bottom: 0; } }
  &__table {
    & .ui.grid .row .column {
      &:nth-child(1) {
        width: 10%; }
      &:nth-child(2) {
        width: 30%!important; }
      &:nth-child(3) {
        width: 30%!important; }
      &:nth-child(4) {
        width: 30%!important; } } } }
