@import "../../../../../styles/theme.sass";

.invite-to-fc-registry-form {
  $block: &;

  &__modal {
    width: 800px; }

  display: flex;
  flex-grow: 1;
  .modal-layer, .dimmer {
    position: fixed;
    textarea {
      border-radius: 7px;
      width: 100%; } }

  &__table {
    margin-top: 15px; }

  &__empty-list {
    display: flex;
    flex-grow: 1;
    height: 100%;
    border: 1px solid $secondary-10;
    white-space: pre-wrap;
    justify-content: center;
    align-items: center;
    &-text {
      font-size: 24px;
      line-height: 30px;
      text-align: center; } }

  &__inv-nm-btn {
    min-width: 350px;
    margin-left: 15px; }
  &-action {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
    margin-left: auto;
    order: 2; } }
