@import "../../../../../../styles/theme";

.nm-list-card-notification {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.8);
  &__message {
    background: rgba(0, 0, 0, 0.8);
    width: 270px;
    border-radius: 6px;
    text-align: center;
    color: $color-white;
    padding: 10px;
    font-size: 13px;
    line-height: 16px; } }
