@import "../../styles/theme";

.emoji-picker {
  position: relative;
  &__icon {
    cursor: pointer;
    color: $secondary-45;
    &:hover {
      color: $secondary-75; } }
  &__picker {
    position: absolute;
    z-index: 100; } }
