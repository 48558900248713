.order-categories-page {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  &__container {
    flex-grow: 1; }
  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; } }
