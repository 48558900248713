@import "../../../../styles/theme";

.fc-registry-list {
  &__registry-info {
    display: flex;
    width: 37.5%;
    flex-direction: column; }

  &__registry-comment {
    color: $secondary-100;
    font-size: 13px; }

  &__registry-link {
    font-size: 18px;
    color: $passive-100;
    margin-bottom: 5px; }

  &__responsible-full-name {
    font-size: 12px;
    color: $secondary-90; }

  &__responsible-label {
    font-size: 12px;
    color: $secondary-70; }

  &__registry-counters {
    border: 1px solid $secondary-15;
    padding: 8px 14px;
    margin-left: 50px;
    margin-right: auto;
    border-radius: 7px;
    &-title {
      font-size: 12px;
      color: $secondary-70;
      margin-bottom: 5px; }
    &-content {
      font-size: 16px;
      line-height: 19px;
      color: $secondary-90; }

    &-first-item {
      font-weight: bold; } }

  &__popup {
    white-space: pre-line; }

  .nm-list-card__secondary-header-value {
    overflow: unset; } }
