@import "../../../styles/mixins";
@import "../../../styles/theme";

.brigade-search {
  width: 900px;
  background: $color-white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  position: relative;
  z-index: 9999;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: 1280px) {
    width: 100%; }
  &__input {
    margin-bottom: 14px; }
  &-textarea {
    margin-bottom: 15px!important;
    resize: none!important; }
  &-row {
    display: flex;
    align-items: center;
    @media screen and (max-width: 640px) {
      flex-direction: column; } }
  &_list {
    padding: 24px 0;
    @media screen and (max-width: 1280px) {
      padding: 12px 0; } }
  &-container {
    @include flex-row; }
  &__text-new-phone {
    font-size: 13px;
    margin-top: 2px;
    padding-left: 10px;
    color: $inert-70; }
  &__item {
    &_flex-column {
      display: flex;
      flex-direction: column; }
    &:not(:last-child) {
      margin-right: 15px; }
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: $color-black;
    @media screen and (max-width: 1280px) {
      font-size: 15px;
      padding: 3px 0; }
    & .ui.button {
      @media screen and (max-width: 1280px) {
        width: 100%; } }
    &-invite-btn {
      margin-left: auto; } }
  &_count {
    margin-bottom: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $primary-100; }
  &-line:not(:last-child) {
    height: 1px;
    width: 100%;
    padding-left: 51px;
    padding-right: 61px;
    background: $secondary-10; } }
