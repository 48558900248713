@import "../../styles/theme";

.contractor-edit-params {
  &-content {
    display: flex;
    flex-direction: column; }
  &-project-params-block {
    display: flex;
    align-items: center;
    margin: {
      bottom: 16px;
      top: 16px; }
    &-new-block {

      display: flex; }
    &__delete-icon {
      color: $secondary-45;
      cursor: pointer;
      &_mt {
        margin-top: 25px; }
      &:hover {
        opacity: 0.8; } }
    &__edit-icon {
      color: $secondary-45;
      cursor: pointer;
      &:hover {
        opacity: 0.8; } }
    &__submit-icon {
      color: $primary-100;
      width: 18px;
      height: 18px;
      cursor: pointer;
      margin: {
        top: 28px;
        left: 8px; }
      &:hover {
        opacity: 0.8; } } } }
