@import "../../../../styles/theme";

.nm-confirm-content {
  &_warning {
    text-align: left; }
  &__message {
    color: $secondary-100;
    word-break: break-word;
    white-space: pre-line;
    &_inline {
      display: inline; } }
  &__warning {
    color: $inert-100;
    &_inline {
      display: inline; } }
  &_align-center {
    text-align: center; }
  &_align-left {
    text-align: left; }
  &_align-right {
    text-align: right; }
  &_align-justify {
    text-align: justify; } }
