@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.order-list-mass-template-publication {
  &__accordion-icon {
    position: unset;
    width: 20px;
    height: 20px;
    @include respond-to(md) {
      width: 24px;
      height: 24px; } }
  &__attention {
    color: $inert-100; } }
