@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.map-toolbar {
  position: relative;
  height: 96px;
  width: 100%;
  background-color: $color-white;
  z-index: 9;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  &_showMap {
    position: fixed;
    padding: 16px 24px 16px 220px; }
  &__filter {
    display: flex;
    align-items: start;
    width: 100%;
    flex-grow: 1; }
  &__controls {
    margin-left: auto; }
  &_loader {
    margin-left: auto; } }
