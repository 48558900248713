@import "../../../styles/theme";

.brigade-payments {
  position: relative;
  padding-top: 25px;
  padding-left: 0;
  padding-right: 0;

  &__confirm {
    max-width: 40%; }
  &__confirm-content {
    white-space: pre-wrap; }

  &__modal {
    z-index: 1001; }

  &__foreman {
    display: flex; }

  &__content {
    position: relative; }

  &__modal {
    z-index: 1001; }

  &__button-rejected {
    background-color: $negative-100;
    color: $color-white;
    &:hover {
      background-color: $negative-100;
      color: $color-white; } }

  &__button-approved {
    background-color: $primary-100;
    color: $color-white;
    &:hover {
      background-color: $primary-100;
      color: $color-white; } }

  &__filter {
    display: flex; }

  &__actions {
    display: flex;
    width: 100%;
    justify-content: flex-end; }

  &__total-row {
    font-size: 16px;
    color: $color-black;
    margin-top: 10px;
    display: flex;
    align-items: center; }

  &__total-label {
    width: calc(47% + 120px);
    text-align: right; }

  &__total-amount {
    width: 17%;
    padding: 0 14px; }

  &__confirm-all {
    margin-left: 14px; }

  &__table {
    color: $secondary-90;
    & .nmx-grid-table__row .column {
      &:nth-child(4), &:nth-child(5), &:nth-child(6) {
        font-size: 16px;
        line-height: 19px;
        color: $color-black; } }
    & .ui.grid .row .column {
      &:first-child {
        width: 70px; }
      &:nth-child(2) {
        width: 10%; }
      &:nth-child(3) {
        width: 15%; }
      &:nth-child(4) {
        width: 15%; }
      &:nth-child(5) {
        width: 17%; }
      &:nth-child(6) {
        width: 17%; }
      &:last-child {
        flex-grow: 1;
        width: 250px; } } }

  &__button {
    margin-right: 10px;
    justify-content: center;
    min-width: 130px;
    text-align: center; }
  &__blocked {
    cursor: pointer; } }
