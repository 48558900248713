@import "../../../styles/theme";
@import "../../../styles/mixins";

.chat-list {
  width: 100%;
  display: flex;
  &_empty {
    background: $secondary-5;
    width: 100%; }

  &__values {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 12px 16px 24px 16px;
    @include respond-to(md) {
      padding: 12px 0 32px 24px;
      min-width: 400px;
      width: 400px; }
    @include respond-to(xxl) {
      padding: 24px 0 32px 48px; } }


  &__content {
    flex-grow: 1;
    overflow: auto; }

  &__header {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    max-width: 450px; }

  &__row {
    cursor: pointer;
    padding: 16px;
    display: flex;
    position: relative;
    &_active {
      background: $secondary-5; } }

  &__icon {
    position: relative;
    &_locked {
      color: $negative-100; } }

  &__details {
    display: flex;
    margin-left: 16px;
    flex-direction: column;
    width: calc(100% - 51px);
    align-items: flex-end;
    @include respond-to(xxl) {
      align-items: flex-start;
      width: auto;
      flex-direction: row; } }

  &__details-info {
    width: 100%;
    @include respond-to(xxl) {
      width: 144px; } }

  &__tabs {
    margin-bottom: 8px;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xxl) {
      margin-bottom: 24px; } }

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  &__time {
    margin-top: 8px;
    margin-left: 16px;
    white-space: nowrap;
    color: $secondary-70;
    @include respond-to(xxl) {
      margin-top: 0; } }
  &__result {
    font-size: 12px;
    &_open {
      color: $color-red-70; } }
  &__label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

  &__check {
    color: $primary-100;
    width: 18px;
    height: 18px;
    min-width: 18px;
    margin-left: 4px; }

  &__msg {
    border-radius: 50%;
    background: $negative-100;
    height: 20px;
    width: 20px;
    text-align: center;
    color: white;
    line-height: 20px;
    position: absolute;
    right: -5px;
    top: 18px;
    font-size: 11px;
    &_green {
      background-color: $primary-100; } } }
