@import "../../../styles/theme";

.user-avatar-edit-form {
  display: unset !important;
  &__modal {
    &_cropMode {
      padding:  40px 0 32px 0 !important; } }
  &__footer {
    &_cropMode {
      padding: 0 32px !important; } }
  &__image-zone {
    width: 100%;
    &-buttons {
      position: absolute;
      padding: 8px;
      display: flex; }
    img {
      width: 100%; } }
  &__label {
    font-size: 12px;
    line-height: 14px;
    color: #666666;
    text-align: center;
    padding-top: 15px;
    margin-bottom: 25px; }
  &__action {
    display: flex;
    width: 100%;
    &-cancel {
      cursor: pointer;
      margin: 0 20px 0 auto; }
    &-save {
      cursor: pointer;
      color: $primary-100;
      margin-right: 20px; } }
  & .ReactCrop {
      width: 100%;
      border-radius: 0 !important;
      &__drag-handle::after {
        width: 21px;
        height: 21px;
        border: unset;
        background: unset; }
      &__image {
        border-radius: 0 !important; }
      &__crop-selection {
        border: unset !important;
        border-image-source: unset !important;
        border-radius: 50%;
        box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.6) !important; }
      & .ord-nw::after {
        border-left: 9px solid $secondary-15;
        border-top: 9px solid $secondary-15; }
      & .ord-ne::after {
        border-right: 9px solid $secondary-15;
        border-top: 9px solid $secondary-15; }
      & .ord-se::after {
        border-right: 9px solid $secondary-15;
        border-bottom: 9px solid $secondary-15; }
      & .ord-sw::after {
        border-left: 9px solid $secondary-15;
        border-bottom: 9px solid $secondary-15; } } }
