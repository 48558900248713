.client-card-documents {
  flex-grow: 1;
  display: flex;
  flex-shrink: 0;
  position: relative;
  flex-direction: column;
  &__tabs {
    margin: 25px 0 20px 0; }
  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center; } }
