@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.advertisement-contractor-card {
  display: flex;
  &__rating-row {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-wrap: wrap;
      flex-direction: row; } }
  &__check {
    width: 18px;
    height: 18px; }
  &__rating-row-item {
    margin-bottom: 6px;
    position: relative;
    margin-right: 8px;
    width: 100%;
    @include respond-to(md) {
      width: auto; }
    @include respond-to(xxl) {
      margin-bottom: 2px; } }

  &__actions {
    display: flex;
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
    &-button {
      &:not(:last-child) {
        margin-right: 15px; }
      & .nm-tooltip {
        top: 50px; } } } }
