@import "../../styles/theme";
@import "../../styles/mixins";

.logo-card-block {
  $block: &;

  display: block;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px; }

  &__icon {
    cursor: pointer;

    &:not(:last-child) {
      margin-right: 17px; }

    &_edit {
      color: $secondary-45;
      &:hover {
        color: $secondary-70; } }

    &_check {
      color: $passive-100;
      font-size: 18px;
      width: 18px;
      height: 18px;
      cursor: pointer; } }

  &__logo {
    width: 128px; }

  &__preview {
    width: 128px;

    &_clickable {
      cursor: zoom-in; } }

  &__original-img {
    max-width: 1000px; } }
