@import "../../../styles/mixins";

.select-variants-table {
  $block: &;

  &__header {
    display: flex;
    margin-bottom: 12px;
    position: relative;
    @include respond-to(md) {
      margin-bottom: 16px; } }

  &__description {
    display: flex;
    flex-grow: 1; }

  &__variant {
    margin-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    min-width: 45px;
    @include respond-to(md) {
      width: 80px;
      min-width: 80px; }
    &_title {
      width: auto;
      min-width: auto;
      @include respond-to(md) {
        width: 80px;
        min-width: 80px; } } }

  &__row {
    display: flex;
    align-items: center; }

  &__name {
    flex-grow: 1; } }
