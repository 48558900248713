@import "../../../styles/mixins";

.filter-buttons-v2 {
  display: flex;
  align-items: flex-end;
  width: 100%;
  @include respond-to(md) {
    width: auto; }

  &__clear {
    width: 50%;
    @include respond-to(md) {
      width: auto; }
    &_onlyClear {
      width: 100%;
      @include respond-to(md) {
        width: auto; } } }

  &__submit {
    margin-right: 10px;
    width: 50%;
    @include respond-to(md) {
      width: auto; } } }
