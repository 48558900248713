@import "../../../../../styles/theme";

.document-management-statement-item {
  &__card-icon {
    &_in-progress {
      color: $inert-100; }

    &_on-signing {
      color: $inert-100; }

    &_signed {
      color: $primary-100; }

    &_rejected {
      color: $negative-100; } }

  &__tooltip {
    margin-left: 4px;
    display: inline; } }
