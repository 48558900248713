
@import "../../../../styles/theme";

.document-management-statements-list {
  flex-grow: 1;
  &__unload {
    margin-left: 20px; }
  &__actions {
    width: 40px; }
  &-participants {
    display: flex;
    align-items: center;
    &__item {
      &:not(:last-child) {
        margin-right: 8px; } } }

  &__status {
    display: flex;
    align-items: center;
    border-radius: 7px;
    color: $color-white;
    padding: {
      top: 4px;
      right: 9px;
      bottom: 4px;
      left: 10px; }

    &_on-signing {
      background-color: $inert-100; }

    &_signed {
      background-color: $primary-100; }

    &_rejected {
      background-color: $negative-100; } }

  &__open-check {
    width: 100%;
    display: flex;
    justify-content: flex-end; }

  &__links {
    flex-grow: 1;
    margin-right: 47px;
    min-width: 47%;
    max-width: 47%; }

  &__advanced-popup {
    right: -9px;
    width: max-content;
    max-width: 350px;
    opacity: 1 !important; }

  &__prompt {
    margin-right: 10px; }

  &__controls {
    display: flex;
    align-items: center; }

  &__icon {
    height: 32px; }

  &__box {
    border: 1px solid $secondary-15;
    border-radius: 7px;
    padding: 12px 15px;
    margin-right: 47px;
    min-width: 160px;

    &-label {
      color: $secondary-70;
      font-size: 12px;
      margin-bottom: 5px; }

    &-value {
      color: $secondary-90;
      font-size: 16px;
      line-height: 19px;
      font-weight: bold; } }

  &__row {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 21px 0 21px 33px; }

  &__client {
    font-size: 14px;
    line-height: 18px;
    color: $passive-100;
    margin-bottom: 21px;
    display: block; }

  &-table {
    &__actions {
      margin-left: 17px; }
    &__checkbox {
      & > input[type=checkbox] {
        border: 2px solid $secondary-15 !important;
        border-radius: 3px !important; } } }


  &__button {
    border: 1px solid $secondary-15;
    background-color: $color-white;
    border-radius: 7px;
    padding: 11px 16px;
    height: 40px;
    color: $secondary-70;
    cursor: pointer;
    font-size: 14px;
    transition: background .1s ease, color .1s ease;

    &:focus {
      outline: none;
      border: 1px solid $secondary-15; }

    &_ml-10 {
      margin-left: 10px; }

    &_normal {
      color: $secondary-70;
      border: 1px solid $secondary-15;
      background: $color-white;

      &:hover {
        background: $color-white; }

      &:focus {
        background: $color-white; } } }

  &__info {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3px; }

  &__label {
    margin-right: 5px;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    color: $secondary-70; }


  &__value {
    color: $passive-100;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &:hover {
      color: $passive-100; } } }
