@import "../../../styles/theme";
@import "../../../styles/mixins";

.contractor-general-info {
  @include respond-to(xxl) {
    border: 1px solid $secondary-5;
    border-radius: 6px;
    padding: 16px; }
  &__refresh-button {
    font-size: 20px; }
  &__client-group-info {
    max-width: 316px;
    .nm-selected-list__showed-list-item {
      font-size: 14px; } }
  &__list-item {
    font-size: 14px; }
  &__dates-item {
    margin-bottom: 14px;
    color: $secondary-70;
    display: flex; }
  &__edit-icon {
    cursor: pointer;
    margin-left: 4px;
    color: $secondary-45;
    width: 18px;
    height: 18px;
    &:hover {
      color: $secondary-70; }
    @include respond-to(xxl) {
      width: 20px;
      height: 20px; } }
  &__client-group-label {
    font-size: 12px!important;
    line-height: 18px;
    @include respond-to(xxl) {
      font-size: 14px!important;
      line-height: 20px; } }
  &__reward-ws-icon {
    color: $secondary-45;
    cursor: pointer;
    &_active {
      color: $passive-100; } }
  &__reward-icon {
    color: $secondary-45;
    cursor: pointer;
    &_active {
      color: $inert-100; } }
  &__project-params {
    width: unset !important; }
  &__note-text {
    color: $secondary-70;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    @include respond-to(xxl) {
      color: $secondary-100;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px; } }
  &__note {
    &-actions-icon {
      cursor: pointer;
      color: $secondary-45;
      width: 18px;
      height: 18px;
      &:hover {
        color: $secondary-70; }
      @include respond-to(xxl) {
        width: 20px;
        height: 20px; } } } }
