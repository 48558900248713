@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.chat-header {
  padding: 0;
  background-color: $color-white;
  @include respond-to(md) {
    background: $secondary-5;
    padding: 22px 24px 0 24px; }
  @include respond-to(xxl) {
    padding: 34px 48px 0 48px; }

  &__container {
    align-items: center;
    display: flex;
    padding: 12px 16px;
    border-bottom: 1px solid $secondary-10;
    @include respond-to(md) {
      padding: 0 0 24px 0; } } }
