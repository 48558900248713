.time-sheet-chart {
  width: 55%;
  margin: 0;

  &__layer {
    display: flex;
    flex-direction: row;
    justify-content: center; }

  &__satellite {
    background: #fff;
    border-radius: 10px;
    height: fit-content;
    &-title {
      font-size: 18px;
      line-height: 21px;
      padding: 20px 40px;
      white-space: nowrap; }
    &-content {
      max-height: 575px;
      overflow-y: auto; } } }

.contractors-item {
    border-top: 2px solid rgba(34, 36, 38, 0.15);
    padding: 15px 40px;
    &__fio {
      font-size: 18px;
      color: #128A10;
      white-space: nowrap; }
    &__phone {
      color: rgba(34, 36, 38, 0.5);
      white-space: nowrap; } }
