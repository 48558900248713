@import "../../../../styles/theme";
@import "../../../../styles/mixins";

.onboarding-scenario-card {
  &__label-text {
    margin-bottom: 2px; }
  &__hint-text {
    color: $passive-100;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer; }
  &__selected-list {
    width: unset;
    &-item {
      font-size: 14px;
      line-height: 20px; } } }
