@import "../../../../styles/mixins";

.self-employed-indicator-icon {
  width: 18px;
  height: 18px;
  min-width: 18px;
  @include respond-to(md) {
    width: 20px;
    height: 20px;
    min-width: 20px; }

  &_clear {
    width: unset;
    height: unset;
    min-width: unset; } }
