@import "../../styles/theme";
.fns-service {
  flex-grow: 1;
  &__method {
    color: $passive-100; }
  &-list {
    padding: 0; }
  &-table-row {
    &:hover {
      &:hover {
        border: 0;
        background: $color-white;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
        border-radius: 7px;
        cursor: pointer; } }
    border-top: 1px solid $secondary-15;
    padding: {
      left: 20px;
      top: 18px;
      bottom: 18px; } } }

