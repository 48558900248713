@font-face {
    font-family: "Montserrat";
    src: url("./Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("./Montserrat-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("./Montserrat-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Montserrat";
    src: url("./Montserrat-ExtraBold.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
}