.icon-text-app {
  display: flex;

  &_text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    word-break: normal; }

  &_icon {
    width: 15px; }

  &>div:not(:last-child) {
    margin-right: 12.5px; } }
