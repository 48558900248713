@import "../../../../styles/mixins";

.tabs-button {
  display: none;
  @include respond-to(md) {
    display: flex; }
  &_first {
    margin-right: 8px; }
  &_last {
    margin-left: 8px; }
  &_primary {
    width: 24px;
    min-width: 24px;
    height: 24px; }
  &__icon {
    &_first {
      transform: rotate(90deg); }
    &_last {
      transform: rotate(-90deg); } } }
