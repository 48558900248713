@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.settings-directories-banks-details {
  &__label {
    margin-bottom: 4px; }
  &__available-wallet-label {
    width: 80% !important;
    @include respond-to(md) {
      width: unset !important;
      white-space: nowrap !important; } } }
