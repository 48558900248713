$iconColor: #D2D2D2;

.brigade-payments-filter {
  display: flex;

  &__input {
    width: 280px; }

  &__icon {
    position: absolute;
    transform: rotate(90deg);
    top: 0;
    bottom: 0;
    margin: auto;
    right: 14px;
    color: $iconColor; } }

