@import "../../../styles/theme";
@import "../../../styles/mixins";

.nm-checkbox-v2 {
  $block: &;

  position: relative;
  display: flex;

  &_itemsCenter {
    align-items: center; }

  &:hover {
    & #{$block}__suggestion {
      display: block; } }

  &__suggestion {
    display: none; }

  &__checkbox {
    display: none; }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-70;
    margin-left: 6px;
    margin-top: 3px;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; } }

  &__additional-label-content {
    display: flex; }

  &__help {
    margin-top: 3px;
    margin-left: 5px; }

  &__box {
    $block: &;
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    margin: initial;
    &:before {
      content: '';
      display: block;
      width: 18px;
      margin: 3px;
      height: 18px;
      min-width: 18px;
      border: 2px solid $secondary-75;
      border-radius: 3px;
      cursor: pointer; }
    &:hover:before {
      border-color: $primary-100; }
    &_toggle#{$block} {
      position: relative;
      align-items: center;

      & > #{$block}__label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: $secondary-45;
        line-height: 24px; }

      &:before {
        width: 32px;
        min-width: 32px;
        height: 20px;
        background-color: $secondary-45;
        border-radius: 32px;
        margin: 0;
        padding: 2px;
        border: none;
        transition: 0.3s linear;
        transition-property: background-color; }

      &:after {
        content: '';
        transition: 0.3s linear;
        transition-property: left;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        position: absolute;
        left: 2px;
        background-color: $color-white; }

      &#{$block}_duplex {
        &:before {
          background-color: $primary-100; } } }

    &_disabled {
      cursor: initial;
      &#{$block} {
        &:before {
          border-color: $secondary-15;
          cursor: initial; }

        & > #{$block}__label {
          color: $color-grey-8; } }

      &#{$block}_toggle {
        &:before {
          opacity: 0.48; }

        & > #{$block}__label {
          color: $secondary-15; } } }

    &_checked {
      &:before {
        background-color: $primary-100;
        background-position: center;
        background-size: cover;
        background-image: url("../../../images/done.svg");
        border-color: $primary-100; }

      & > #{$block}__label {
        color: $secondary-100; }

      &#{$block}_disabled {
        &:before {
          background-color: $secondary-15; }

        & > #{$block}__label {
          color: $secondary-70; } }

      &#{$block}_toggle {
        &:before {
          background-color: $primary-100;
          background-image: none; }

        &:after {
          left: 14px; }

        & > #{$block}__label {
          color: $primary-100; } }

      &#{$block}_indeterminate {
        &:before {
          background-color: $color-white;
          border-color: $secondary-75;
          background-image: url("../../../images/done_indeterminate.svg"); } } }

    &_error {
      &:before {
        border-color: $negative-100; }
      &:hover {
        &:before {
          border-color: $negative-100; } }

      &#{$block}_checked {
        &:before {
          background-color: $negative-100; } }

      & > #{$block}__label {
        color: $negative-100; } } } }
