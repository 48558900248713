@import "../../styles/theme";
@import "../../styles/mixins";

.nmx-grid-table {
  $block: &;

  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  @include respond-to(md) {
    font-size: 14px;
    line-height: 20px; }

  &_scrollable-container {
    overflow-x: auto;
    height: 100%; }

  &__tooltip {
    margin-left: 10px; }

  &__row-layer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgba(255,255,255,.55);
    &_disabled {
      background: rgba(255, 255, 255, 0.8); }
    &-message {
      background: rgba(0, 0, 0, 0.8);
      width: 270px;
      border-radius: 6px;
      text-align: center;
      color: #fff;
      padding: 10px;
      font-size: 13px;
      line-height: 16px; } }
  & .nmx-suggestion {
    width: 265px;
    left: -131px; }
  &__help {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -2px;
    cursor: pointer;
    margin-left: 10px;
    &-color {
      background: url("../../images/help-color.svg") no-repeat left center; }
    &-gray {
      background: url("../../images/help.svg") no-repeat left center; } }
  &__help-block {
    position: absolute;
    cursor: pointer;
    padding: 20px;
    z-index: 2;
    background: #FFFFFF;
    border-radius: 7px;
    top: 20px;
    left: 0;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
    .close {
      top: 5px;
      position: absolute;
      right: 5px; } }
  @media screen and (max-width: 1500px) {
    font-size: 0.85em;
    line-height: 16px; }
  &__header {
    color: $secondary-70;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    background: $secondary-5; }
  &__row {
    background: $color-white;
    &:hover {
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.25);
      z-index: 10; }
    &_last .nmx-suggestion {
      bottom: calc(100% + 15px);
      top: auto;
      &:before {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 10px solid $color-black;
        bottom: -10px;
        left: 50%;
        top: auto;
        border-bottom: 0; } } }
  &__column {
    word-break: break-word !important;
    &_center {
      justify-content: center; }
    &_left {
      justify-content: flex-start; }
    &_right {
      justify-content: flex-end; } }
  & .ui.grid {
    margin: 0;
    & > .row {
      padding: 8px 0;
      @include respond-to(md) {
        padding: 10px 0; }
      &#{$block}__header {
        padding: 8px 0;
        @include respond-to(xxl) {
          padding: 16px 0; } } }

    & > .row > .column {
      padding: 0 8px;
      @include respond-to(md) {
        padding: 0 12px; } } }
  & .row {
    margin: 0;
    &:not(:last-child) {
      border-bottom: 1px solid $secondary-15; }
    &:not(#{$block}__header) .column {
      @include respond-to(md) {
        min-height: 52px; } }

    & .column {
      display: flex!important;
      align-items: center; } } }

.grid-checkbox {
  &_overflowX {
   min-width: 1610px; } }

.contractor-list-table-div {
  & .row .column {
    &:nth-child(1) {
      width: 10%!important; }
    &:nth-child(2) {
      width: 15%!important; }
    &:nth-child(3) {
      width: 9%!important; }
    &:nth-child(4) {
      width: 25%!important; }
    &:nth-child(5) {
      width: 25%!important; }
    &:nth-child(6) {
      width: 5%!important; }
    &:nth-child(7) {
      width: 5%!important; }
    &:nth-child(8) {
      width: 5%!important; } } }

.projects-order-list__table {
  & .nmx-grid-table__header,
  .nmx-grid-table__row {
    & .column {
      &:nth-child(1) {
        width: 35% !important; }
      &:nth-child(2) {
        width: 15% !important; }
      &:nth-child(3) {
        width: 30% !important; }
      &:nth-child(4) {
        width: 10% !important; }
      &:nth-child(5) {
        width: 10% !important;
        & > div {
          justify-content: flex-end !important; } } } } }

.projects-object-list__table {
  & .nmx-grid-table__header,
  .nmx-grid-table__row {
    & .column {
      &:nth-child(1) {
        width: 20% !important; }
      &:nth-child(2) {
        width: 15% !important; }
      &:nth-child(3) {
        width: 15% !important; }
      &:nth-child(4) {
        width: 30% !important; }
      &:nth-child(5) {
        width: 10% !important; }
      &:nth-child(6) {
        width: 10% !important;
        & > div {
          justify-content: flex-end !important; } } } } }
