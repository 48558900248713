@import "../../../styles/theme";
@import "../../../styles/mixins";

.cke-text-editor {
  $block: &;
  position: relative;

  & .ck-placeholder {
    &:before {
      color: $secondary-45!important; } }

  & .ck.ck-toolbar {
    padding: 0; }

  & .ck.ck-sticky-panel .ck.ck-sticky-panel__content {
    border-color: $secondary-15;
    padding: 2px 6px!important;
    border-top-left-radius: 7px!important;
    border-top-right-radius: 7px!important; }

  & .ck.ck-content {
    padding: 2px 8px;
    min-height: 147px;
    max-height: 450px;
    border-color: $secondary-15!important;
    background: $secondary-5!important;
    border-bottom-left-radius: 7px!important;
    border-bottom-right-radius: 7px!important;
    @include respond-to(md) {
      padding: 2px 16px; } }

  &_active {
    & .ck.ck-content {
      background: $color-white!important;
      border-color: $secondary-70!important; }
    & .ck.ck-sticky-panel .ck.ck-sticky-panel__content {
      border-color: $secondary-70; } }

  &_focused {
    & .ck.ck-sticky-panel .ck.ck-sticky-panel__content {
      border-color: $primary-100; }
    & .ck.ck-content {
      background: $color-white!important;
      border: 1px solid $primary-100!important; }
    &#{$block}_error .ck.ck-content {
      border: 1px solid $negative-100!important; } }

  &_error {
    & .ck.ck-content {
      background: $negative-15!important;
      border-color: $negative-100!important; }
    & .ck.ck-sticky-panel .ck.ck-sticky-panel__content {
      border-color: $negative-100!important; } }

  &_focused {
    & .ck.ck-content {
      box-shadow: none!important; } }

  &:hover:not(#{$block}_focused) {
    & .ck.ck-sticky-panel .ck.ck-sticky-panel__content {
      border-color: $primary-100!important; }
    & .ck.ck-content {
      border-color: $primary-100!important; } }

  & .ck-icon {
    color: red; }

  & .ck.ck-button {
    cursor: pointer;
    min-height: 24px;
    min-width: 24px;
    border: none;
    padding: 0;
    margin: 2px!important; }

  & .ck.ck-button.ck-on, a.ck.ck-button.ck-on {
    color: $primary-100;
    background: $primary-5;
    &:hover {
      background: $primary-5!important; } }

  &__wrapper {
    position: relative; }

  &__counter {
    position: absolute;
    right: 5px;
    font-size: 11px;
    line-height: 13px;
    color: $secondary-40;
    bottom: 2px;
    @include respond-to(md) {
      bottom: 4px; } } }
