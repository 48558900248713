@import "../../../styles/theme";

.dialog {
  padding: 16px;
  display: flex;
  align-items: flex-start;

  cursor: pointer;
  &:focus {
    opacity: 0.7; }

  &_active {
    background: $secondary-5; }

  &__avatar {
    margin-right: 6px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 34px;
    height: 34px;
    border-radius: 50px; }
  &__attached {
    color: $secondary-45;
    margin-left: 2px;
    &:focus {
      opacity: 0.8;
      cursor: pointer; } }

  &__name {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px; }

  &__primary-name {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2px; }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%; }

  &__last-message {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 274px;
    color: $secondary-45; }
  &__date {
    color: $secondary-70; }

  &__chat-info {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 274px;
    color: $secondary-90; } }
