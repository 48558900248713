@import "../../../../../styles/theme";

.order-card-disputes-card {
  padding: 24px 16px;
  position: relative;
  border: 1px solid $secondary-5;
  border-radius: 8px;
  &__container {
    font-size: 12px;
    line-height: 18px;
    display: flex;
    &:not(:last-child) {
      margin-right: 9px; } }
  &__row {
    display: flex;
    flex-wrap: wrap;
    &:not(:last-child) {
      margin-bottom: 5px; } }
  &__title {
    cursor: pointer;
    color: $passive-100;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 12px; }
  &__value {
    font-weight: 500;
    color: $secondary-100;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; }
  &__label {
    white-space: nowrap;
    margin-right: 5px;
    color: $secondary-75; }
  &__button {
    @media screen and (min-width: 768px) {
      position: absolute;
      top: 24px;
      right: 16px; } } }
