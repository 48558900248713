@import "../../styles/theme";
@import "../../styles/mixins";

.mass-confirm {
  $block: &;

  display: block !important;
  &__attention {
    color: $inert-100; }
  &__counters-container {
    display: flex;
    align-items: center;
    position: relative;
    &_ml {
      margin-left: 16px; } }
  &__counters {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include respond-to(md) {
      align-items: center;
      flex-direction: row; }
    & #{$block}__counters-container:last-child:not(:first-child) {
      margin-top: 8px;
      @include respond-to(md) {
        margin-top: 0;
        margin-left: 16px; } }

    &-item {
      &_color {
        &-primary {
          color: $primary-100; }
        &-inert {
          color: $inert-100; } } }
    &-tooltip {
      display: flex;
      align-items: center;
      margin-left: 8px; } }
  &__text {
    color: $secondary-45;
    &_mt {
      margin-top: 8px;
      @include respond-to(md) {
        margin-top: 16px; } } }
  &__list-text {
    color: $secondary-70;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  &__list {
    padding-inline-start: 0;
    margin-top: 8px;
    margin-bottom: 0;
    padding-right: 16px;
    max-height: 668px;
    overflow: auto;
    @include respond-to(md) {
      margin-top: 16px; }
    &-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 16px; }
      &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $secondary-5;
        border-radius: 50%;
        margin-right: 10px;
        min-width: 20px;
        min-height: 20px;
        width: 20px;
        height: 20px;
        &_color {
          &-primary {
            background-color: $primary-100; }
          &-inert {
            background-color: $inert-70; } }
        &-elem {
          max-width: 16px;
          overflow: hidden;
          color: $color-white; } } } } }
