@import "../../../styles/theme";
@import "../../../styles/mixins";

.client-member-card {
  padding: 25px;
  &-window {
    box-shadow: 0 4px 30px rgba(0,0,0,.15);
    margin-top: 15px;
    padding: 15px;
    &-close {
      position: absolute;
      right: 37px; } }
  &_full-name {
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    margin-left: 19px; }
  &-header {
    @include flex_row;
    align-items: center;
    &_icon {
      color: $secondary-45;
      cursor: pointer;
      width: 20px;
      height: 20px;

      &:hover {
        opacity: 0.6; } } }

  &-body {
    padding-top: 24px;
    border-bottom: 1px solid $secondary-10;
    padding-bottom: 41px;

    &_label {
      font-size: 14px;
      line-height: 16px;
      color: $color-black; }

    &-btn {
      &-save {
        &__text {
          font-family: $font-family-default;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: $color-black; } }

      &_password {
        min-width: 106px !important;
        margin-top: 30px !important;
        background-color: inherit !important;
        border: 1px solid $passive-100 !important;
        color: $passive-100 !important;
        font-style: normal !important;
        font-weight: normal !important;
        font-size: 14px !important; } } }

  &-footer {
    padding-top: 27px; } }

.password-block {
  &__icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 10px;
    bottom: 0;
    margin: auto; } }
