@import "../../../../../styles/theme";
@import "../../../../../styles/mixins";

.order-edit-publish {
  $block: &;

  &__show-on-map {
    color: $passive-100;
    margin-top: 2px;
    text-decoration: underline; }

  &__sum-value {
    color: $primary-100;
    font-size: 18px;
    line-height: 26px;
    font-weight: bold;
    @include respond-to(md) {
      font-size: 24px;
      line-height: 30px; }
    &_mobile {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px; } }

  &__content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    & #{$block}__column:first-child {
      margin-right: 16px; }
    @include respond-to(xl) {
      flex-direction: row; } }

  &__column {
    width: 100%;
    margin-bottom: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-to(md) {
      margin-bottom: 24px; }
    @include respond-to(xl) {
      margin-bottom: 0;
      width: 375px; }
    &:last-child {
      margin-bottom: 0; } }

  &__rel-container {
    position: relative; }

  &__responsive-row {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; }
    @include respond-to(xl) {
      flex-direction: column; } }

  &__header {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: $secondary-90;
    font-weight: 400;
    margin-bottom: 26px;
    @include respond-to(md) {
      font-size: 18px;
      line-height: 26px; }
    @include respond-to(xl) {
      font-size: 24px;
      line-height: 28px; } }

  &__description {
    width: 100%;
    color: $secondary-90;
    margin-bottom: 18px;
    padding: 16px;
    border: 1px solid $secondary-5;
    filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
    border-radius: 7px;
    position: relative;
    @include respond-to(md) {
      margin-bottom: 16px; }
    @include respond-to(xl) {
      padding: 8px 16px;
      border: 1px solid $secondary-15;
      margin-bottom: 20px;
      min-height: 98px; }
    &:before {
      width: 0;
      height: 0;
      position: absolute;
      z-index: 21;
      content: '';
      border: 8px solid transparent;
      border-top: 10px solid #E5E5E5;
      bottom: -18px;
      left: 50%; }
    &_full {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center; }
    &-head {
      text-align: center;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 8px;
      @include respond-to(md) {
        font-size: 14px;
        line-height: 20px; }
      @include respond-to(xl) {
        margin-bottom: 0; }
      &_no-mb {
        margin-bottom: 0; } }
    &-foot {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      @include respond-to(md) {
        font-size: 14px;
        line-height: 20px; }
      @include respond-to(xl) {
        text-decoration: underline; } } }

  &__card-app {
    padding: 16px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    border: none;
    border-radius: 8px;
    max-width: 375px;
    width: 100%;
    &_mobile {
      padding: 0; }
    &_full {
      padding-bottom: 22px;
      & #{$block}__card-app-name {
        width: 100%; } } }

  &__card-app-mobile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px 6px 16px;
    background-color: $secondary-5; }

  &__card-app-mobile-rating-value {
    font-size: 11px;
    line-height: 16px; }

  &__card-app-mobile-rating {
    & .ui.disabled.rating {
      & .icon:not(:first-child) {
        display: none; } } }

  &__card-app-mobile-client-name {
    margin-left: 6px;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    color: $secondary-70;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2; }

  &__card-app-mobile-content {
    position: relative;
    padding: 12px 16px 16px 16px; }

  &__tariff-indicator {
    font-size: 12px;
    line-height: 18px;
    color: $primary-100;
    &_mobile {
      font-size: 9px;
      line-height: 12px; } }

  &__sum-row {
    font-size: 12px;
    text-align: left;
    line-height: 18px;
    white-space: nowrap;
    font-weight: normal;
    color: $secondary-70;
    &_mobile {
      font-size: 9px;
      line-height: 12px; }
    @include respond-to(md) {
      text-align: right; } }

  &__address {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: $secondary-70; }

  &__response {
    background: $secondary-5;
    line-height: 20px;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    width: 100%;
    color: $secondary-45;
    border-radius: 7px;
    margin-top: 12px;
    padding: 10px;
    @include respond-to(xl) {
      margin-top: 16px; } }

  &__documentation {
    margin: 16px 0;
    color: $primary-100;
    background-color: $primary-5;
    border-radius: 7px;
    padding: 8px 12px 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 40px; }

  &__icon {
    color: $secondary-70; }

  &__read {
    color: $passive-100;
    font-size: 14px;
    line-height: 22px; }

  &__name {
    font-weight: 500;
    color: $color-black;
    word-break: break-word;
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 20px;
    &_mobile {
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 20px;
      padding-right: 100px; }
    @include respond-to(md) {
      font-size: 16px;
      line-height: 24px; } }

  &__sum {
    top: 14px;
    right: 14px;
    position: absolute;
    color: $primary-100;
    &_fixed {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end; }
    &_un-fixed {
      display: flex;
      flex-direction: column;
      position: unset;
      font-size: 18px;
      line-height: 16px;
      margin: 13px 0;
      @include respond-to(md) {
        flex-direction: row;
        align-items: center;
        justify-content: space-between; }
      @include respond-to(xl) {
        margin: 16px 0; } } }

  &__sum-stats {
    margin-top: 8px;
    @include respond-to(md) {
      margin-top: 0; } }

  &__amount {
    font-size: 18px;
    line-height: 26px;
    white-space: nowrap;
    color: $primary-100;
    @include respond-to(md) {
      font-size: 24px;
      line-height: 30px; } }

  &__period {
    color: $secondary-70;
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700; }

  &__keywords {
    margin-top: 8px;
    gap: 5px;
    flex-wrap: wrap;
    display: flex; }

  &__keyword {
    background-color: $secondary-5;
    padding: 4px 7px;
    font-size: 9px;
    line-height: 12px;
    color: $secondary-45;
    border: 1px solid $secondary-5;
    border-radius: 3px;
    &:last-child {
      margin-right: 0; }
    &_no-mob {
      font-size: 12px;
      line-height: 13px; } }

  &__content {
    font-size: 14px;
    line-height: 16px;
    color: $color-black;
    word-break: break-word; }

  &__speciality-name {
    white-space: nowrap;
    margin-right: 2px; }

  &__speciality-value {
    font-weight: 700;
    word-break: break-word; }

  &__speciality {
    display: flex;
    flex-wrap: wrap;
    color: $secondary-70;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px; } }
