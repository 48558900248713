@import "../../styles/theme";

.nm-readonly-rating {
  display: flex;
  align-items: center;
  width: fit-content;
  & .ui.disabled.rating {
    font-size: 18px;
    line-height: 18px; }
  &__value {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: $secondary-90;
    margin-right: 6px;
    &_reverse {
      margin-right: 0;
      margin-left: 6px; } }
  & .ui.star.rating .icon.selected {
    color: $inert-70!important;
    text-shadow: 0 -1px 0 $inert-100,-1px 0 0 $inert-100,0 1px 0 $inert-100,1px 0 0 $inert-100!important;
    &.active {
      color: $inert-70!important;
      text-shadow: 0 -1px 0 $inert-100,-1px 0 0 $inert-100,0 1px 0 $inert-100,1px 0 0 $inert-100!important; } }
  & .ui.star.rating .active.icon {
    text-shadow: none!important;
    color: $inert-100!important; }

  &_reverse {
    flex-direction: row-reverse; }

  &_mini {
    & .ui.disabled.rating {
      font-size: 15px;
      line-height: 15px; } } }
