@import "../../styles/theme";
@import "../../styles/mixins";

.act-creation {
  $block: &;

  background: #fff;
  width: 100%;
  position: relative;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  margin: 0 auto;
  @include respond-to(md) {
    width: 100%;
    max-width: 940px; }

  &__description-container {
    @include respond-to(md) {
      padding-right: 0;
      width: calc(62.5% + 18px); } }

  &__stats-container {
    @include respond-to(md) {
      width: calc(37.5% - 18px);
      border-left: 1px solid $secondary-15; } }

  &__stats {
    padding: 0;
    height: 100%;
    margin-top: 16px;
    @include respond-to(md) {
      margin-top: 0;
      padding: 16px 6px; } }

  &__description {
    height: 100%;
    font-size: 14px;
    line-height: 20px;
    color: $secondary-100;
    overflow-wrap: break-word;
    width: 100%;
    padding: 16px;
    overflow: auto;
    max-height: 171px;
    border: 1px solid $secondary-70;
    border-radius: 7px;
    @include respond-to(md) {
      padding: 16px 0;
      border-radius: unset;
      border: none; } }

  &__form {
    width: 100%; }

  &__info {
    border-radius: 7px;
    display: flex;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    flex-direction: column;
    @include respond-to(md) {
      border: 1px solid $secondary-15;
      min-height: 95px;
      max-height: 172px;
      flex-direction: row;
      margin: 0 0 16px 0; }
    @include respond-to(xxl) {
      margin: 0 0 24px 0; } }

  &__info-row {
    font-weight: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &:not(:last-child) {
      margin-bottom: 6px;
      @include respond-to(xxl) {
        margin-bottom: 8px; } }
    &_volume {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: unset;
      @include respond-to(md) {
        align-items: center;
        flex-direction: row; } }
    &_input {
      margin-bottom: 12px;
      @include respond-to(xxl) {
        margin-bottom: 8px; } } }

  &__info-label {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-75;
    margin-right: 8px;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; }
    &_fixed {
      min-width: 147px;
      width: 60%; }
    &_calculate {
      color: $secondary-70;
      @include respond-to(xxl) {
        color: $secondary-75; } } }

  &__rating {
    display: flex;
    flex-direction: column; }

  &__info-value {
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    color: $secondary-100;
    font-weight: 500;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px; }
    &_fixed {
      flex-grow: 1; }
    &_calculate {
      color: $secondary-80;
      font-weight: normal;
      @include respond-to(xxl) {
        color: $secondary-90;
        font-weight: 500; } } }


  &__divider {
    width: 100%;
    height: 1px;
    background-color: $secondary-5;
    margin: 16px 0;
    &_mobile {
      display: block;
      @include respond-to(md) {
        display: none; } } }

  &__h {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-90;
    @include respond-to(md) {
      margin-bottom: 4px; } }

  &__volume-sum {
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px; }

  &__volume-unit {
    position: absolute;
    right: -15px;
    top: 27px; }

  &__amount {
    width: 100%;
    margin: 0!important;
    & input {
      padding-right: 45px!important; } }

  &__volume-field {
    width: 100%;
    @include respond-to(xxl) {
      width: 260px; } }

  &__calc-btn {
    margin-top: 16px;
    justify-content: center;
    width: 100%; }

  &__contractor-info {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      font-size: 14px;
      line-height: 20px;
      flex-direction: row; } }

  &__contractor-info-value {
    margin-bottom: 4px;
    margin-right: 0;
    @include respond-to(md) {
      margin-right: 16px;
      &:last-child {
        margin-bottom: 12px;
        @include respond-to(md) {
          margin-bottom: 16px; } } } }

  &__calculate-label {
    font-size: 12px;
    line-height: 18px;
    color: $secondary-100;
    @include respond-to(md) {
      color: $secondary-75;
      font-size: 14px;
      line-height: 20px; }
    @include respond-to(xxl) {
      font-size: 16px;
      line-height: 24px; } }


  &__calculate-value {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $secondary-100; }

  &__calculate {
    box-shadow: 0px -1px 34px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    background-color: $color-white;
    margin-left: 0;
    flex-grow: 1;
    width: 100%;
    padding: 12px;
    margin-top: 16px;
    @include respond-to(md) {
      margin-top: 24px;
      padding: 16px 24px; }
    @include respond-to(xxl) {
      padding: 24px;
      width: auto;
      margin-top: 0; } }


  &__block {
    width: 100%;

    .nm-textarea-counter_fluid-height {
      height: unset; } }

  &__header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px; }

  & .h2 {
    margin-bottom: 20px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
      font-size: 20px; } } }
