@import "../../../styles/theme";
@import "../style";

.footer {
  background: $open-naimix-secondary-100;
  color: $color-white;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 16px;
  @include respond-to(md) {
    padding: 24px; }
  @include respond-to(xxl) {
    padding: 31px 0; }
  &_promoPoisk {
    background-color: $promo-poisk-color-4; }

  &__copyright {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    @include respond-to(xxl) {
      margin-bottom: 0; } }

  &__privacy {
    color: $color-white;
    margin-bottom: 8px;
    &:hover {
      color: $color-white; }
    @include respond-to(md) {
      margin-bottom: 0;
      margin-right: 96px; }
    @include respond-to(xxl) {
      margin-bottom: 0;
      margin-right: 20px; } }

  &__agreement {
    color: $color-white;
    &:hover {
      color: $color-white; } }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include respond-to(xxl) {
      flex-direction: row;
      justify-content: space-between;
      width: 1320px; } }

  &__links {
    font-weight: normal;
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row; } } }
